import React from "react";
import {
  Col,
  Row,
  Input,
  Button,
  DatePicker,
  Select,
  Form,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { COUNTRYCODES } from "../Constants/COUNTRYCODES";
import AUTH_API from "./../../api/auth";
import "./Onboardingbase.css";
import PrivacyTerms from "./PrivacyTerms";
const { Option } = Select;

const OPTIONS = COUNTRYCODES.map((item, index) => (
  <Option key={index} value={item.code + " " + item.dial_code}>
    {item.code + " " + item.dial_code}
  </Option>
));
const COUNTRIES = COUNTRYCODES.map((item, index) => (
  <Option key={index} value={item.name}>
    {item.name}
  </Option>
));

const validateMessages = {
  required: "Required Field",
  types: {
    email: "This is not valid Email!",
  },
};

class Step1 extends React.Component {
  state = {
    dob: null,
    show_privacy: false,
    country_code: "",
  };
  onChange = (date, dateString) => {
    this.setState({
      dob: dateString,
    });
  };
  onCountryChange = (value) => {
    this.state.country_code = value;
  }


  onClick = (values) => {
    values.country_code = values.country_code.split(" ")[1];
    const data = { ...values, dob: this.state.dob };
    console.log("data:", data);
    if (this.state.show_privacy) {
      AUTH_API.registerUser(data)
        .then((response) => {
          console.log("response !!", response);
          if (response.data.Response.user_key === "false") {
            message.success("Registered successfully");
            this.props.onclicknext(values.phone, values.country_code);
          } else {
            message.error("User already registered, Redirecting to Sign in ");
            // this.props.onclicknext(values.phone,values.country_code);
            setTimeout(function () {
              window.location.href = "/signin";
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          //   message.error('Error')
        });
    } else {
      this.setState({
        show_privacy: true,
      });
    }
  };

  render() {
    return (
      <div style={{ width: "48%", margin: "10% 0 10% 29%" }}>
        {!this.state.show_privacy && (
          <h3 style={{ font: "normal normal 600 24px/80px AvenirBook" }}>
            Create your account
          </h3>
        )}
        <Form
          name="signup_form"
          onFinish={(values) => {
            this.onClick(values);
          }}
          validateMessages={validateMessages}
          style={{}}
        >
          <div style={this.state.show_privacy ? { display: "none" } : null}>
            <Row>
              <Col span={11}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  First Name *
                </p>
                <Form.Item
                  name="first_name"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ textAlign: "left" }} 
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Last Name *
                </p>
                <Form.Item
                  name="last_name"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ textAlign: "left" }} 
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <p
              style={{
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Email address *
            </p>
            <Form.Item
              name="email"
              rules={[{ required: true, type: "email" }]}
              validateTrigger={["onBlur"]}
              style={{ textAlign: "left" }} 
            >
              <Input size="large" style={{ marginBottom: "5%" }} />
            </Form.Item>

            {/* <Input.Group size="large"> */}
            <Row style={{ marginBottom: "5%" }} gutter={12}>
              <Col span={10}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Country Code*
                </p>
                <Form.Item
                  name="country_code"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ textAlign: "left" }} 
                >
                  <Select
                    style={{}}
                    size="large"
                    // options={OPTIONS}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.onCountryChange}
                  >
                    {/* {COUNTRIES.map((item,index)=>
                                    <Option key={index} value={item.dial_code}>{item.code}&nbsp;{item.dial_code}</Option>
                                    )} */}
                    {/* <Option value="US">USA(01)</Option>
                                    <Option value="IND">IND(+91)</Option> */}
                    {OPTIONS}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={14}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Phone number *
                </p>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      pattern: /^\d+$/,
                      message: "Input only numbers",
                    },
                    {
                      message: 'Phone number should not include the country code',
                      validator: (_, value) => {

                        const countryCode = this.state.country_code.split(" ")[1];
                        if (!value.startsWith(countryCode.replace("+", ""))) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('Some message here');
                        }
                       }
                     }
                  ]}
                  style={{ textAlign: "left" }} 
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            {/* </Input.Group> */}

            <p
              style={{
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Date of Birth *
            </p>
            <div style={{ textAlign: "left", marginBottom: "40px" }}>
              <Form.Item
                name="dob"
                rules={[{ required: true, message: "Required Field" }]}
                style={{ textAlign: "left" }} 
              >
                <DatePicker
                  size="large"
                  style={{ width: "50%" }}
                  onChange={this.onChange}
                />
              </Form.Item>
            </div>

            <p
              style={{
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: 20,
              }}
            >
              Location Details
            </p>
            <Row gutter={12} style={{ marginBottom: 20 }}>
              <Col span={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  City*
                </p>
                <Form.Item
                  name="city"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ textAlign: "left" }} 
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  State*
                </p>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="state"
                  style={{ textAlign: "left" }} 
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <p
              style={{
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Country*
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name="country"
              style={{ textAlign: "left" }} 
            >
              {/* <Input size="large" style={{marginBottom:30}} /> */}
              <Select
                style={{}}
                size="large"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {COUNTRIES}
              </Select>
            </Form.Item>

            <Link to={{ pathname: "/signin" }}>
              <Button className="already-signin">
                Already registered? Sign in here !
              </Button>
            </Link>
            <Form.Item name="submit">
              <button
              style={{
                width: "100%",
                padding: "10px 20px",
                fontSize: "14px"
              }}
                size="large"
                type="primary"
                className="button-main"
                htmlType="submit"
                block
              >
                Next
              </button>
            </Form.Item>
          </div>
          {this.state.show_privacy && (
            <>
              <PrivacyTerms />
              <Row
                justify="space-between"
                style={{ width: "165%", marginLeft: "-35%", marginTop: "60px" }}
              >
                <Col span={7} style={{ textAlign: "left" }}>
                  <button
                    size="large"
                   style={{
                    width: "50%"
                   }}
                   className="button-secondary"
                    onClick={() => this.setState({ show_privacy: false })}
                    block
                  >
                    Back
                  </button>
                </Col>
                <Col span={10}></Col>
                <Col span={7} style={{ textAlign: "right" }}>
                  <Form.Item name="submit">
                    <button
                      size="large"
                      type="primary"
                     className="button-main"
                      htmlType="submit"
                      block
                      style={{
                        width: "50%"
                       }}
                    >
                      Accept
                    </button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {/* <Form.Item name="submit">
                        <Button size='large' type="primary" className="login-form-button" htmlType="submit" block>
                            Accept
                        </Button>
                    </Form.Item> */}
        </Form>
        {/* {!this.state.show_privacy && <p style={{margin:'7% 0 100px', fontSize:'11px'}}>By creating a Woken account, you're agreeing to accept the Woken terms of service Privacy Policy.
                </p>} */}
        <div style={{ height: "10px" }}></div>
      </div>
    );
  }
}
export default Step1;
