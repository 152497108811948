import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Row,
  Col,
  Select,
  Input,
  Tag,
  Form,
  Popconfirm,
} from "antd";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import "./Learning.css";
import { selectTargetRoles } from "slices/targetRolesSlice";
import { selectTargetIndustries } from "slices/targetIndustriesSlice";

import Selectinterest from "Components/Common/Selectinterest";
const { Option } = Select;
const { TextArea } = Input;


function tagRenderTR(props) {
  const { label, closable, onClose } = props;

  return (
    <Tag
      color={"white"}
      closable={closable}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "14px 5px",
        padding: "2px 15px",
        backgroundColor: "#8375E8",
      }}
    >
      {label}
    </Tag>
  );
}

function tagRenderTI(props) {
  const { label, value, closable, onClose } = props;

  return (
    <Tag
      color={"white"}
      closable={closable}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "14px 5px",
        padding: "2px 15px",
        backgroundColor: "#FFA86B",
      }}
    >
      {label}
    </Tag>
  );
}

class Addlearningmodal extends React.Component {
  formRef = React.createRef();

  componentDidMount() {}

  getIndustries = () => {
    return this.props.industries
      ? this.props.industries
          .filter((item) => item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };

  getRoles() {
    return this.props.roles
      ? this.props.roles
          .filter((item) => item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  }

  handleCancelFromModal = () => {
    this.props.handleCancel();
    this.formRef.current.resetFields();
  };

  onFinish = (val) => {
    console.log({ val });
    const data = {
      ...val,
      category: this.props.category,
    };
    this.props.onFinish(data, this.props.selected_id);
    this.handleCancelFromModal();
  };

  handleDelete = () => {
    this.props.handleDelete(this.props.selected_id);
    this.handleCancelFromModal();
  };

  onVal = (val, allval) => {
    // console.log(val, allval)
  };

  render() {
    // console.log('in modal', this.props.current_data.name?this.props.current_data:{interest_level: 1})
    const icon = <CloseOutlined className="close-icon" onClick={this.handleCancelFromModal} />;
    return (
      <Modal
        visible={this.props.visible}
        footer={null}
        width="796px"
        // closable={true}
        closeIcon={icon}
        placement="top"
      >
        {/* <div>
          <CloseCircleFilled
            onClick={this.handleCancelFromModal}
            className="close-btn-modal"
            style={{ margin: "-39px -37px -40px", cursor: "pointer" }}
          />
        </div> */}
        <Form
          onFinish={this.onFinish}
          ref={this.formRef}
          initialValues={
            this.props.current_data.name
              ? this.props.current_data
              : { interest_level: 1 }
          }
          onValuesChange={this.onVal}
        >
          <div>
            <p className="contact-modal-content">Name</p>
            <Form.Item name="name" rules={[{ required: true }]}>
              <Input size="large" className="extra-size" />
            </Form.Item>
          </div>

          <Row gutter={16}>
            <Col span={12}>
              <p className="contact-modal-content">Target Role</p>
              <Form.Item name="roles" className="extra-size">
                <Select
                  mode="tags"
                  showArrow
                  tagRender={tagRenderTR}
                  className="extra-size"
                  placeholder="Select Role"
                  style={{ width: "100%" }}
                  // options={[...this.getRoles()]}
                  size="large"
                >
                  {this.getRoles().map((item, index) => (
                    <Option value={item.title} key={index + 22}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <p className="contact-modal-content">Target Industry</p>
              <Form.Item name="industries" className="extra-size">
                <Select
                  mode="tags"
                  showArrow
                  tagRender={tagRenderTI}
                  className="extra-size"
                  placeholder="Select Role"
                  style={{ width: "100%" }}
                  // options={[...this.getIndustries()]}
                  size="large"
                >
                  {this.getIndustries().map((item, index) => (
                    <Option value={item.title} key={index + 43}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div>
            <p className="contact-modal-content">Useful Links</p>
            <Form.Item name="links">
              <TextArea size="large" className="extra-size" />
            </Form.Item>
          </div>

          <div>
            <p className="contact-modal-content">Additional Notes</p>
            <Form.Item name="notes">
              <TextArea size="large" className="extra-size" />
            </Form.Item>
          </div>

          <div>
            <p className="contact-modal-content">Interest Level</p>
            {/* <Form.Item name="interest_level" style={{width:'10%'}}> */}
            {/* <Select size='large' >
                      {Array.apply(null, {length: 10}).map((item, index)=>
                        <Option value={index+1} key={index+964}>{index+1}</Option>
                      )}
                    </Select> */}
            <div style={{ width: "12%", height: "48px", }}>
              <Selectinterest
                name="interest_level"
                selectedValue={
                  this.props.current_data.name
                    ? this.props.current_data.interest_level
                    : 1
                }
              />
            </div>

            {/* </Form.Item> */}
          </div>

          <Row style={{ margin: "20px 0 0" }}>
            <Col span={8}>
              <Popconfirm
                title="Are you sure you want to delete ?"
                onConfirm={() => {
                  this.handleDelete();
                }}
              >
                <button className="button-secondary" size="large" block style={{paddingLeft:"20px" , paddingRight:"20px"}}>
                  Delete
                </button>
              </Popconfirm>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <Form.Item name="save">
                <button className="button-main" style={{paddingLeft:"40px" , paddingRight:"40px",float:"right"}} type="primary" size="large" block htmlType="submit">
                  {" "}
                  Save{" "}
                </button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
  industries: selectTargetIndustries(state),
});

export default connect(mapStateToProps)(Addlearningmodal);
