import { Button, Col, Form, Input, Row, Select, Slider } from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "./../../api/auth";
import "./Onboardingbase.css";
const { Option } = Select;

export default class Step3 extends React.Component {
  formRef = React.createRef();
  state = {
    emp_questions: "",
    form_initialvalues: {},
    emp_type: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    AUTH_API.aboutYourselfGet()
      .then((response) => {
        const data = response.data;
        console.log(data);
        this.empSelect(data.employment_type);
        this.formRef.current.setFieldsValue({
          ...data,
        });
        this.setState({ emp_type: data.employment_type });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onFormChange = (values) => {
    console.log(values);
    const formdata = HELPERS.converToFormData(values);

    AUTH_API.aboutYourself(formdata)
      .then((response) => {
        console.log("response !!", response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  empSelect = (e) => {
    // console.log(e);
    if (e === "SU") {
      this.setState({
        emp_type: e,
        emp_questions: (
          <Row gutter={16} style={{ paddingTop: "50px" }}>
            <Col span={12}>
              <p style={{ textAlign: "left" }}>What school do you attend?</p>
              <Form.Item
                name="attended"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: "left" }}>What school year are you in?</p>
              <Form.Item
                name="attended_in"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Select style={{ width: "100%" }} size="large">
                  <Option value="Freshmen">Freshmen</Option>
                  <Option value="Sophomore">Sophomore</Option>
                  <Option value="Junior">Junior</Option>
                  <Option value="Senior">Senior</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ),
      });
    }
    if (e === "SG") {
      this.setState({
        emp_type: e,
        emp_questions: (
          <Row gutter={16} style={{ paddingTop: "50px" }}>
            <Col span={12}>
              <p style={{ textAlign: "left" }}>What school do you attend?</p>
              <Form.Item
                name="attended"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: "left" }}>What school year are you in?</p>
              <Form.Item
                name="attended_in"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Input size="large" style={{ width: "100%" }}></Input>
              </Form.Item>
            </Col>
          </Row>
        ),
      });
    }

    if (e === "WP" || e === "WF") {
      this.setState({
        emp_type: e,
        emp_questions: (
          <Row gutter={16} style={{ paddingTop: "50px" }}>
            <Col span={12}>
              <p style={{ textAlign: "left" }}>What is your role?</p>
              <Form.Item
                name="attended"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: "left" }}>What company are you at?</p>
              <Form.Item
                name="attended_in"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Input size="large" style={{ width: "100%" }}></Input>
              </Form.Item>
            </Col>
          </Row>
        ),
      });
    }
    if (e === "UP") {
      this.setState({
        emp_type: e,
        emp_questions: (
          <Row gutter={16} style={{ paddingTop: "50px" }}>
            <Col span={12}>
              <p style={{ textAlign: "left", fontSize: "16px" }}>What was your prior role?</p>
              <Form.Item
                name="attended"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: "left", fontSize: "16px" }}>What was your prior company?</p>
              <Form.Item
                name="attended_in"
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Input size="large" style={{ width: "100%" }}></Input>
              </Form.Item>
            </Col>
          </Row>
        ),
      });
    }
  };

  render() {
    // console.log(product.value)
    return (
      <div style={{ margin: "5% 0" }}>
        <div
          className="h"
          style={{
            fontSize: "30px",
            font: "normal normal 600 24px/44px AvenirBook",
          }}
        >
          Tell me a bit about yourself
        </div>

        <Form
          name="about_yourself_form"
          onFinish={this.props.onclicknext}
          onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
          ref={this.formRef}
        >
          <div>
            <Row justify="space-between">
              <Col span={5}></Col>

              <Col span={14}>
                <Form.Item
                  name="employment_type"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ margin: "0" }}
                >
                  <div style={{ textAlign: "left", paddingTop: "50px" }}>
                    {/* <Radio.Group defaultValue="a" size="large" onChange={this.empSelect}> */}
                    <p style={{ textAlign: "left", fontSize: "16px" }}>
                      Which best describes you?
                    </p>

                    <label className="width-label">
                      <input
                        name="product"
                        type="radio"
                        ref={this.SU}
                        className="radio-input-element "
                        value="SU"
                        checked={this.state.emp_type === "SU"}
                        onClick={() => {
                          this.empSelect("SU");
                        }}
                      ></input>
                      <div className="radio-input">
                        <p className="" style={{ margin: "8px 0 2px 0" }}>
                          {" "}
                          Student{" "}
                        </p>
                        <p
                          className=""
                          style={{
                            font: "normal normal normal 10px/14px AvenirBook",
                            margin: "4px 0 5px 0",
                          }}
                        >
                          {" "}
                          (UNDERGRAD){" "}
                        </p>
                      </div>
                    </label>

                    <label className="width-label">
                      <input
                        name="product"
                        type="radio"
                        ref={this.SG}
                        className="radio-input-element"
                        value="SG"
                        checked={this.state.emp_type === "SG"}
                        onClick={() => {
                          this.empSelect("SG");
                        }}
                      ></input>
                      <div className="radio-input">
                        <p className="" style={{ margin: "8px 0 2px 0" }}>
                          {" "}
                          Student{" "}
                        </p>
                        <p
                          className=""
                          style={{
                            font: "normal normal normal 10px/14px AvenirBook",
                            margin: "4px 0 5px 0",
                          }}
                        >
                          {" "}
                          (GRADUATE){" "}
                        </p>
                      </div>
                    </label>

                    <label className="width-label">
                      <input
                        name="product"
                        type="radio"
                        ref={this.WP}
                        className="radio-input-element"
                        value="WP"
                        checked={this.state.emp_type === "WP"}
                        onClick={() => {
                          this.empSelect("WP");
                        }}
                      ></input>
                      <div className="radio-input">
                        <p className="" style={{ margin: "8px 0 2px 0" }}>
                          Working{" "}
                        </p>
                        <p
                          className=""
                          style={{
                            font: "normal normal normal 10px/14px AvenirBook",
                            margin: "4px 0 5px 0",
                          }}
                        >
                          {" "}
                          (PART TIME){" "}
                        </p>
                      </div>
                    </label>

                    <label className="width-label">
                      <input
                        name="product"
                        type="radio"
                        ref={this.WF}
                        className="radio-input-element"
                        value="WF"
                        checked={this.state.emp_type === "WF"}
                        onClick={() => {
                          this.empSelect("WF");
                        }}
                      ></input>
                      <div className="radio-input">
                        <p className="" style={{ margin: "8px 0 2px 0" }}>
                          {" "}
                          Working{" "}
                        </p>
                        <p
                          className=""
                          style={{
                            font: "normal normal normal 10px/14px AvenirBook",
                            margin: "4px 0 5px 0",
                          }}
                        >
                          {" "}
                          (FULL TIME){" "}
                        </p>
                      </div>
                    </label>

                    <label
                      className="width-label"
                      style={{ position: "absolute" }}
                    >
                      <input
                        name="product"
                        type="radio"
                        ref={this.UP}
                        className="radio-input-element"
                        value="UP"
                        checked={this.state.emp_type === "UP"}
                        onClick={() => {
                          this.empSelect("UP");
                        }}
                      ></input>
                      <div className="radio-input">
                        <p style={{ margin: "15px 0" }}>Unemployed</p>
                      </div>
                    </label>
                  </div>
                </Form.Item>

                {this.state.emp_questions}

                <p style={{ textAlign: "left", paddingTop: "50px", fontSize: "16px" }}>
                  To what extent do you feel stressed about figuring out your
                  next career step?
                </p>
                <Form.Item
                  name="stress_level"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ margin: "0" }}
                >
                  <Slider
                    defaultValue={0}
                    max={10}
                    tooltipVisible
                    style={{ paddingTop: "15px"}}
                    className=""
                  />
                </Form.Item>
                <Row justify="space-between">
                  <Col span={8}>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#898E8E",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      I am not stressed
                    </p>
                  </Col>
                  <Col span={8}>
                    <p
                      style={{
                        color: "#898E8E",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      I'm moderately stressed
                    </p>
                  </Col>
                  <Col span={8}>
                    <p
                      style={{
                        textAlign: "right",
                        color: "#898E8E",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      I'm feeling extremely stressed!
                    </p>
                  </Col>
                </Row>

                <p style={{ textAlign: "left", paddingTop: "50px", fontSize: "16px" }}>
                  What is your level of clarity in your ideal career path?
                </p>
                <Form.Item
                  name="clarity_level"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ margin: "0" }}
                >
                  <Slider
                    defaultValue={0}
                    max={10}
                    tooltipVisible
                    style={{ paddingTop: "15px" }}
                  />
                </Form.Item>
                <Row justify="space-between">
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#898E8E",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      I have no idea what I want
                    </p>
                  </Col>
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "right",
                        color: "#898E8E",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      I know exactly what I want
                    </p>
                  </Col>
                </Row>

                <p style={{ textAlign: "left", paddingTop: "50px", fontSize: "16px" }}>
                  How confident are you in how to approach the career
                  exploration process?
                </p>
                <Form.Item
                  name="confidence_level"
                  rules={[{ required: true, message: "Required Field" }]}
                  style={{ margin: "0" }}
                >
                  <Slider
                    defaultValue={0}
                    max={10}
                    tooltipVisible
                    style={{ paddingTop: "15px" }}
                  />
                </Form.Item>
                <Row justify="space-between">
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#898E8E",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      I have no clue what to do
                    </p>
                  </Col>
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "right",
                        color: "#898E8E",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      I know exactly what to do
                    </p>
                  </Col>
                </Row>
                <div
                  style={{
                    textAlign: "right",
                    paddingTop: "70px",
                    marginBottom: "100px",
                  }}
                >
                  <button
                    style={{ width: "40%" }}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="button-main"
                  >
                    Next
                  </button>
                </div>
              </Col>

              <Col span={5}></Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}
