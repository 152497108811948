import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectStepReflections } from "slices/dashboardSlice";
import DraggablecompTR from "../Common/DraggablecompTR";

const Targetrolescard = (props) => {

  const stepReflections = useSelector(selectStepReflections);

  const isDone = (step) => 
    stepReflections[step] ? stepReflections[step].status === "DO" || stepReflections[step].status === "RE" : false;
   
  return (
    <Card
      bordered={false}
      style={{
        boxShadow: "0px 3px 16px 0px #1313131A",
        borderRadius: "12px",
        textAlign: "left",
        margin: "0px 20px",
        height: "fit-content",
      }}
    >

      <div className="h" style={{ fontWeight: "bold", textAlign: "left" , fontFamily:"AvenirBook" }}>
        {/* My Target Roles */}
      </div>
      <div className="">

<div className="my-target-role">
{/* Future Release Build */}
<img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/dashboardMyTasksDrag.svg" className="element-hide-temporary" />
<span className="my-target-roles-title">My Target Roles</span>




</div>
{/* <div className="inner-container-targetRole">
  <div className="inner-container-with-button">

<h6 className="inner-container-subtitle-title">
1. finTech
</h6>
<button className="target-roles-subbuttons">
  Full time
</button>
  </div>
  <div className="inner-container-with-button">

<h6 className="inner-container-subtitle-title">
1. Project Manager
</h6>
<button className="target-roles-subbuttons">
  Full time
</button>
  </div>
  <div className="inner-container-with-button">

<h6 className="inner-container-subtitle-title">
2. Program Manager

</h6>
<button className="target-roles-subbuttons">
  Full time
</button>
  </div>

  <div className="inner-container-with-button">

<h6 className="inner-container-subtitle-title">
3. Sales Manager

</h6>
<button className="target-roles-subbuttons">
  Full time
</button>
  </div>

  <div className="inner-container-with-button">

<h6 className="inner-container-subtitle-title">
4. Account Manager

</h6>
<button className="target-roles-subbuttons">
  Full time
</button>
  </div>
</div> */}
</div>
<div style={{
  marginTop:"40px"
}}>

        <DraggablecompTR isDone={isDone('TR')} buttonVisible={true}/>
</div>
    </Card>
  );
};

export default Targetrolescard;