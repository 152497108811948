import React from "react";
import { Badge } from "antd";
import "antd/dist/antd.css";
const style = { paddingBottom: "24px" };
export default class AssessmentC3 extends React.Component {
  render() {
    return (
      <div>
        <p
          style={{
            font: "normal normal 600 20px/12px AvenirBook",
            color: "#414141",
            padding: "40px 0",
          }}
        >
          More resources
        </p>

        <div>
          <div style={style}>
            <Badge color="#ACE3E6" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://youtu.be/Oco1E15R2fM"
            >
              Video: Assessment
            </a>
          </div>
          <div style={style}>
            <Badge color="#ACE3E6" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/watch?v=2FDczYJyj14&feature=youtu.be"
            >
              Why our assessment is different{" "}
            </a>
          </div>
          <div style={style}>
            <Badge color="#ACE3E6" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/file/d/1s_yoA5U5nL4EiOK_VxoQk7BQSCxq6SAt/view?usp=sharing"
            >
              Book Chapter 1-7
            </a>
          </div>
          <div style={style}>
            <Badge color="#ACE3E6" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://business360.fortefoundation.org/a-conversation-on-finding-and-leveraging-your-career-purpose/?utm_source=forte&utm_medium=email&utm_campaign=webinars"
            >
              Event
            </a>
          </div>
          <div style={style}>
            <Badge color="#ACE3E6" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href=" https://medium.com/@getwoken/the-top-five-patterns-ive-seen-in-people-who-pursue-their-dream-career-path-f210ecc299dd"
            >
              Blog
            </a>
          </div>
        </div>
      </div>
    );
  }
}
