import { Card, Col, Form, Input, Row, Select, message } from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import Editablecards from "./Editablecards";
import "./InterviewResearch.css";

const { TextArea } = Input;
const { Option } = Select;

export default class InterviewResearch extends React.Component {
  formRef = React.createRef();
  state = {
    additional_cards: [],
    loading: true,
    submitted: false,
    companies: [],
    current: 0,
    card_keys: [],
  };

  componentDidMount() {
    AUTH_API.getAllCompanies()
      .then((response) => {
        console.log("get api response!!", response);
        if (response.data.length) {
          const all_companies =
            response.data.length > 0 ? [...response.data] : [];
          const companies = [];
          all_companies.map((item) => {
            companies.push({
              id: item.id,
              name: item.title,
            });
          });
          companies.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          console.log("sorted", companies);
          this.setState({ companies: companies, loading: false });

          const id = this.state.companies[0].id;
          AUTH_API.getCompany(id)
            .then((response) => {
              console.log(
                "get single company res in get api",
                response,
                this.formRef.current
              );
              const research_data = response.data.research_notes[0];
              this.formRef.current.setFieldsValue({
                ...research_data,
              });
              const research_data_keys = Object.keys(research_data);
              const additional_cards = research_data_keys.filter(
                (it) => it.length < 3
              );
              this.setState({
                card_keys: research_data_keys,
                additional_cards: additional_cards,
              });
              console.log(
                "research_notes fro get Api",
                additional_cards,
                typeof additional_cards[0]
              );
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
      })
      .catch((err) => console.log("no companies found!", err));
  }

  addNew = () => {
    const form_values = this.formRef.current.getFieldsValue();
    console.log("form values", form_values);
    const key = this.state.additional_cards.length + 1;
    form_values[key] = {};
    const data = {
      research_notes: [form_values],
    };
    const company_id = this.state.companies[this.state.current].id;
    AUTH_API.patchCompany(data, company_id, "IR")
      .then((response) => {
        console.log("add res", response);
        const research_data = response.data.research_notes[0];
        console.log("research data after addition ", research_data);
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
          ...research_data,
        });
        const research_data_keys = Object.keys(research_data);
        const additional_cards = research_data_keys.filter(
          (it) => it.length < 3
        );
        this.setState({
          additional_cards: additional_cards,
        });
      })
      .catch((err) => {
        console.log("not added", err);
      });
  };
  deleteDraft = (id) => {
    const form_values = this.formRef.current.getFieldsValue();
    console.log("form values", form_values, "id is:", id);
    const index = id.toString();
    delete form_values[index];
    console.log("data is:", form_values, this.state.additional_cards);
    const data = {
      research_notes: [form_values],
    };
    const company_id = this.state.companies[this.state.current].id;
    AUTH_API.patchCompany(data, company_id, "IR")
      .then((response) => {
        console.log("delete res", response);
        const research_data = response.data.research_notes[0];
        console.log("research data after delete ", research_data);
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
          ...research_data,
        });
        const research_data_keys = Object.keys(research_data);
        const additional_cards = research_data_keys.filter(
          (it) => it.length < 3
        );
        this.setState({
          additional_cards: additional_cards,
        });
      })
      .catch((err) => {
        console.log("not deleted", err);
      });
  };
  OnCompanyChange = (val) => {
    this.setState({ current: val, card_keys: [] });
    console.log(
      "companies in state",
      this.state.companies,
      typeof this.state.companies
    );
    const id = this.state.companies[val].id;
    this.formRef.current.resetFields();
    AUTH_API.getCompany(id)
      .then((response) => {
        console.log("get single company res in patch api", response);
        if (response.data.research_notes.length > 0) {
          const research_data = response.data.research_notes[0];
          this.formRef.current.setFieldsValue({
            ...research_data,
          });
          const research_data_keys = Object.keys(research_data);
          const additional_cards = research_data_keys.filter(
            (it) => it.length < 3
          );
          this.setState({
            card_keys: research_data_keys,
            additional_cards: additional_cards,
          });
          console.log("research notes from get Api", research_data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  OnFormChange = (changed_values, all_values) => {
    console.log("changed values:", changed_values, "all_values:", all_values);
    const id = this.state.companies[this.state.current].id;
    console.log("selected company id is:", id);

    const index = Object.keys(changed_values)[0];
    const newcard_keys = [...this.state.card_keys];
    newcard_keys.push(index);
    this.setState({ card_keys: newcard_keys });
    const data = {
      research_notes: [all_values],
    };
    AUTH_API.patchCompany(data, id, "IR")
      .then((response) => {
        message.success('Your work has been saved')
        console.log("patch res", response);
      })
      .catch((err) => {
        console.log("not patched", err);
      });
  };

  render() {
    console.log("add cards in state", this.state);
    return (
      <>
        {!this.state.loading ? (
          <div>
            <p className="research-head">
              Prepare sufficiently for each interview by researching the company
              website, job description, news, interviewers, and draft authentic
              questions.{" "}
            </p>

            <div style={{ display: "flex", marginBottom: "30px" }}>
              <Select
                className="select-caller"
                listHeight={120}
                onChange={this.OnCompanyChange}
                defaultValue={0}
              >
                {this.state.companies.map((item, index) => (
                  <Option value={index}>{item.name}</Option>
                ))}
              </Select>
            </div>

            <Form
              name="research-notes-form"
              ref={this.formRef}
              onValuesChange={HELPERS.debounceFormChanges(this.OnFormChange)}
            >
              <Row>
                <Col span="16">
                  <h3 className="research_notes-head">Your research notes:</h3>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.card_keys.includes("background-notes")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you know about the interviewer's{" "}
                      <strong>background </strong>?
                    </p>
                    <p className="research_notes-card-subhead">
                      Key info about the role, company, industry and path.
                    </p>
                    <Form.Item name="background-notes">
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
                <Col span="8">
                  <h3 className="research_notes-head">
                    What questions you want to ask in the call:
                  </h3>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.card_keys.includes("background-ques")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                    style={{ width: "100%" }}
                  >
                    <p className="research_notes-card-heading">
                      What do you want to ask about their{" "}
                      <strong>background </strong>?
                    </p>
                    <Form.Item name="background-ques">
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
              </Row>
              <Row>
                <Col span="16">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.card_keys.includes("company-notes")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you know about their <strong>company </strong>?
                    </p>
                    <Form.Item name="company-notes">
                      <TextArea
                        autoSize={{ minRows: 8, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
                <Col span="8">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    style={{ width: "100%" }}
                    className={
                      this.state.card_keys.includes("company-ques")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you want to ask about their{" "}
                      <strong>company </strong>?
                    </p>
                    <Form.Item name="company-ques">
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
              </Row>
              <Row>
                <Col span="16">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.card_keys.includes("role-notes")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you know about their <strong>role </strong>?
                    </p>
                    <Form.Item name="role-notes">
                      <TextArea
                        autoSize={{ minRows: 8, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
                <Col span="8">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    style={{ width: "100%" }}
                    className={
                      this.state.card_keys.includes("role-ques")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you want to ask about their <strong>role </strong>
                      ?
                    </p>
                    {/* <p className='research_notes-card-subhead'>Key info about their role, company, industry and path.</p> */}
                    <Form.Item name="role-ques">
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
              </Row>
              <Row>
                <Col span="16">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.card_keys.includes("industry-notes")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you know about their <strong>industry </strong>?
                    </p>
                    <Form.Item name="industry-notes">
                      <TextArea
                        autoSize={{ minRows: 8, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
                <Col span="8">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    style={{ width: "100%" }}
                    className={
                      this.state.card_keys.includes("industry-ques")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you want to ask about their{" "}
                      <strong>industry </strong>?
                    </p>
                    <Form.Item name="industry-ques">
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
              </Row>
              <Row>
                <Col span="16">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.card_keys.includes("recruiting-notes")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you know about their{" "}
                      <strong>recruiting process </strong>?
                    </p>
                    <Form.Item name="recruiting-notes">
                      <TextArea
                        autoSize={{ minRows: 8, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
                <Col span="8">
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    style={{ width: "100%" }}
                    className={
                      this.state.card_keys.includes("recruiting-ques")
                        ? "research_notes-card-focus-within"
                        : "research_notes-card"
                    }
                  >
                    <p className="research_notes-card-heading">
                      What do you want to ask about their{" "}
                      <strong>recruiting process </strong>?
                    </p>
                    <Form.Item name="recruiting-ques">
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
              </Row>

              {this.state.additional_cards.map((item, index) => {
                return (
                  <Editablecards
                    key={index.toString()}
                    name={item}
                    deleteDraft={(id) => this.deleteDraft(id)}
                  />
                );
              })}

              <div style={{ textAlign: "right", padding: "46px 0" }}>
                <p
                  onClick={() => {
                    this.addNew();
                  }}
                  style={{
                    color: "#199094",
                    cursor: "pointer",
                    width: "fit-content",
                    fontSize: "16px",
                  }}
                >
                  &#43; &nbsp; Add more research notes
                </p>
              </div>
            </Form>
          </div>
        ) : (
          <div
            style={{
              margin: 30,
              textAlign: "center",
              font: "300 20px / 12px AvenirBook",
              opacity: "0.6",
            }}
          >
            Add some companies first!
          </div>
        )}
      </>
    );
  }
}