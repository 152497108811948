import React from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default function Thanks(props) {
  const icon = (
    <a href="/dashboard">
      <CloseOutlined className="close-icon" onClick={props.onClose} />
    </a>
  );
  return (
    <Modal
      title=""
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
      width="40%"
      style={{ textAlign: "center" }}
      closeIcon={icon}
    >
      <div>
        <p
          style={{
            font: "normal normal bold 24px/12px AvenirBook",
            textAlign: "center",
            margin: "80px 0 60px 0",
          }}
        >
          Thanks!
        </p>

        <p style={{ margin: "50px 0 90px 0" }}>
          Your coach will get back to you shortly with feedback.
        </p>

        <a href="/dashboard">
          <Button
            type="primary"
            style={{ marginBottom: "90px", width: "200px" }}
            size="large"
            onClick={props.onClose}
          >
            Okay
          </Button>
        </a>
      </div>
    </Modal>
  );
}
