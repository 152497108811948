import React from "react";
import { Row, Col, Dropdown } from "antd";
import { useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

import Targetrolescard from "./Targetrolescard";
import Targetindustriescard from "./Targetindustries";
import Timeline from "./Timeline";
import MyTasks from "./MyTasks";
import Calendar from "../Calendar";
import { selectProfile } from "slices/dashboardSlice";
import Profile from "Components/Modals/Profile";


const WelcomeMessage = () => {
  const profile = useSelector(selectProfile);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "0px 4px 20px 0px #0000000F",
        alignItems: "center",
        height: "72px",
      }}
    >
    <div
      style={{
        fontSize: "20px",
        fontWeight: '800',
        color: "#111111",
        textAlign: "left",
        padding: "2% 1%",
        fontFamily:'AvenirBook',
        width: 'fit-content',
        lineHeight: "27.32px",
      }}
      >
      Welcome, {profile ? profile.first_name : "User"}!
    </div>
    <div
    style={{
      fontSize: 30,
      color: "#111111",
      textAlign: "left",
      padding: "2% 1%",
      width: 'fit-content',
    }}
    > 
    </div>
    </div>
  );
};

const DashboardComp = () => (
  <div>
    {/* <WelcomeMessage /> */}
    <Row>
      <Col span={16}>
        <Row>
          <Col span={12}>
            {/* //Target roles list */}
            <Targetrolescard />
          </Col>
          <Col span={12}>
            {/* //Target industries list */}
            <Targetindustriescard />
          </Col>
        </Row>

        {/* //Timeline */}
        <Timeline />
      </Col>

      {/* //Tasks */}
      <Col span={8}>
        <MyTasks />
        <Calendar />
      </Col>
      {/* <Col span={1}></Col> */}
    </Row>
  </div>
);

export default DashboardComp;
