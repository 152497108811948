const STEP_KEY = {
  Onboarding: "ON",
  Assessment: "AS",
  TargetRoles: "TR",
  TargetIndustries: "TI",
  ResearchRoles: "RR",
  ResearchIndustries: "RI",
  Contacts: "CO",
  EmailDrafts: "ED",
  Tracker: "NT",
  CallPrep: "CP",
  CallNotes: "CN",
  NetworkingReflections: "NF",
  LearningOpportunities: "LO",
  ProfessionalSummary: "PS",
  Resume: "RE",
  CoverLetter: "CL",
  Linkedin: "LK",
  ApplicationTracker: "AT",
  InterviewResearch: "IR",
  InterviewPractice: "BP",
  Reflections: "JR",
};

const order = [
  STEP_KEY.Assessment,
  STEP_KEY.TargetRoles,
  STEP_KEY.TargetIndustries,
  STEP_KEY.ResearchRoles,
  STEP_KEY.ResearchIndustries,
  STEP_KEY.Contacts,
  STEP_KEY.EmailDrafts,
  STEP_KEY.Tracker,
  STEP_KEY.CallPrep,
  STEP_KEY.CallNotes,
  STEP_KEY.NetworkingReflections,
  STEP_KEY.LearningOpportunities,
  STEP_KEY.ProfessionalSummary,
  STEP_KEY.Resume,
  STEP_KEY.CoverLetter,
  STEP_KEY.Linkedin,
  STEP_KEY.ApplicationTracker,
  STEP_KEY.InterviewResearch,
  STEP_KEY.InterviewPractice,
  STEP_KEY.Reflections,
];

const canBeNotified = (stepKey) => order.indexOf(stepKey) !== -1;

const stepOrder = (stepKey) => order.indexOf(stepKey);

const subSteps = {
  ONBOARDING: [
    {
      title: "Commitment",
      linkTo: { pathname: "/onboarding", stepProps: { curr: 4 } },
      key: "1",
    },
    {
      title: "Accountability",
      linkTo: { pathname: "/onboarding", stepProps: { curr: 5 } },
      key: "2",
    },
    {
      title: "Assessment",
      linkTo: "/assessment",
      key: "3",
      stepKey: STEP_KEY.Assessment,
    },
    {
      title: "Target Roles",
      linkTo: "/target-roles",
      key: "4",
      stepKey: STEP_KEY.TargetRoles,
    },
    {
      title: "Target Industries",
      linkTo: "/target-industries",
      key: "5",
      stepKey: STEP_KEY.TargetIndustries,
    },
  ],
  EXPLORATION_RESEARCH: [
    {
      title: "Roles",
      key: "6",
      linkTo: "/research-roles",
      stepKey: STEP_KEY.ResearchRoles,
    },
    {
      title: "Industries",
      key: "7",
      linkTo: "/research-industries",
      stepKey: STEP_KEY.ResearchIndustries,
    },
  ],
  EXPLORATION_NETWORKING: [
    {
      title: "Contacts",
      key: "8",
      linkTo: "/networking/contacts",
      stepKey: STEP_KEY.Contacts,
    },
    {
      title: "Email Drafts",
      key: "9",
      linkTo: "/networking/email-drafts",
      stepKey: STEP_KEY.EmailDrafts,
    },
    {
      title: "Tracker",
      key: "10",
      linkTo: "/networking/tracker",
      stepKey: STEP_KEY.Tracker,
    },
    {
      title: "Call Prep",
      key: "11",
      linkTo: "/networking/call-prep",
      stepKey: STEP_KEY.CallPrep,
    },
    {
      title: "Call Notes",
      key: "12",
      linkTo: "/networking/call-notes",
      stepKey: STEP_KEY.CallNotes,
    },
    {
      title: "Reflections",
      key: "13",
      linkTo: "/networking/reflections",
      stepKey: STEP_KEY.NetworkingReflections,
    },
  ],
  EXPLORATION_EXPERIENCES: [
    {
      title: "Learning Opportunities",
      key: "14",
      linkTo: "/learning-opportunities",
      stepKey: STEP_KEY.LearningOpportunities,
    },
  ],
  EXECUTION_MATERIALS: [
    {
      title: "Professional Summary",
      key: "15",
      linkTo: "/materials/professional-summary",
      stepKey: STEP_KEY.ProfessionalSummary,
    },
    {
      title: "Resume",
      key: "16",
      linkTo: "/materials/resume",
      stepKey: STEP_KEY.Resume,
    },
    {
      title: "Cover Letter",
      key: "17",
      linkTo: "/materials/cover-letter",
      stepKey: STEP_KEY.CoverLetter,
    },
    {
      title: "Linkedin",
      key: "18",
      linkTo: "/materials/linkedin",
      stepKey: STEP_KEY.Linkedin,
    },
  ],
  EXECUTION_JOB_SEARCH: [
    {
      title: "Application Tracker",
      key: "19",
      linkTo: "/job-search/application-tracker",
      stepKey: STEP_KEY.ApplicationTracker,
    },
    {
      title: "Interview Research",
      key: "20",
      linkTo: "/job-search/interview-research",
      stepKey: STEP_KEY.InterviewResearch,
    },
    {
      title: "Interview Practice",
      key: "21",
      linkTo: "/job-search/interview-prep",
      stepKey: STEP_KEY.InterviewPractice,
    },
    {
      title: "Reflections",
      key: "22",
      linkTo: "/job-search/reflections",
      stepKey: STEP_KEY.Reflections,
    },
  ],
  DASHBOARD: [
    {
      title: "Dashboard",
      linkTo: "/dashboard",
      key: "0",
    }
  ],
  CALENDAR: [
    {
      title: "Calendar",
      linkTo: "/calendar",
      key: "23",
    }
  ],
  RESOURCES: [
    {
      title: "Videos",
      linkTo: "https://www.youtube.com/channel/UC-odYAm60szKcSnZzcmWA6w?view_as=subscriber",
      key: "24",
    },
    {
      title: "Woken Book",
      linkTo: "https://drive.google.com/file/d/1r0_pYv6MpTNXKBxlhK0aU9TjadZzMyOO/view",
      key: "25",
    },
    {
      title: "Woken Blog",
      linkTo: "https://getwoken.medium.com/",
      key: "26",
    }
  ],
};

const steps = [
  {
    title: "DASHBOARD",
    key: "sub1",
    icon: "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/dashboard.svg",
    linkTo: "/dashboard",
    level: "0",
    subSteps: [
      // {
      //   steps: subSteps.DASHBOARD,
      // },
    ],
  },
  {
    title: "ONBOARDING",
    key: "sub2",
    icon: "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/careerCoaching.svg",
    level: "0",
    subSteps: [
      {
        steps: subSteps.ONBOARDING,
        key: "sub2.1",
        level: "1",
      },
    ],
  },
  {
    title: "EXPLORATION",
    key: "sub3",
    icon: "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/mySupportSystem.svg",
    level: "0",
    subSteps: [
      {
        title: "RESEARCH",
        key: "sub3_1",
        steps: subSteps.EXPLORATION_RESEARCH,
        level: "1",
      },
      {
        title: "NETWORKING",
        key: "sub3_2",
        steps: subSteps.EXPLORATION_NETWORKING,
        level: "1",
      },
      {
        title: "EXPERIENCES",
        key: "sub3_3",
        steps: subSteps.EXPLORATION_EXPERIENCES,
        level: "1",
      },
    ],
  },
  {
    title: "EXECUTION",
    key: "sub4",
    icon: "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/lifeCoaching.svg",
    level: "0",
    subSteps: [
      {
        title: "MATERIALS",
        key: "sub4_1",
        steps: subSteps.EXECUTION_MATERIALS,
        level: "1",
      },
      {
        title: "JOB SEARCH",
        key: "sub4_2",
        steps: subSteps.EXECUTION_JOB_SEARCH,
        level: "1",
      },
    ],
  },
  // {
  //   title: "CALENDAR",
  //   key: "sub5",
  //   icon: "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/community.svg",
  //   linkTo: "/calendar",
  //   level: "0",
  //   subSteps: [
  //     // {
  //     //   steps: subSteps.CALENDAR,
  //     // },
  //   ],
  // },
  // {
  //   title: "RESOURCES",
  //   key: "sub6",
  //   icon: "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/resources.svg",
  //   subSteps: [
  //     {
  //      steps: subSteps.RESOURCES,
  //      level: "1",
  //     },
  //   ],
  // },
];

export { STEP_KEY, subSteps, stepOrder, canBeNotified };

export default steps;
