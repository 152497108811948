import React from "react";
import { connect } from "react-redux";
import { Row, Col, Steps, Button, message } from "antd";
import "./Targetindustries.css";
import TIprioritize from "./TIprioritize";
import Potentialindustries from "./Potentialindustries";
import TIselect from "./TIselect";
import { updateQuickReflection } from "slices/dashboardSlice";
import {
  selectTargetIndustries
} from "slices/targetIndustriesSlice";

import Sorry from "Components/Modals/sorry";


const { Step } = Steps;

class TargetIndustriesSteps extends React.Component {
  state = {
    // current:0,
    current: this.props.curr_step,
    showSorry: false
  };

  next() {
    const selected_industries = this.props.industries.filter(item => item.learn_more)
    let current = this.state.current;
    if (selected_industries.length > 10 && current == 1) {
      this.setState({ showSorry: true })
      return
    }
    current += 1;
    this.setState({ current });
    this.props.setStep(current);
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
    this.props.setStep(current);
  }

  render() {
    const { current } = this.state;
    // console.log(this.props.fade);
    const steps = [
      {
        title: "Potential Industries",
        content: <Potentialindustries next={() => this.next()} />,
      },
      {
        title: "Target Industries",
        content: <TIselect />,
      },
      {
        title: "Prioritize",
        content: <TIprioritize />,
      },
    ];
    return (
      <div
        style={this.props.fade ? {} : { opacity: "0.4", pointerEvents: "none" }}
      > 
        <Sorry 
        message ="Please limit your choices to 10 industries. This does not mean you are closing yourself off to any options, but we want to focus your research on what you think is most interesting, just as a starting point."
        visible={this.state.showSorry} onClose={() => this.setState({showSorry:false})}/>
        <Steps
          current={current}
          style={{ padding: "2% 0 6% 0", fontSize: 14 }}
          onChange={(current) => this.setState({ current: current })}
        >
          {steps.map((item, index) => (
            <Step
              style={{ fontSize: 12 }}
              key={item.title}
              title={item.title}
              status={index === this.state.current ? "process" : "wait"}
            />
          ))}
        </Steps>

        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          <Row>
            <Col span={5} style={{ paddingTop: "5%" }}>
              {current > 0 && (
                <button
                  size="large"
                  style={{ margin: "0 8px" ,width:"100%"}}
                  onClick={() => this.prev()}
                  block
                  className="button-secondary"
                >
                  Back
                </button>
              )}
              {/* {current === 1 && (
                            <Button size= 'large' style={{ margin: '0 8px'}}  block>
                                Back
                            </Button>
                        )} */}
            </Col>
            <Col span={7}></Col>
            <Col span={7}></Col>

            <Col span={5} style={{ textAlign: "right", padding: "5% 0% 5% 0" }}>
              {current < steps.length - 1 && current === 1 && (
                <button
                  size="large"
                  type="primary"
                  onClick={() => this.next()}
                  block
                  className="button-main"
                  style={{
                    width:"100%"
                  }}
                >
                  Next
                </button>
              )}
              {current === steps.length - 1 && (
                <button
                  size="large"
                  type="primary"
                  block
                  onClick={() => {
                    this.props.updateQuickReflection({
                      stepId: this.props.stepId,
                      show: true,
                    });
                  }}
                  className="button-main"
                  style={{
                    width:"100%"
                  }}
                >
                  Submit
                </button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  industries: selectTargetIndustries(state)
});

const mapDispatchToProps = () => ({
  updateQuickReflection,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(TargetIndustriesSteps);