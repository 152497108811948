import React, { Component, useState } from "react";
import moment from "moment";
import { Row, Col, Input, Button, Form, Slider, message } from "antd";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import Img from "../../../Assets/noun_think_2157497.svg";

// import  "./WeeklyReflection.css"
const { TextArea } = Input;

export default class ReflectionAboutYourProcess extends Component {
  formRef = React.createRef();

  syncForm = () => {
    console.log(
      this.formRef.current.getFieldValue("stress_level"),
      this.props.data.stress_level
    );
    if (
      this.formRef.current
      // && this.formRef.current.getFieldValue("stress_level") !==
      //   this.props.data.stress_level
    ) {
      HELPERS.log("We need change, process", this.props.data);
      this.formRef.current.setFieldsValue(this.props.data);
    }
  };

  componentDidMount = () => {
    console.log("in didmnt process");
    this.syncForm();
  };

  componentDidUpdate = () => {
    HELPERS.log("We got update", this.props.data);
    this.syncForm();
  };

  onFinish = (values) => {
    HELPERS.log(values);
    this.props.onSubmit(this.props.reflectionId, values);
    this.setState({ showThanks: true });
  };

  isOverdue = () => {
    console.log(this.props.data);
    const releasedOn = moment(this.props.data.released_on).add(7, "days");
    return moment(new Date()).isAfter(releasedOn);
  };


  
  onKeyPressed = () => {
    HELPERS.resetPing();
  };

  OnFormChange = (change_values, all_values) => {
    // if (!this.props.data.completed) {
      const currentStep = HELPERS.getCurrentStep();
      const id = all_values.id;

      AUTH_API.patchReflectionsProcess(all_values, id, currentStep)
        .then((response) => {
          console.log(response.data);
          message.success("Your work has been saved successfully");
        })
        .catch((error) => {
          message.error(
            "We could not save your work due to an error. Kindly be patient as we work to resolve it. "
          );
          console.log("Reflections About Process NOT Patched", error);
        });
    // }
  };

  render() {
    console.log("is overdue", this.props.data, this.isOverdue());
    return (
      <Form
        ref={this.formRef}
        name="weekly-reflection-about-your-process"
        onFinish={this.onFinish}
        onFinishFailed={() => {
          message.error("Please fill all the required fields first!");
        }}
        onValuesChange={HELPERS.debounceFormChanges(this.OnFormChange)}
      >
        <div>
          <Row style={{ marginTop: 100 }}>
            <Col style={{marginRight: '10px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="43.005" viewBox="0 0 38 43.005">
              <path d="M36.023,7.915A17.2,17.2,0,0,1,39.25,21.493c3.226,5.982,4.033,6.722,3.226,7.058l-4.705,1.815V35a3.83,3.83,0,0,1-3.831,3.831H30.512c-.2,3.9.4,5.109-.538,5.109-16-.2-16.8.471-16.8-.538V33.189a17.405,17.405,0,0,1-8.4-14.855C4.834,1.731,26.075-5.394,36.023,7.915ZM13.976,32.45a.553.553,0,0,1,.269.4V42.936H29.5v-4.1H25a.53.53,0,0,1-.538-.538A.5.5,0,0,1,25,37.76h8.94a2.84,2.84,0,0,0,2.823-2.823V29.963a.505.505,0,0,1,.336-.471l4.5-1.748L38.443,21.9a.416.416,0,0,1-.134-.471A16.248,16.248,0,0,0,35.351,8.453C25.941-3.982,5.91,2.471,5.91,18.267A16.062,16.062,0,0,0,13.976,32.45Z" transform="translate(-4.767 -0.975)" fill="#199094"></path>
              <path fill="#199094" d="M17.826,20.853a3.26,3.26,0,1,1,2.554-1.277L23.54,22.8a4.9,4.9,0,0,1,3.226-1.21,4.5,4.5,0,0,1,3.092,1.143l2.42-2.487a3.1,3.1,0,0,1-.739-2.017,3.226,3.226,0,1,1,3.226,3.226,3,3,0,0,1-1.748-.538l-2.487,2.487a4.743,4.743,0,0,1,1.075,3.025V26.5l3.7,1.075A1.966,1.966,0,0,1,37.118,26.5a2.151,2.151,0,1,1-2.151,2.151v-.067l-3.5-1.008a4.862,4.862,0,0,1-4.235,3.764v2.554a2.1,2.1,0,0,1,1.613,2.084,2.151,2.151,0,1,1-2.689-2.084V31.339A4.749,4.749,0,0,1,23,29.659l-2.689,1.949a2.209,2.209,0,0,1,.269,1.143,2.621,2.621,0,1,1-2.621-2.622,2.387,2.387,0,0,1,1.68.672L22.4,28.852a4.541,4.541,0,0,1-.6-2.353,4.8,4.8,0,0,1,.941-2.89l-3.226-3.226A3.106,3.106,0,0,1,17.826,20.853Zm19.291,8.873a1.143,1.143,0,1,0-1.143-1.143A1.133,1.133,0,0,0,37.118,29.726Zm-19.09,1.412a1.613,1.613,0,1,0,1.613,1.613A1.636,1.636,0,0,0,18.028,31.137Zm-.2-15.729a2.218,2.218,0,1,0,2.218,2.218A2.238,2.238,0,0,0,17.826,15.408ZM37.051,18.3a2.218,2.218,0,1,0-2.218,2.218A2.238,2.238,0,0,0,37.051,18.3ZM25.624,35.91a1.143,1.143,0,1,0,2.285,0,1.094,1.094,0,0,0-1.143-1.143A1.228,1.228,0,0,0,25.624,35.91Zm1.143-5.579A3.83,3.83,0,0,0,30.6,26.5a3.831,3.831,0,1,0-7.663,0A3.83,3.83,0,0,0,26.766,30.331Z" transform="translate(-7.878 -5.376)"></path>
              <path fill="#199094" d="M22.541,10.518a.5.5,0,1,1-.874-.471l.739-1.479a.5.5,0,1,1,.874.471Z" transform="translate(3.338 -3.376)"></path>
              <path fill="#199094" d="M27.633,8.51a.489.489,0,1,1-.941-.269l.471-1.613A.489.489,0,1,1,28.1,6.9Z" transform="translate(-4.846 -2.712)"></path>
              <path fill="#199094" d="M32.471,7.889A.519.519,0,0,1,32,7.351l.134-1.68a.519.519,0,0,1,.538-.471.53.53,0,0,1,.538.538l-.134,1.68A.646.646,0,0,1,32.471,7.889Z" transform="translate(-13.515 -2.36)"></path>
              <path fill="#199094" d="M37.857,7.889a.519.519,0,0,1-.538-.471l-.134-1.68a.505.505,0,1,1,1.008-.067l.134,1.68A.476.476,0,0,1,37.857,7.889Z" transform="translate(-22.128 -2.36)"></path>
              <path fill="#199094" d="M43.167,8.846a.481.481,0,0,1-.6-.336L42.092,6.9a.489.489,0,1,1,.941-.269L43.5,8.241A.481.481,0,0,1,43.167,8.846Z" transform="translate(-30.597 -2.712)"></path>
              <path fill="#199094" d="M48.246,10.82a.466.466,0,0,1-.672-.2l-.807-1.479a.5.5,0,1,1,.874-.471l.807,1.479A.48.48,0,0,1,48.246,10.82Z" transform="translate(-38.701 -3.409)"></path>
              </svg>
            </Col>
            <p
              style={{
                fontSize: 20,
                marginLeft: -5,
                marginTop: 25,
                font: "normal normal bold 20px/12px AvenirBook",
                color: "#199094",
                textAlign: "left",
              }}
            >
              Reflection about your Process
            </p>
          </Row>
          <div className="" onKeyDown={this.onKeyPressed}>
            <p style={{ marginTop: 50, fontSize: "17px", fontWeight: "bold", }}>1. What went well this week?</p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_1"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90, fontSize: "17px", fontWeight: "bold", }}>2. What didn't go well this week?</p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_2"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90, fontSize: "17px", fontWeight: "bold", }}>
              3. Based on what didn’t go so well, how will you improve your
              process next week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_3"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90, fontSize: "17px", fontWeight: "bold", }}>
              4. What did you learn from online research this week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_4"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90, fontSize: "17px", fontWeight: "bold", }}>
              5. What did you learn from your networking this week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_5"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90, fontSize: "17px", fontWeight: "bold", }}>
              6. What experiential learning opportunities did you learn about
              this week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_6"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90, fontSize: "17px", fontWeight: "bold", }}>
              7. What questions do you have for your coach?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_7"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <p
            style={{
              marginTop: 72,
              font: "normal normal bold 20px/12px AvenirBook",
              color: "#111111",
            }}
          >
            Additional Reflections
          </p>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "bold normal normal 17px/44px AvenirBook",
                color: "#414141",
              }}
            >
              How stressed are you about figuring out your next career step?
            </p>
            <Form.Item name="stress_level" noStyle>
              <Slider style={{ width: "50%" }} min={0} max={10} tooltipVisible />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#111111", fontSize: 16 }}>
                  I’m very relaxed
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#111111", fontSize: 16 }}>
                  I’m feeling extremely stressed
                </p>
              </Col>
            </Row>
          </div>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "bold normal normal 17px/44px AvenirBook",
                color: "#414141",
              }}
            >
              What is your level of clarity on your ideal career path?
            </p>
            <Form.Item name="clarity_level" noStyle>
              <Slider style={{ width: "50%" }} min={0} max={10} tooltipVisible />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#111111", fontSize: 16 }}>
                  I have no idea what I want
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#111111", fontSize: 16 }}>
                  I know exactly what I want
                </p>
              </Col>
            </Row>
          </div>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "bold normal normal 17px/44px AvenirBook",
                color: "#414141",
              }}
            >
              How confident are you in how to approach the career exploration
              process?
            </p>
            <Form.Item name="confidence_level" noStyle>
              <Slider style={{ width: "50%" }} min={0} max={10} tooltipVisible />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#111111", fontSize: 16 }}>
                  I have no clue what to do
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#111111", fontSize: 16 }}>
                  I know exactly what to do
                </p>
              </Col>
            </Row>
          </div>

          {/* <Button
            style={{
              width: 255,
              height: 42,
              marginTop: 35,
              marginLeft: "80%",
              background: "#49CDE1 0% 0% no-repeat padding-box",
              font: "normal normal bold 16px/44px AvenirBook",
              paddingTop: 0,
            }}
            type="primary"
            htmlType="submit"
            disabled={this.props.data.completed || this.isOverdue()}
          >
            Submit to Coach 2
          </Button> */}
          <button
            style={{
              width: 255,
              height: 42,
              marginTop: 35,
              marginLeft: "80%",
            }}
            className="button-main"
            type="primary"
            htmlType="submit"
            disabled={this.props.data.completed || this.isOverdue()}
          >
            Submit to Coach
          </button>
        </div>
      </Form>
    );
  }
}
