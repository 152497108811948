import { AlignLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker, Input, Popover, Select, Tag } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Filterdropdown from "./Filterdropdown";

const { Option } = Select;

const category_mapping = {
  fr: "Friends",
  aq: "Acquaintances",
  pc: "Past Colleagues",
  so: "Social Organizations",
  al: "Alumni",
  tr: "Target Roles",
  ti: "Target Industries",
  tc: "Target Companies",
  rc: "Recruiters",
  cc: "Cold Connections",
};

const baseColumns = (
  addNewCol,
  onChange,
  showDrawer,
  getRoles,
  getIndustries
) => {
  return [
    {
      title: "sno",
      dataIndex: "sno",
      key: "sno",
      fixed: "left",
      colSpan: 0,
      width: 45,
      render: (text, rec, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      className: "no-space",
      fixed: "left",
      colSpan: 2,
      width: 180,
      // onHeaderCell: (column) => ({
      //   width: column.width,
      //   onResize: this.handleResize(0),
      // }),
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <a style={{ color: "#32AAB0" }} onClick={() => showDrawer(record)}>
          {text}
        </a>
      ),
    },

    {
      title: "Contact Info".toUpperCase(),
      key: "contactinfo",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          className="filter-dropdown"
          val={"contactinfo"}
        />
      ),
      className: "col-1",
      children: [
        // {
        //   title: "Name",
        //   dataIndex: "title",
        //   key: "title",
        //   className: "no-space",
        //   width: 180,
        //   // onHeaderCell: (column) => ({
        //   //   width: column.width,
        //   //   onResize: this.handleResize(0),
        //   // }),
        //   sorter: (a, b) => a.title.localeCompare(b.title),
        //   render: (text, record) => (
        //     <a style={{ color: "#32AAB0" }} onClick={() => showDrawer(record)}>
        //       {text}
        //     </a>
        //   ),
        // },
        {
          title: "Type",
          dataIndex: "contact_type",
          key: "contact_type",
          width: 210,
          sorter: (a, b) => a.contact_type.localeCompare(b.contact_type),
          className: "no-space",
          render: (text, record) => (
            <Select
              key={`type-for-${text}-${record.id}`}
              onChange={(val) => onChange(val, record, "contact_type")}
              defaultValue={category_mapping[text]}
              style={{ maxWidth: "100%", minWidth: "100%" }}
              bordered={false}
            >
              {[...Object.values(category_mapping)].map((item) => (
                <Option
                  key={record.id + "type" + item}
                  value={
                    Object.keys(category_mapping)[
                      Object.values(category_mapping).indexOf(item)
                    ]
                  }
                >
                  {item}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Role",
          dataIndex: "roles",
          key: "roles",
          className: "no-space",
          width: 250,
          sorter: (a, b) =>
            a.roles.length ? a.roles.localeCompare(b.roles) : true,
          render: (text, record) => (
            <Select
              key={`role-for-${text}-${record.id}`}
              onChange={(val) => onChange(val, record, "roles")}
              defaultValue={text}
              style={{ maxWidth: "100%", minWidth: "100%" }}
              bordered={false}
            >
              {getRoles().map((item) => (
                <Option value={item.title}>{item.title}</Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Industry",
          dataIndex: "industries",
          key: "industries",
          className: "no-space",
          width: 250,
          sorter: (a, b) =>
            a.industries.length
              ? a.industries.localeCompare(b.industries)
              : true,
          render: (text, record) => (
            <Select
              key={`ind-for-${text}-${record.id}`}
              onChange={(val) => onChange(val, record, "industries")}
              defaultValue={text}
              style={{ maxWidth: "100%", minWidth: "100%" }}
              bordered={false}
              dropdownClassName="option-style"
            >
              {getIndustries().map((item) => (
                <Option key={record.id + "ind"} value={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          ),
        },
      ],
    },
    {
      title: "Reach Out".toUpperCase(),
      key: "reachout",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          className="filter-dropdown"
          val={"reachout"}
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Initial reach-out date",
          dataIndex: "initial",
          key: "initial",
          sorter: (a, b) =>
            a.initial ? a.initial.localeCompare(b.initial) : true,
          className: "no-space",
          width: 300,
          render: (text, record) => (
            <>
              {React.createElement(() => (
                <DatePicker
                  key={"initial" + record.id}
                  onChange={(date, dateString) =>
                    onChange(dateString, record, "initial")
                  }
                  defaultValue={text ? moment(text) : null}
                  bordered={false}
                ></DatePicker>
              ))}
            </>
          ),
        },
        {
          title: "Follow up Date",
          dataIndex: "follow",
          key: "follow",
          sorter: (a, b) =>
            a.follow ? a.follow.localeCompare(b.follow) : true,
          className: "no-space",
          width: 250,
          render: (text, record) => (
            <>
              {React.createElement(() => (
                <DatePicker
                  key={"follow" + record.id}
                  onChange={(date, dateString) =>
                    onChange(dateString, record, "follow")
                  }
                  // defaultValue={text ? moment(text):null}
                  defaultValue={
                    record.tracker_info["follow"]
                      ? moment(record.tracker_info["follow"])
                      : null
                  }
                  bordered={false}
                ></DatePicker>
              ))}
            </>
          ),
        },
      ],
    },
    {
      title: "Introductions".toUpperCase(),
      key: "intro",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"intro"}
          className="filter-dropdown"
        />
      ),
      className: "col-1",

      children: [
        {
          title: "New Contacts",
          dataIndex: "contact",
          key: "contact",
          className: "no-space",
          sorter: (a, b) =>
            a.contact ? a.contact.localeCompare(b.contact) : true,
          width: 250,
          render: (text, record) => (
            <Input
              key={`newcontact-${text}-${record.id}`}
              onChange={(val) => onChange(val.target.value, record, "contact")}
              defaultValue={text}
            />
          ),
        },
      ],
    },
    {
      title: "Before Call".toUpperCase(),
      key: "beforecall",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"beforecall"}
          className="filter-dropdown"
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Call Date",
          dataIndex: "scheduled",
          key: "scheduled",
          sorter: (a, b) =>
            a.scheduled ? a.scheduled.localeCompare(b.scheduled) : true,
          className: "no-space",
          width: 200,
          render: (text, record) => (
            <>
              {React.createElement(() => (
                <DatePicker
                  key={"scheduled" + record.id}
                  onChange={(date, dateString) =>
                    onChange(dateString, record, "scheduled")
                  }
                  defaultValue={text ? moment(text) : null}
                  bordered={false}
                ></DatePicker>
              ))}
            </>
          ),
        },
        {
          title: "Call Prep",
          dataIndex: "callprep",
          key: "callprep",
          className: "no-space",
          width: 200,
          sorter: (a, b) => a.callprep > b.callprep,
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {text ? (
                <Link to={{ pathname: "/networking/call-prep", id: record.id }}>
                  <AlignLeftOutlined className="row-btn" />
                </Link>
              ) : (
                <Link to={{ pathname: "/networking/call-prep", id: record.id }}>
                  <PlusOutlined className="row-btn" />
                </Link>
              )}
            </div>
          ),
        },
        {
          title: "Call Prep Done?",
          dataIndex: "callprepdone",
          key: "callprepdone",
          width: 250,
          sorter: (a, b) => a.callprepdone > b.callprepdone,
          className: "no-space",
          render: (text, record) => (
            // <div style={{ textAlign: "center" }}>
            //   {text ? (
            //     <Tag color="#54D1C1">Done</Tag>
            //   ) : (
            //     <Tag color="#AEB7C2">Not started</Tag>
            //   )}
            // </div>
            <Select
              key={`callpdone${record.id}`}
              defaultValue={text ? text : "Not Started"}
              style={
                text === "Done"
                  ? {
                      backgroundColor: "#e5f2f3",
                      borderRadius: "15px",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                    }
                  : text === "In Progress"
                  ? {
                      backgroundColor: "#FDECC8",
                      borderRadius: "15px",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                    }
                  : {
                      backgroundColor: "#D3E5EF",
                      borderRadius: "15px",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                    }
              }
              bordered={false}
              onSelect={(val) => onChange(val, record, "callprepdone")}
            >
              <Option key={record.id + "callpdone1"} value="Not Started">
                Not Started
              </Option>
              <Option key={record.id + "callpdone2"} value="In Progress">
                In Progress
              </Option>
              <Option key={record.id + "callpdone3"} value="Done">
                Done
              </Option>
            </Select>
          ),
        },
      ],
    },
    {
      title: "Call".toUpperCase(),
      key: "call",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"call"}
          className="filter-dropdown"
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Call Notes",
          dataIndex: "callnotes",
          key: "callnotes",
          className: "no-space",
          width: 200,
          sorter: (a, b) => a.callprep > b.callnotes,
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {text ? (
                <Link
                  to={{ pathname: "/networking/call-notes", id: record.id }}
                >
                  <AlignLeftOutlined className="row-btn" />
                </Link>
              ) : (
                <Link
                  to={{ pathname: "/networking/call-notes", id: record.id }}
                >
                  <PlusOutlined className="row-btn" />
                </Link>
              )}
            </div>
          ),
        },
      ],
    },
    {
      title: "After Call".toUpperCase(),
      key: "aftercall",
      filterIcon: () => <PlusOutlined className="filter-dropdown" />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"aftercall"}
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Follow up and Next Steps",
          dataIndex: "followup",
          key: "followup",
          className: "no-space",
          width: 350,
          sorter: (a, b) =>
            a.followup ? a.followup.localeCompare(b.followup) : true,
          render: (text, record) => (
            <Input
              key={`${text}-${record.id}`}
              onChange={(val) => onChange(val.target.value, record, "followup")}
              defaultValue={text}
            />
          ),
        },
      ],
    },
  ];
};

const extraColumnTemplate = (
  key,
  title,
  parent_col,
  onChange,
  onHeaderCell
) => ({
  title: (
    <Popover
      placement="topLeft"
      content={<div>Right click to edit</div>}
      trigger="hover"
    >
      {" "}
      {title}
    </Popover>
  ),
  col_name: title,
  parent_col: parent_col,
  dataIndex: `${key}`,
  key: `${key}`,
  className: "no-space",
  width: 250,
  onHeaderCell: onHeaderCell,
  sorter: (a, b) =>
    a[`${key}`] ? a[`${key}`].localeCompare(b[`${key}`]) : true,
  render: (text, record) => (
    <Input
      key={`${text}-${record.id}`}
      onChange={(val) => {
        onChange(val.target.value, record, `${key}`);
      }}
      defaultValue={text}
    />
  ),
});

export { baseColumns, category_mapping, extraColumnTemplate };