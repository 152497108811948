import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { Card, Row, Col, Steps } from "antd";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
// import "antd/dist/antd.css";
import "./Networking.css";
import Contacts from "./Contacts/Contacts";
import DueDate from "../Common/DueDate";
import Stepinfo from "../Modals/Stepinfo";
import Email from "./Email/Email";
import CallPrep from "./CallPrep/CallPrep";
import CallNotes from "./CallNotes/CallNotes";
import Trackerbase from "./Tracker/Trackerbase";
import {
  selectNetworkingStepInfo,
  setStepNotifiedAsync,
  updateQuickReflection,
  selectStepReflections,
} from "slices/dashboardSlice";

const { Step } = Steps;

class NetworkingBase extends React.Component {
  state = {
    current: this.props.current_step ? this.props.current_step : 0,
    collapsed: false, //menu collapse
    showInfo: null, //show/hide infobox
    setTarget: null, //show/hide duedate component
    curr_comp_target_date: {
      0: "",
      1: "",
      2: "",
      3: "",
      4: "",
    }, //date coming from Duedate.js
  };

  // checks if step is locked or not
  isLocked = (step) =>
    this.props.stepReflections[step]
      ? this.props.stepReflections[step].status === "LO"
      : false;

  //opens set date box (pink colored) if date in header is clicked
  setDateAgain = () => {
    this.setState({
      setTarget: true,
    });
  };

  //close button handling
  onCloseClick = () => {
    this.setState({
      setTarget: false,
    });
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.props.toggle();
  };

  changeRoute = () => {
    this.props.history.push("/dashboard");
  };

  takesNotes = (id) => {
    this.props.history.push({
      // "/networking/call-notes", { response: id });
      pathname: "/networking/call-notes",
      response: id,
    });
  };

  notifyStep = () => {
    if (
      this.props.stepInfo[this.state.current] &&
      !this.props.stepInfo[this.state.current].notified &&
      this.props.impersonated
    ) {
      this.props.setStepNotifiedAsync(
        this.props.stepInfo[this.state.current].id,
        this.props.stepInfo[this.state.current].step
      );
    }
  };

  onDateSet = () => {
    // setTimeout(() => {
    this.onCloseClick();
    // }, 6000);
  };

  newReleased = () =>
    this.props.stepInfo[this.state.current] &&
    !this.props.stepInfo[this.state.current].notified;

  showInfo = () =>
    this.state.showInfo === true ||
    (this.state.showInfo === null && this.newReleased());

  targetSet = () =>
    this.state.setTarget === true ||
    (this.state.setTarget === null &&
      !!this.props.stepInfo[this.state.current] &&
      this.props.stepInfo[this.state.current].complete_by === null &&
      !this.props.location.id &&
      this.props.stepInfo[this.state.current].status != 'DO' &&
      this.state.current !== 4);

  stepId = () =>
    !!this.props.stepInfo[this.state.current]
      ? this.props.stepInfo[this.state.current].id
      : -1;

  onSubmit = () => {
    this.props.updateQuickReflection({
      stepId: this.stepId(),
      show: true,
    });
  };

  render() {
    const steps = [
      {
        title: "Contacts",
        content: <Contacts onSubmit={this.onSubmit} />,
      },
      {
        title: "Email Drafts",
        content: <Email onSubmit={this.onSubmit} />,
      },
      {
        title: "Tracker",
        content: <Trackerbase />,
      },
      {
        title: "Call Prep",
        content: <CallPrep takesNotes={this.takesNotes} {...this.props} />,
      },
      {
        title: "Call Notes",
        content: <CallNotes {...this.props} />,
      },
    ];

    return (
      <div style={{ padding: "4% 0" }}>
        <div className="collapse-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? (
            <RightCircleFilled className="make-white" />
          ) : (
            <LeftCircleFilled className="make-white" />
          )}
        </div>

        <Card
          bordered={false}
          style={{
            margin: "20px !important",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "13px",
            textAlign: "left",
            marginRight: "6%",
            marginLeft: "20px",
            padding: "0 2%",
          }}
        >
          <div>
            <Row style={{ padding: "4% 0% 3% 0%" }}>
              <Col
                span={18}
                style={{
                  font: "normal normal 300 30px/12px AvenirBook",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {steps[this.state.current].title}&nbsp;
                <span
                  className={`just-released ${
                    this.newReleased() ? "" : "hidden"
                  }`}
                >
                  NEW
                </span>
                {this.props.stepInfo[this.state.current] && (
                  // this.props.stepInfo[this.state.current].complete_by !== null &&
                  <span
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      position: "relative",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={this.setDateAgain}
                  >
                    <img
                      src="https://django-woken-static.s3.amazonaws.com/static/media/calendaricon.c713d4db.svg"
                      style={{ position: "relative", top: "-3px" }}
                    ></img>
                    &nbsp;&nbsp;
                    {this.props.stepInfo[this.state.current].complete_by
                      ? "Due date"
                      : "Set Due Date"}
                    &nbsp;
                    {this.props.stepInfo[this.state.current] &&
                      this.props.stepInfo[this.state.current].complete_by &&
                      Moment(
                        this.props.stepInfo[this.state.current].complete_by
                      ).format("MM-DD-YYYY")}
                  </span>
                )}
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  marginTop: "-20px",
                }}
              >
                <a
                  href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                  target="_blank"
                  style={{
                    marginRight: "10px",
                   
                      cursor:"pointer"
                   
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="mail_2_" data-name="mail (2)" transform="translate(-0.001)">
                      <g id="Group_2502" data-name="Group 2502" transform="translate(0.001)">
                        <path id="Path_1376" data-name="Path 1376" d="M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm0,4.917,8.285,5.166H5.716Zm8.388,13.416h0a1.5,1.5,0,0,1-1.5,1.5H7.114a1.5,1.5,0,0,1-1.5-1.5V10.412a1.492,1.492,0,0,1,.024-.257l7.915,4.936.03.016.032.017a.831.831,0,0,0,.172.067l.018,0a.854.854,0,0,0,.2.025h0a.839.839,0,0,0,.2-.025l.018,0a.839.839,0,0,0,.172-.067l.032-.017.03-.016,7.915-4.936a1.49,1.49,0,0,1,.024.257v7.921Z" transform="translate(-0.001)" fill="#199094"/>
                      </g>
                    </g>
                  </svg>
                </a>
                <span
                  onClick={() =>
                    this.setState({
                      showInfo: true,
                    })
                  }
                  style={{
                    cursor:"pointer"
                  }}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" style={{marginTop: "5px"}}>
                  <path id="question" d="M14,0A14,14,0,1,0,28,14,14.015,14.015,0,0,0,14,0Zm0,22.167A1.167,1.167,0,1,1,15.167,21,1.167,1.167,0,0,1,14,22.167Zm1.847-7.418a1.172,1.172,0,0,0-.68,1.061v.524a1.167,1.167,0,1,1-2.333,0V15.81a3.515,3.515,0,0,1,2.036-3.18,3.813,3.813,0,0,0,2.047-2.713,2.917,2.917,0,1,0-5.833,0,1.167,1.167,0,1,1-2.333,0,5.25,5.25,0,1,1,10.5,0,6.079,6.079,0,0,1-3.4,4.832Zm0,0" fill="#199094"/>
                </svg>
                </span>
              </Col>
            </Row>
            <p
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #F0F0F0",
              }}
            ></p>
          </div>
          {this.showInfo() ? (
            <Stepinfo
              stepname={steps[this.state.current].title}
              closeInfo={() => {
                this.notifyStep();
                this.setState({
                  showInfo: false,
                });
              }}
            />
          ) : this.targetSet() ? (
            <div>
              <DueDate
                stepName={steps[this.state.current].title === 'Tracker'? 'Host Networking Calls' : steps[this.state.current].title }
                stepKey={this.props.stepInfo[this.state.current].step}
                onDateSet={this.onDateSet}
                onCloseClick={this.onCloseClick}
                stepId={this.props.stepInfo[this.state.current].id}
                excludeFinish={ steps[this.state.current].title === 'Tracker' }
                targetDate={
                  this.props.stepInfo[this.state.current].complete_by !== null
                    ? Moment(
                        this.props.stepInfo[this.state.current].complete_by
                      )
                    : null
                }
              />
              <div style={{ opacity: "0.4", pointerEvents: "none" }}>
                <Steps
                  current={this.state.current}
                  onChange={(current) => this.setState({ current: current })}
                  className="networking-nav"
                >
                  {/* {steps.map(item => (
                                <Step key={item.title} title={item.title.toUpperCase()} onClick={()=>{<Redirect to='/dashboard'/>}}/>
                            ))} */}
                  <Step
                    key="1"
                    title={steps[0].title}
                    disabled={this.isLocked("CO") ? true : false}
                    onClick={() => {
                      if (!this.isLocked("CO"))
                        this.props.history.push("/networking/contacts");
                    }}
                    status={this.state.current === 0 ? "process" : "wait"}
                  />
                  <Step
                    key="2"
                    title={steps[1].title}
                    onClick={() => {
                      if (!this.isLocked("ED"))
                        this.props.history.push("/networking/email-drafts");
                    }}
                    disabled={this.isLocked("ED") ? true : false}
                    status={this.state.current === 1 ? "process" : "wait"}
                  />
                  <Step
                    key="3"
                    title={steps[2].title}
                    disabled={this.isLocked("NT") ? true : false}
                    onClick={() => {
                      if (!this.isLocked("NT"))
                        this.props.history.push("/networking/tracker");
                    }}
                    status={this.state.current === 2 ? "process" : "wait"}
                  />
                  <Step
                    key="4"
                    title={steps[3].title}
                    disabled={this.isLocked("CP") ? true : false}
                    onClick={() => {
                      if (!this.isLocked("CP"))
                        this.props.history.push("/networking/call-prep");
                    }}
                    status={this.state.current === 3 ? "process" : "wait"}
                  />
                  <Step
                    key="5"
                    title={steps[4].title}
                    disabled={this.isLocked("CN") ? true : false}
                    onClick={() => {
                      if (!this.isLocked("CN"))
                        this.props.history.push("/networking/call-notes");
                    }}
                    status={this.state.current === 3 ? "process" : "wait"}
                  />
                </Steps>

                {steps[this.state.current].content}
              </div>
            </div>
          ) : (
            <div>
              <Steps
                current={this.state.current}
                onChange={(current) => this.setState({ current: current })}
                className="networking-nav"
              >
                {/* {steps.map(item => (
                                <Step key={item.title} title={item.title.toUpperCase()} />
                            ))} */}
                <Step
                  key="1"
                  title={steps[0].title}
                  onClick={() => {
                    if (!this.isLocked("CO"))
                      this.props.history.push("/networking/contacts");
                  }}
                  status={this.state.current === 0 ? "process" : "wait"}
                  disabled={this.isLocked("CO") ? true : false}
                />
                <Step
                  key="2"
                  title={steps[1].title}
                  onClick={() => {
                    if (!this.isLocked("ED"))
                      this.props.history.push("/networking/email-drafts");
                  }}
                  status={this.state.current === 1 ? "process" : "wait"}
                  disabled={this.isLocked("ED") ? true : false}
                />
                <Step
                  key="3"
                  title={steps[2].title}
                  disabled={this.isLocked("NT") ? true : false}
                  onClick={() => {
                    if (!this.isLocked("NT"))
                      this.props.history.push("/networking/tracker");
                  }}
                  status={this.state.current === 2 ? "process" : "wait"}
                />
                <Step
                  key="4"
                  title={steps[3].title}
                  onClick={() => {
                    if (!this.isLocked("CP"))
                      this.props.history.push("/networking/call-prep");
                  }}
                  status={this.state.current === 3 ? "process" : "wait"}
                  disabled={this.isLocked("CP") ? true : false}
                />
                <Step
                  key="5"
                  title={steps[4].title}
                  onClick={() => {
                    if (!this.isLocked("CN"))
                      this.props.history.push("/networking/call-notes");
                  }}
                  status={this.state.current === 4 ? "process" : "wait"}
                  disabled={this.isLocked("CN") ? true : false}
                />
              </Steps>
              {steps[this.state.current].content}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stepInfo: selectNetworkingStepInfo(state),
  stepReflections: selectStepReflections(state),
});

const mapDispatchToProps = () => ({
  setStepNotifiedAsync,
  updateQuickReflection,
});

export default connect(mapStateToProps, mapDispatchToProps())(NetworkingBase);