import React from "react";
import { Row, Col, Form, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const divstyle = {
  position: "relative",
  display: "block",
  marginBottom: 15,
  before: {
    content: "I want to: ",
    position: "absolute",
    top: "6px",
    left: "6px",
    zIndex: "99",
    fontSize: "14px",
  },
};
export default class Freeformbox extends React.Component {
  render() {
    // console.log(this.props.q_number, this.props.default_len)
    const formname = this.props.q_number;
    return (
      <Row gutter={36}>
        <Col span={16}>
          <Form.List
            name={this.props.q_number}
            rules={[
              {
                validator: async (_, formname) => {
                  if (!formname) {
                    return Promise.reject(
                      new Error("Please fill atleast 1 field")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {Array.from(Array(this.props.default_len).keys()).map(
                  (item, index) =>
                    index !== 0 ? (
                      <div
                        className={"cont-" + this.props.q_number}
                        style={{ marginBottom: 15 }}
                      >
                        <Form.Item name={index.toString()}>
                          <TextArea
                            autoSize={{ minRows: 2, maxRows: 60 }}
                            className={"text-" + this.props.q_number}
                          >
                            {" "}
                          </TextArea>
                        </Form.Item>
                      </div>
                    ) : (
                      <div
                        className={"cont-" + this.props.q_number}
                        style={{ marginBottom: 15 }}
                      >
                        <Form.Item
                          name={index.toString()}
                          rules={[
                            { required: true, message: "Required Field" },
                          ]}
                          validateTrigger={["onBlur"]}
                        >
                          <TextArea
                            autoSize={{ minRows: 2, maxRows: 60 }}
                            className={"text-" + this.props.q_number}
                          >
                            {" "}
                          </TextArea>
                        </Form.Item>
                      </div>
                    )
                )}
              </>
            )}
          </Form.List>
          <Form.List name={this.props.q_number + "_added"}>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div
                      style={{ marginBottom: 15 }}
                      className={"cont-" + this.props.q_number}
                      
                    >
                      <Form.Item
                        name={index.toString()}
                        style={{ marginBottom: 15 }}
                      >
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 60 }}
                          className={"text-" + this.props.q_number}
                        >
                          {" "}
                        </TextArea>
                      </Form.Item>
                    </div>
                  ))}
                  <style>
                    {`.assessment-custom-inline-style { 
                      color: #199094 !important;
                      border-radius: 12px;
                    }`}
                  </style>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                    size="large"
                    style={{ color: "#199094" }}
                    className="assessment-custom-inline-style"
                  >
                    <PlusOutlined /> Add field
                  </Button>
                </div>
              );
            }}
          </Form.List>
        </Col>
        <Col span={8}></Col>
      </Row>
    );
  }
}