import { Button, Table, message } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  selectConfigurations,
  updateProfileAsync,
} from "slices/dashboardSlice";
import { selectTargetIndustries } from "slices/targetIndustriesSlice";
import { selectTargetRoles } from "slices/targetRolesSlice";
import HELPERS from "utils/helper";
import AUTH_API from "../../../api/auth";
import Companydrawer from "./Companydrawer";
import Renamecol from "./Renamecol";
import "./Tracker.css";
import { baseColumns, extraColumnTemplate } from "./utilities";

class Tracker extends React.Component {
  state = {
    rename_col: { show: false, curr: {} },
    show_drawer: { show: false, curr: {} },
    loading: false,
    companies: [],
  };

  defaultColumns = () => {
    const columns = {
      companyinfo: [
        { title: "Company name", key: "name" },
        { title: "Industry", key: "industry" },
        { title: "Company size", key: "company_size" },
      ],
      contact_at_company: [
        { title: "Contact name", key: "contact_name" },
        { title: "Scheduled call date", key: "scheduled_date" },
        { title: "Call prep", key: "callprep" },
      ],
      role: [
        { title: "Source", key: "source" },
        { title: "Title", key: "role_title" },
        { title: "Job posting link", key: "job_link" },
      ],
      application_interviews: [
        { title: "Date applied", key: "date_applied" },
        { title: "Phone Screening", key: "phone_screening" },
        { title: "Interview Round #2", key: "interview_2" },
        { title: "Interview Round #3", key: "interview_3" },
      ],
      interest_level: [
        { title: "Role", key: "role_interest" },
        { title: "Industry", key: "industry_interest" },
      ],
      aftercall: [{ title: "Follow up and next steps", key: "followup" }],
    };

    const configurations = this.props.configurations.application_tracker;

    for (let colKey in columns) {
      if (configurations) {
        if (configurations[colKey]) {
          for (var column of configurations[colKey]) {
            if (!column.deleted) {
              columns[colKey].push({ title: column.title, key: column.key });
            }
          }
        }
      }
    }

    return columns;
  };

  columns = () => {
    const columns = baseColumns(
      this.addNewCol,
      this.onChange,
      this.showDrawer,
      this.getRoles,
      this.getSize,
      this.getIndustries,
      this.onChangeContact,
      this.onPostContact,
      this.onDeleteContact
    );

    if (
      this.props.configurations &&
      this.props.configurations.application_tracker
    ) {
      const configurations = this.props.configurations.application_tracker;

      for (let index = 0; index < columns.length; ++index) {
        if (configurations[columns[index].key]) {
          for (var column of configurations[columns[index].key]) {
            if (!column.deleted) {
              columns[index].children.push(
                extraColumnTemplate(
                  column.key,
                  column.title,
                  columns[index].key,
                  this.onChange,
                  this.onHeaderCell
                )
              );
            }
          }
        }
      }
    }

    return columns;
  };

  addNewCol = (child, parent) => {
    HELPERS.log("add new in parent", child, parent);
    let configurations = this.configurations();

    if (!configurations.application_tracker) {
      if (Array.isArray(configurations)) {
        configurations = {};
      }
      configurations.application_tracker = {};
    }

    if (!configurations.application_tracker[parent]) {
      configurations.application_tracker[parent] = [];
    }

    configurations.application_tracker[parent].push({
      title: child,
      key: `col_${
        configurations.application_tracker[parent].length + 1
      }-${parent}`,
      deleted: false,
    });

    this.props.updateProfileAsync(
      {
        configurations,
      },
      "AT"
    );
  };

  onChange = HELPERS.debounceFormChanges((val, rec, type) => {
    console.log(val, rec, type);
    const patch_data = {};
    let tracker_data = {};
    patch_data[type] = val;
    console.log(patch_data);
    let outside_tracker = ["name", "industry", "company_size"];
    if (outside_tracker.filter((item) => item === type).length) {
      console.log("in if", patch_data);
      tracker_data = patch_data;
    } else {
      tracker_data = { ...rec.tracker_info, ...patch_data };
      tracker_data = { tracker_info: tracker_data };
      console.log("in else", tracker_data);
    }
    AUTH_API.patchCompany(tracker_data, rec.id)
      .then((response) => {
        console.log("patch company !!", response);
        this.getAllData();
        message.success('Your work has been saved');
      })
      .catch((err) => console.log("Patch Api error", err));
  });

  getIndustries = () => {
    return this.props.industries
      ? this.props.industries
          .filter((item) => item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };

  getSize = () => {
    return [
      "Small (1-100)",
      "Small-Medium (100-1k) ",
      "Medium (1k-5k)",
      "Medium-Large (5k-10k)",
      "Large (10k +)",
    ];
  };

  getRoles = () => {
    return this.props.roles
      ? this.props.roles
          .filter((item) => item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };

  getAllData = (out_state) => {
    //get all companies and map them for table
    AUTH_API.getAllCompanies()
      .then((response) => {
        console.log("get companies !!", response);
        let companies = response.data;

        AUTH_API.getAllContacts()
          .then((response) => {
            console.log("all contacts", response);
            let contacts =
              // [...response.data].slice(0, 6)
              [...response.data];
            if (companies.length) {
              companies = companies.map((item, index) => {
                let row_data = {
                  ...item,
                  ...item.tracker_info,
                  //---------------filter them with company name here-----------------
                  contact_name: contacts.filter(
                    (con) => con.tracker_info.company_id === item.id
                  ),
                  scheduled_date: contacts.filter(
                    (con) => con.tracker_info.company_id === item.id
                  ),
                  callprep: contacts.filter(
                    (con) => con.tracker_info.company_id === item.id
                  ),
                };
                return row_data;
              });

              HELPERS.log("companies !!", companies);

              // this.setState({
              //   companies: companies,
              //   loading: false,
              //   ...out_state
              // });
            }
          })
          .then((res) => {
            companies.sort((a, b) => {
              return a.id - b.id;
            });
            this.setState({
              companies: companies,
              loading: false,
              ...out_state,
            });
          })
          .catch((err) => {
            console.log("contact err", err);
          });
      })
      .catch((err) => console.log("no companies found!", err));
  };

  componentDidMount() {
    const configurations = this.configurations();
    console.log("in tracker didmount", configurations);
    if (!configurations.application_tracker) {
      configurations.application_tracker = {};
    }
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "AT"
    );

    this.getAllData();
  }

  showDrawer = (record) => {
    HELPERS.log({ record });
    this.setState({
      show_drawer: { show: true, curr: record },
    });
  };

  onClose = () => {
    console.log("onclose");
    this.getAllData({
      show_drawer: { show: false, curr: {} },
    });
  };

  onHeaderCell = (record) => ({
    onContextMenu: (event) => {
      event.preventDefault();
      this.setState({
        rename_col: { curr: record, show: true },
      });
    },
  });

  configurations = () => JSON.parse(JSON.stringify(this.props.configurations));

  renameCol = (newVal) => {
    let configurations = this.configurations();
    let parent =
      configurations.application_tracker[this.state.rename_col.curr.parent_col];

    for (let index = 0; index < parent.length; ++index) {
      if (parent[index].key === this.state.rename_col.curr.dataIndex) {
        parent[index].title = newVal.rename;
        break;
      }
    }

    this.closeRenameModal();
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "AT"
    );
  };

  deleteCol = () => {
    let configurations = this.configurations();
    let parent =
      configurations.application_tracker[this.state.rename_col.curr.parent_col];

    for (let index = 0; index < parent.length; ++index) {
      if (parent[index].key === this.state.rename_col.curr.dataIndex) {
        parent[index].deleted = true;
        break;
      }
    }

    this.closeRenameModal();
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "AT"
    );
  };

  closeRenameModal = () => {
    this.setState({
      rename_col: false,
      curr: {},
    });
  };

  addRowBelow = () => {
    const data = {
      title: "Add Company Here",
      industry: "",
      company_size: "",
      tracker_info: {},
      research_notes: [{}],
      call_prep: [{}],
      notes: "abcd",
      reflections: [{}],
      contact: "",
    };
    AUTH_API.postCompany(data)
      .then((response) => {
        console.log("post company !!", response);
        this.getAllData();
      })
      .catch((err) => console.log("Post Api error", err));
  };

  onPostContact = (rec) => {
    console.log("post contact", rec);
    const data = {
      title: "New Contact",
      country_code: "",
      phone: "",
      company_name: rec.title,
      email: "",
      linkedin: "",
      roles: "",
      industries: "",
      contact_type: "tc",
      selected: false,
      //see if anuthing breaks because of pre filled tracker_info
      tracker_info: { company_id: rec.id },
      call_prep: [],
      notes: "",
      reflections: [],
    };
    AUTH_API.setNewContact(data, "NT")
      .then((res) => {
        console.log("contact post", res);
        this.getAllData();
      })
      .catch((res) => console.log("np post", res));
  };

  onChangeContact = HELPERS.debounceFormChanges((val, rec, type) => {
    console.log("Newcontact patch", val, rec, type);
    if (val) {
      let patch_data = {};
      let tracker_data = {};
      patch_data[type] = val;
      if (type === "title") {
        tracker_data = patch_data;
      } else {
        tracker_data = { ...rec.tracker_info, ...patch_data };
        tracker_data = { tracker_info: tracker_data };
      }
      console.log("data to be patched", tracker_data, rec.id);
      AUTH_API.patchContact(tracker_data, rec.id)
        .then((response) => {
          console.log("patch res", response);
          this.getAllData();
          message.success('Your work has been saved');
        })
        .catch((err) => console.log("not patched", err));
    }
  });

  onDeleteContact = (id) => {
    AUTH_API.deleteContact(id, "NT")
      .then((response) => {
        console.log("delete res", response);
        this.getAllData();
      })
      .catch((err) => console.log("not patched", err));
  };

  render() {
    HELPERS.log("table state", this.state, this.configurations());
    return (
      <div>
        <p className="tracker-head">
          Reach out to your connections to gain introductions, hold informational networking calls and track the status of your conversations.
        </p>
        {/* =============================================================table here======================================================= */}
        <div className="minimized">
          {!this.state.loading ? (
            <Table
              dataSource={this.state.companies}
              onRow={this.onRow}
              columns={this.columns()}
              rowClassName="row-style"
              scroll={{ x: "calc(700px + 50%)", y: 640 }}
              pagination={false}
              showSorterTooltip={false}
              filterDropdownVisible={true}
              // components={this.components}
              // className='minimized'
              bordered
            />
          ) : (
            ""
          )}

          {this.state.show_drawer.show ? (
            <Companydrawer
              onClose={this.onClose}
              record={this.state.show_drawer.curr}
              show_drawer={this.state.show_drawer.show}
              columns={this.defaultColumns()}
              roles={this.getRoles()}
              industries={this.getIndustries()}
              sizes={this.getSize()}
              onChange={this.onChange}
            />
          ) : (
            ""
          )}

          {this.state.rename_col ? (
            <Renamecol
              visible={this.state.rename_col.show}
              col_name={this.state.rename_col.curr}
              closeRenameModal={this.closeRenameModal}
              handleOk={this.renameCol}
              deleteCol={this.deleteCol}
            />
          ) : (
            ""
          )}
          <button
            onClick={this.addRowBelow}
            style={{ margin: "30px 0", paddingLeft:"20px" , paddingRight:"20px"}}
            size="large"
            className="button-main"
          >
            Add New Company
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
  industries: selectTargetIndustries(state),
  configurations: selectConfigurations(state),
});

const mapDispatchToProps = () => ({
  updateProfileAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(Tracker);