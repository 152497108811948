import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Popconfirm, Row, Tooltip } from "antd";
import React from "react";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import "./Draggable.css";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "pointer" }} className="drag-hover">
    <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/drag.svg" />
  </span>
));

const SortableItem = SortableElement(
  ({ title, itemIndex, onChangeText, onDeleteItem }) => (
    <li className="list-item">
      <div className="title">
        <Row>
          <Col span={2}>
            <DragHandle />
          </Col>
          <Col span={20}>
            <Input.Group>
              <Input
                suffix={<EditOutlined className="editable" />}
                className="input-edit"
                onPressEnter={(e) => onChangeText(e, itemIndex)}
                onBlur={(e) => onChangeText(e, itemIndex)}
                style={{ margin: 0, paddingTop: 2 }}
                bordered={false}
                defaultValue={title}
                title={title}
              ></Input>
            </Input.Group>
          </Col>
          <Col span={2}>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                onDeleteItem(itemIndex);
              }}
            >
              {" "}
              <span style={{ cursor: "pointer" }} className="drag-hover">
                <img src="https://django-woken-static.s3.amazonaws.com/static/media/delete.037bee2b.svg" width="16px" />
              </span>
            </Popconfirm>
          </Col>
        </Row>
      </div>
    </li>
  )
);

const SortableList = SortableContainer(
  ({ items, onChangeText, onDeleteItem, onAddItem, addTitle, isDone }) => (
    <>
    <ul className="list">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${index}-${item.title}`}
          index={index}
          title={item.title}
          itemIndex={index}
          onChangeText={onChangeText}
          onDeleteItem={onDeleteItem}
        />
      ))}
      <li>
     
        {/* <Button
          type="primary"
          onClick={onAddItem}
          className="add-new"
          title={isDone ? addTitle : 'The target roles and industries step will allow you to translate your assessment reflections into potential relevant roles and industries. Continue completing your onboarding, assessment, and target roles and industries tabs in order to populate your target roles and industries.'}
          disabled={!isDone}
        >
          <PlusOutlined /> {addTitle}
  
        </Button> */}
    
      </li>
    </ul>
    <button
      type="primary"
      onClick={onAddItem}
      size="large"
      className="button-main my-target-role-industry-add-new-buttom"
      title={isDone ? addTitle : 'The target roles and industries step will allow you to translate your assessment reflections into potential relevant roles and industries. Continue completing your onboarding, assessment, and target roles and industries tabs in order to populate your target roles and industries.'}
      disabled={!isDone}
    ><PlusOutlined /> {addTitle}</button>
    </>
  )
);

const ListWithNumbers = ({
  items,
  onChangeText,
  onDeleteItem,
  onSortEnd,
  onAddItem,
  addTitle,
  isDone,
}) => (
  <>
    <ul className="numbering">
      {items.map((item, index) => (
        <li key={`${addTitle}-item-${index}`}>{index + 1}</li>
      ))}
    </ul>
    <SortableList
      useDragHandle
      items={items}
      onSortEnd={onSortEnd}
      onChangeText={onChangeText}
      onDeleteItem={onDeleteItem}
      onAddItem={onAddItem}
      addTitle={addTitle}
      isDone={isDone}
    />
    <div className="clearfix"></div>
  </>
);

export default ListWithNumbers;


// import { EditOutlined, PlusOutlined } from "@ant-design/icons";
// import { Button, Col, Input, Popconfirm, Row, Tooltip } from "antd";
// import React from "react";
// import {
//   SortableContainer,
//   SortableElement,
//   sortableHandle,
// } from "react-sortable-hoc";
// import "./Draggable.css";
 
// const DragHandle = sortableHandle(() => (
//   <div style={{
//     display: "flex",
//         justifyContent: "center",
//         alignItems: "center"
//   }}>
 
//   <span style={{ cursor: "pointer" }} className="drag-hover">
//     <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/drag.svg" />
//   </span>
//   </div>
// ));
 
// const SortableItem = SortableElement(
//   ({ title, itemIndex, onChangeText, onDeleteItem, buttonVisible}) => (
//     <>
//     <li className="list-item">
//       <div className={` ${buttonVisible ? 'title-new' : 'title'}`}>
//         <Row className="row-container">
//           <span className="index-numbering">{itemIndex+1}.</span>
//           <div className="draghandle">
//             <DragHandle />
//           </div>
//           <div
//             className="ant-col ant-col-xs-18 ant-col-sm-19 ant-col-md-19 ant-col-lg-20 ant-col-22"
//             style={{
//               display:"flex",
//               alignItems:"center"
//             }}
//           >
//             <Input.Group>
//               <Input
//                 suffix={<EditOutlined className="editable" />}
//                 className="input-edit"
//                 onPressEnter={(e) => onChangeText(e, itemIndex)}
//                 onBlur={(e) => onChangeText(e, itemIndex)}
//                 style={{ margin: 0, }}
//                 bordered={false}
//                 defaultValue={title}
//                 title={title}
//               ></Input>
              
//             </Input.Group>
           
 
           
//           </div>
         
         
 
//           <Col span={2}>
//             <Popconfirm
//               title="Are you sure you want to delete?"
//               onConfirm={() => {
//                 onDeleteItem(itemIndex);
//               }}
//             >
//               {" "}
//               <span style={{ cursor: "pointer" }} className="drag-hover">
//                 <img src="https://django-woken-static.s3.amazonaws.com/static/media/delete.037bee2b.svg" />
//               </span>
//             </Popconfirm>
//           </Col>
          
//         </Row>
//         {buttonVisible && (
//               <button className="target-roles-subbuttons element-hide-temporary">Full time</button> //Future Release Build
//             )}
//       </div>
//     </li>
//     </>
 
//   )
// );
 
// const SortableList = SortableContainer(
//   ({ items, onChangeText, onDeleteItem, onAddItem, addTitle, isDone ,buttonVisible}) => (
//     <ul className="list">
//       {items.map((item, index) => (
//         <SortableItem
//           key={`item-${index}-${item.title}`}
//           index={index}
//           title={item.title}
//           itemIndex={index}
//           onChangeText={onChangeText}
//           onDeleteItem={onDeleteItem}
//           addTitle={addTitle}
//           buttonVisible={buttonVisible}
//         />
//       ))}
//       <li>
     
//         <Button
//           type="dashed"
//           onClick={onAddItem}
//           className="add-new"
//           title={isDone ? addTitle : 'The target roles and industries step will allow you to translate your assessment reflections into potential relevant roles and industries. Continue completing your onboarding, assessment, and target roles and industries tabs in order to populate your target roles and industries.'}
//           disabled={!isDone}
//           style={{
//             marginTop:"5px"
//           }}
//         >
//           <PlusOutlined /> {addTitle}
  
//         </Button>
//       </li>
//     </ul>
//   )
// );
 
// const ListWithNumbers = ({
//   items,
//   onChangeText,
//   onDeleteItem,
//   onSortEnd,
//   onAddItem,
//   addTitle,
//   isDone,
//   buttonVisible,
// }) => (
//   <>
//     <SortableList
//       useDragHandle
//       items={items}
//       onSortEnd={onSortEnd}
//       onChangeText={onChangeText}
//       onDeleteItem={onDeleteItem}
//       onAddItem={onAddItem}
//       addTitle={addTitle}
//       isDone={isDone}
//       buttonVisible={buttonVisible}
//     />
//     <div className="clearfix"></div>
//   </>
// );
 
// export default ListWithNumbers;
