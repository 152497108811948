import { Col, Row } from "antd";
import React from "react";
import DraggablecompTI from "../Common/DraggablecompTI";
import "./Targetindustries.css";

export default class TIprioritize extends React.Component {
  state = {
    roles: [
      {
        key: "1",
        name: "Item 1",
        delete: "",
        index: 0,
      },
      {
        key: "2",
        name: "Item 2",
        delete: "",
        index: 1,
      },
      {
        key: "3",
        name: "Item 3",
        delete: "",
        index: 2,
      },
      {
        key: "4",
        name: "Item 4",
        delete: "",
        index: 3,
      },
      {
        key: "5",
        name: "Item 5",
        delete: "",
        index: 4,
      },
      {
        key: "6",
        name: "Item 6",
        delete: "",
        index: 5,
      },
    ],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  addNew = (size) => {
    const temp = [
      ...this.state.roles,
      {
        key: (size + 1).toString(),
        name: "Enter New Role",
        delete: "",
        index: size,
      },
    ];
    this.setState({
      roles: temp,
    });
  };

  render() {
    // console.log('trprioritize rendered', this.state.roles);
    const { roles } = this.state;
    return (
      <div>
        <p
          style={{
            color: "#199094",
            font: "normal normal 600 18px/22px AvenirBook",
          }}
        >
          Drag and drop the industries in order of how interested you are in
          them.
        </p>
        <Row>
          <Col span={10}>
            {/* could make 2 components, DraggablecompRoles and DraggablecompIndustries */}
            <DraggablecompTI
            // data={this.state.roles} addNew={()=>{this.addNew(this.state.roles.length)}}
              isDone={true}
            />
          </Col>
          <Col span={14}></Col>
        </Row>
      </div>
    );
  }
}