import React from "react";
import { Badge } from "antd";
import "antd/dist/antd.css";

const style = { paddingBottom: "30px" };

export default class AssessmentC2 extends React.Component {
  render() {
    return (
      <div>
        <p
          style={{
            font: "normal normal 600 20px/12px AvenirBook",
            color: "#414141",
            padding: "40px 0",
          }}
        >
          Why is this important?
        </p>

        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p style={style}>The assessment has three parts:</p>
        </div>
        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p>Function: What do you love doing? </p>
        </div>
        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p>Content: What content do you love?</p>
        </div>
        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p>Environment: What environment do you thrive in?</p>
        </div>
        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p style={style}>Be truthful! </p>
        </div>
        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p style={style}>Don't think too hard, go with your gut!</p>
        </div>
        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p style={style}>
            The process is fluid, so while this assessment will help you start
            the journey, your options and clarity will naturally evolve
            throughout the process. It's only a starting point so don't
            overthink it. Give it your best shot!
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <Badge color="#ACE3E6" />
          <p style={style}>
            Try not to think ahead to the next steps in the process, just focus
            on one step at a time.
          </p>
        </div>
      </div>
    );
  }
}
