import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";
import { selectProfile, updateQuickReflection } from "slices/dashboardSlice";
import AUTH_API from "../../../api/auth";
import EditableSummary from "./EditableSummary";
import GeneratedSummary from "./GeneratedSummary";
import "./ProfSummary.css";

class GS_wrapper extends React.Component {
  SummaryForm = React.createRef();

  state = {
    submitted: false,
    Api_response: "",
    loading: true,
    editable: false,
  };
  componentDidMount() {
    console.log("did_mount");
    this.getApi({});
  }
  getApi = (inp_state) => {
    AUTH_API.getMaterials()
      .then((response) => {
        console.log("get Api data", response);
        const form_values =
          response.data.length > 0 ? response.data[0].professional_summary : "";
        this.setState({
          Api_response: response.data.length > 0 ? response.data[0] : "",
          loading: false,
          ...inp_state,
        });
      })
      .catch((err) => console.log("prof summary get Api error!", err));
  };
  saveChanges = () => {
    this.getApi({ editable: false });
  };

  onSubmit = () => {
    this.setState({ ...this.state, submitted: true });
  };

  render() {
    console.log("generated summary wrapper rendered");
    return (
      <div>
        <h3 className="sum-head">
          {this.props.profile ? this.props.profile.first_name + "'s" : "User's"}
          <span className="sum-sub-heading"> Professional Summary</span>
        </h3>
        {this.state.loading ? (
          "...Generating"
        ) : (
          <div>
            {!this.state.editable ? (
              <div>
                <GeneratedSummary
                  stepId={this.props.stepId}
                  summary={{ ...this.state.Api_response.professional_summary }}
                  materials_id={this.state.Api_response.id}
                />
                <a
                  onClick={() => this.setState({ editable: true })}
                  className="button-secondary"
                  style={{ width:"7%",  textAlign:"center", float: "left" }}
                >
                  Edit
                </a>
                <button
                className="button-main"
                  type="primary"
                  onClick={() =>
                    this.props.updateQuickReflection({
                      stepId: this.props.stepId,
                      show: true,
                    })
                  }
                  style={{ float: "right", paddingLeft:"20px", paddingRight:"20px"}}
                >
                  Submit to Coach
                </button>
              </div>
            ) : (
              <div>
                <EditableSummary
                  summary={{ ...this.state.Api_response.professional_summary }}
                  materials_id={this.state.Api_response.id}
                />
                <div style={{ marginTop: "20px" }}>
                  <button
                   className="button-main"
                    type="primary"
                    onClick={() => this.saveChanges()}
                    style={{ float: "right" ,paddingLeft:"20px", paddingRight:"20px"}}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: selectProfile(state),
});

const mapDispatchToProps = () => ({
  updateQuickReflection,
});

export default connect(mapStateToProps, mapDispatchToProps())(GS_wrapper);
