import { Button, Form, Input, message } from "antd";
import DASHBOARD_API from "api/dashboard";
import React from "react";
import { connect } from "react-redux";
import {
  selectConfigurations,
  updateProfileAsync,
  updateQuickReflection,
} from "slices/dashboardSlice";
import HELPERS from "utils/helper";
import AddPrep from "./AddPrep";
import FormComponent from "./FormComponent";
import "./InterviewPrep.css";

const { TextArea } = Input;

const getAdditionalQ = (additional_q) => {
  console.log("additional ques:", additional_q);
  return additional_q;
};

// let additional_q = []

class InterviewPrep extends React.Component {
  formRef = React.createRef();

  state = {
    submitted: false,
    Api_response: [],
    added_q: [
      //     {
      //     id:25,
      //     title:'Enter Question Here',
      // }
    ],
    card_id: 99,
    config: {},
  };

  additional_q = [];

  configurations = (config) => JSON.parse(JSON.stringify(config));

  componentDidMount() {
    window.scrollTo(0, 0);
    DASHBOARD_API.get()
      .then((response) => {
        console.log("dashborad Api res in interviewprep", response);
        const config = this.configurations(response.data.configurations);
        console.log("config:", config);
        if (config && config.interview_prep) {
          this.formRef.current.setFieldsValue({
            ...config.interview_prep,
          });
          const keys = Object.keys(config.interview_prep).filter((q_no) => {
            if (q_no > 23) return q_no;
          });
          console.log("keys ", keys);
          this.setState({ config: config, added_q: keys });
          return;
        }
      })
      .catch((err) => console.log("Dashboard API error!", err));
  }
  // static getDerivedStateFromProps(nextProps, prevState){
  //     if(nextProps.configurations!==prevState.config){

  //         const config = JSON.parse(JSON.stringify(nextProps.configurations));
  //         return {config : config};

  //     }
  //     else return null;
  //   }

  //   componentDidUpdate(prevProps, prevState) {
  //     if (prevState.configurations !== this.state.config) {
  //       this.updateForm(this.state.config);
  //     }
  //   }

  updateForm = (config) => {
    if (config && config.interview_prep) {
      this.formRef.current.setFieldsValue({
        ...config.interview_prep,
      });
    }
    console.log(
      "all questions: ",
      config.interview_prep,
      typeof config.interview_prep
    );
    //   const additional_q = [];
    if (config.interview_prep) {
      const keys = Object.keys(config.interview_prep).filter((q_no) => {
        if (q_no > 23) return q_no;
      });
      console.log("keys ", keys);
      // this.setState({added_q : keys})
      this.additional_q = [...keys];
      console.log("set addn keys", this.additional_q);
      // getAdditionalQ(keys);
      return;
    }
  };

  addNew = () => {
    const temp = this.state.added_q;
    // temp.push({
    //     id:this.state.card_id,
    //     title:'Enter Question Here',
    // });
    let configurations = this.configurations(this.props.configurations);
    console.log("configurations", configurations);
    if (Array.isArray(configurations)) {
      configurations = {};
    }
    configurations.interview_prep = {};
    configurations.interview_prep[this.state.added_q.length + 24] = {};
    temp.push(this.state.added_q.length + 24);
    this.setState({ added_q: temp });

    console.log("update config", configurations);
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "BP"
    );
  };

  onFormChange = (changed_values, all_values) => {
    console.log("changed_values:", changed_values, "all values:", all_values);
    const data = all_values;
    let configurations = this.configurations(this.props.configurations);
    // if (
    //     config &&
    //     config.interview_prep
    //   )
    //   {
    //       configurations.interview_prep = all_values
    //   }
    configurations.interview_prep = all_values;
    console.log("update config", configurations);
    this.props.updateProfileAsync(
      {
        configurations: configurations,
      },
      "BP"
    );
    message.success('Your work has been saved')
    // AUTH_API.patchEmailDrafts(data,id).then(
    //     response=>{
    //         console.log("patch Api data",response);
    //     }
    // )
    // .catch(
    //     (err)=>console.log('email drafts patch Api error!', err)
    // )
  };

  onSubmit = () => {
    this.setState({ ...this.state, submitted: true });
  };
  deleteDraft = (id) => {
    console.log("deleteed id is:", id);
    const temp = this.state.added_q;
    const new_added_q = temp.filter(function (value, index, arr) {
      return value !== id;
    });
    console.log("new added q", new_added_q);
    this.setState({ added_q: new_added_q });
    let configurations = this.configurations(this.props.configurations);
    delete configurations.interview_prep[id];

    console.log("update config", configurations);
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "BP"
    );
  };

  render() {
    console.log("Interview prep rendered", this.state.added_q, this.formRef);
    return (
      <div>
        <h3 className="ip-head">
          Draft interview responses and practice them out loud.
        </h3>
        <p className="ip-subhead">
          {" "}
          Upfront interview preparation is critical to ensure yu come across
          clearly and believably in interviews.
        </p>

        <Form
          name="interview_prep_form"
          ref={this.formRef}
          onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
        >
          <FormComponent />

          {this.state.added_q.map((item, index) => {
            console.log("item in map is:", item);
            return (
              <AddPrep
                key={item}
                deleteDraft={() => this.deleteDraft(item)}
                obj={item}
                q_no={item}
              />
            );
          })}
          {/* {console.log("additional ques in return", this.additional_q)}
                    {this.additional_q.map((item, index)=>{
                        return( <AddPrep key={item} deleteDraft={(item)=>this.deleteDraft(item)} obj={item} q_no={index + 24} />)
                    })} */}

          <div
            style={{ textAlign: "left" }}
            onClick={() => {
              this.addNew();
            }}
          >
            <p style={{ color: "#199094", cursor: "pointer"}}>
              &#43; &nbsp; Add a New Question
            </p>
          </div>

          <button
            className="button-main"
            onClick={() =>
              this.props.updateQuickReflection({
                stepId: this.props.stepId,
                show: true,
              })
            }
            style={{
              paddingLeft:"20px",paddingRight:"20px", float: "right",
            }}
          >
            Submit To Coach
          </button>
        </Form>
        {console.log(this.formRef)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  configurations: selectConfigurations(state),
});

const mapDispatchToProps = () => ({
  updateProfileAsync,
  updateQuickReflection,
});

export default connect(mapStateToProps, mapDispatchToProps())(InterviewPrep);
