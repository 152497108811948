import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import AddToCalendar from "react-add-to-calendar";
import {
    selectTasks,
    selectStepReflections, addTaskAsync, deleteTaskAsync, updateTaskAsync,
} from "../../slices/dashboardSlice";
import {Button, Card, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, TimePicker} from "antd";
import "./MyTasks.css";
import TaskComp from "./TaskComp";
import DraggablecompTI from "../Common/DraggablecompTI";
import {CloseOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const Mytasks = () => {
    const tasks = useSelector(selectTasks);
    const stepReflections = useSelector(selectStepReflections);
    const [addToCal, setAddToCal] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const dispatch = useDispatch()
    const [addForm] = Form.useForm();
    const [taskId, setTaskId] = useState(null);


    useEffect(() => {
        setAddToCal({
            title: "New Task",
            description: "",
            location: "",
            startTime: '',
            endTime: '',
        });
    }, [])

    const getStatus = (id) => {
        let status = "";

        if (id > 0 && stepReflections) {
            for (let k in stepReflections) {
                let reflection = stepReflections[k];
                if (reflection.id === id) {
                    if (reflection.notified === false) {
                        status = "just-released";
                    } else if (reflection.status === "ON") {
                        status = "ongoing";
                    }
                }
            }
        }
        return status;
    };

    const dummyTasks = () => {
        let tasks = [];
        let exists = {};

        if (tasks) {
            for (let task of tasks) {
                if (task.stepReflection > 0) {
                    exists[task.stepReflection] = true;
                }
            }
        }
        if (stepReflections) {
            for (let k in stepReflections) {
                let reflection = stepReflections[k];
                // console.log('reflections',reflection);

                if (
                    reflection.step !== "ON" &&
                    reflection.step !== "MI" &&
                    reflection.step !== "NP"
                    //  && !(NO_SUBMIT[reflection.step] && reflection.status!=="UN")
                ) {
                    if (
                        exists[reflection.id] !== true &&
                        // !reflection.complete_by &&
                        (reflection.status === "ON" || reflection.status === "UN") &&
                        reflection.stepName.toLowerCase().indexOf("reflection") === -1
                    ) {
                        // console.log('reflections',reflection);
                        tasks.push(
                            <TaskComp
                                key={`${reflection.stepName}-${reflection.id}`}
                                title={reflection.stepName}
                                date={reflection.complete_by}
                                stepId={reflection.id}
                                stepKey={reflection.step}
                                stepName={reflection.stepName}
                                status={
                                    reflection.status === "ON" ? "ongoing" : "just-released"
                                }
                                id={-1}
                            />
                        );
                    }
                }
            }
        }
        return tasks;
    };
    const editTask = (id) => {

    setTaskId(id);
    const currentTask = tasks.find(task => task.id === id);

    addForm.setFieldsValue({
      title: currentTask.title,
      date: moment(currentTask.due_date),
      time: moment(currentTask.due_date),
      duration: currentTask.duration,
      description: currentTask.description,
    });
    setModalVisible(true);

    let start_date = moment(currentTask.start);
    console.log("start!!!!!!", start_date);

    setAddToCal({
      title: currentTask.title,
      description: currentTask.description,
      location: "",
      startTime: currentTask.start,
      endTime: start_date.add(currentTask.duration, "minutes").toDate(),
    });
    }
    console.log("Bolg!! I have a task for you", tasks);

    const checkReflection = (reflection) => {
        console.log(reflection.title.slice(0, 5));
        if (reflection.weekly_reflection) {
            if (
                moment().isAfter(moment(reflection.assigned_on)) &&
                moment(reflection.due_date).isAfter(moment())
            )
                return true;
            else return false;
        } else {
            return true;
        }
    };
    const tasksList = tasks
        ? tasks.filter(task1 => {
                if (!(task1.step_reflection || task1.weekly_reflection)) {
                    if (!moment(task1.due_date).isAfter(moment()) && task1.due_date) {
                        return false
                    }
                }
                return true
            }
        ).map(
            (task) =>
                checkReflection(task) &&
                getStatus(task.step_reflection) === "ongoing" ||
                getStatus(task.step_reflection) === "just-released" &&
                (
                    <TaskComp
                        key={`${task.id} ${task.due_date}`}
                        title={task.title === 'Tracker' ? 'Host Networking Calls' : task.title}
                        date={task.due_date}
                        weeklyReflection={task.weekly_reflection > 0}
                        stepReflection={task.step_reflection > 0}
                        status={getStatus(task.step_reflection)}
                        hasDelete={!(task.step_reflection || task.weekly_reflection)}
                        onEdit = {() => editTask(task.id)}
                        id={task.id}
                    />
                )
        )
        : [];

    const onFinish = (values) => {
        let timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
        const task = {
            title: values.title,
            due_date:values.data && values.time ? `${values?.date?.format("YYYY-MM-DD")}T${values?.time?.format(
                "HH:mm:ss"
            )}${timeZoneFormatted}` : null,
            duration: values.duration,
            description: values.description,
        };
        taskId ? dispatch(updateTaskAsync(taskId, task)) :
        dispatch(addTaskAsync(task))
        setModalVisible(false)
    }
    const handleCancel = () => {
        setModalVisible(false)
    }

    const onFinishFailed = () => {

    }
    const onFormChange = (val, allVal) => {
        let start_date = allVal.date;
        var timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
        let temp1 = moment()
        if (allVal?.date && allVal?.time) {
            temp1 = moment(
                `${allVal?.date?.format("YYYY-MM-DD")}T${allVal?.time?.format(
                    "HH:mm:ss"
                )}${timeZoneFormatted}`
            );
        }
        let end_time = temp1.add(allVal?.duration, "minutes");
        setAddToCal({
            title: allVal.title,
            description: allVal.description,
            location: "",
            startTime: (allVal?.date && allVal?.time) && `${allVal?.date?.format("YYYY-MM-DD")}T${allVal?.time?.format(
                "HH:mm:ss"
            )}${timeZoneFormatted}`,
            endTime: `${end_time?.format("YYYY-MM-DD")}T${end_time?.format(
                "HH:mm:ss"
            )}${timeZoneFormatted}`,
        });
    }
    const handleDeleteTask = (id) => {
        dispatch(deleteTaskAsync(id))
        setTaskId(null)
        setModalVisible(false)
    }

    const addNewTask = () => {
        setTaskId(null)
        setModalVisible(true)
        addForm.setFieldsValue({
          title: '',
          date: moment(),
          time: '',
          duration:'',
          description: '',
    });
    }

    return (
        <div>
            <div style={{margin: "0px 20px"}}>
                <Card
                    bordered={false}
                    style={{
                        backgroundColor: "#white",
                        boxShadow: "0px 3px 16px 0px #1313131A",
                        borderRadius: "12px",
                        textAlign: "left",
                    }}
                >
                    <div className="h" style={{
                        fontWeight: "800",
                        color:"#111111",
                        fontFamily:'AvenirBook',
                        fontSize: "18px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gridGap: "12px",
                        gap: "12px",
                        }}>
                        {" "}
                        <div className="div-my-tasks-title">
                            {/* Future Release Build */}
                            <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/dashboardMyTasksDrag.svg" className="element-hide-temporary" />
                            <span className="my-tasks-title">My Tasks</span>
                        </div>
                        <div
                        className="element-hide-temporary" //Future Release Build
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gridGap: "12px",
                            gap: "12px",
                        }}
                        >
                        <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/myTaskFilterLineIcon.svg" />
                        <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/myTaskSwitchVerticalIcon.svg" />
                        </div>
                        {/* My Tasks */}
                    </div>
                    <ul className="tasks-list">
                        {dummyTasks()}
                        {tasksList}
                        <br/>

                    </ul>
                    <button
                        type="primary"
                        size="large"
                        className="button-main my-tasks-add-new-buttom element-hide-temporary" //Future Release Build
                    >&#43;&nbsp;Add New Task</button>
                    {/* <Button
                            type="primary"
                            onClick={addNewTask}
                            // className="add-new"
                            className="button-main my-tasks-add-new-buttom"
                            style={
                                {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }
                            }
                        >
                            <PlusOutlined/> Add New Task

                        </Button> */}
                </Card>
            </div>

            <Modal
                title=""
                visible={modalVisible}
                onCancel={handleCancel}
                footer={false}
                destroyOnClose={true}
                closeIcon={<CloseOutlined className="close-icon"/>}
                className="edit-task-modal"
            >
                <Form
                    name="add-task-form"
                    initialValues={{}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={addForm}
                    layout="vertical"
                    onValuesChange={onFormChange}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        className="edit-task-items"
                        rules={[{required: true, message: "Please input your title!"}]}
                    >
                        <Input size="large"/>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        className="edit-task-items"
                        label="Additional Notes"
                    >
                        <TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name="date" className="edit-task-items" label="Date">
                        <DatePicker style={{margin: "0"}}/>
                    </Form.Item>
                    <Form.Item name="time" className="edit-task-items" label="Time">
                        <TimePicker
                            use12Hours
                            format="h:mm a"
                            style={{margin: "0 "}}
                            // bordered={false}
                        />
                    </Form.Item>
                    {/* <div style={{paddingBottom:20}}>
            Add to <a style={{color:'#49CDE1'}}>Google Calendar</a>
          </div> */}

                    <Form.Item
                        label="Duration (minutes)"
                        name="duration"
                        className="edit-task-items"
                    >
                        <InputNumber min={30} max={60 * 24} step={30}/>
                    </Form.Item>
                    <div style={{marginBottom: "20px"}}>
                        {/* <span className="add-to-calendar">Add to </span>
            <a
              className="google-calendar"
              href="http://localhost:8000/accounts/google/login/?process=login"
              target="_blank"
            >
              Google Calendar
            </a> */}
                        {React.createElement(() => (
                            <AddToCalendar
                                event={addToCal}
                                listItems={[{google: "Add to Google Calendar"}]}
                                optionsOpen={true}
                            />
                        ))}
                    </div>

                        <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                            handleDeleteTask(taskId);
                        }}
                    >
                        <Form.Item style={{maxWidth: "100px", display: "inline-block"}}>
                            <Button
                                className="delete-task-btn"
                                disabled={!taskId}
                                icon={
                                    <DeleteOutlined
                                        style={{
                                            color: "#49CDE1",
                                            position: "relative",
                                            top: "-2px",
                                        }}
                                    />
                                }
                            >
                                Delete
                            </Button>
                        </Form.Item>
                    </Popconfirm>
                    <Form.Item
                        style={{
                            maxWidth: "100px",
                            display: "inline-block",
                            float: "right",
                        }}
                    >
                        <Button
                            type="primary"
                            className="save-task-btn"
                            htmlType="submit"
                            style={{float: "right"}}
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Mytasks;