import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import DASHBOARD_API from "api/dashboard";
import React from "react";
import OtpInput from "react-otp-input";
import HELPERS from "utils/helper";
import AUTH_API from "./../../api/auth";
import "./Onboardingbase.css";

export default class Step2 extends React.Component {
  state = {
    inputphone: this.props.phone,
    inputcc: this.props.c_c,
  };

  changePhone = (e) => {
    console.log("phone changed", e.target.value);
    const phone = e.target.value;
    this.setState({
      phone_no: phone,
    });
  };
  onClickOtp = (values) => {
    const data = {
      otp: values.otp,
      phone: this.state.inputphone,
      country_code: this.state.inputcc,
    };

    console.log("data: ", data);
    const formdata = HELPERS.converToFormData(data);

    AUTH_API.verifyOTP(formdata)
      .then((response) => {
        console.log("otp response !!", response);
        console.log(response.data.Response.token);
        localStorage.setItem("token", response.data.Response.token);
        localStorage.setItem("user_id", response.data.Response.id);
        console.log("user is:", localStorage.getItem("user_id"));
        if (response.data.Status === "Success") {
          const assess_data = {
            ideal_career_path_score: 0,
            experiences: [],
            content_areas: [],
            environment: [],
          };
          console.log("token");
          AUTH_API.postAssessment(assess_data)
            .then((res) => {
              console.log("assessment added", res)
            })
            .catch((err) => console.log("assessment post error", err));
          this.postEmailDrafts("intro");
          this.postEmailDrafts("phone call");
          this.postEmailDrafts("linkedIn");
          this.postMaterials();
          message.success("OTP Matched");
          // window.location.href = "/";
          this.props.onclicknext();
        } else message.error("Incorrect OTP");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  postEmailDrafts = (title) => {
    const data = {
      title: title,
    };
    AUTH_API.postEmailDraft(data)
      .then((response) => {
        console.log("post Api data", response);
      })
      .catch((err) => console.log("email drafts post Api error!", err));
  };
  postMaterials = () => {
    DASHBOARD_API.get()
      .then((response) => {
        const profile = response.data;
        const profile_val = {
          "01": profile.city,
          "02": profile.state,
          "03": profile.email,
          "05": profile.country_code + profile.phone,
        };
        const data = {
          professional_summary: [{ aa: 11 }],
          resume_best_practices: [{}],
          cover_letter: { ...profile_val },
          linked_in: [{}],
        };
        AUTH_API.postMaterials(data)
          .then((response) => {
            console.log("Materials post Api data", response);
          })
          .catch((err) => console.log("Materials post Api error!", err));
      })
      .catch((err) => console.log("dashboard get Api error!", err));
  };

  resendOtp = (value) => {
    const data = {
      country_code: this.state.inputcc,
      phone: this.state.inputphone,
    };
    const formdata = HELPERS.converToFormData(data);

    AUTH_API.sendOTP(formdata)
      .then((response) => {
        this.setState({
          inputcc: data.country_code,
          inputphone: data.phone,
        });
        message.success("OTP is sent to your number");
      })
      .catch((err) => {
        message.error("User is not registered! Create an account first");
      });
  };

  render() {
    return (
      <div style={{ width: "33%", marginLeft: "33%", marginTop: "20%" }}>
        <Form
          name="otp-form"
          onFinish={(values) => {
            this.onClickOtp(values);
          }}
        >
          <p
            style={{ textAlign: "left", fontSize: "13px", fontWeight: "bold" }}
          >
            OTP Has Been Sent To Your
          </p>
          <Row style={{ textAlign: "left", padding: "0px 0 10px" }} gutter={8}>
            <Col span={9}>Mobile Number</Col>
            <Col
              span={15}
              className="phone-inp-wrapper"
              style={{ display: "flex" }}
            >
              {React.createElement(() => (
                <>
                  <Input
                    className="phone-input-edit-cc"
                    onPressEnter={(e) =>
                      this.setState({ inputcc: e.target.value })
                    }
                    onBlur={(e) => this.setState({ inputcc: e.target.value })}
                    style={{ padding: "0px" }}
                    bordered={false}
                    defaultValue={this.state.inputcc}
                  />
                  <Input
                    suffix={<EditOutlined className="num-editable" />}
                    className="phone-input-edit"
                    onPressEnter={(e) =>
                      this.setState({ inputphone: e.target.value })
                    }
                    style={{ padding: "0px" }}
                    bordered={false}
                    defaultValue={this.state.inputphone}
                    onBlur={(e) =>
                      this.setState({ inputphone: e.target.value })
                    }
                  />
                </>
              ))}
            </Col>
          </Row>

          <p
            style={{ textAlign: "left", fontSize: "13px", fontWeight: "bold" }}
          >
            Did Not receive OTP? &nbsp; &nbsp;
            <a style={{ color: "#199094" }} onClick={this.resendOtp}>
              Resend OTP
            </a>
          </p>

          <div style={{ padding: "30px 0 60px 0" }}>
            <p
              style={{
                textAlign: "left",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Enter OTP
            </p>
            <Form.Item name="otp" rules={[{ required: true }]}>
              <OtpInput
                inputStyle={{ width: "70%", height: "160%" }}
                numInputs={6}
                separator={<span style={{ width: "3%" }}></span>}
              />
            </Form.Item>
          </div>
          <button
            size="large"
            type="primary"
            htmlType="submit"
            className="button-main"
            block
            style={{
              width: "100%"
             }}
          >
            Next
          </button>
        </Form>
      </div>
    );
  }
}
