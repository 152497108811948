import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, Radio, Row, message, Select } from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "./../../api/auth";

const { Option } = Select;

class Step4 extends React.Component {
  formRef = React.createRef();
  componentDidMount() {
    window.scrollTo(0, 0);
    AUTH_API.ourCommitmentGet()
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        this.formRef.current.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onFormChange = (values) => {
    console.log(values);
    const formdata = HELPERS.converToFormData(values);
    AUTH_API.ourCommitment(formdata)
      .then((response) => {
        console.log("otp response !!", response);
        message.success('Your work has been saved')
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <div style={{ margin: "5% 0 7% 0" }}>
        <div
          className="h"
          style={{
            fontSize: "30px",
            fontFamily: "AvenirBook",
          }}
        >
          Our Commitment to You
        </div>
        <Row>
          <Col span={5}></Col>
          <Col span={14} style={{ textAlign: "left" }}>
            <p
              style={{
                font: "normal normal bold 18px/22px AvenirBook",
                margin: "25px 0",
              }}
            >
              As your career exploration guide, we vouch to:
            </p>

            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#199094",
                  fontSize: "18px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                guide you through proven process of learning and reflection to
                create clarity on your ideal career path
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                 color: "#199094",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                take the guesswork out of career exploration and job search
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                 color: "#199094",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                provide you with a linear, step-by-step process so you can focus
                on one thing at a time
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                 color: "#199094",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                teach you what steps to take and the best practices so you can
                be effective and strategic
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                 color: "#199094",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                review your efforts, provide honest feedback, and help you
                process what you're learning
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                 color: "#199094",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                proactively check in throughout the week for support and respond
                promptly to questions
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                 color: "#199094",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                keep your best interest in mind and ensure you stay true to
                yourself through the process
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                 color: "#199094",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                provide holistic motivation, support, accountability and
                guidance
              </p>
            </div>

            <p
              style={{
                font: "normal normal bold 18px/22px AvenirBook",
                paddingTop: "20px",
              }}
            >
              How we will do it:
            </p>
            <p style={{ font: "normal normal normal 16px/24px AvenirBook" }}>
              We will check in with you throughout the week and you should
              proactively reach out to us whenever you have a question or
              updates.
            </p>

            <p style={{ font: "normal normal normal 16px/24px AvenirBook" }}>What is your preference for communicating with your coach?</p>
            <Form
              name="our_comm_form"
              onFinish={this.props.onclicknext}
              onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
              ref={this.formRef}
            >
              <Form.Item
                name="coach_communication_mode"
                // initialValue='TE'
              >
                <Radio.Group>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px", fontSize: "16px" }}
                    value="TE"
                  >
                    I'm good receiving text or email (Suggested)
                  </Radio>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px", fontSize: "16px" }}
                    value="EM"
                  >
                    I'd prefer to only receive email
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>
                How frequently would you like your coach to check in with you?
              </p>
              <Form.Item name="communication_frequency">
                <Select
                  defaultValue={3}
                  style={{
                    width: 200,
                    color: "#199094",
                    // margin: "0 80px 20px 80px",
                    fontSize: "16px"
                  }}
                  // bordered={false}
                >
                  {/* <Option value={3}><p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>Very frequently</p></Option>
                  <Option value={2}><p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>Somewhat frequently</p></Option>
                  <Option value={1}><p style={{ font: "normal normal normal 16px/22px AvenirBook" }}>Not frequently</p></Option> */}
                  <Option value={3}>Very frequently</Option>
                  <Option value={2}>Somewhat frequently</Option>
                  <Option value={1}>Not frequently</Option>
                </Select>
              </Form.Item>

              <p style={{ font: "normal normal normal 18px/22px AvenirBook" }}>
                When your coach releases the next step to you in the platform,
                how do you want to be notified?
              </p>
              <Form.Item name="communication_mode">
                <Radio.Group defaultValue="TE">
                  <Radio
                    style={{ display: "block", paddingBottom: "14px", font: "normal normal normal 16px/22px AvenirBook" }}
                    value="EM"
                  >
                    Email
                  </Radio>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px", font: "normal normal normal 16px/22px AvenirBook" }}
                    value="TX"
                  >
                    Text
                  </Radio>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px", font: "normal normal normal 16px/22px AvenirBook" }}
                    value="TE"
                  >
                    Both Email + Text
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Row style={{ paddingTop: "40px", marginBottom: "100px" }}>
                <Col span={12}>
                  <button
                  className="button-secondary"
                   style={{
                    width: "80%",
                    outline: 'none'
                  }}
                    size="large"
                    onClick={this.props.onclickback}
                  >
                    Back
                  </button>
                </Col>
                <Col span={12} style={{ textAlign: "right"}}>
                  <button
                  className="button-main"
                    style={{
                      width: "80%",
                      outline: 'none'
                    }}
                    size="large"
                    // type="primary"
                    htmlType="submit"
                  >
                    Next
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={5}></Col>
        </Row>
      </div>
    );
  }
}
export default Step4;
