import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Input,
  message,
  Select,
  Space,
  Table,
  Spin,
} from "antd";
import COACH_API from "api/coach";
import moment from "moment";
import React from "react";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import AUTH_API from "./../../api/auth";
import "./Coach.css";
import Selectbutton from "./Selectbutton";

const { Option } = Select;
const { TextArea } = Input;
const steps = [
  "ON",
  "AS",
  "TR",
  "TI",
  "RR",
  "RI",
  "CO",
  "ED",
  "MI",
  "NT",
  "CP",
  "NF",
  "LO",
  "NP",
  "PS",
  "RE",
  "CL",
  "LK",
  "AT",
  "IR",
  "BP",
  "JR",
];
const step_names = [
  "Onboarding",
  "Assessment",
  "Target Roles",
  "Target Industries",
  "Research Roles",
  "Research Industries",
  "Contacts",
  "Email Drafts",
  "Setup Call with Coach",
  "Networking Tracker",
  "Call Prep & Call Notes",
  "Networking Reflections",
  "Learning Opportunities",
  "Next Phase",
  "Professional Summary",
  "Resume",
  "Cover Letter",
  "LinkedIn",
  "Application Tracker",
  "Interview Research",
  "Behavioral Interview Prep",
  "Job Search Reflections",
];

//change
const rows = (props) => {
  return <tr {...props} className="tr-a"></tr>;
};
//till here

export default class CoachTable extends React.Component {
  formRef = React.createRef();

  state = {
    selected_date: "",
    api_response: [],
    last_activity_date: "",
    PatchAPIdata: {},
    loading: true,
    searchText: "",
    searchedColumn: "",
    till_index: 10,
  };

  saveDate = (date, dateString) => {
    console.log(dateString);
    this.setState({ selected_date: dateString });
  };

  componentDidMount() {
    // var tableContent = document.querySelector('.ant-table-body');

    if (
      !localStorage.getItem("has_bd") &&
      moment().format("DD-MM") === "25-02"
    ) {
      console.log(moment().format("DD-MM"), "25-02");
      const mes = message.success({
        icon: () => <></>,
        content: (
          <div>
            <img
              src="https://raw.githubusercontent.com/DenverCoder1/Responsive-Birthday-Card/main/birthday.svg"
              alt="birthday"
              class="birthday"
            />
            <div class="text">
              <h1>Happy Birthday Rachel!</h1>
              <p>We hope you have a wonderful birthday</p>
              <p class="muted"></p>
            </div>
            <div class="spaced"></div>
          </div>
        ),
        className: "custom-class",
        // duration:2000,
        style: {
          marginTop: "20vh",
        },
      });
      localStorage.setItem("has_bd", true);
      setTimeout(mes, 4500);
    } else {
      console.log("dont show");
    }

    console.log("id in get api is:", this.props.id);
    AUTH_API.CoachListView()
      .then((response) => {
        console.log("get api response", response);
        let temp = [...response.data];
        let temp2 = [];
        temp.forEach((item) => {
          if (item.is_active) {
            if (item.status !== "HO") {
              temp2.push(item);
            }
          }
        });
        console.log("tem", temp2);
        this.setState({ api_response: temp2, loading: false });
      })
      .catch((err) => {
        console.log("get api error", err);
        // this.setState({ loading: false });
      });
  }

  patchUser = (data, id) => {
    AUTH_API.CoachListPatch(data, id)
      .then((response) => {
        console.log("patch api response", response);
      })
      .catch((err) => {
        console.log("patch api error", err);
      });
  };

  OnFormChange = (e, field, id, datestring) => {
    console.log(e, field, id, datestring);
    if (field === "last_communication_mode") {
      const data = {
        last_communication_mode: e,
      };
      this.patchUser(data, id);
    }
    if (field === "last_communication_by") {
      const data = {
        last_communication_by: e.target.value,
      };
      console.log("data is:", data);
      this.patchUser(data, id);
    }
    if (field === "last_communicated_on") {
      const data = {
        last_communicated_on: datestring,
      };
      this.patchUser(data, id);
    }
    if (field === "coach_notes") {
      const data = {
        coach_notes: e.target.value,
      };
      this.patchUser(data, id);
      console.log("newdata is:", data);
    }
    if (field === "last_call") {
      const data = {
        last_call: datestring,
      };
      this.patchUser(data, id);
    }
  };

  impersonate = (clientId) => {
    console.log("client id", clientId);
    // if (localStorage.getItem("impersonating")) {
    //   message.error("Please stop impersonating the other client first, or refresh.");
    // } else {
    COACH_API.impersonate(clientId)
      .then((resp) => {
        localStorage.setItem("user_id", clientId);
        // localStorage.setItem("impersonating", true);
        // window.open(`${window.location.origin}/dashboard`, "_blank");
        // window.open(`${window.location.origin}/dashboard`)
        window.location.href = "/";
        // console.log("imporsonate resp:",resp);
      })
      .catch((err) => {
        message.error("Won't be able to impersonate this client!");
      });
    // }
  };

  setupCall = (obj, text) => {
    const data = {
      id: obj.id,
      step: obj.step,
      status: "UN",
    };
    const id = obj.id;
    console.log("patch api data:", data, "step_id", id);
    AUTH_API.CoachStepsPatch(data, id)
      .then((response) => {
        console.log("patch api response for popups", response);
        message.success("User Notified ");
      })
      .catch((err) => {
        console.log("patch api error", err);
      });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: "#51cde1" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
          <div>
            <Link
              to={{
                pathname: `/coach/drill-down`,
                id: record.client_id,
                userName: record.client,
              }}
              onClick={() =>
                localStorage.setItem("current_drilldown", record.client_id)
              }
              style={{ color: "inherit !important" }}
            >
              <span className="imp-image-l">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className="imp-image-r">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
        </>
      ) : (
        <>
          {text}
          <div>
            <Link
              to={{
                pathname: `/coach/drill-down`,
                id: record.client_id,
                userName: record.client,
              }}
              onClick={() =>
                localStorage.setItem("current_drilldown", record.client_id)
              }
              style={{ color: "inherit !important" }}
            >
              <span className="imp-image-l">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              onClick={() => this.impersonate(record.client_id)}
              className="imp-image-r"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
        </>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleScroll = (e) => {
    let bottom =
      e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop;
    if (bottom === 0) {
      let n = this.state.till_index;
      n = n + 10;
      this.setState({ till_index: n });
    }
  };

  time_convert = (num) => {
    if (num > 59) {
      var hours = Math.floor(num / 60);
      var minutes = num % 60;
      return hours + " hrs " + minutes + " mins";
    } else {
      return num + " mins";
    }
  };

  getUserstatus = (days) => {
    console.log("days since last use:", days);
    if (days > 14) return "Inactive";
    if (days >= 7) return "Light Active";
    if (days < 7) return "Active";
    else return "On Hold";
  };

  render() {
    console.log(this.state.api_response);
    const columns = [
      {
        title: "Client",
        width: "150px",
        dataIndex: "client",
        key: "client",
        fixed: "left",
        className: "col-class",
        sorter: {
          compare: (a, b) =>
            a.client
              ? a.client
                  .toString()
                  .localeCompare(b.client.toString())
              : true,
          //
        },
        defaultSortOrder: "ascend",
        // render:(text)=>(
        //             <>
        //                 <h3 style={{fontSize:'14px'}}>{text}</h3>
        //                 <div>
        //                 <Link to ={`/coach/drill-down`} style={{color:'inherit !important'}}><span className='imp-image-l'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></Link>
        //                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //                     <span className='imp-image-r'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        //                 </div>
        //             </>
        //             ),
        ...this.getColumnSearchProps("client"),
      },
      {
        title: "Last Communication",
        dataIndex: "last_comm_on",
        key: "1",
        // width: "250px",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.last_comm_on
              ? a.last_comm_on
                  .toString()
                  .localeCompare(b.last_comm_on.toString())
              : true,
          //
        },
        render: (text, record) => (
          <>
            <div>
              <span style={{ fontSize: "14px" }} className="sms-span">
                <Select
                  style={{ width: 70 }}
                  bordered={false}
                  defaultValue={record.last_comm === "TE" ? "SMS" : "Email"}
                  onChange={(e) =>
                    this.OnFormChange(
                      e,
                      "last_communication_mode",
                      record.client_id
                    )
                  }
                >
                  <Option value="TE">SMS</Option>
                  <Option value="EM">Email</Option>
                </Select>
                By &nbsp;&nbsp;
              </span>
              <Input
                className="lco-inp"
                defaultValue={
                  record.last_comm_by ? record.last_comm_by : "Rachel"
                }
                onBlur={(e) =>
                  this.OnFormChange(
                    e,
                    "last_communication_by",
                    record.client_id
                  )
                }
              ></Input>
              {/* <span style={{opacity:'50%',fontSize:'12px'}}>Anna Tim</span> */}
            </div>
            <div>
              <DatePicker
                style={{ padding: "0", width: "120px" }}
                defaultValue={
                  record.last_comm_on._isValid ? record.last_comm_on : null
                }
                onChange={(date, datestring) =>
                  this.OnFormChange(
                    date,
                    "last_communicated_on",
                    record.client_id,
                    datestring
                  )
                }
                bordered={false}
              />
            </div>
          </>
        ),
      },
      {
        title: "Last Activity",
        dataIndex: "last_activity_days",
        key: "2",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.last_activity
              ? a.last_activity.localeCompare(b.last_activity)
              : true,
        },
        render: (text, record) => (
          <>
            <h3 style={{ fontSize: "14px" }}>
              {record.last_activity_days} days ago
            </h3>
            <div style={{ opacity: "40%", fontSize: "12px" }}>
              {step_names[steps.indexOf(record.last_activity_step)]}
            </div>
          </>
        ),
      },
      {
        title: "Start Date",
        dataIndex: "startdate",
        key: "3",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.startdate ? a.startdate.localeCompare(b.startdate) : true,
        },
      },
      {
        title: "Time spent",
        dataIndex: "time_spent",
        key: "4",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.time_spent_comp
              ? a.time_spent_comp < b.time_spent_comp
                ? 1
                : -1
              : true,
        },
      },
      {
        title: "Last Meeting",
        dataIndex: "last_call",
        key: "5",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.last_call
              ? moment(a.last_call).isAfter(moment(b.last_call))
                ? -1
                : 1
              : true,
        },
        render: (text, record) => (
          <>
            {/* {console.log('last_call',text,typeof text)} */}
            <DatePicker
              style={{ padding: "0" }}
              defaultValue={text._isValid ? text : null}
              className="lc-input"
              onChange={(date, datestring) =>
                this.OnFormChange(
                  date,
                  "last_call",
                  record.client_id,
                  datestring
                )
              }
              bordered={false}
            />
          </>
        ),
      },

      {
        title: "Coach Notes",
        dataIndex: "coachnotes",
        key: "6",
        // width: "300px",
        width: "150px",
        render: (text, record) => (
          <TextArea
            rows={2}
            onChange={(val) =>
              this.OnFormChange(val, "coach_notes", record.client_id)
            }
            defaultValue={record.coach_notes}
          />
        ),
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "7",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.status ? a.status.localeCompare(b.status) : false,
        },
        render: (text, record) => (
          <>
            <button
              className="status-btn"
              style={{
                background:
                  record.status === "Active"
                    ? "#D1FBBD"
                    : record.status === "Light Active"
                    ? "#FFFBD9"
                    : "#FBBDBD",
                color:
                  record.status === "Active"
                    ? "#0A8A1D"
                    : record.status === "Light Active"
                    ? "#C8B700"
                    : "#8a0a0a",
                cursor: "default",
              }}
            >
              {record.status}
            </button>
          </>
        ),
      },
      // {
      //   title: 'Onboarding', dataIndex: 'on_obj', key: '8',
      //   showSorterTooltip: false,
      //   sorter: {
      //     compare: (a, b) => (a.on_obj) ? a.on_obj.status.localeCompare(b.on_obj.status) : false,
      //
      //   },
      //   render:(text,record)=>(
      //      record.on_obj &&  <Selectbutton step_obj={record.on_obj} />
      //     )
      // },
      {
        title: "Assessment",
        dataIndex: "as_obj",
        key: "9",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.as_obj ? a.as_obj.status.localeCompare(b.as_obj.status) : false,
        },
        render: (text, record) =>
          record.as_obj && (
            <>
              <Selectbutton step_obj={record.as_obj} />
            </>
          ),
      },
      {
        title: "Target - Roles",
        dataIndex: "tr_obj",
        key: "10",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.tr_obj ? a.tr_obj.status.localeCompare(b.tr_obj.status) : false,
        },
        render: (text, record) =>
          record.tr_obj && <Selectbutton step_obj={record.tr_obj} />,
      },
      {
        title: "Target - Industries",
        dataIndex: "ti_obj",
        key: "11",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.ti_obj ? a.ti_obj.status.localeCompare(b.ti_obj.status) : false,
        },
        render: (text, record) =>
          record.ti_obj && <Selectbutton step_obj={record.ti_obj} />,
      },
      {
        title: "Research - Roles",
        dataIndex: "rr_obj",
        key: "12",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.rr_obj ? a.rr_obj.status.localeCompare(b.rr_obj.status) : false,
        },
        render: (text, record) =>
          record.rr_obj && <Selectbutton step_obj={record.rr_obj} />,
      },
      {
        title: "Research - Industries",
        dataIndex: "ri_obj",
        key: "13",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.ri_obj ? a.ri_obj.status.localeCompare(b.ri_obj.status) : false,
        },
        render: (text, record) =>
          record.ri_obj && <Selectbutton step_obj={record.ri_obj} />,
      },
      {
        title: "Contacts",
        dataIndex: "co_obj",
        key: "14",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.co_obj ? a.co_obj.status.localeCompare(b.co_obj.status) : false,
        },
        render: (text, record) =>
          record.co_obj && <Selectbutton step_obj={record.co_obj} />,
      },
      {
        title: "Email Drafts",
        dataIndex: "ed_obj",
        key: "15",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.ed_obj ? a.ed_obj.status.localeCompare(b.ed_obj.status) : false,
        },
        render: (text, record) =>
          record.ed_obj && <Selectbutton step_obj={record.ed_obj} />,
      },
      {
        title: "Setup Call with Coach",
        dataIndex: "midway",
        key: "16",
        width: "150px",
        showSorterTooltip: false,
        render: (text, record) =>
          record.mi_obj && (
            <Button
              className="midway-btn"
              onClick={() => this.setupCall(record.mi_obj, "setup-call")}
            >
              Setup A Call
            </Button>
          ),
      },
      {
        title: "Networking - Tracker",
        dataIndex: "nt_obj",
        key: "17",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.nt_obj ? a.nt_obj.status.localeCompare(b.nt_obj.status) : false,
        },
        render: (text, record) =>
          record.nt_obj && (
            <Selectbutton
              step_obj={record.nt_obj}
              cn_obj={record.cn_obj}
              cp_obj={record.cp_obj}
            />
          ),
      },
      {
        title: "Call Prep & Call Notes",
        dataIndex: "cp_obj",
        key: "18",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.cp_obj ? a.cp_obj.status.localeCompare(b.cp_obj.status) : false,
        },
        render: (text, record) =>
          record.cp_obj && (
            <Selectbutton step_obj={record.cp_obj} cn_obj={record.cn_obj} />
          ),
      },
      {
        title: "Networking Reflections",
        dataIndex: "nr_obj",
        key: "19",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.nr_obj ? a.nr_obj.status.localeCompare(b.nr_obj.status) : false,
        },
        render: (text, record) =>
          record.nr_obj && <Selectbutton step_obj={record.nr_obj} />,
      },
      {
        title: "Experiential Learning",
        dataIndex: "ex_obj",
        key: "20",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.ex_obj ? a.ex_obj.status.localeCompare(b.ex_obj.status) : false,
        },
        render: (text, record) =>
          record.ex_obj && <Selectbutton step_obj={record.ex_obj} />,
      },
      {
        title: "Next phase",
        dataIndex: "next-phase",
        key: "21",
        width: "150px",
        showSorterTooltip: false,
        render: (text, record) =>
          record.mi_obj && (
            <Button
              className="midway-btn"
              onClick={() => this.setupCall(record.np_obj, "nextphase")}
            >
              Release
            </Button>
          ),
      },
      {
        title: "Professional Summary",
        dataIndex: "ps_obj",
        key: "22",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.ps_obj ? a.ps_obj.status.localeCompare(b.ps_obj.status) : false,
        },
        render: (text, record) =>
          record.ps_obj && <Selectbutton step_obj={record.ps_obj} />,
      },
      {
        title: "Resume",
        dataIndex: "re_obj",
        key: "23",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.re_obj ? a.re_obj.status.localeCompare(b.re_obj.status) : false,
        },
        render: (text, record) =>
          record.re_obj && <Selectbutton step_obj={record.re_obj} />,
      },
      {
        title: "Cover letter",
        dataIndex: "cl_obj",
        key: "24",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.cl_obj ? a.cl_obj.status.localeCompare(b.cl_obj.status) : false,
        },
        render: (text, record) =>
          record.cl_obj && <Selectbutton step_obj={record.cl_obj} />,
      },
      {
        title: "LinkedIn",
        dataIndex: "li_obj",
        key: "25",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.li_obj ? a.li_obj.status.localeCompare(b.li_obj.status) : false,
        },
        render: (text, record) =>
          record.li_obj && <Selectbutton step_obj={record.li_obj} />,
      },
      {
        title: "Application - Tracker",
        dataIndex: "at_obj",
        key: "26",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.at_obj ? a.at_obj.status.localeCompare(b.at_obj.status) : false,
        },
        render: (text, record) =>
          record.at_obj && (
            <Selectbutton step_obj={record.at_obj} ir_obj={record.ir_obj} />
          ),
      },
      {
        title: "Interview - Research",
        dataIndex: "ir_obj",
        key: "27",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.ir_obj ? a.ir_obj.status.localeCompare(b.ir_obj.status) : false,
        },
        render: (text, record) =>
          record.ir_obj && <Selectbutton step_obj={record.ir_obj} />,
      },
      {
        title: "Behavioral - Int. Prep",
        dataIndex: "bp_obj",
        key: "28",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.bp_obj ? a.bp_obj.status.localeCompare(b.bp_obj.status) : false,
        },
        render: (text, record) =>
          record.bp_obj && <Selectbutton step_obj={record.bp_obj} />,
      },
      {
        title: "Job Search Reflections",
        dataIndex: "jr_obj",
        key: "29",
        width: "150px",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) =>
            a.jr_obj ? a.jr_obj.status.localeCompare(b.jr_obj.status) : false,
        },
        render: (text, record) =>
          record.jr_obj && <Selectbutton step_obj={record.jr_obj} />,
      },
    ];

    // const obj = this.state.api_response[0]
    if (this.state.loading === false) {
    }
    const data = this.state.api_response.map((item, index) => ({
      client_id: item.id,
      key: index + 1,
      client: item.name,
      last_activity_step: item.activity,
      lastactivity: item.last_activity,
      last_comm: item.last_communication_mode,
      last_comm_on: moment(item.last_communicated_on),
      last_comm_by: item.last_communication_by,
      startdate: item.start_date,
      time_spent:
        item.time_spent !== null ? this.time_convert(item.time_spent) : "",
      time_spent_comp: parseInt(item.time_spent),
      last_activity_days: moment().diff(
        item.last_activity === null ? moment() : moment(item.last_activity),
        "days"
      ),
      last_activity: item.last_activity,
      last_call: moment(item.last_call),
      status:
        item.status === "HO"
          ? "On Hold"
          : this.getUserstatus(
              moment().diff(
                item.last_activity === null
                  ? moment()
                  : moment(item.last_activity),
                "days"
              )
            ),
      coach_notes: item.coach_notes,
      on_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "ON")[0]
            : ""
          : "",
      as_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "AS")[0]
            : ""
          : "",
      tr_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "TR")[0]
            : ""
          : "",
      ti_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "TI")[0]
            : ""
          : "",
      rr_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "RR")[0]
            : ""
          : "",
      ri_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "RI")[0]
            : ""
          : "",
      co_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "CO")[0]
            : ""
          : "",
      ed_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "ED")[0]
            : ""
          : "",
      mi_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "MI")[0]
            : ""
          : "",
      nt_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "NT")[0]
            : ""
          : "",
      cp_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "CP")[0]
            : ""
          : "",
      cn_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "CN")[0]
            : ""
          : "",
      nr_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "NF")[0]
            : ""
          : "",
      ex_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "LO")[0]
            : ""
          : "",
      np_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "NP")[0]
            : ""
          : "",
      ps_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "PS")[0]
            : ""
          : "",
      re_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "RE")[0]
            : ""
          : "",
      cl_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "CL")[0]
            : ""
          : "",
      li_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "LK")[0]
            : ""
          : "",
      at_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "AT")[0]
            : ""
          : "",
      ir_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "IR")[0]
            : ""
          : "",
      bp_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "BP")[0]
            : ""
          : "",
      jr_obj:
        this.state.loading === false
          ? item.stepreflection_set.length > 0
            ? item.stepreflection_set.filter((item) => item.step === "JR")[0]
            : ""
          : "",
      // as_id :2,
      // on_id:1,
      // tr_id :3,
      // ti_id :2,
      // rr_id :2,
      // ri_id :2,
      // co_id :2,
      // mi_id :2,
      // nt_id :2,
      // cp_id :2,
      // nr_id :2,
      // ex_id :2,
      // np_obj :2,
      // ps_id :2,
      // re_id :2,
      // cl_id :2,
      // li_id :2,
      // at_id :2,
      // ir_id :2,
      // bp_id :2,
      // jr_id :2,
    }));
    // let data=[];
    // for(let i=0;i<this.state.api_response.length;i++){
    //   if(i<this.state.till_index){
    //   data.push( {
    //      client_id:this.state.api_response.id,
    //     key : i+1,
    //     client : this.state.api_response.name,
    //     lastactivity : this.state.api_response.last_activity,
    //     last_comm:this.state.api_response.last_communication_mode,
    //     last_comm_on:moment(this.state.api_response.last_communicated_on),
    //     last_comm_by:this.state.api_response.last_communication_by,
    //     startdate:this.state.api_response.start_date,
    //     last_activity_days : moment().diff(moment(this.state.api_response.last_activity_on),'days'),
    //     last_activity : this.state.api_response.last_activity,
    //     last_call : moment(this.state.api_response.last_call),
    //     status : this.state.api_response.status==="AC" ? "Active" : "Inactive",
    //     coach_notes:this.state.api_response.coach_notes,
    //     on_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='ON'))[0] : '' : '',
    //     as_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='AS'))[0] : '' : '',
    //     tr_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='TR'))[0] : '' : '',
    //     ti_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='TI'))[0] : '' : '',
    //     rr_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='RR'))[0] : '' : '',
    //     ri_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='RI'))[0] : '' : '',
    //     co_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='CO'))[0] : '' : '',
    //     ed_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='ED'))[0] : '' : '',
    //     mi_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='MI'))[0] : '' : '',
    //     nt_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='NT'))[0] : '' : '',
    //     cp_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='CP'))[0] : '' : '',
    //     nr_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='NF'))[0] : '' : '',
    //     ex_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='LO'))[0] : '' : '',
    //     // np_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='NP'))[0] : '' : '',
    //     ps_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='PS'))[0] : '' : '',
    //     re_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='RE'))[0] : '' : '',
    //     cl_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='CL'))[0] : '' : '',
    //     li_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='LK'))[0] : '' : '',
    //     at_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='AT'))[0] : '' : '',
    //     ir_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='IR'))[0] : '' : '',
    //     bp_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='BP'))[0] : '' : '',
    //     jr_obj :this.state.loading === false ? this.state.api_response[i].stepreflection_set.length > 0 ? (this.state.api_response[i].stepreflection_set.filter((item)=>item.step==='JR'))[0] : '' : '',
    //     as_id :2,
    //     on_id:1,
    //     tr_id :3,
    //     ti_id :2,
    //     rr_id :2,
    //     ri_id :2,
    //     co_id :2,
    //     mi_id :2,
    //     nt_id :2,
    //     cp_id :2,
    //     nr_id :2,
    //     ex_id :2,
    //     np_obj :2,
    //     ps_id :2,
    //     re_id :2,
    //     cl_id :2,
    //     li_id :2,
    //     at_id :2,
    //     ir_id :2,
    //     bp_id :2,
    //     jr_id :2,

    //   })
    // }
    // }

    //for damo just uncomment the below loop and the Data array above and also comment the above code from line 438 to 5602

    //  for(let i=0;i<Data.length;i++){
    //   if(i<this.state.till_index){
    //     data.push( {
    //       client_id:this.state.api_response.id,
    //      key : i+1,
    //      client : this.state.api_response.name,
    //      lastactivity : this.state.api_response.last_activity,
    //      last_comm:this.state.api_response.last_communication_mode,
    //      last_comm_on:moment(this.state.api_response.last_communicated_on),
    //      last_comm_by:this.state.api_response.last_communication_by,
    //      startdate:this.state.api_response.start_date,
    //      last_activity_days : moment().diff(moment(this.state.api_response.last_activity_on),'days'),
    //      last_activity : this.state.api_response.last_activity,
    //      last_call : moment(this.state.api_response.last_call),
    //      status : this.state.api_response.status==="AC" ? "Active" : "Inactive",
    //      coach_notes:this.state.api_response.coach_notes,
    //   })}
    //   }

    // const data = Data.map((item,index)=>{
    //   console.log(item)
    //     if(index<=this.state.till_index){
    //       console.log(index)
    //     return{
    //     client_id:item.id,
    //     key : index+1,
    //     client : item.name,
    //     lastactivity : item.last_activity,
    //     last_comm:item.last_communication_mode,
    //     last_comm_on:moment(item.last_communicated_on),
    //     last_comm_by:item.last_communication_by,
    //     startdate:item.start_date,
    //     last_activity_days : moment().diff(moment(item.last_activity_on),'days'),
    //     last_activity : item.last_activity,
    //     last_call : moment(item.last_call),
    //     status : item.status==="AC" ? "Active" : "Inactive",
    //     coach_notes:item.coach_notes,
    //     on_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='ON'))[0] : '' : '',
    //     as_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='AS'))[0] : '' : '',
    //     tr_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='TR'))[0] : '' : '',
    //     ti_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='TI'))[0] : '' : '',
    //     rr_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='RR'))[0] : '' : '',
    //     ri_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='RI'))[0] : '' : '',
    //     co_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='CO'))[0] : '' : '',
    //     ed_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='ED'))[0] : '' : '',
    //     mi_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='MI'))[0] : '' : '',
    //     nt_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='NT'))[0] : '' : '',
    //     cp_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='CP'))[0] : '' : '',
    //     nr_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='NF'))[0] : '' : '',
    //     ex_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='LO'))[0] : '' : '',
    //     // np_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='NP'))[0] : '' : '',
    //     ps_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='PS'))[0] : '' : '',
    //     re_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='RE'))[0] : '' : '',
    //     cl_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='CL'))[0] : '' : '',
    //     li_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='LK'))[0] : '' : '',
    //     at_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='AT'))[0] : '' : '',
    //     ir_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='IR'))[0] : '' : '',
    //     bp_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='BP'))[0] : '' : '',
    //     jr_obj :this.state.loading === false ? item.stepreflection_set.length > 0 ? (item.stepreflection_set.filter((item)=>item.step==='JR'))[0] : '' : '',
    //     as_id :2,
    //     on_id:1,
    //     tr_id :3,
    //     ti_id :2,
    //     rr_id :2,
    //     ri_id :2,
    //     co_id :2,
    //     mi_id :2,
    //     nt_id :2,
    //     cp_id :2,
    //     nr_id :2,
    //     ex_id :2,
    //     np_obj :2,
    //     ps_id :2,
    //     re_id :2,
    //     cl_id :2,
    //     li_id :2,
    //     at_id :2,
    //     ir_id :2,
    //     bp_id :2,
    //     jr_id :2,

    //   }}
    // }
    // )

    return (
      <div>
        <div
          // onScroll={this.handleScroll}
          className="table-div"
        >
          {!this.state.loading ? (
            <Table
              columns={columns}
              dataSource={data}
              // scroll={{ x: 100 }}
              scroll={{ x: 100, y: 800 }}
              pagination={false}
              rowClassName="coach-row"
              // change
              components={{
                body: {
                  // wrapper: con,
                  row: rows,
                },
              }}
              //till here
            />
          ) : (
            <LoadingOutlined style={{ fontSize: 50, color: "#7fced2" }} />
          )}
        </div>
      </div>
    );
  }
}
