import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Modal, Popconfirm, Row, Select } from "antd";
import React from "react";
import { connect } from "react-redux";
import { selectTargetRoles } from "slices/targetRolesSlice";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import "./contacts.css";
const { Option } = Select;
const Title = <p className="contact-modal-title">Edit Contact Background</p>;

const categories = [
  "Friends",
  "Acquaintances",
  "Past Colleagues",
  "Social Organizations",
  "Alumni",
  "Target Roles",
  "Target Industries",
  "Target Companies",
  "Recruiters",
  "Cold Connections",
];

class ParentContactModal extends React.Component {
  state = {
    allroles: [],
    allindustries: [],
  };
  formRef = React.createRef();

  handleCancelFromModal = () => {
    this.props.handleCancel();
    // TODO: the main form values should be updated
    // this.formRef.current.resetFields();
  };

  allRoles = () => {
    let UR = [];
    if (this.props.roles)
      this.props.roles.forEach((role) => {
        if (role.learn_more) UR.push({ value: role.title });
      });
    return UR;
  };

  onUpdate = HELPERS.debounceFormChanges((changedValues, values) => {
    const selected_cat = this.props.category_mapping[values.category];

    this.formRef.current.validateFields().catch((err) => {
      return;
    });

    if (values.id) {
      //patch
      console.log("patch hoga");
      const data = {
        title: values.name,
        company_name: values.company,
        email: values.email,
        linkedin: values.linkedin,
        roles: values.roles,
        industries: values.industries,
        contact_type: selected_cat,
        // contact_type: this.category_mapping[values.category],
      };
      // console.log('patch data', data)
      AUTH_API.patchContact(data, values.id)
        .then((response) => {
          console.log("patch res", response);
          // this.getContacts();
          message.success("Your work has been saved");
        })
        .catch((err) => {
          console.log("not patched", err);
        });
    } else {
      console.log("post hoga");

      const data = {
        title: values.name ? values.name : "",
        country_code: "",
        phone: "",
        company_name: values.company ? values.company.toString() : "",
        email: values.email ? values.email : "",
        linkedin: values.linkedin ? values.linkedin.toString() : "",
        roles: values.roles ? values.roles : [],
        industries: values.industries ? values.industries : [],
        contact_type: this.category_mapping[values.category],
        selected: false,
        tracker_info: [],
        call_prep: [],
        notes: "",
        reflections: [],
      };

      // console.log('post data', data)
      // AUTH_API.setNewContact(data, "CO")
      //   .then((response) => {
      //     console.log("post res", response);
      //     // this.getContacts();
      //     message.success("Your work has been saved");
      //   })
      //   .catch((err) => {
      //     console.log("not posted", err);
      //   });
      message.success("New contact added");
    }

    // alert(JSON.stringify(this.props.formRef));
    return;

  });

  componentDidMount() {
    let UI = [];
    AUTH_API.getAllTargetindustries()
      .then((response) => {
        // console.log('tr !!', response);
        const roles = response.data;
        roles.forEach((role) => {
          if (role.learn_more)
            UI.push({
              value: role.title,
            });
        });
        this.setState({
          allindustries: UI,
        });
      })
      .catch((err) => console.log("no roles found!", err));
  }

  render() {
    const default_contents = {
      ...this.props.current_data,
      category: this.props.current_category,
    };
    if (default_contents["roles"] === "") {
      delete default_contents["roles"];
    }
    if (default_contents["industries"] === "") {
      delete default_contents["industries"];
    }

    const id = this.props.current_data ? this.props.current_data.id : null;

    return (
      <>
        <Modal
          title={Title}
          visible={this.props.visible}
          footer={null}
          width="796px"
          // closable={false}
          closeIcon={<CloseOutlined className="close-icon" onClick={this.handleCancelFromModal} />}
        >
          {/* <div>
            <CloseCircleFilled
              onClick={this.handleCancelFromModal}
              className="close-btn-modal"
            />
          </div> */}
          <Form
            onFinish={(value) => {
              this.props.onSubmit(value, id);
            }}
            ref={this.formRef}
            initialValues={default_contents}
            onValuesChange={this.onUpdate}
          >
            <div>
              <p className="contact-modal-content">Contact Category</p>
              <Form.Item name="category" className="extra-size">
                <Select
                  style={{ width: "49%" }}
                  size="large"
                  className="extra-size"
                >
                  {categories.map((category, index) => (
                    <Option value={category}>{category}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Row gutter={8}>
              <Col span={12}>
                <div>
                  <p className="contact-modal-content">Name</p>
                  <Form.Item name="name" rules={[{ required: true }]}>
                    <Input size="large" className="extra-size" />
                  </Form.Item>
                </div>

                <div>
                  <p className="contact-modal-content">Email</p>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                      },
                    ]}
                  >
                    <Input size="large" className="extra-size" />
                  </Form.Item>
                </div>

                <div>
                  <p className="contact-modal-content">Target Role</p>
                  <Form.Item name="roles" className="extra-size">
                    <Select
                      // mode="tags"
                      showArrow
                      // tagRender={tagRenderTR}
                      className="extra-size"
                      placeholder="Select Role"
                      style={{ width: "100%" }}
                      options={[...this.allRoles()]}
                      size="large"
                    ></Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <p className="contact-modal-content">Company</p>
                  <Form.Item name="company">
                    <Input size="large" className="extra-size" />
                  </Form.Item>
                </div>

                <div>
                  <p className="contact-modal-content">LinkedIn</p>
                  <Form.Item name="linkedin">
                    <Input size="large" className="extra-size" />
                  </Form.Item>
                </div>

                <div>
                  <p className="contact-modal-content">Target Industry</p>
                  <Form.Item name="industries" className="extra-size">
                    <Select
                      // mode="tags"
                      showArrow
                      // tagRender={tagRenderTI}
                      className="extra-size"
                      placeholder="Select Industries"
                      style={{ width: "100%" }}
                      options={this.state.allindustries}
                      size="large"
                    >
                      {[]}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row style={{ margin: "20px 0 0" }}>
              <Col span={8}>
                <Popconfirm
                  title="Are you sure you want to delete ?"
                  onConfirm={() => this.props.handleDelete(id)}
                >
                  <button size="large" style={{ color: "#199094",width:"100%" }} block  className="button-secondary" >
                    Delete Contact
                  </button>
                </Popconfirm>
              </Col>
              <Col span={8}></Col>
              <Col span={8}>
                <Form.Item name="industries">
                  <button type="primary" size="large" block htmlType="submit" className="button-main"
                            style={{
                              width:"100%"
                            }} >
                    {" "}
                    Save{" "}
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
});

export default connect(mapStateToProps)(ParentContactModal);