import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Row, Col } from "antd";
import { connect } from "react-redux";
import Moment from "moment";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
// import "antd/dist/antd.css";
import Assessmentstart from "./Assessmentstart";
import Assessmentsteps from "./Assessmentsteps";
import DueDate from "./../Common/DueDate";
import Stepinfo from "./../Modals/Stepinfo";
import HELPERS from "utils/helper";
import {
  selectAssessmentStepInfo,
  setStepNotifiedAsync,
} from "slices/dashboardSlice";
import { selectAssessment, fetchAssessmentAsync } from "slices/assessmentSlice";

const Assessment = (props) => {
  const [assessmentComp, setAssessmentComp] = useState(
    <Assessmentstart
      start={() => {
        setAssessmentComp(<Assessmentsteps backToStart={() => startComp()} />);
      }}
    />
  );

  const trStatus = (props.stepsStatus.TR == undefined ?  "" : props.stepsStatus.TR.status);
  const isTargetRolesLocked = trStatus === "LO";

  const stepInfo = useSelector(selectAssessmentStepInfo);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [showInfo, setShowInfo] = useState(null);
  const [setTarget, setSetTarget] = useState(null);
  const [currStep, setCurrStep] = useState(0);

  // useEffect(() => {
  //   if (stepInfo && !stepInfo.notified) {
  //     dispatch(setStepNotifiedAsync(stepInfo.id, stepInfo.step));
  //   }
  // });

  const notifyStep = () => {
    console.log("impersonated??", props.impersonated);
    if (stepInfo && !stepInfo.notified && props.impersonated) {
      console.log("not impersonating", props.impersonated);
      dispatch(setStepNotifiedAsync(stepInfo.id, stepInfo.step));
    }
  };

  const newReleased = () => stepInfo && !stepInfo.notified;

  const getShowInfo = () =>
    showInfo === true || (showInfo === null && newReleased());

  const targetSet = () =>
    setTarget === true ||
    (setTarget === null && !!stepInfo && stepInfo.complete_by === null && stepInfo.status != 'DO');

  const setStep = (val) => {
    setCurrStep(val);
  };

  //sets date at header and makes setdate component disappear after 6sec
  const onDateSet = () => {
    console.log("DONE !");
    // setTimeout(() => {
    setSetTarget(false);
    // }, 6000);
  };

  //opens set date box (pink colored) if date in header is clicked
  const setDateAgain = () => {
    // console.log('set!');
    setSetTarget(true);
  };

  //close button handling
  const onCloseClick = () => {
    setSetTarget(false);
  };

  const startComp = () => {
    console.log("startcomp");
    setAssessmentComp(
      <Assessmentstart
        start={() => {
          setAssessmentComp(<Assessmentsteps backToStart={startComp()} />);
        }}
      />
    );
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
    props.toggle();
  };

  const stepId = () => (!!stepInfo ? stepInfo.id : -1);

  HELPERS.log(
    "assessment rendered",
    props.impersonated,
    stepInfo,
    stepInfo && stepInfo.complete_by === null,
    setTarget
  );
  return (
    <div style={{ padding: "4% 0" }}>
      <div className="collapse-btn" onClick={onCollapse}>
        {collapsed ? (
          <RightCircleFilled className="make-white" />
        ) : (
          <LeftCircleFilled className="make-white" />
        )}
      </div>

      <Card
        bordered={false}
        style={{
          margin: "20px !important",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "13px",
          textAlign: "left",
          marginRight: "6%",
          marginLeft: "20px",
          padding: "0 2%",
        }}
      >
        <div>
          <Row style={{ padding: "4% 0% 3% 0%" }}>
            <Col
              span={18}
              style={{
                font: "normal normal 300 30px/12px AvenirBook",
                display: "flex",
                alignItems: "center",
              }}
            >
              Assessment&nbsp;
              <span
                className={`just-released ${newReleased() ? "" : "hidden"}`}
              >
                NEW
              </span>
              {stepInfo && (
                // stepInfo.complete_by !== null &&
                <span
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    position: "relative",
                    marginLeft: "20px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={setDateAgain}
                >
                  <img
                    src="https://django-woken-static.s3.amazonaws.com/static/media/calendaricon.c713d4db.svg"
                    style={{ position: "relative", top: "-3px" }}
                  ></img>
                  &nbsp;&nbsp;
                  {stepInfo.complete_by ? "Due date:" : "Set Due Date"}&nbsp;
                  {stepInfo &&
                    stepInfo.complete_by &&
                    Moment(stepInfo.complete_by).format("MM-DD-YYYY")}
                </span>
              )}
            </Col>
            <Col
              span={6}
              style={{
                textAlign: "right",
                fontSize: "26px",
                marginTop: "-20px",
              }}
            >
              <a
                  href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                  target="_blank"
                  style={{
                    marginRight: "10px",
                   
                      cursor:"pointer"
                   
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="mail_2_" data-name="mail (2)" transform="translate(-0.001)">
                      <g id="Group_2502" data-name="Group 2502" transform="translate(0.001)">
                        <path id="Path_1376" data-name="Path 1376" d="M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm0,4.917,8.285,5.166H5.716Zm8.388,13.416h0a1.5,1.5,0,0,1-1.5,1.5H7.114a1.5,1.5,0,0,1-1.5-1.5V10.412a1.492,1.492,0,0,1,.024-.257l7.915,4.936.03.016.032.017a.831.831,0,0,0,.172.067l.018,0a.854.854,0,0,0,.2.025h0a.839.839,0,0,0,.2-.025l.018,0a.839.839,0,0,0,.172-.067l.032-.017.03-.016,7.915-4.936a1.49,1.49,0,0,1,.024.257v7.921Z" transform="translate(-0.001)" fill="#199094"/>
                      </g>
                    </g>
                  </svg>
                </a>
                <span
                  // onClick={() =>
                  //   this.setState({
                  //     showInfo: true,
                  //   })
                  // }
                  onClick={() => setShowInfo(true)}
                  style={{
                    cursor:"pointer"
                  }}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" style={{marginTop: "5px"}}>
                  <path id="question" d="M14,0A14,14,0,1,0,28,14,14.015,14.015,0,0,0,14,0Zm0,22.167A1.167,1.167,0,1,1,15.167,21,1.167,1.167,0,0,1,14,22.167Zm1.847-7.418a1.172,1.172,0,0,0-.68,1.061v.524a1.167,1.167,0,1,1-2.333,0V15.81a3.515,3.515,0,0,1,2.036-3.18,3.813,3.813,0,0,0,2.047-2.713,2.917,2.917,0,1,0-5.833,0,1.167,1.167,0,1,1-2.333,0,5.25,5.25,0,1,1,10.5,0,6.079,6.079,0,0,1-3.4,4.832Zm0,0" fill="#199094"/>
                </svg>
              </span>
            </Col>
          </Row>

          <p
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "1px solid #F0F0F0",
            }}
          ></p>
        </div>

        {/* starts here__________________________________________________________________________________ */}

        {console.log("assessmenmt props", props.assessment)}
        {getShowInfo() ? (
          <Stepinfo
            stepname="Assessment"
            closeInfo={() => {
              notifyStep();
              setShowInfo(false);
            }}
          />
        ) : targetSet() ? (
          <div>
            <DueDate
              stepName="Assessment"
              stepKey={stepInfo.step}
              onDateSet={onDateSet}
              onCloseClick={onCloseClick}
              stepId={stepInfo.id}
              targetDate={
                stepInfo.complete_by !== null
                  ? Moment(stepInfo.complete_by)
                  : null
              }
            />{" "}
            <Assessmentsteps
              stepId={stepInfo.id}
              setStep={setStep}
              currStep={currStep}
              all_assess={props.assessment}
              trLocked={isTargetRolesLocked}
            />
          </div>
        ) : (
          <Assessmentsteps
            style={{ opacity: "0.4", display: "none" }}
            fade={!targetSet()}
            stepId={stepId()}
            setStep={setStep}
            currStep={currStep}
            all_assess={props.assessment}
            trLocked={isTargetRolesLocked}
          />
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  assessment: selectAssessment(state),
});

const mapDispatchToProps = () => ({
  fetchAssessmentAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(Assessment);