import React from "react";
import {
  Row,
  Col,
  Input,
  Tag,
  Form,
  Select,
  Slider,
  Button,
  message,
} from "antd";
import AUTH_API from "./../../api/auth";
import Freeformbox from "./Freeformbox";
import HELPERS from "utils/helper";

const { TextArea } = Input;
const { Option } = Select;

function tagRender(props) {
  const { label, closable, onClose } = props;
  return (
    <Tag
      color={"white"}
      closable={closable}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "14px 5px",
        padding: "2px 15px",
        backgroundColor: "#8375E8",
      }}
    >
      {label}
    </Tag>
  );
}

const OPTIONS = [
  " I feel like I'm just going off of stereotypes that I've heard about this career ",
  "I know this is what I want but it seems like an unrealistic/tough path ",
  "Lack of clarity and understanding of what my options/opportunities are ",
];

export default class Environment extends React.Component {
  state = {
    assess_id: "",
    role: "",
  };

  formRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    AUTH_API.getAssessment()
      .then((response) => {
        // console.log('get assessment', response, response.data.environment['75']);
        this.formRef.current.setFieldsValue(response.data.environment);
        this.props.getRef(this.formRef);
        this.setState({
          assess_id: response.data.id,
          role: response.data.environment["75"],
        });
      })
      .catch((err) => console.log("err", err));
  }

  onValChange = (val, allvalues) => {
    console.log("form values", allvalues);
    this.props.getRef(this.formRef);
    AUTH_API.patchAssessment({ environment: allvalues }, this.state.assess_id)
      .then((response) => {
        // console.log('experiences patched!!', response);
        this.setState({ role: allvalues["75"] });
        message.success('Your work has been saved')
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  onFinish = () => {
    this.props.next();
  };

  onFail = () => {
    message.error("Fill all the required fields first");
  };

  render() {
    return (
      <div>
        <p
          style={{
            color: "#199094",
            font: "normal normal 600 18px/22px AvenirBook",
            paddingTop: "14px",
          }}
        >
          Complete the third and final section of the assessment. This section
          focuses on environments you thrive in. Go with your gut. Don’t
          overthink it!
        </p>
        <Form
          ref={this.formRef}
          name="environment"
          onValuesChange={HELPERS.debounceFormChanges(this.onValChange)}
          onFinish={this.onFinish}
          onFinishFailed={this.onFail}
        >
          <div style={{ paddingBottom: "90px" }} id="59">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              59. Rank your priorities as it relates to pursuing your passion
              and finding a stable career path.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="59" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    color: "rgb(45 45 45)",
                  }}
                >
                  Pursuing my passion is my sole priority
                </p>
              </Col>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                >
                  Security and passion-pursuit are equally important to me
                </p>
              </Col>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "right",
                    color: "rgb(45 45 45)",
                  }}
                >
                  Securing a stable job is my sole priority
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="60">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              60. Rank your priorities as it relates to work-life balance.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="60" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    color: "rgb(45 45 45)",
                  }}
                >
                  My priority is being in a competitive environment even if I
                  work long hours.
                </p>
              </Col>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                >
                  My priority is a good work-life balance.
                </p>
              </Col>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "right",
                    color: "rgb(45 45 45)",
                  }}
                >
                  My priority is to work in a stress-free enviornment.
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="61">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              61. Rank your preference for work-related travel.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="61" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-between">
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    color: "rgb(45 45 45)",
                  }}
                >
                  I want to travel 0% of the time for work.
                </p>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                ></p>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                ></p>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                ></p>
              </Col>
              {/* <Col span={2}><p style={{ fontSize: '14px',textAlign:'center', color: 'rgb(45 45 45)'}}></p></Col> */}
              <Col span={4}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                >
                  I'd like to travel 50% of the time
                </p>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                ></p>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                ></p>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                ></p>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "right",
                    color: "rgb(45 45 45)",
                  }}
                >
                  I want to travel 100% of the time for work.
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="62">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              62. Rank your preference for pace of work.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="62" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    color: "rgb(45 45 45)",
                  }}
                >
                  I prefer a slow paced work environment
                </p>
              </Col>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                >
                  I prefer a medium paced work environment
                </p>
              </Col>
              <Col span={8}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "right",
                    color: "rgb(45 45 45)",
                  }}
                >
                  I prefer a fast paced work environment
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="63">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              63. Rank your preferred amount of time to spend on a project.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="63" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-between">
              <Col span={4}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    color: "rgb(45 45 45)",
                  }}
                >
                  A year+
                </p>
              </Col>
              <Col span={4}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                >
                  A few months
                </p>
              </Col>
              <Col span={4}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                >
                  A few weeks
                </p>
              </Col>
              <Col span={4}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "rgb(45 45 45)",
                  }}
                >
                  One week
                </p>
              </Col>
              <Col span={4}>
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "right",
                    color: "rgb(45 45 45)",
                  }}
                >
                  One day
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="64">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              64. Rank your preference for variety of work.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="64" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-between">
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                I prefer that every day feels different
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                I prefer to have 50% of my work be routine, and 50% of my work
                vary.
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                I prefer to follow the same routine everyday.
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="65">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              65. Rank your preference for remote or office work.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="65" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around">
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                I want my job to be totally remote
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                I want the option to work from home or at the office
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                I want to be at the office when I am at work
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="66">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              66. Rank your preference for office work or hands-on work.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="66" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around" gutter={32}>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                I prefer hands on work
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                I prefer a balance between hands on and computer-based work
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                I prefer computer-based work
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="67">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              67. Rank your preference for group or independent work.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="67" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around" gutter={64}>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                I prefer to be working independently 100% of the time
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                I want a job that is a good mix of independent work and
                interpersonal work
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                I prefer to interact with others 100% of my time (in meetings -
                whether on site, phone, or video)
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="68">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              68. Rank your preference for an established company or a startup.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="68" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around" gutter={64}>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                I'd prefer a startup where all procedures need to be both
                created and implemented
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                I'd prefer a balance of some established procedures and some
                procedures yet to be created
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                I'd prefer an established company where procedures are already
                in place, with room for improvement
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="69">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              69. Rank your preference for company size.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="69" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around">
              <Col
                span={3}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                An early stage startup (0-20 people)
              </Col>
              <Col
                span={3}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                A startup (20-100 people)
              </Col>
              <Col
                span={3}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                A later stage startup (100-500 people)
              </Col>
              <Col
                span={3}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                A small-mid sized organization (500-3,000 people)
              </Col>
              <Col
                span={3}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                A mid-sized organization (3,000-10,000 people)
              </Col>
              <Col
                span={3}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                A large organization (10,000-30,000 people)
              </Col>
              <Col
                span={3}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                A massive organization (30,000+ people)
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="70">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              70. Rank your preference for how a company values the way you
              work.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="70" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around" gutter={128}>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                Values my IMPACT, but not necessarily how I got there
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                Values equally HOW I do the work I do and the IMPACT of my work{" "}
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                {" "}
                Values HOW I do the work I do
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="71">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              71. Rank the type of office environment you prefer.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="71" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around" gutter={64}>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                A fun working environment that is laidback
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                A balanced environment that is somewhat corporate and somewhat
                laid back
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                A corporate office environment
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="72">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              72. Rank your confidence in your answers.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="72" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around">
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                I am not confident at all
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                I am somewhat confident
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                I am very confident in them
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="73">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              73. Describe the values of the people you want to work with.
            </p>
            <Freeformbox q_number={73} default_len={4} />
          </div>

          <div style={{ paddingBottom: "90px" }} id="74">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              74. If you close your eyes and envision what your next job looked
              and felt like (what you're doing, who you're with, what the
              environment is like) how would you describe it?
            </p>
            <Form.Item
              name="74"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 60 }}> </TextArea>
            </Form.Item>
          </div>

          <div style={{ paddingBottom: "90px" }} id="75">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              75. What job do you think you want to pursue?
            </p>
            <Form.Item
              name="75"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 60 }}> </TextArea>
            </Form.Item>
          </div>

          <div style={{ paddingBottom: "90px" }} id="76">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              76. Why are you interested in this?
            </p>
            <Form.Item
              name="76"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 60 }}> </TextArea>
            </Form.Item>
          </div>

          <div style={{ paddingBottom: "90px" }} id="77">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              77. Rank your confidence in this path.
            </p>
            <Row>
              <Col
                span={12}
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
              <Col
                span={12}
                style={{
                  fontSize: "14px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              ></Col>
            </Row>
            <Form.Item name="77" style={{ margin: 0 }}>
              <Slider max={10} tooltipVisible />
            </Form.Item>
            <Row justify="space-around">
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "rgb(45 45 45)",
                }}
              >
                I'm not confident about this path at all.{" "}
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "rgb(45 45 45)",
                }}
              >
                I'm interested in the path, but I don't know what else might be
                out there.
              </Col>
              <Col
                span={8}
                style={{
                  fontSize: "16px",
                  textAlign: "right",
                  color: "rgb(45 45 45)",
                }}
              >
                I'm 100% confident this is the career path for me
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "90px" }} id="78">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              78. What is driving your level of confidence in {this.state.role}?
            </p>
            <Form.Item
              name="78"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <Select
                mode="tags"
                showArrow
                tagRender={tagRender}
                placeholder=""
                style={{ width: "100%", margin: "6px" }}
                size="large"
              >
                {/* {[...OPTIONS]} */}
                {OPTIONS.map((item, index) => (
                  <Option key={index + 100}>{item}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div style={{ paddingBottom: "90px" }} id="79">
            <p style={{ paddingTop: "14px", fontSize: "17px" }}>
              79. If you had to choose a second possible career path, what would
              you choose? Why?
            </p>
            <Form.Item
              name="79"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 60 }}> </TextArea>
            </Form.Item>
          </div>

          <div style={{ paddingBottom: "90px" }} id="80">
            <p style={{ paddingTop: "14px", fontSize: "17px", paddingBottom: "20px" }}>
              80. How do you foresee your ideal working style?{" "}
            </p>
            <Form.Item
              name="80"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <Select size="large" style={{ width: "50%" }}>
                <Option id="1dsawq" value={" Full time salaried work"}>
                  Full time salaried work
                </Option>
                <Option
                  id="2cqwdaa"
                  value={"Consulting/freelance/part time work"}
                >
                  Consulting/freelance/part time work
                </Option>
                <Option id="3cqwdaa" value={"Entrepreneur "}>
                  Entrepreneur
                </Option>
                <Option id="4cqwdaa" value={"Full time plus side gigs"}>
                  Full time plus side gigs
                </Option>
              </Select>
            </Form.Item>
          </div>
          <Row>
            <Col span={6}>
              <button
                onClick={this.props.prev}
                style={{ margin: "0 8px", color: "#199094", borderColor: "#199094", width:"100%" }}
                size="large"
                block
                className="button-secondary"
              >
                Back
              </button>
            </Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <Form.Item name="submit-b">
                <button htmlType="submit" size="large" type="primary" block className="button-main" style={{
                  width:"100%"
                }}>
                  Submit
                </button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}