import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { Card, Col, message, Row, Steps } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  fetchTasksAsync,
  selectJobReflectionsStepInfo,
  selectNetworkingReflectionsStepInfo,
  setStepNotifiedAsync,
} from "slices/dashboardSlice";
import { updateIndustryAsync } from "slices/targetIndustriesSlice";
import { updateRoleAsync } from "slices/targetRolesSlice";
import {
  fetchWeeklyReflectionsAsync,
  selectJobSearchWeeklyReflections,
  selectNetworkingWeeklyReflections,
  updateWeeklyReflectionAsync,
} from "slices/weeklyReflectionSlice";
import HELPERS, { WEEKLY_REFLECTION_TYPES } from "utils/helper";
import Stepinfo from "./../../Modals/Stepinfo";
import ReflectionAboutRoles from "./ReflectionAboutRoles";
import ReflectionAboutYourProcess from "./ReflectionAboutYourProcess";
import ReflectionAboutYourProcessForJobSearch from "./ReflectionAboutYourProcessForJobSearch";
import ThankYou from "Components/Modals/ThanksReflections";
import "./Networkingref.css"
const { Step } = Steps;

class WeeklyReflections extends React.Component {
  state = {
    current: -1,
    collapsed: false,
    show_info: false, //show/hide infobox
    curr_step: 0,
  };

  getCurrent = () =>
    this.state.current > -1
      ? this.state.current
      : // this.props.current;
        this.filteredRefs().length - 1;

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.props.toggle();
  };

  reflections = () => {
    return this.props.reflections ? this.props.reflections : [];
  };

  filteredRefs = () => {
    try {
      let temp = [];
      // temp.filter(item=>!moment(item.released_on).isAfter(moment()))
      this.reflections().forEach(
        (item) => !moment(item.released_on).isAfter(moment()) && temp.push(item)
      );
      let fish = temp.sort((a, b) =>
        !moment(a.released_on).isAfter(b.released_on) ? -1 : 1
      );
      console.log("filtered", fish);
      return fish;
    } catch {
      console.log("in catch");
      return [];
    }
  };

  isOverdue = (reflection) => {
    const releasedOn = moment(reflection.released_on).add(7, "days");
    return moment(new Date()).isAfter(releasedOn);
  };

  render() {
    {
      console.log("netref rendered");
    }

    return (
      <div style={{ padding: "4% 0" }}>
        <div className="collapse-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? (
            <RightCircleFilled className="make-white" />
          ) : (
            <LeftCircleFilled className="make-white" />
          )}
        </div>

        <Card
          bordered={false}
          style={{
            margin: "20px !important",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "13px",
            textAlign: "left",
            marginRight: "6%",
            marginLeft: "20px",
            padding: "0 2%",
          }}
        >
          <div>
            <Row style={{ padding: "4% 0% 3% 0%" }}>
              <Col
                span={18}
                style={{ font: "normal normal 300 30px/12px AvenirBook" }}
              >
                {/* {`Weekly Reflection ${this.getCurrent() + 1}`} */}
                {this.reflections().length !== 0
                  ? `Weekly Reflections ${this.getCurrent() + 1}`
                  : "No reflections released yet!"}
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  marginTop: "-20px",
                }}
              >
                <a
                  href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                  target="_blank"
                  style={{
                    marginRight: "10px",
                   
                      cursor:"pointer"
                   
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="mail_2_" data-name="mail (2)" transform="translate(-0.001)">
                      <g id="Group_2502" data-name="Group 2502" transform="translate(0.001)">
                        <path id="Path_1376" data-name="Path 1376" d="M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm0,4.917,8.285,5.166H5.716Zm8.388,13.416h0a1.5,1.5,0,0,1-1.5,1.5H7.114a1.5,1.5,0,0,1-1.5-1.5V10.412a1.492,1.492,0,0,1,.024-.257l7.915,4.936.03.016.032.017a.831.831,0,0,0,.172.067l.018,0a.854.854,0,0,0,.2.025h0a.839.839,0,0,0,.2-.025l.018,0a.839.839,0,0,0,.172-.067l.032-.017.03-.016,7.915-4.936a1.49,1.49,0,0,1,.024.257v7.921Z" transform="translate(-0.001)" fill="#199094"/>
                      </g>
                    </g>
                  </svg>
                </a>
                <span
                  onClick={() =>
                    this.setState({
                      showInfo: true,
                    })
                  }
                  style={{
                    cursor:"pointer"
                  }}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" style={{marginTop: "5px"}}>
                  <path id="question" d="M14,0A14,14,0,1,0,28,14,14.015,14.015,0,0,0,14,0Zm0,22.167A1.167,1.167,0,1,1,15.167,21,1.167,1.167,0,0,1,14,22.167Zm1.847-7.418a1.172,1.172,0,0,0-.68,1.061v.524a1.167,1.167,0,1,1-2.333,0V15.81a3.515,3.515,0,0,1,2.036-3.18,3.813,3.813,0,0,0,2.047-2.713,2.917,2.917,0,1,0-5.833,0,1.167,1.167,0,1,1-2.333,0,5.25,5.25,0,1,1,10.5,0,6.079,6.079,0,0,1-3.4,4.832Zm0,0" fill="#199094"/>
                </svg>
                </span>
              </Col>
            </Row>
            <p
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #F0F0F0",
              }}
            ></p>
          </div>
          {/* starts here__________________________________________________________________________________ */}
          {this.reflections()[0] &&
            console.log(
              "refs",
              moment(this.reflections()[0].released_on),
              moment(),
              moment(this.reflections()[0].released_on).isAfter(moment())
            )}
          {/* {this.filteredRefs()} */}
          {this.state.show_info ? (
            <Stepinfo
              stepname={"Weekly Reflections"}
              closeInfo={() =>
                this.setState({
                  show_info: !this.state.show_info,
                })
              }
            />
          ) : this.reflections().length === 0 ? (
            ""
          ) : (
            <div>
              {/* {console.log('all refs')} */}
              <Steps
                current={this.getCurrent()}
                onChange={(current) => this.setState({ current: current })}
                className="networking-nav net-ref"
              >
                {this.filteredRefs().map((reflection, index) => (
                  // !moment(reflection.released_on).isAfter(moment()) &&

                  <Step
                    key={`networking-reflection-${index}`}
                    // title={`Weekly Reflection ${index + 1}`}
                    title={moment(reflection.released_on).format("MM-DD-yy")}
                    // disabled={this.isOverdue(reflection)}
                    // status={
                    //   !reflection.completed && this.isOverdue(reflection)
                    //     ? "error"
                    //     : reflection.completed?"finish":"process"
                    // }
                    status={
                      this.getCurrent() === index
                        ? "process"
                        : this.isOverdue(reflection)
                        ? !reflection.completed
                          ? "error"
                          // : "finish"
                          : "process"
                        : !reflection.completed
                        ? "waiting"
                        // : "finish"
                        : "process"
                    }
                    onClick={() => {
                      console.log(reflection);
                    }}
                  />
                ))}
              </Steps>
              {console.log(
                "type!!!",
                this.props.type,
                WEEKLY_REFLECTION_TYPES.JOB_SEARCH
              )}
              {this.props.type === WEEKLY_REFLECTION_TYPES.JOB_SEARCH
                ? React.createElement(() => (
                    <ReflectionAboutYourProcessForJobSearch
                      reflectionId={this.filteredRefs()[this.getCurrent()].id}
                      data={this.filteredRefs()[this.getCurrent()]}
                      onSubmit={this.props.onSubmit}
                    />
                  ))
                : this.getCurrent() % 2 !== 0
                ? React.createElement(() => (
                    <ReflectionAboutRoles
                      reflectionId={this.filteredRefs()[this.getCurrent()].id}
                      data={this.filteredRefs()[this.getCurrent()]}
                      onSubmit={this.props.onSubmit}
                    />
                  ))
                : React.createElement(() => (
                    <ReflectionAboutYourProcess
                      reflectionId={this.filteredRefs()[this.getCurrent()].id}
                      data={this.filteredRefs()[this.getCurrent()]}
                      onSubmit={this.props.onSubmit}
                    />
                  ))}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

class WeeklyReflectionsWrapper extends React.Component {
  componentDidMount = () => {
    this.props.fetchWeeklyReflectionsAsync(this.props.type);
  };
  state = {
    showThanks: false
  }

  componentDidUpdate = () => {
    HELPERS.log(
      "WeeklyReflectionsWrapper > componentDidUpdate",
      this.props.networkingWeeklyReflections
    );
  };

  stepInfo = () =>
    this.props.type === WEEKLY_REFLECTION_TYPES.NETWORKING
      ? this.props.networkingStepInfo
      : this.props.jobSearchStepInfo;

  notifyStep = () => {
    if (
      this.stepInfo() &&
      !this.stepInfo().notified &&
      this.props.impersonated
    ) {
      this.props.setStepNotifiedAsync(this.stepInfo().id, this.stepInfo().step);
    }
  };

  handleSubmit = (reflectionId, data) => {
    HELPERS.log("WeeklyReflectionsWrapper > handleSubmit", reflectionId, data);
    this.props
      .updateWeeklyReflectionAsync(this.props.type, reflectionId, data)
      .then(() => {
        // message.success("Your weekly reflection is submitted!");
        this.setState({showThanks: true})
        this.props.fetchTasksAsync();
        if (data.reflections.role) {
          // Todo: Merge requests to one
          // Update Role priorities
          for (let role in data.reflections.role) {
            this.props.updateRoleAsync(role, {
              priority:
                10 - parseInt(data.reflections.role[role].interest_level),
            });
          }

          // Update Industry priorities
          for (let industry in data.reflections.industry) {
            this.props.updateIndustryAsync(industry, {
              priority:
                10 -
                parseInt(data.reflections.industry[industry].interest_level),
            });
          }
        }
      });
  };

  reflections = () =>
    this.props.type === WEEKLY_REFLECTION_TYPES.NETWORKING
      ? this.props.networkingWeeklyReflections
      : this.props.jobSearchWeeklyReflections;

  render() {
    this.notifyStep();
    return (
      <>
      <ThankYou visible={this.state.showThanks} />
      <WeeklyReflections
        onSubmit={this.handleSubmit}
        reflections={this.reflections()}
        current={this.reflections().length - 1}
        toggle={this.props.toggle}
        type={this.props.type}
      />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  networkingStepInfo: selectNetworkingReflectionsStepInfo(state),
  jobSearchStepInfo: selectJobReflectionsStepInfo(state),
  networkingWeeklyReflections: selectNetworkingWeeklyReflections(state),
  jobSearchWeeklyReflections: selectJobSearchWeeklyReflections(state),
});

const mapDispatchToProps = () => ({
  setStepNotifiedAsync,
  fetchWeeklyReflectionsAsync,
  updateWeeklyReflectionAsync,
  updateRoleAsync,
  updateIndustryAsync,
  fetchTasksAsync,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(WeeklyReflectionsWrapper);