import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, Menu, message, Row } from "antd";
import COACH_API from "api/coach";
import "bootstrap/dist/css/bootstrap.min.css";
import WeeklyReflections from "Components/Common/WeeklyReflections/WeeklyReflections";
import JobSearchBase from "Components/JobSearch/JobSearchbase";
import Learningbase from "Components/Learning/Learningbase";
import Materialsbase from "Components/Materials/Materialsbase";
import Midwaycall from "Components/Modals/Midwaycall";
import NewStep from "Components/Modals/NewStep";
import Profile from "Components/Modals/Profile";
import Skipstep from "Components/Modals/Skipstep";
import QuickReflection from "Components/Modals/Success";
import FeedbackModal from "Components/Modals/Feedback";
import ThankYou from "Components/Modals/Thanks";
import { CalendarBase } from "Components/Scheduler/CalendarBase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, useHistory } from "react-router-dom";
import { fetchIndustriesAsync } from "slices/targetIndustriesSlice";
import { fetchRolesAsync } from "slices/targetRolesSlice";
import HELPERS, { WEEKLY_REFLECTION_TYPES } from "utils/helper";
import {
  fetchDataAsync,
  selectFetched,
  selectProfile,
  selectQuickReflectionInfo,
  selectShowThankYou,
  selectStepReflections,
  setShowThankYou,
} from "../../slices/dashboardSlice";
import steps, { canBeNotified, stepOrder } from "../../utils/steps";
import DashboardComp from "../Dashboard/DashboardComp";
import Assessment from "./../Assessment/Assessment";
import Networkingbase from "./../Networking/Networkingbase";
import Researchindustries from "./../Researchindustries/Researchindustries";
import Researchroles from "./../Researchroles/Researchroles";
import Targetindustries from "./../Targetindustries/Targetindustries";
import Targetroles from "./../Targetroles/Targetroles";
// import "antd/dist/antd.css";
import "./Baselayout.css";
import TIdropdown from "./TIdropdown";
import TRdropdown from "./TRdropdown";
import MenuItem from "antd/lib/menu/MenuItem";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const menu = (
  <Menu>
    <Menu.Item>
      {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
      Videos
      {/* </a> */}
    </Menu.Item>
    <Menu.Item>
      {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
      Woken Book
      {/* </a> */}
    </Menu.Item>
    <Menu.Item>
      {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
      Woken Blog
      {/* </a> */}
    </Menu.Item>
  </Menu>
);

const resources = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UC-odYAm60szKcSnZzcmWA6w?view_as=subscriber"
      >
        Videos
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1r0_pYv6MpTNXKBxlhK0aU9TjadZzMyOO/view"
      >
        Woken Book
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://getwoken.medium.com"
      >
        Woken Blogs
      </a>
    </Menu.Item>
  </Menu>
);

const profileComp = (props) => (
  <Menu>
    {props.impersonated && (
      <Menu.Item onClick={() => props.showProfileModal(true)}>
        {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
        Edit Profile
        {/* </a> */}
      </Menu.Item>
    )}
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/privacy-policy">
        Privacy Policy
      </a>
    </Menu.Item>
    {props.impersonated && (
      <Menu.Item
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("isCoach");
          window.heap.resetIdentity();
          setTimeout(function(){window.location.href = "/signin";}, 6000);
        }}
      >
        <a>Logout</a>
      </Menu.Item>
    )}
  </Menu>
);

const UserDropDown = (props) => {
  return (
    <Dropdown overlay={profileComp(props)} placement="bottomLeft">
      <Button type="text" style={{ color: "#111111" }}>
        {props.full_name} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

//THIS OBJECT MAPS RELATIVE URL WITH DEFAULT SELECTED MENU
const curr_active_map = {
  "/dashboard": "0",
  "/assessment": "3",
  "/target-roles": "4",
  "/target-industries": "5",
  "/research-roles": "6",
  "/research-industries": "7",
  "/networking/contacts": "8",
  "/networking/email-drafts": "9",
  "/networking/tracker": "10",
  "/networking/call-prep": "11",
  "/networking/call-notes": "12",
  "/networking/reflections": "13",
  "/learning-opportunities": "14",
  "/materials/professional-summary": "15",
  "/materials/resume": "16",
  "/materials/cover-letter": "17",
  "/materials/linkedin": "18",
  "/job-search/application-tracker": "19",
  "/job-search/interview-research": "20",
  "/job-search/interview-prep": "21",
  "/job-search/reflections": "22",
  "/calendar": "23",
};
const curr_active_map_filter = {
  "/dashboard": "0",
  "/assessment": "3",
  "/target-roles": "4",
  "/target-industries": "5",
  "/research-roles": "6",
  "/research-industries": "7",
  "/networking/contacts": "8",
  "/networking/email-drafts": "9",
  // "/networking/tracker": "10",
  "/networking/call-prep": "11",
  "/networking/call-notes": "12",
  "/networking/reflections": "13",
  "/learning-opportunities": "14",
  "/materials/professional-summary": "15",
  "/materials/resume": "16",
  "/materials/cover-letter": "17",
  "/materials/linkedin": "18",
  // "/job-search/application-tracker": "19",
  "/job-search/interview-research": "20",
  // "/job-search/interview-prep": "21",
  "/job-search/reflections": "22",
  "/calendar": "23",
};

const Baselayout = (props) => {
  const [timeCounter, setTimeCounter] = useState(0);
  const [saved, setSaved] = useState(false);
  const [value, setValue] = useState(0);
  const [mode, setMode] = useState("inline");
  // State to track which submenu is currently open
  const [openKeys, setOpenKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showNewStepModal, setShowNewStepModal] = useState(true);
  const [selected, setSelected] = useState(
    curr_active_map[window.location.pathname]
  );
  const showFeedback = useSelector(state => state.dashboard.show_feedback)
  const [pathName, setPathName] = useState(window.location.pathname);
  const stepReflections = useSelector(selectStepReflections);
  const quickReflectionInfo = useSelector(selectQuickReflectionInfo);
  const showThankYou = useSelector(selectShowThankYou);
  const profile = useSelector(selectProfile);
  const history = useHistory();
  const full_name = () =>
    profile ? `${profile.first_name} ${profile.last_name}` : "User";

  const impersonated = () => (profile ? profile.impersonated : false);

  const [visibleProfileModal, setVisibleProfileModal] = useState(false);
  useEffect(() => {
    console.log("impersonated in use effect", impersonated(), profile);
    if (!curr_active_map_filter[window.location.pathname]) {
      //incorrect routes handled
      history.push("/");
    }
    if (window.location.pathname.endsWith("/dashboard") && collapsed) {
      HELPERS.log("I am not supposed to be collapsed!");
      setCollapsed(false);
    }

    if (!impersonated() && !localStorage.getItem("isCoach")) {
      console.log("impersonated!!");
      HELPERS.ping();
      if (saved) {
        message.success("Your work has been saved!");
      }
      // HELPERS.changesSaved(() => message.success("Your work has been saved!"));
    } else {
      console.log(" not impersonated!!");
      HELPERS.log("PING: Stop pinging.");
      HELPERS.stopPing();
    }
  }, []);
  const changeMode = (value) => {
    setMode(value ? "vertical" : "inline");
  };

  // Handle submenu open/close
  const onOpenChange = (keys) => {
    // Get the latest opened submenu (last item in keys array)
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    
    if (latestOpenKey) {
      // If there's a newly opened submenu, only keep that one open
      setOpenKeys([latestOpenKey]);
    } else {
      // If all are being closed, empty the array
      setOpenKeys([]);
    }
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const showProfileModal = (visible) => {
    setVisibleProfileModal(visible);
  };

  const isLocked = (step) =>
    stepReflections[step] ? stepReflections[step].status === "LO" : false;

  const isUnlocked = (step) =>
    stepReflections[step] ? stepReflections[step].status === "UN" : false;
  
  const isReview = (step) =>
    stepReflections[step] ? stepReflections[step].status === "RE" : false;

  const isOngoing = (step) =>
    stepReflections[step] ? stepReflections[step].status === "ON" : false;

  const isDone = (step) =>
    stepReflections[step] ? stepReflections[step].status === "DO" : false;

  const isContinuous = (step) =>
    stepReflections[step] ? stepReflections[step].status === "CO" : false;

  const justLaunched = (step) =>
    stepReflections[step]
      ? !isLocked(step) && stepReflections[step].notified === false
      : false;
  const goToOnboarding = () => {
    console.log("going to onboarding");
    // props.history.push({
    //   pathname: '/onboarding',
    //   state: { curr: 4 }
    // })
    return (
      <Redirect to={{ pathname: "/onboarding", stepProps: { curr: 3 } }} />
    );
  };

  const renderMenu = () => (
    <Menu
      style={{
        padding: "35px 12px 12px 12px",
        fontSize: "small",
        height: "100%",
        marginTop: "-3px",
        borderRight: "0px",
      }}
      defaultOpenKeys={["sub2", "sub3", "sub4"]}
      mode={mode}
      // openKeys={openKeys}
      // onOpenChange={onOpenChange}
      selectedKeys={[curr_active_map[window.location.pathname]]} //CURRENT RELATIVE URL USED AS KEY TO INDEX DEFAULT KEY
    >
      {steps.map((step, i) => {
        const subSteps = [];
        let stepLocked = true;

        step.subSteps.forEach((subStep) => {
          let subStepLocked = !subStep.steps.some(
            (step) => isLocked(step.stepKey) === false
          );

          // If any sub step is not locked then consider step not locked
          if (!subStepLocked && stepLocked) {
            stepLocked = false;
          }

          if (subStep.title) {
            console.log('satyam', subStep.title)
            subSteps.push(
              <p
                key={`sub-steps-heading-${subStep.title}`}
                className={subStepLocked ? "locked" : ""}
                style={{ padding: "6% 16%", fontSize: 15, fontWeight: "bold" }}
              >
                {subStep.title}
              </p> 
            );
            
            // const subSteps2 = [];
            // subStep.steps.forEach((step) => {
            //   subSteps2.push(
            //     <Menu.Item
            //       key={step.key}
            //       className={[
            //         step.stepKey && isLocked(step.stepKey) ? "locked" : "",
            //         justLaunched(step.stepKey) ? "just-launched" : "",
            //         !justLaunched(step.stepKey) && isOngoing(step.stepKey)
            //           ? "ongoing"
            //           : !justLaunched(step.stepKey) && isUnlocked(step.stepKey) ? "unlocked" : !justLaunched(step.stepKey) && isReview(step.stepKey) ? "review" : !justLaunched(step.stepKey) && isDone(step.stepKey) ? "done" : !justLaunched(step.stepKey) && isContinuous(step.stepKey) ? "continuous" : "",
            //       ].join(" ")}
            //       title={
            //         step.stepKey && isLocked(step.stepKey)
            //           ? "This step is locked. We know its hard to focus on one thing at a time, but we aim to make your life easier by helping you do just that. Try to not think a few steps ahead as this is a proven, ordered process. Each step will set you up for the next. Let us know if you have questions on how to achieve this step or the purpose, and we're happy to clarify."
            //           : step.title
            //       }
            //     >
            //       {step.stepKey === "AS" &&
            //       isLocked(step.stepKey) &&
            //       justLaunched(step.stepKey)
            //         ? goToOnboarding()
            //         : ""}
            //       {String(step.linkTo).startsWith("http") ? (
            //         <a
            //           href={step.linkTo}
            //           target="_blank"
            //           rel="noopener noreferrer"
            //         >
            //           &nbsp;&nbsp;&nbsp;{step.title}
            //           {justLaunched(step.stepKey) ? (
            //             <span className="just-released">NEW</span>
            //           ) : null}
            //         </a>
            //       ) : ( 
            //         <>
            //           <Link to={step.linkTo}>
            //             &nbsp;&nbsp;&nbsp;{step.title}
            //             {justLaunched(step.stepKey) ? (
            //               <span className="just-released">NEW</span>
            //             ) : null}
            //           </Link>
            //            <img
            //              src={!justLaunched(step.stepKey) && isOngoing(step.stepKey)
            //                ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg"
            //                : isUnlocked(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuUnlockedSmall.svg" : isReview(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuReviewSmall.svg" : isDone(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuDoneSmall.svg" : isContinuous(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg" : "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg"}
            //              width="20px"
            //            />
            //         </>
            //       )}
            //     </Menu.Item>
            //   );
            // })
            
            // // console.log('satyam2', subStep.key);
            // subSteps.push(
            //   <SubMenu
            //     style={{ borderRight: "3px !important", marginBottom: "5px" }}
            //     key={subStep.key}
            //     title={subStep.title}
            //     // openKeys={openKeys}
            //     // onOpenChange={onOpenChange}
            //     className={[
            //       stepLocked ? "locked" : "",
            //       subStep.title.toLowerCase(),
            //       "main-menu-level-1"
            //     ].join(" ")}
            //   >
            //     {subSteps2}
            //   </SubMenu>
            // )
          }
          // else{

            subStep.steps.forEach((step) => {
              // console.log('step: ', step);
              subSteps.push(
                <Menu.Item
                  key={step.key}
                  className={[
                    step.stepKey && isLocked(step.stepKey) ? "locked" : "",
                    justLaunched(step.stepKey) ? "just-launched" : "",
                    !justLaunched(step.stepKey) && isOngoing(step.stepKey)
                      ? "ongoing"
                      : !justLaunched(step.stepKey) && isUnlocked(step.stepKey) ? "unlocked" : (!justLaunched(step.stepKey) && isReview(step.stepKey) && impersonated()) ? "review" : !justLaunched(step.stepKey) && isDone(step.stepKey) ? "done" : !justLaunched(step.stepKey) && isContinuous(step.stepKey) ? "continuous" : "",
                  ].join(" ")}
                  title={
                    step.stepKey && isLocked(step.stepKey)
                      ? "This step is locked. We know its hard to focus on one thing at a time, but we aim to make your life easier by helping you do just that. Try to not think a few steps ahead as this is a proven, ordered process. Each step will set you up for the next. Let us know if you have questions on how to achieve this step or the purpose, and we're happy to clarify."
                      : step.title
                  }
                >
                  {step.stepKey === "AS" &&
                  isLocked(step.stepKey) &&
                  justLaunched(step.stepKey)
                    ? goToOnboarding()
                    : ""}
                  {String(step.linkTo).startsWith("http") ? (
                    <a
                      href={step.linkTo}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {step.title}
                      {/* {justLaunched(step.stepKey) ? (
                        <span className="just-released">NEW</span>
                      ) : null} */}
                    </a>
                  ) : ( 
                    <>
                      <Link to={step.linkTo}>
                        {step.title}
                        {/* {justLaunched(step.stepKey) ? (
                          <span className="just-released">NEW</span>
                        ) : null} */}
                      </Link>
                      {/* {
                        !justLaunched(step.stepKey) && isContinuous(step.stepKey)
                      } */}
                      {/* <img
                        src={!justLaunched(step.stepKey) && isOngoing(step.stepKey)
                          ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg"
                          : isUnlocked(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuUnlockedSmall.svg" : isReview(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuReviewSmall.svg" : isDone(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuDoneSmall.svg" : isContinuous(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg" : ""}
                        width="20px"
                      /> */}
                      {
                        !isContinuous(step.stepKey) ? <img
                          src={isOngoing(step.stepKey)
                            ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg"
                            : (isReview(step.stepKey) && impersonated()) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuReviewSmall.svg" : isDone(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuDoneSmall.svg" : justLaunched(step.stepKey) ? "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuUnlockedSmall.svg" : ""}
                          width="20px" className="status-badge"
                        /> : ""
                      }
                    </>
                  )}
                </Menu.Item>
              );
            });

          // }

        });

        // Render Menu.Item if no subSteps, otherwise SubMenu
        if (!step.subSteps || step.subSteps.length === 0) {
          return (
            <Menu.Item
            style={{ borderRight: "3px !important", }}
            key={step.key}
            title={step.title}
            >
             <div 
             className="ant-menu-submenu-title"
             >
              <Link to={step.linkTo}
              >
              <span>
                <img
                  src={step.icon}
                  width="20px"
                  height="20px"
                  // alt={step.title}
                  style={{
                    // width: "15px",
                    // height: "15px",
                    // marginRight: "8px",
                    verticalAlign: "middle",
                    // gap: 0px;
                    top: "-0.5px",
                    left: "-1.5px",
                    position: "relative",
                    borderRadius: "1px 0px 0px 0px",
                    border: "1px 0px 0px 0px",
                    opacity: "0px",

                  }}
                />
                {step.title}
              </span>
                </Link></div>
            </Menu.Item>
          )
        }

        return (
          <SubMenu
            style={{ borderRight: "3px !important", marginBottom: "5px" }}
            key={step.key}
            // title={step.title}
            title={
              <span>
                <img
                  src={step.icon}
                  width="20px"
                  height="20px"
                  alt={step.title}
                  style={{
                    // marginRight: "8px",
                    verticalAlign: "middle",
                    // gap: 0px;
                    top: "-0.5px",
                    left: "-1.5px",
                    position: "relative",
                    borderRadius: "1px 0px 0px 0px",
                    border: "1px 0px 0px 0px",
                    opacity: "0px",

                  }}
                />
                {step.title}
              </span>
            }
            className={[
              stepLocked ? "locked" : "",
              step.title.toLowerCase(),
              "main-menu-level-0"
            ].join(" ")}
          >
            {subSteps}
          </SubMenu>
        );
      })}
    </Menu>
  );
  console.log(
    "$$woken$$",
    "base rendered",
    "fetched",
    props.fetched,
    "impersonated",
    impersonated(),
    // props,
    showNewStepModal,
    { mode, collapsed, visible, selected, pathName, stepReflections },
    window.location.pathname
  );
  const d = new Date();
  console.log(d.getMonth() + 1);

  const notifiedNewStep = (item) => {
    let temp = { ...showNewStepModal };
    temp[item] = false;
    console.log("close!!", temp);
    setShowNewStepModal(temp);
    // setValue(value+1)
  };

  const newStepAvailable = () => {
    let newStep = [];

    for (let stepReflection in stepReflections) {
      if (justLaunched(stepReflection)) {
        if (
          canBeNotified(stepReflection)
          // && (newStep === null || stepOrder(stepReflection) < stepOrder(newStep))
        ) {
          newStep.push(stepReflection);
        }
      }
    }
    if (typeof showNewStepModal !== "object" && newStep.length) {
      console.log("is object!!");
      let temp = {};
      newStep.forEach((item) => {
        temp[item] = true;
      });
      setShowNewStepModal(temp);
    }
    // console.log("new Step", newStep);
    // return newStep;
  };

  const stopImpersonating = () => {
    COACH_API.stopImpersonate()
      .then(() => {
        // window.close();
        window.location.href = "/coach";
      })
      .catch(() => {
        message.error("Something went wrong. Try reloading the page!");
      });
  };

  return (
    <>
      {console.log("time??")}

      {props.fetched ? (
        <div
          className="headerimage"
          style={{ backgroundColor: "#ffffff", height: "100vh" }}
        >
          <div style={{boxShadow: "0px 4px 20px 0px #0000000F", display: "flex"}}>
          <div style={{ backgroundColor: "#111111" }} className="res-width-left">
              <div style={{ padding: '15px', width: '100%', backgroundColor: '#111111', textAlign: 'left', padding: "35px 0px 5px 17px" }}>
                {/* <div className="sitelogo">WOKEN</div> */}
                <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/logo.png" Width="123.95px" Height="32px" />
              </div>
            </div>
            <div style={{ }} className="res-width-right">
              {/* <Row style={{ padding:"22px 24px" ,boxShadow: " 0px 4px 6px rgba(0, 0, 0, 0.06)" ,backgroundColor:"white" }}> */}
              <Row style={{ minHeight: "72px", maxHeight:"72px", alignItems: "center", padding: "0 20px", }}>

                <Col span={9}>
                  <div 
                  className="username-header-top"
                  style={{ 
                    textAlign: "left",
                    fontSize: "20px",
                    fontWeight: '800',
                    color: "#111111",
                    padding: "2% 1%",
                    fontFamily:'AvenirBook',
                    width: 'fit-content',
                    lineHeight: "27.32px",
                    display: "flex",
                        gap: "20px",
                        alignItems: "center",
                    }}>
                    {/* <Link
                      to={{
                        pathname: "/dashboard",
                        stepProps: { collapsed: false },
                      }}
                    >
                      <Button
                        type="text"
                        style={{ fontWeight: "bold", color: "white" }}
                        onClick={() => {
                          setSelected("0");
                        }}
                      >
                        Dashboard
                      </Button>
                    </Link> */}
                    <Link 
                      to="/dashboard"
                    >
                      Welcome, {profile ? profile.first_name : "User"}!
                    </Link>
                    <div>
                      <Link to="/calendar">
                        <Button
                          type="text"
                          style={{ fontWeight: "bold", color: "#111111", paddingTop: "6px" }}
                        >
                          Calendar
                        </Button>
                      </Link>
                      <Dropdown overlay={resources} placement="bottomRight">
                        <Button
                          type="link"
                          style={{ fontWeight: "bold", color: "#111111" }}
                        >
                          Resources
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                    {/* Welcome, {profile ? profile.first_name : "User"}! */}
                  </div>
                </Col>
                <Col span={15}>
                  <div style={{ float: "right"}}>
                    <TRdropdown />
                    <TIdropdown />
                    <UserDropDown
                      {...props}
                      full_name={full_name()}
                      showProfileModal={showProfileModal}
                      impersonated={!impersonated()}
                    />
                  </div>
                </Col>
                 {/* <div className="top-bar-container">
                      <h3 className="user-name">Welcome, Rachel</h3>
                      <div className="right-icon-container">
                        <div>

                      <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9375 19.9453C17.8208 20.0602 17.6637 20.1247 17.5 20.125C17.334 20.1243 17.1747 20.06 17.0547 19.9453L13.6797 16.5625C12.2583 17.7564 10.4308 18.3555 8.57844 18.2348C6.72607 18.114 4.99182 17.2828 3.73738 15.9146C2.48294 14.5463 1.80518 12.7465 1.84545 10.8906C1.88571 9.03477 2.6409 7.2661 3.9535 5.9535C5.2661 4.6409 7.03477 3.88571 8.89063 3.84545C10.7465 3.80518 12.5463 4.48294 13.9146 5.73738C15.2828 6.99182 16.114 8.72607 16.2348 10.5784C16.3555 12.4308 15.7564 14.2583 14.5625 15.6797L17.9375 19.0547C17.9966 19.1128 18.0434 19.1822 18.0755 19.2586C18.1075 19.3351 18.124 19.4171 18.124 19.5C18.124 19.5829 18.1075 19.6649 18.0755 19.7414C18.0434 19.8178 17.9966 19.8872 17.9375 19.9453ZM9.0625 17C10.2368 17 11.3848 16.6518 12.3612 15.9993C13.3376 15.3469 14.0986 14.4196 14.548 13.3347C14.9974 12.2497 15.115 11.0559 14.8859 9.90415C14.6568 8.75239 14.0913 7.69443 13.2609 6.86405C12.4306 6.03368 11.3726 5.46819 10.2208 5.23909C9.06909 5.00999 7.87525 5.12757 6.79032 5.57696C5.70538 6.02636 4.77807 6.78738 4.12565 7.7638C3.47323 8.74022 3.125 9.88817 3.125 11.0625C3.12707 12.6366 3.75329 14.1456 4.86634 15.2587C5.97938 16.3717 7.48841 16.9979 9.0625 17Z" fill="black"/>
</svg>
</div>
<div>

<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6679 19.5001H8.33461M15.0013 8.66675C15.0013 7.34067 14.4745 6.0689 13.5368 5.13121C12.5991 4.19353 11.3274 3.66675 10.0013 3.66675C8.6752 3.66675 7.40343 4.19353 6.46574 5.13121C5.52806 6.0689 5.00128 7.34067 5.00128 8.66675C5.00128 11.2419 4.35167 13.005 3.626 14.1713C3.01388 15.155 2.70783 15.6468 2.71905 15.784C2.73147 15.936 2.76366 15.9939 2.88609 16.0847C2.99666 16.1667 3.4951 16.1667 4.49199 16.1667H15.5106C16.5075 16.1667 17.0059 16.1667 17.1165 16.0847C17.2389 15.9939 17.2711 15.936 17.2835 15.784C17.2947 15.6468 16.9887 15.155 16.3766 14.1713C15.6509 13.005 15.0013 11.2419 15.0013 8.66675Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="14" cy="4" r="4" fill="#FF4747"/>
</svg>
</div>
<div>

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.9166 11.25L17.2505 9.58333L15.5833 11.25M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C12.7516 2.5 15.1571 3.98178 16.4621 6.19091M10 5.83333V10L12.5 11.6667" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<div>

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 17.5H5.16667C4.23325 17.5 3.76654 17.5 3.41002 17.3183C3.09641 17.1586 2.84144 16.9036 2.68166 16.59C2.5 16.2335 2.5 15.7668 2.5 14.8333V2.5M5.83333 12.0833V14.5833M9.58333 9.58333V14.5833M13.3333 7.08333V14.5833M17.0833 4.58333V14.5833" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
<button className="customize-dashboard">Customize Dashboard</button>
                      </div>
                 </div> */}
              </Row>
            </div>
          </div>
          
          {newStepAvailable()}
          <Layout style={{ backgroundColor: "#f5f5f500" }}>
            <Sider
              style={{ backgroundColor: "#f5f5f500", padding: "0% 0 0"}}
              collapsed={collapsed}
              className="res-width-left"
            >
              {renderMenu()}
            </Sider>

            <Layout style={{ backgroundColor: "#f5f5f500", }} className="res-width-right">
            <div
              className="impersonating-wrapper"
              style={{
                display: impersonated() ? "block" : "none",
              }}
            >
              <span>
                Impersonate Mode on.{" "}
                <span onClick={stopImpersonating}>Stop impersonating</span>
              </span>
            </div>
              <Content style={{ backgroundColor: "#f5f5f500", marginTop: "40px" }}>
                <div>
                  <Route
                    path="/calendar"
                    exact
                    render={(props) => (
                      <CalendarBase toggle={toggle} collapsed={collapsed} />
                    )}
                  />
                  <Route
                    path="/dashboard"
                    exact
                    render={(props) => (
                      <DashboardComp
                        toggle={toggle}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/assessment"
                    exact
                    render={(props) => (
                      <Assessment
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        stepsStatus={stepReflections}
                      />
                    )}
                  />
                  <Route
                    path="/target-roles"
                    exact
                    render={(props) => (
                      <Targetroles
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/target-industries"
                    exact
                    render={(props) => (
                      <Targetindustries
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/research-roles"
                    exact
                    render={(props) => (
                      <Researchroles
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/research-industries"
                    exact
                    render={(props) => (
                      <Researchindustries
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/contacts"
                    exact
                    render={(props) => (
                      <Networkingbase
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/email-drafts"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={1}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/tracker"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={2}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/call-prep"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={3}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/call-notes"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={4}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/reflections"
                    exact
                    render={(props) => (
                      <WeeklyReflections
                        current_step={0}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        type={WEEKLY_REFLECTION_TYPES.NETWORKING}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/learning-opportunities"
                    exact
                    render={(props) => (
                      <Learningbase
                        current_step={0}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/professional-summary"
                    exact
                    render={(props) => (
                      <Materialsbase
                        // current_step={4}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/resume"
                    exact
                    render={(props) => (
                      <Materialsbase
                        current_step={1}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/cover-letter"
                    exact
                    render={(props) => (
                      <Materialsbase
                        current_step={2}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/linkedin"
                    exact
                    render={(props) => (
                      <Materialsbase
                        current_step={3}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/application-tracker"
                    exact
                    render={(props) => (
                      <JobSearchBase
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/interview-research"
                    exact
                    render={(props) => (
                      <JobSearchBase
                        current_step={1}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/interview-prep"
                    exact
                    render={(props) => (
                      <JobSearchBase
                        current_step={2}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/reflections"
                    exact
                    render={(props) => (
                      <WeeklyReflections
                        current_step={0}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        type={WEEKLY_REFLECTION_TYPES.JOB_SEARCH}
                        {...props}
                      />
                    )}
                  />
                  {/* <Route
                path="/"
                // exact
                render={() => (
                  <Redirect to='/dashboard'/>
                )}
              /> */}
                </div>
              </Content>
            </Layout>
          </Layout>

          {!impersonated() &&
            typeof showNewStepModal === "object" &&
            Object.keys(showNewStepModal)
              .sort((a, b) => stepOrder(b) - stepOrder(a))
              .map((item, index) => (
                <NewStep
                  visible={showNewStepModal[item]}
                  stepKey={item}
                  onClose={() => notifiedNewStep(item)}
                  mask={index === 0}
                />
              ))}
          {console.log(
            "quickrefinfo",
            quickReflectionInfo,
            window.location.pathname
          )}
          <QuickReflection
            visible={quickReflectionInfo.show}
            stepId={quickReflectionInfo.stepId}
            path={window.location.pathname}
          />
          <FeedbackModal
              visible ={showFeedback}
          ></FeedbackModal>
          <ThankYou visible={showThankYou} onClose={props.closeThankYou} />

          {visibleProfileModal && (
            <Profile
              visible={visibleProfileModal}
              handleCancel={() => showProfileModal(false)}
            />
          )}
          {!isLocked("MI") && Object.keys(stepReflections).length > 0 && (
            <Midwaycall visible={true} obj={stepReflections["MI"]} />
          )}
          {!isLocked("NP") &&
            Object.keys(stepReflections).length > 0 &&
            Object.keys(stepReflections).includes("NP") && (
              <Skipstep visible={true} obj={stepReflections["NP"]} />
            )}
        </div>
      ) : (
        <LoadingOutlined
          style={{ fontSize: 50, color: "#7fced2", marginTop: "50px" }}
        />
      )}
    </>
  );
};

const BaselayoutContainer = ({ props }) => {
  const fetched = useSelector(selectFetched);
  const dispatch = useDispatch();


  useEffect(() => {
    // console.log('$$woken$$', 'dashboard current state', { tasks, fetched });
    if (!fetched) {
      // console.log('$$woken$$', 'Dispatching fetchDataAsync...');
      dispatch(fetchDataAsync());
      dispatch(fetchRolesAsync());
      dispatch(fetchIndustriesAsync());
      // window.setInterval(function(){
      //   message.success('Your work has been saved!')
      // }, 60000)
      // HELPERS.changesSaved(()=>message.success('Your work has been saved!'))
    }
  }, []);

  const closeThankYou = () => {
    dispatch(setShowThankYou(false));
  };

  return (
    <Baselayout fetched={fetched} closeThankYou={closeThankYou} {...props} />
  );
};

export default BaselayoutContainer;
