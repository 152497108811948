import { CheckCircleFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  TimePicker,
} from "antd";
import moment from "moment";
import React from "react";
import HELPERS from "utils/helper";
import { getMonths, getMonthsWithYears, getWidth, TIMELINE_AREAS } from "utils/timeline";
import "../Dashboard/Timeline.css";
import AUTH_API from "./../../api/auth";
import { withRouter } from "react-router-dom";
import debounce from 'lodash.debounce';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = TimePicker;

const today = moment();

const arr = [];
for (var i = 1; i <= 30; i++) {
  arr.push(i);
}

class Step5 extends React.Component {
  formRef = React.createRef();
  sectionRef = React.createRef();

  state = {
    items: [
      { day: "monday", checked: false, time: ["12:00 am", "12:00 pm"] },
      { day: "tuesday", checked: false, time: ["12:00 am", "12:00 pm"] },
      { day: "wednesday", checked: false, time: ["12:00 am", "12:00 pm"] },
      { day: "thursday", checked: false, time: ["12:00 am", "12:00 pm"] },
      { day: "friday", checked: false, time: ["12:00 am", "12:00 pm"] },
      { day: "saturday", checked: false, time: ["12:00 am", "12:00 pm"] },
      { day: "sunday", checked: false, time: ["12:00 am", "12:00 pm"] },
    ],
    answers: [],
    hours_per_week_commitment: -1,
    marginLeft: "0",
    user_data: null,
    hasError: false,
    isNotificationUp: false,
    timeoutId:0,
  };

  componentDidMount() {
    let question_fields = {}
    for(let i = 0; i < 8; i++){
        question_fields = {...question_fields,[`q${i}`]: this.state.answers[i]}

    }
    this.formRef.current.setFieldsValue({
        ...question_fields
    })
    window.scrollTo(0, 0);

    AUTH_API.getYourCommitment()
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        this.setState({
          items: data.daily_commitment.length
            ? [...data.daily_commitment]
            : this.state.items,
          answers: [...data.vouch],
          hours_per_week_commitment: data.hours_per_week_commitment,
        });
        console.log(this.state.items, this.state.answers);
        this.formRef.current.setFieldsValue({
          hours_per_week_commitment: data.hours_per_week_commitment,
          date_picker1: [
            moment(this.state.items[0].time[0], "HH:mm a"),
            moment(this.state.items[0].time[1], "HH:mm a"),
          ],
          date_picker2: [
            moment(this.state.items[1].time[0], "HH:mm a"),
            moment(this.state.items[1].time[1], "HH:mm a"),
          ],
          date_picker3: [
            moment(this.state.items[2].time[0], "HH:mm a"),
            moment(this.state.items[2].time[1], "HH:mm a"),
          ],
          date_picker4: [
            moment(this.state.items[3].time[0], "HH:mm a"),
            moment(this.state.items[3].time[1], "HH:mm a"),
          ],
          date_picker5: [
            moment(this.state.items[4].time[0], "HH:mm a"),
            moment(this.state.items[4].time[1], "HH:mm a"),
          ],
          date_picker6: [
            moment(this.state.items[5].time[0], "HH:mm a"),
            moment(this.state.items[5].time[1], "HH:mm a"),
          ],
          date_picker7: [
            moment(this.state.items[6].time[0], "HH:mm a"),
            moment(this.state.items[6].time[1], "HH:mm a"),
          ],
        });
      })
      .then((res) => {
        AUTH_API.CoachListView()
          .then((response) => {
            const user_id = localStorage.getItem("user_id");
            const user_data = response.data.filter(
              (item) => item.id.toString() === user_id
            )[0];
            console.log("userdata", user_data, response);
            this.setState({ user_data });
          })
          .catch((err) => {
            console.log("get api error", err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate() {
    let question_fields = {}
    for(let i = 0; i < 8; i++){
        question_fields = {...question_fields,[`q${i}`]: this.state.answers[i]}

    }
    this.formRef.current.setFieldsValue({
        ...question_fields
    })
    if (this.sectionRef.current) {
      let margin = `${
        (today.date() * this.sectionRef.current.offsetWidth) /
        today.daysInMonth()
      }px`;
      // if (margin !== this.state.marginLeft) {
      //   this.setState({
      //     marginLeft: margin,
      //   });
      // }
    }
  };

  onFormChange2 = debounce((values) => {
    console.log("values:", values);

    console.log(this.state)
    const data = {
      hours_per_week_commitment: values.hours_per_week_commitment,
      daily_commitment: [...this.state.items],
      vouch: [...this.state.answers],
    };
    console.log("data is:", data);
    AUTH_API.updateYourCommitment(data)
      .then((response) => {
        console.log("response !!", response);
        message.success('Your work has been saved');

        this.setState({
          hours_per_week_commitment: response.data.hours_per_week_commitment,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, 1000)

  onFormChange = (values) => {
    console.log("values:", values);

      console.log(this.state)
      const data = {
        hours_per_week_commitment: values.hours_per_week_commitment,
        daily_commitment: [...this.state.items],
        vouch: [...this.state.answers],
      };
      console.log("data is:", data);
      AUTH_API.updateYourCommitment(data)
        .then((response) => {
          console.log("response !!", response);
          message.success('Your work has been saved');

          this.setState({
            hours_per_week_commitment: response.data.hours_per_week_commitment,
          });
        })
        .catch((err) => {
          console.log(err);
        });
  };

  onSubmit = () => {
    let step_data = { ...this.state.user_data };
    console.log("on submit", step_data, step_data.id);
    if (step_data.id) {
      step_data = step_data.stepreflection_set.filter(
        (item) => item.step === "AS"
      )[0];
    } else {
      step_data.status = "UN";
    }
    console.log("step_data", step_data, step_data.id);
    if (step_data.status === "LO") {
      AUTH_API.CoachStepsPatch(
        { id: step_data.id, step: "AS", status: "UN" },
        step_data.id
      )
        .then((response) => {
          message.success("Commitment Saved");
          console.log("patch api response", response);
          // localStorage.setItem('thru_onb', true)
          window.location.href = "/";
          // this.props.history.push("/");
          // return <Redirect to='/'/>
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
    } else {
      console.log("in else");
      window.location.href = "/";
      // this.props.history.push("/");
      // return <Redirect to='/dashboard'/>
    }
  };

  selectCheckbox = (e) => {
    const index = e.target.value;
    const newItems = [...this.state.items];
    newItems[index].checked = !this.state.items[index].checked;
    console.log("newItems:", newItems);
    this.setState({
      items: newItems,
    });
    const data = {
      daily_commitment: [...this.state.items],
    };
    AUTH_API.updateYourCommitment(data)
      .then((response) => {
        console.log("response !!", response);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("new state:", this.state.items);
  };
  onTimechange = (time, timeString, index) => {
    console.log(time, timeString, index);
    const newItems = [...this.state.items];
    newItems[index].time = timeString;
    console.log("newItems after dateset:", newItems);
    this.setState({
      items: newItems,
    });
    console.log("new state after dateset:", this.state.items);
  };
  onAnswers = (e, index) => {
    const ans = e.target.value;
    const newanswers = [...this.state.answers];
    newanswers[index] = ans;
    // console.log("newanswers:", newanswers)
    this.setState({
      answers: newanswers,
    });
    // console.log('new state after answers:',this.state.answers);
  };

  render() {
    // console.log("rendered arr is",this.state.items);
    // console.log(moment(this.state.items[1].time[0],'HH:mm a'),moment(this.state.items[1].time[1],'HH:mm a'));
    return (
      <div style={{ margin: "5% 0 7% 0" }}>
        <div
          className="h"
          style={{
            font: "normal normal 600 30px/44px AvenirBook",
          }}
        >
          Your Commitment to Yourself & to Us
        </div>
        <Form
          name="your_comm_form"
          onFinish={this.onSubmit}
          onFinishFailed={() => this.setState({ hasError: true })}
          //onValuesChange={HELPERS.debounceFormChanges(this.onFormChange2)}
          onValuesChange={this.onFormChange2}
          ref={this.formRef}
          scrollToFirstError={true}
        >
          <Row>
            <Col span={5}></Col>
            <Col span={14} style={{ textAlign: "left" }}>
              <p
                style={{
                  font: "normal normal bold 18px/22px AvenirBook",
                  margin: "35px 0 10px 0",
                }}
              >
                What you vouch:
              </p>
              <p style={{ font: "normal normal normal 15px/22px AvenirBook" }}>
                Remember that you deserve to thrive during your career
                exploration. To do so, follow the linear exploration path your
                coach sets out for you and take it one step at a time. Fill in
                the sentences below to create your own commitment to your path.
              </p>

              <Form.Item name="q0" rules={[{ required: true, message: 'This field is required' }]}>
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: this?.formRef?.current?.getFieldError("q0").length
                        ? "red"
                        : "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />
                  <p
                    style={{
                      font: "normal normal normal 16px/22px AvenirBook",
                      width: "100%",
                    }}
                  >
                    In order to stay accountable, I will
                    <TextArea
                      style={{
                        color: "#199094",
                        padding: 0,
                        borderBottom: "dotted 3px",
                      }}
                      value={this.state.answers[0]}
                      onChange={(e) => this.onAnswers(e, 0)}
                      placeholder="your action"
                      autoSize
                      bordered={false}
                    />
                  </p>
                  {/* <br/><span style={{color:'#199094', padding:0}}>write a journal with my progress every day.</span></p> */}
                </div>
              </Form.Item>

              <Form.Item rules={[{ required: true, message: 'This field is required' }]} name="q1">
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: this?.formRef?.current?.getFieldError("q1").length
                        ? "red"
                        : "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />
                  <p
                    style={{
                      font: "normal normal normal 16px/22px AvenirBook",
                      width: "100%",
                    }}
                  >
                    I know I feel discouraged when
                    <TextArea
                      style={{
                        color: "#199094",
                        padding: 0,
                        borderBottom: "dotted 3px",
                      }}
                      value={this.state.answers[1]}
                      onChange={(e) => this.onAnswers(e, 1)}
                      placeholder="your action"
                      autoSize
                      bordered={false}
                    />
                  </p>
                </div>
              </Form.Item>

              <Form.Item rules={[{ required: true, message: 'This field is required' }]} name="q2">
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: this?.formRef?.current?.getFieldError("q2").length
                        ? "red"
                        : "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />
                  <p
                    style={{
                      font: "normal normal normal 16px/22px AvenirBook",
                      width: "100%",
                    }}
                  >
                    When I feel discouraged I will
                    <TextArea
                      style={{
                        color: "#199094",
                        padding: 0,
                        borderBottom: "dotted 3px",
                      }}
                      value={this.state.answers[2]}
                      onChange={(e) => this.onAnswers(e, 2)}
                      placeholder="your action"
                      autoSize
                      bordered={false}
                    />
                  </p>
                </div>
              </Form.Item>

              <Form.Item rules={[{ required: true, message: 'This field is required' }]} name="q3">
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: this?.formRef?.current?.getFieldError("q3").length
                        ? "red"
                        : "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />
                  <p
                    style={{
                      font: "normal normal normal 16px/22px AvenirBook",
                      width: "100%",
                    }}
                  >
                    This process requires both persistence and patience.
                    <br />
                    When I feel impatient I will
                    <TextArea
                      style={{
                        color: "#199094",
                        padding: 0,
                        borderBottom: "dotted 3px",
                      }}
                      value={this.state.answers[3]}
                      onChange={(e) => this.onAnswers(e, 3)}
                      placeholder="your action"
                      autoSize
                      bordered={false}
                    />
                  </p>
                </div>
              </Form.Item>

              <Form.Item rules={[{ required: true, message: 'This field is required' }]} name="q4">
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: this?.formRef?.current?.getFieldError("q4").length
                        ? "red"
                        : "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />
                  <p
                    style={{
                      font: "normal normal normal 16px/22px AvenirBook",
                      width: "100%",
                    }}
                  >
                    This process requires learning and action.
                    <br />
                    When I feel stuck I will
                    <TextArea
                      style={{
                        color: "#199094",
                        padding: 0,
                        borderBottom: "dotted 3px",
                      }}
                      value={this.state.answers[4]}
                      onChange={(e) => this.onAnswers(e, 4)}
                      placeholder="your action"
                      autoSize
                      bordered={false}
                    />
                  </p>
                </div>
              </Form.Item>

              <Form.Item rules={[{ required: true, message: 'This field is required' }]} name="q5">
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: this?.formRef?.current?.getFieldError("q5").length
                        ? "red"
                        : "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />
                  <p
                    style={{
                      font: "normal normal normal 16px/22px AvenirBook",
                      width: "100%",
                    }}
                  >
                    I know this process will be driven by me, my time and my
                    effort.
                    <br />
                    I will make certain that I will commit my energy every week
                    by:
                    <TextArea
                      style={{
                        color: "#199094",
                        padding: 0,
                        borderBottom: "dotted 3px",
                      }}
                      value={this.state.answers[5]}
                      onChange={(e) => this.onAnswers(e, 5)}
                      placeholder="your action"
                      autoSize
                      bordered={false}
                    />
                  </p>
                </div>
              </Form.Item>

              <Form.Item rules={[{ required: true, message: 'This field is required' }]} name="q6">
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: this?.formRef?.current?.getFieldError("q6").length
                        ? "red"
                        : "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />
                  <p
                    style={{
                      font: "normal normal normal 16px/22px AvenirBook",
                      width: "100%",
                    }}
                  >
                    I vouch to trust the process and embrace a period of
                    tolerating uncertainty.
                    <br />
                    When I feel impulsive to have all the answers, I will....
                    <TextArea
                      style={{
                        color: "#199094",
                        padding: 0,
                        borderBottom: "dotted 3px",
                      }}
                      value={this.state.answers[6]}
                      onChange={(e) => this.onAnswers(e, 6)}
                      placeholder="your action"
                      autoSize
                      bordered={false}
                    />
                  </p>
                </div>
              </Form.Item>

              <Form.Item name="q7">
                <div style={{ display: "flex" }}>
                  <CheckCircleFilled
                    style={{
                      color: "#199094",
                      fontSize: "16px",
                      marginRight: "16px",
                      marginTop: "5px",
                    }}
                  />

                  <TextArea
                    style={{
                      color: "#199094",
                      padding: 0,
                      font: "normal normal normal 14px/22px AvenirBook",
                      borderBottom: "dotted 3px",
                    }}
                    value={this.state.answers[7]}
                    onChange={(e) => this.onAnswers(e, 7)}
                    placeholder="Insert your own commitment here"
                    autoSize
                    bordered={false}
                  />
                </div>
              </Form.Item>
              <p
                style={{
                  font: "normal normal bold 18px/22px AvenirBook",
                  paddingTop: "50px",
                }}
              >
                How we will do it:
              </p>

              <p>
                I commit
                <Form.Item
                  name="hours_per_week_commitment"
                  style={{
                    margin: "0",
                    width: "70px",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                  rules={[{ required: true, message: "Required Field" }]}
                >
                  <Select
                    bordered={false}
                    style={{ color: "#199094", width: "60px" }}
                    listHeight={120}
                  >
                    {arr.map((item, index) => (
                      <Option value={index + 1}>{index + 1}</Option>
                    ))}
                  </Select>
                </Form.Item>
                hours per week to my process.
              </p>
              <p style={{ font: "normal normal normal 14px/22px AvenirBook" }}>
                Based on the hours you committed, your timeline will look
                approximately like this.
              </p>
              <div className="woken-progress">
                {/* <div className="timeline title section">
                  <span
                    ref={this.sectionRef}
                    className="space"
                    style={{
                      marginLeft: this.state.marginLeft,
                    }}
                  ></span>
                  <div
                    className="portion"
                    style={{
                      width: `${getWidth(
                        TIMELINE_AREAS.EXPLORATION,
                        this.state.hours_per_week_commitment
                      )}%`,
                    }}
                    title="Career Exploration"
                  >
                    <span>Career Exploration</span>
                  </div>
                  <div
                    className="portion"
                    style={{
                      width: `${getWidth(
                        TIMELINE_AREAS.JOB_SEARCH,
                        this.state.hours_per_week_commitment
                      )}%`,
                    }}
                    title="Job Search"
                  >
                    <span>Job Search</span>
                  </div>
                </div> */}
                {/* <div class="timeline section"></div> */}
                {/* <div class="timeline section"></div>
                <div class="timeline section"></div> */}
                {/* <div className="timeline section">
                  <span
                    className="space"
                    style={{
                      // marginLeft: "102px",
                      width: "21%",
                    }}
                  ></span>
                  <div style={{ position: "relative", width: "60px" }}>
                    <div className="" style={{
                        position:"absolute",
                        top:"-38px"
                      }}>
                      <button className="" style={{
                        width:"", height:"32px",background:"#CCE6E7",
                        borderRadius:"4px",width:"171px", color:"#111111" , fontSize:"16px"  , border:"none" , padding:"4px 8px", position:"relative"
                        }}>
                        Personal Branding
                      </button>
                      <svg width="8" style={{
                        position:"absolute" , top:"28px", left:"10px"
                        }} height="30" viewBox="0 0 6 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 16.6667C4.47276 16.6667 5.66667 15.4728 5.66667 14C5.66667 12.5272 4.47276 11.3333 3 11.3333C1.52724 11.3333 0.333333 12.5272 0.333333 14C0.333333 15.4728 1.52724 16.6667 3 16.6667ZM3.5 14L3.5 -9.53674e-07H2.5L2.5 14H3.5Z" fill="#9F9F9F"/>
                      </svg>
                    </div>
                    <span
                      className="job-search portion"
                      style={{
                        width: `${getWidth(
                          TIMELINE_AREAS.JOB_SEARCH,
                          this.state.hours_per_week_commitment
                        )}%`,
                        width: "60px",
                        background: "linear-gradient(90deg, rgba(2, 90, 97, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%)",
                        padding: "0",
                      }}
                      title="Personal Branding"
                    >
                    </span>
                  </div>
                  <span
                    className="job-search portion"
                    style={{
                      width: `${getWidth(
                        TIMELINE_AREAS.JOB_SEARCH,
                        this.state.hours_per_week_commitment
                      )}%`,
                    }}
                    title="Job Searching"
                  >
                    Job Searching
                  </span>
                  <span
                    className="job-search portion"
                    style={{
                      // width: `${getWidth(
                      //   TIMELINE_AREAS.JOB_SEARCH,
                      //   this.state.hours_per_week_commitment
                      // )}%`,
                      width: "29%",
                      background: "linear-gradient(0deg, #D5F1F4, #D5F1F4),linear-gradient(90deg, rgba(0, 26, 28, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%);",
                      borderRight: "0px solid #329CA0",
                    }}
                    title="Career Advancement"
                  >
                    Career Advancement
                  </span>
                </div> */}
                <div className="timeline section">
                  <span
                    className="space"
                    style={{
                      marginLeft: this.state.marginLeft,
                    }}
                  ></span>
                  <span
                    className="exploration portion"
                    style={{
                      width: `${getWidth(
                        TIMELINE_AREAS.EXPLORATION,
                        this.state.hours_per_week_commitment
                      )}%`,
                    }}
                    title="Career Exploration"
                  >
                    Career Exploration
                  </span>
                  <span
                    className="job-search portion"
                    style={{
                      width: `${getWidth(
                        TIMELINE_AREAS.JOB_SEARCH,
                        this.state.hours_per_week_commitment
                      )}%`,
                      background: "linear-gradient(90deg, rgba(6, 131, 141, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%)",
                      borderRight: "0px solid #329CA0",
                    }}
                    title="Job Search"
                  >
                    Job Search
                  </span>
                </div>
                <div className="months section" style={{ textAlign: "center" }}>
                  {/* {getMonths(today).map((month) => (
                    <span className="month" key={month}>
                      {month}
                    </span>
                  ))} */}
                  {getMonthsWithYears(today).map((monthYear) => (
                <div className="month" key={monthYear}>
                  <div>{monthYear.month}</div>
                  <div>{monthYear.year}</div>
                </div>
              ))}
                </div>
              </div>
              <br />
              <p style={{ font: "normal normal bold 16px/22px AvenirBook" }}>
                Which days do you want to commit to your process?
              </p>
              <p style={{ font: "normal normal normal 14px/18px AvenirBook" }}>
                You can always work when it is convenient for you, but by
                selecting specific times and hours we can better hold you
                accountable each week on spending time toward your career goals.
              </p>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item>
                    <label className="week-label">
                      <input
                        type="checkbox"
                        checked={this.state.items[0].checked}
                        className="week-input-element"
                        value={0}
                        onChange={this.selectCheckbox}
                      ></input>
                      <div className="week-input">
                        <p className="" style={{ margin: "8px 0 8px 0" }}>
                          {" "}
                          Mondays{" "}
                        </p>
                      </div>
                    </label>

                    <label className="week-label">
                      <input
                        type="checkbox"
                        checked={this.state.items[1].checked}
                        className="week-input-element"
                        value={1}
                        onChange={this.selectCheckbox}
                      ></input>
                      <div className="week-input">
                        <p className="" style={{ margin: "8px 0 8px 0" }}>
                          {" "}
                          Tuesdays{" "}
                        </p>
                      </div>
                    </label>

                    <label className="week-label">
                      <input
                        type="checkbox"
                        checked={this.state.items[2].checked}
                        className="week-input-element"
                        value={2}
                        onChange={this.selectCheckbox}
                      ></input>
                      <div className="week-input">
                        <p className="" style={{ margin: "8px 0 8px 0" }}>
                          {" "}
                          Wednesdays{" "}
                        </p>
                      </div>
                    </label>

                    <label className="week-label">
                      <input
                        type="checkbox"
                        checked={this.state.items[3].checked}
                        className="week-input-element"
                        value={3}
                        onChange={this.selectCheckbox}
                      ></input>
                      <div className="week-input">
                        <p className="" style={{ margin: "8px 0 8px 0" }}>
                          {" "}
                          Thursdays{" "}
                        </p>
                      </div>
                    </label>

                    <label className="week-label">
                      <input
                        type="checkbox"
                        checked={this.state.items[4].checked}
                        className="week-input-element"
                        value={4}
                        onChange={this.selectCheckbox}
                      ></input>
                      <div className="week-input">
                        <p className="" style={{ margin: "8px 0 8px 0" }}>
                          {" "}
                          Fridays{" "}
                        </p>
                      </div>
                    </label>

                    <label className="week-label">
                      <input
                        type="checkbox"
                        checked={this.state.items[5].checked}
                        className="week-input-element"
                        value={5}
                        onChange={this.selectCheckbox}
                      ></input>
                      <div className="week-input">
                        <p className="" style={{ margin: "8px 0 8px 0" }}>
                          {" "}
                          Saturdays{" "}
                        </p>
                      </div>
                    </label>

                    <label className="week-label">
                      <input
                        type="checkbox"
                        checked={this.state.items[6].checked}
                        className="week-input-element"
                        value={6}
                        onChange={this.selectCheckbox}
                      ></input>
                      <div className="week-input">
                        <p className="" style={{ margin: "8px 0 8px 0" }}>
                          {" "}
                          Sundays{" "}
                        </p>
                      </div>
                    </label>
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item style={{ margin: "0" }} name="date_picker1">
                    <RangePicker
                      use12Hours
                      format="h:mm a"
                      disabled={this.state.items[0].checked ? false : true}
                      style={{ margin: "0 0 3px 0" }}
                      listHeight={100}
                      // className="date-pick"
                      onChange={(time, timeString) =>
                        this.onTimechange(time, timeString, 0)
                      }
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "0" }} name="date_picker2">
                    <RangePicker
                      use12Hours
                      format="h:mm a"
                      disabled={this.state.items[1].checked ? false : true}
                      listHeight={100}
                      className="date-pick"
                      onChange={(time, timeString) =>
                        this.onTimechange(time, timeString, 1)
                      }
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "0" }} name="date_picker3">
                    <RangePicker
                      use12Hours
                      format="h:mm a"
                      disabled={this.state.items[2].checked ? false : true}
                      listHeight={100}
                      className="date-pick"
                      onChange={(time, timeString) =>
                        this.onTimechange(time, timeString, 2)
                      }
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "0" }} name="date_picker4">
                    <RangePicker
                      use12Hours
                      format="h:mm a"
                      disabled={this.state.items[3].checked ? false : true}
                      listHeight={100}
                      className="date-pick"
                      onChange={(time, timeString) =>
                        this.onTimechange(time, timeString, 3)
                      }
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "0" }} name="date_picker5">
                    <RangePicker
                      use12Hours
                      format="h:mm a"
                      disabled={this.state.items[4].checked ? false : true}
                      listHeight={100}
                      className="date-pick"
                      onChange={(time, timeString) =>
                        this.onTimechange(time, timeString, 4)
                      }
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "0" }} name="date_picker6">
                    <RangePicker
                      use12Hours
                      format="h:mm a"
                      disabled={this.state.items[5].checked ? false : true}
                      listHeight={100}
                      className="date-pick"
                      onChange={(time, timeString) =>
                        this.onTimechange(time, timeString, 5)
                      }
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "0" }} name="date_picker7">
                    <RangePicker
                      use12Hours
                      format="h:mm a"
                      disabled={this.state.items[6].checked ? false : true}
                      listHeight={100}
                      className="date-pick"
                      onChange={(time, timeString) =>
                        this.onTimechange(time, timeString, 6)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={10}></Col>
              </Row>
              <Row style={{ padding: "70px 0 100px" }}>
                <Col span={12}>
                  <button
                   className="button-secondary"
                    style={{ width: "80%" }}
                    size="large"
                    onClick={this.props.onclickback}
                  >
                    Back
                  </button>
                </Col>
                <Col span={12} style={{ keyAlign: "right" }}>
                  {/* <Link> */}
                  <button
                    style={{ width: "80%" }}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="button-main"
                  >
                    Finish
                  </button>
                  {/* </Link> */}
                </Col>
              </Row>
            </Col>
            <Col span={3}></Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default withRouter(Step5);
