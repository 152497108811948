import React from "react";
import { connect } from "react-redux";
import { Button, Badge, Popover } from "antd";
import DraggablecompTI from "../Common/DraggablecompTI";
import "./Baselayout.css";
import { selectTargetIndustriesCount } from "slices/targetIndustriesSlice";

class TIdropdown extends React.Component {
  state = {
    show: false,
  };

  render() {
    // console.log('popover rendered')
    const drag = (
      <>
        {React.createElement(() => (
          <div className="ti-dropdown">
            <p
              style={{
                textAlign: "left",
                font: "bold normal normal 18px/24px AvenirBook",
                marginBottom: "1em",
              }}
            >
              My Target Industries
            </p>
            <DraggablecompTI />
          </div>
        ))}
      </>
    );

    return (
      <>
        <Popover content={drag} trigger="click" placement="bottomRight">
          <Button
            type="text"
            style={{ fontWeight: "bold", color: "#111111" }}
            onClick={() => {
              this.setState({ show: !this.state.show });
            }}
          >
            MY TARGET INDUSTRIES &nbsp;&nbsp;
            <Badge
              count={this.props.count}
              showZero={true}
              style={{
                backgroundColor: "#FD9349",
                marginTop: "-5px",
                boxShadow: "0 0 0 1px #FD9349",
              }}
            />
          </Button>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  count: selectTargetIndustriesCount(state),
});

export default connect(mapStateToProps)(TIdropdown);
