import { Button, Form, Input, message } from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "../../../api/auth";
import "./CoverLetter.css";
import FormComp from "./FormComp";
import GeneratedCL from "./generatedCL";

const { TextArea } = Input;
const labelCol = { span: 24 };

export default class CoverLetter extends React.Component {
  CLForm = React.createRef();

  state = {
    generate: false,
    Api_response: "",
    loading: true,
  };
  componentDidMount() {
    AUTH_API.getMaterials()
      .then((response) => {
        console.log("get Api data", response);
        const form_values =
          response.data.length > 0 ? response.data[0].cover_letter : "";
        this.setState({
          Api_response: response.data.length > 0 ? response.data[0] : "",
          generate: form_values.generate ? true : false,
          loading: false,
        });
        this.CLForm.current.setFieldsValue({
          ...form_values,
        });
      })
      .catch((err) => console.log("cover letter get Api error!", err));
  }
  onFormChange = (changed_values, all_values) => {
    console.log("changed_values:", changed_values, "all values:", all_values);
    const data = {
      cover_letter: all_values,
    };
    const id = this.state.Api_response.id;
    AUTH_API.patchMaterials(data, id, "CL")
      .then((response) => {
        console.log("patch Api data", response);
        message.success('Your work has been saved');
      })
      .catch((err) => console.log("cover letter patch Api error!", err));
  };
  onGenerate = () => {
    let form_data = this.CLForm.current.getFieldsValue();
    form_data["generate"] = true;
    console.log("form data", form_data);
    const data = {
      cover_letter: form_data,
    };
    const id = this.state.Api_response.id;
    AUTH_API.patchMaterials(data, id, "CL")
      .then((response) => {
        console.log("patch Api data", response);
        message.success('Your work has been saved')
        this.setState({ generate: true });
      })
      .catch((err) => console.log("cover letter patch Api error!", err));
  };

  onSubmit = () => {
    this.setState({ ...this.state, submitted: true });
  };

  render() {
    console.log("cover letter summary rendered", this.state);
    return (
      <div>
        <h3 className="cl-head">
          Draft a strong cover letter that represents your background,
          strengths, and your fit and interest in the role and company.{" "}
        </h3>
        <p className="cl-subhead">
          Use an example job posting for something you are very interested in.
          Then, you can brainstorm which past work experiences are most relevant
          to your future direction to highlight in your letter.
        </p>
        {this.state.loading ? (
          "...Loading"
        ) : this.state.generate ? (
          <>
          {/* Future Release Build - remove this complete form div*/}
          {/* <div> 
            <Form
              name="Cl-form"
              ref={this.CLForm}
              onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
              onFinish={this.onGenerate}
            >
              <FormComp />
              <div className="ip-div">
                <h4 className="cl-title" style={{ marginBottom: "30px" }}>
                  5. Wrapping it Up
                </h4>
                <Form.Item
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      Write 1 sentence describing your strengths, soft skills,
                      unique approach or mindset that makes you stand out as a
                      strong candidate.
                    </label>
                  }
                  labelAlign="left"
                  rules={[{ required: true, message: "Required Field" }]}
                  labelCol={labelCol}
                  colon={false}
                  name={"51"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
                <Form.Item
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      Write 1 sentence reiterating your understanding of the
                      role, your fit for the role, and/or your fit and interest
                      for the company culture.
                    </label>
                  }
                  labelAlign="left"
                  rules={[{ required: true, message: "Required Field" }]}
                  labelCol={labelCol}
                  colon={false}
                  name={"52"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              </div>
              <div className="ip-div">
                <h4 className="cl-title" style={{ marginBottom: "30px" }}>
                  6. Closing
                </h4>
                <Form.Item
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      Write 1 sentence listing how and where the company can
                      reach you for more information.
                    </label>
                  }
                  labelAlign="left"
                  rules={[{ required: true, message: "Required Field" }]}
                  labelCol={labelCol}
                  colon={false}
                  name={"61"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              </div>
              <button
                className="button-main"
                style={{ width: "245px" }}
                type="primary"
                htmlType="submit"
                size="large"
              >
                Generate Cover Letter
              </button>
            </Form>
          </div> */}
          <GeneratedCL
            stepId={this.props.stepId}
            obj={this.state.Api_response}
          />
          </>
        ) : (
          <div>
            <Form
              name="Cl-form"
              ref={this.CLForm}
              onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
              onFinish={this.onGenerate}
            >
              <FormComp />
              <div className="ip-div">
                <h4 className="cl-title" style={{ marginBottom: "30px" }}>
                  5. Wrapping it Up
                </h4>
                <Form.Item
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      Write 1 sentence describing your strengths, soft skills,
                      unique approach or mindset that makes you stand out as a
                      strong candidate.
                    </label>
                  }
                  labelAlign="left"
                  rules={[{ required: true, message: "Required Field" }]}
                  labelCol={labelCol}
                  colon={false}
                  name={"51"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
                <Form.Item
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      Write 1 sentence reiterating your understanding of the
                      role, your fit for the role, and/or your fit and interest
                      for the company culture.
                    </label>
                  }
                  labelAlign="left"
                  rules={[{ required: true, message: "Required Field" }]}
                  labelCol={labelCol}
                  colon={false}
                  name={"52"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              </div>
              <div className="ip-div">
                <h4 className="cl-title" style={{ marginBottom: "30px" }}>
                  6. Closing
                </h4>
                <Form.Item
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      Write 1 sentence listing how and where the company can
                      reach you for more information.
                    </label>
                  }
                  labelAlign="left"
                  rules={[{ required: true, message: "Required Field" }]}
                  labelCol={labelCol}
                  colon={false}
                  name={"61"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              </div>
              <button
                className="button-main"
                style={{ width: "245px" }}
                type="primary"
                htmlType="submit"
                size="large"
              >
                Generate Cover Letter
              </button>
            </Form>
          </div>
        )}
      </div>
    );
  }
}
