import { Card } from "antd";
import React from "react";
import DraggablecompTI from "../Common/DraggablecompTI";
import { useSelector } from "react-redux";
import { selectStepReflections } from "slices/dashboardSlice";

const Targetindustriescard = (props) => {
  const stepReflections = useSelector(selectStepReflections);

  const isDone = (step) => 
    stepReflections[step] ? stepReflections[step].status === "DO" || stepReflections[step].status === "RE" : false;
  return (
    <Card
      bordered={false}
      style={{
        boxShadow: "0px 3px 16px 0px #1313131A",
        borderRadius: "12px",
        textAlign: "left",
        margin: "0px 20px",
        height: "fit-content",
      }}
    >
      <div className="h" style={{ fontWeight: "bold", textAlign: "left" }}>
        <div className="">

        <div className="my-target-industry">

        {/* Future Release Build */}
          <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/dashboardMyTasksDrag.svg" className="element-hide-temporary" /> 
          <span className="my-target-industries-title">My Target Industries</span>



 
        </div>
        {/* <div className="inner-container-targetRole">
  <h6 className="inner-container-subtitle">
    1. finTech
  </h6>
  <h6 className="inner-container-subtitle">
    2. HR Tech
  </h6>
  <h6 className="inner-container-subtitle">
    3. Non Profit Organisation 
  </h6>
 </div> */}
        </div>
     
      </div>
      <div style={{ marginTop:"40px"}}>
        <DraggablecompTI isDone={isDone('TI')} buttonVisible={false} />
        
      </div>
    </Card>
  );
};

export default Targetindustriescard;