import { EditOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { Form, Input, Popover, Select } from "antd";
import React from "react";
import * as CONTENT from "./Infocontent";
import "./InterviewPrep.css";

const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const layoutT = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const Items = [
  {
    title: "3. What is your biggest accomplishment?",
    subtext:
      "Be honest and authentifc here by first reflecting on what you truly believe is your proudest accomplishment, even if it is a personal example.",
    infocontent: CONTENT.CONTENT3,
    situation: true,
  },
  {
    title:
      "4. Tell me about a time you were faced with an ethical/moral challenge - what’d you do?",
    subtext:
      "Give an example if you can, or use a framework to list the factors you would consider in this type of situation.",
    infocontent: CONTENT.CONTENT4,
    situation: true,
  },
  {
    title: "5. Two people have a different opinion - how do you handle it?",
    subtext:
      "Give an example if you can, or use a framework to list the factors you would consider in this type of situation.",
    infocontent: CONTENT.CONTENT5,
    situation: true,
  },
  {
    title: "6. You and a colleague disagree. What do you do?",
    subtext:
      "Give an example if you can, or use a framework to list the factors you would consider in this type of situation.",
    infocontent: CONTENT.CONTENT6,
    situation: true,
  },
  {
    title:
      "7. You’re faced with many competing priorities​ ​​and tight deadlines. What’s your approach?",
    subtext:
      "Give an example if you can, or use a framework to list the factors you would consider in this type of situation.",
    infocontent: CONTENT.CONTENT7,
    situation: true,
  },
  {
    title: "8. Tell me about a time you failed and how you handled it.",
    subtext:
      "Keep in mind: this question differs from asking about your biggest challenge.",
    infocontent: CONTENT.CONTENT8,
    situation: true,
  },
  {
    title: (
      <>
        <span>9. Why are you interested in </span>
        <Form.Item
          name="industry_name"
          style={{ display: "inline-block", margin: "-4px 0 0 0" }}
        >
          <Input
            bordered={false}
            placeholder="Industry Name"
            style={{ width: "300px", color: "#199094" }}
          />
        </Form.Item>
      </>
    ),
    subtext:
      "Explain your authentic interest in the goals, purpose, mission and innovation happening in this sector.",
    infocontent: CONTENT.CONTENT9,
    situation: false,
  },
  {
    title: (
      <>
        <span>10. Why </span>
        <Form.Item
          name="company_name"
          style={{ display: "inline-block", margin: "-4px 0 0 0" }}
        >
          <Input
            bordered={false}
            placeholder="Company Name"
            style={{ width: "300px", color: "#199094" }}
          />
        </Form.Item>
      </>
    ),
    subtext:
      "Prove that you understand what the company does and believe in what they do and why they do it.",
    infocontent: CONTENT.CONTENT10,
    situation: false,
  },
  {
    title: "11. What are your strengths? Give examples!",
    subtext:
      "Be as authentic and specific as you can to describe what you think you do really well. Avoid generic terms! Show them what is unique about you so they can get to know you.",
    infocontent: CONTENT.CONTENT11,
    situation: false,
  },
  {
    title: "12. Where do you see yourself in a few years?",
    subtext:
      "Reiterate why you are interested in the type of role and industry and that you can see yourself growing in both of those areas!",
    infocontent: CONTENT.CONTENT12,
    situation: false,
  },
  {
    title: "13. Why are you a fit for this role?         ",
    subtext:
      "This is an extension of your strengths, but describing how your strenghts align well with the requirements for this role.",
    infocontent: CONTENT.CONTENT13,
    situation: false,
  },
  {
    title:
      "14. Each day we handle lots of information and there are always several stakeholders involved. How do you figure out who needs to know what? When do you decide when to ask questions?",
    subtext: "",
    infocontent: CONTENT.CONTENT14,
    situation: false,
  },
  {
    title: "15. What role do you play on a team?",
    subtext:
      "The leader, the delegator, the innovator, the time keeper, the moderater, the note taker, the project manager, the presenter, the client`s voice, something else?",
    infocontent: CONTENT.CONTENT15,
    situation: false,
  },
  {
    title: "16. What’s important to you about working with others?",
    subtext:
      "There's no right answer here. Give them your perspective on the impact and value of effective teamwork.",
    infocontent: CONTENT.CONTENT16,
    situation: false,
  },
  {
    title: "17. What’s your biggest weakness?",
    subtext:
      "Reflect honestly on what trips you up and give practical solutions for how you improve or handle that trait so it doesn't get in your way at work.",
    infocontent: CONTENT.CONTENT17,
    situation: false,
  },
  {
    title: "18. What do you enjoy doing outside of work?",
    subtext:
      "They want to see if you'd get along in the workplace! Tell them about any fun hobbies or interests you have.",
    infocontent: CONTENT.CONTENT18,
    situation: false,
  },
  {
    title: "19. What questions do you have for me?",
    subtext:
      "Come in with prepared questions that are specific to the role, team, department, company, and industry.",
    infocontent: CONTENT.CONTENT19,
    situation: false,
  },
  {
    title:
      "20. Tell me about a time when you had to tell someone “the hard truth,” something that wasn’t easy to share perhaps, feedback, or something they weren’t seeing?",
    subtext: "",
    infocontent: CONTENT.CONTENT20,
    situation: true,
  },
  {
    title:
      "21. What was your biggest challenge and how did you handle/overcome it?        ",
    subtext:
      "This is different than the failure question. A challenge was simply a roadblock that you rose above.",
    infocontent: CONTENT.CONTENT21,
    situation: true,
  },
  {
    title: (
      <>
        <span>22. Tell me about your time at </span>
        <Form.Item
          name="company_name"
          style={{ display: "inline-block", margin: "-4px 0 0 0" }}
        >
          <Input
            bordered={false}
            placeholder="Company Name"
            style={{ width: "300px", color: "#199094" }}
          />
        </Form.Item>
      </>
    ),
    subtext:
      "Explain your role, responsibilities, projects and skills involved at a high level.",
    infocontent: CONTENT.CONTENT22,
    situation: false,
  },
  {
    title: "23. Tell me about a time you received tough feedback.        ",
    subtext:
      "Can you handle difficult conversations? Are you interested in growing and developing? ",
    infocontent: CONTENT.CONTENT23,
    situation: true,
  },
];

export default class FormComponent extends React.Component {
  render() {
    console.log("resume rendered");
    return (
      <>
        <div className="ip-div">
          <div>
            <span className="ip-div-head">1. Tell me about yourself.</span>
            <Popover
              placement="bottomLeft"
              overlayClassName="ip-popover"
              content={CONTENT.CONTENT1}
              overlayStyle={{ maxWidth: "45%" }}
            >
              <InfoCircleTwoTone className="info-circle" />
            </Popover>
          </div>
          <p className="ip-div-subhead">
            This is a concise, summarized pitch including your background, your
            strengths, and your direction.Leverage your professional summary tab
            as the same foundation for your elevator pitch.
          </p>

          <Form.List name={1}>
            {(fields, { add, remove }) => (
              <>
                {Array.from(Array(1).keys()).map((it, index) => (
                  <div style={{ marginBottom: 15 }}>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={1}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="I'm a XYZ professional with expertise in XYZ functional areas and XYZ industries"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={2}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="In particular, my strengths are in [describe what you do well and how/why you do it well]"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={3}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="Now, I'm looking forward to contributing in a XYZ role in XYZ industry"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={4}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                        />
                      </Form.Item>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </div>
        <div className="ip-div">
          <div>
            <span className="ip-div-head">2. Walk me through your resume</span>
            <Popover
              placement="bottomLeft"
              overlayClassName="ip-popover"
              content={CONTENT.CONTENT2}
              overlayStyle={{ maxWidth: "45%" }}
            >
              <InfoCircleTwoTone className="info-circle" />
            </Popover>
          </div>
          <p className="ip-div-subhead">
            Take 1-2 minutes to describe each work experience at a high level
            and why you made the switch between each one.
          </p>
          <Form.List name={2}>
            {(fields, { add, remove }) => (
              <>
                {Array.from(Array(1).keys()).map((it, index) => (
                  <div style={{ marginBottom: 15 }}>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={1}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="I went to XYZ school and was XYZ major with a focus in XYZ"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={2}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="I started out at [first job] and my core responsibilities were [describe at a high level]. "
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={3}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="I was interested in learning more about [xyz] so I took a new role at [second job]."
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={4}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="At [second job] my core responsibilities were [describe at a high level]"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name={5}
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="Continue until you get up to the current day where you describe your forward looking direction: your ideal next role and industry."
                        />
                      </Form.Item>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </div>
        {Items.map((item, index) => {
          return (
            <div className="ip-div">
              {item.situation ? (
                <Form.List name={index + 3}>
                  {(fields, { add, remove }) => (
                    <>
                      {Array.from(Array(1).keys()).map((it, index) => (
                        <div>
                          <div>
                            <span className="ip-div-head">{item.title}</span>
                            <Popover
                              placement="bottomLeft"
                              overlayClassName="ip-popover"
                              content={item.infocontent}
                              overlayStyle={{ maxWidth: "45%" }}
                            >
                              <InfoCircleTwoTone className="info-circle" />
                            </Popover>
                          </div>
                          <p className="ip-div-subhead">{item.subtext}</p>

                          {/* <div  style={{marginBottom:15}}> */}
                          <div style={{ marginBottom: 15 }}>
                            <Form.Item
                              name="Situation"
                              colon={false}
                              labelCol={{ span: 2 }}
                              labelAlign="left"
                              label={
                                <label
                                  style={{
                                    fontSize: "16px",
                                    paddingTop: "23px",
                                  }}
                                >
                                  Situation :
                                </label>
                              }
                            >
                              <TextArea
                                className="ip-input"
                                placeholder="Describe the context of the scenario - where did this situation happen? when? in what role?"
                                autoSize={{ minRows: 2, maxRows: 15 }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="Task"
                              colon={false}
                              labelCol={{ span: 2 }}
                              labelAlign="left"
                              label={
                                <label
                                  style={{
                                    fontSize: "16px",
                                    paddingTop: "23px",
                                  }}
                                >
                                  Task :
                                </label>
                              }
                            >
                              <TextArea
                                className="ip-input"
                                placeholder="What was the goal, problem, or task at hand? "
                                autoSize={{ minRows: 2, maxRows: 15 }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="Action"
                              colon={false}
                              labelCol={{ span: 2 }}
                              labelAlign="left"
                              label={
                                <label
                                  style={{
                                    fontSize: "16px",
                                    paddingTop: "23px",
                                  }}
                                >
                                  Action :
                                </label>
                              }
                            >
                              <TextArea
                                className="ip-input"
                                placeholder="What did you do to resolve or achieve the goal? What steps did you take? What skills did you use?"
                                autoSize={{ minRows: 2, maxRows: 15 }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="Result"
                              colon={false}
                              labelCol={{ span: 2 }}
                              labelAlign="left"
                              label={
                                <label
                                  style={{
                                    fontSize: "16px",
                                    paddingTop: "23px",
                                  }}
                                >
                                  Result :
                                </label>
                              }
                            >
                              <TextArea
                                className="ip-input"
                                placeholder="What were the outcomes? Any key metrics to share? Any takeaways or learnings?"
                                autoSize={{ minRows: 2, maxRows: 15 }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              ) : (
                <Form.List name={index + 3}>
                  {(fields, { add, remove }) => (
                    <>
                      {Array.from(Array(1).keys()).map((it, index) => (
                        <div style={{ marginBottom: 15 }}>
                          {/* <div> */}
                          <div>
                            <span className="ip-div-head">{item.title}</span>
                            <Popover
                              placement="bottomLeft"
                              overlayClassName="ip-popover"
                              content={item.infocontent}
                              overlayStyle={{ maxWidth: "45%" }}
                            >
                              <InfoCircleTwoTone className="info-circle" />
                            </Popover>
                          </div>
                          <p className="ip-div-subhead">{item.subtext}</p>
                          <Form.Item name="Situation" colon={false}>
                            <TextArea
                              className="ip-input"
                              placeholder="[insert response here]"
                              autoSize={{ minRows: 2, maxRows: 15 }}
                            />
                          </Form.Item>
                          <Form.Item name="Task" colon={false}>
                            <TextArea
                              className="ip-input"
                              placeholder="[insert response here]"
                              autoSize={{ minRows: 2, maxRows: 15 }}
                            />
                          </Form.Item>
                          <Form.Item name="Action" colon={false}>
                            <TextArea
                              className="ip-input"
                              placeholder="[insert response here]"
                              autoSize={{ minRows: 2, maxRows: 15 }}
                            />
                          </Form.Item>
                          <Form.Item name="Result" colon={false}>
                            <TextArea
                              className="ip-input"
                              placeholder="[insert response here]"
                              autoSize={{ minRows: 2, maxRows: 15 }}
                            />
                          </Form.Item>
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              )}
              {/* </div> */}
            </div>
          );
        })}
      </>
    );
  }
}
