import React from "react";
import { Button, Col, Row } from "antd";
import "antd/dist/antd.css";
import "./Modals.css";
import TargetrolesC1 from "./Content1/TargetrolesC1";
import TargetrolesC2 from "./Content2/TargetrolesC2";
import TargetrolesC3 from "./Content3/TargetrolesC3";

import AssessmentC1 from "./Content1/AssessmentC1";
import AssessmentC2 from "./Content2/AssessmentC2";
import AssessmentC3 from "./Content3/AssessmentC3";
// import star from './../../Assets/fav.svg';
// import map from './../../Assets/map.svg';
// import play from './../../Assets/play.svg';

const stepname_map = {
  Assessment: [<AssessmentC1 />, <AssessmentC2 />, <AssessmentC3 />],
  "Target Roles": [<TargetrolesC1 />, <TargetrolesC2 />, <TargetrolesC3 />],
};
export default class Stepinfo extends React.Component {
  state = {
    info: "",
  };

  render() {
    return (
      <div
        className="parent-antd"
        style={{ textAlign: "left", padding: "75px 54px" }}
      >
        {/* <Popover placement="bottomRight" className='here' content={content} trigger="click" style={{padding:0, display:'none' }}>
                    <Button type="primary">Hover me</Button>
                </Popover> */}
        <div
          className="site-card-wrapper"
          style={{
            margin: "-52px",
            position: "relative",
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <div className="triangle2"></div>
          <div style={{ textAlign: "center", padding: "50px 0 0px 0" }}>
            <div
              className="h"
              style={{ font: "normal normal bold 24px/12px AvenirBook" }}
            >
              Getting started with {this.props.stepname}
            </div>
            <p style={{ font: "normal normal normal 16px/44px AvenirBook" }}>
              Browse through resources below to understand your goal better.
            </p>
          </div>
          {/* <Radio.Group> */}
          <Row style={{ padding: "40px 40px" }}>
            <Col span={8}>
              <label className="new-label">
                <input
                  type="radio"
                  name="product"
                  className="card-input-element"
                  onClick={() => {
                    this.setState({
                      info: <TargetrolesC1 {...this.props} />,
                    });
                  }}
                />

                <div className="card-input">
                  <img
                    src="https://django-woken-static.s3.amazonaws.com/static/media/fav.60dbee2b.svg"
                    className="svg-space"
                  ></img>

                  <p className="content-space"> Why is this important?</p>
                </div>
              </label>
            </Col>

            <Col span={8}>
              <label className="new-label">
                <input
                  type="radio"
                  name="product"
                  className="card-input-element"
                  onClick={() => {
                    this.setState({
                      info: <TargetrolesC2 {...this.props} />,
                    });
                  }}
                />

                <div className="card-input">
                  <img
                    src="https://django-woken-static.s3.amazonaws.com/static/media/map.d09dfa9b.svg"
                    className="svg-space"
                    style={{ padding: "37px" }}
                  ></img>
                  <p className="content-space">How to achieve this step</p>
                </div>
              </label>
            </Col>
            <Col span={8}>
              <label className="new-label">
                <input
                  type="radio"
                  name="product"
                  className="card-input-element"
                  onClick={() => {
                    this.setState({
                      info: <TargetrolesC3 {...this.props} />,
                    });
                  }}
                />

                <div className=" card-input">
                  <img
                    src="https://django-woken-static.s3.amazonaws.com/static/media/play.b4fccf79.svg"
                    className="svg-space"
                    style={{ padding: "32px", margin: "7px", height: "99px" }}
                  ></img>
                  <p className="content-space">More resources</p>
                </div>
              </label>
            </Col>

            {this.state.info}
          </Row>
          {/* </Radio.Group> */}
          <div style={{ textAlign: "right", padding: "0 45px 45px 0" }}>
            <button
            className="button-secondary"
              type="text"
              danger
              style={{
                paddingRight:"20px",paddingLeft:"20px"
              }}
              onClick={this.props.closeInfo}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}
