import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { selectProfile, updateProfileAsync } from "slices/dashboardSlice";
import { COUNTRYCODES } from "../Constants/COUNTRYCODES";
// import 'antd/dist/antd.css';
// import Thanks from './../../Assets/thankyou.svg'

const { Option } = Select;
const OPTIONS = COUNTRYCODES.map((item, index) => (
  <Option key={index} value={item.dial_code}>
    {item.code + "" + " " + item.dial_code}
  </Option>
));
const COUNTRIES = COUNTRYCODES.map((item, index) => (
  <Option key={index} value={item.name}>
    {item.name}
  </Option>
));

const validateMessages = {
  required: "Required Field",
  types: {
    email: "This is not valid Email!",
    number: "${label} is not a validate number!",
  },
};

class Profile extends React.Component {
  // formRef = React.createRef()
  formRef = (e) => {
    console.log(e, this.props.profile);
    if (this.props.profile && e != null) {
      e.setFieldsValue({
        ...this.props.profile,
        dob: moment(this.props.profile.dob),
      });
    }
  };
  state = {
    synced: false,
    dob: this.props.profile.dob ? this.props.profile.dob : null,
  };
  onDateChange = (date, dateString) => {
    this.setState({
      dob: dateString,
    });
  };

  componentDidMount() {
    this.setState({ synced: true });
  }
  onSubmit = (values) => {
    console.log("changed values:", values);

    const data = { ...values, dob: this.state.dob };
    this.props.updateProfileAsync({
      ...this.props.profile,
      ...data,
    });
    this.props.handleCancel();
  };

  render() {
    console.log("props from Redux", this.props, this.formRef);
    const icon = <CloseOutlined className="close-icon" />;
    // this.syncForm()

    return (
      <>
        {/* <Button type="primary" onClick={this.showModal}>
          Open Modal
        </Button> */}

        <Modal
          title=""
          visible={this.props.visible}
          onCancel={this.props.handleCancel}
          footer={null}
          width="90%"
          style={{ textAlign: "center", padding: 0, top: 20 }}
          closeIcon={icon}
          // placement="top"
        >
          <h3
            style={{
              textAlign: "center",
              font: "normal normal 300 30px/12px AvenirBook",
              color: "#242424",
              margin: "30px 0 50px",
            }}
          >
            Edit Profile
          </h3>
          <Row>
            <Col span={7}></Col>
            <Col span={10}>
              <Form
                name="signup_form"
                ref={this.formRef}
                onFinish={(values) => {
                  this.onSubmit(values);
                }}
                validateMessages={validateMessages}
              >
                <Row>
                  <Col span={11}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "19px",
                        fontWeight: "normal",
                      }}
                    >
                      First Name *
                    </p>
                    <Form.Item
                      name="first_name"
                      rules={[{ required: true, message: "Required Field" }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "19px",
                        fontWeight: "normal",
                      }}
                    >
                      Last Name *
                    </p>
                    <Form.Item
                      name="last_name"
                      rules={[{ required: true, message: "Required Field" }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "19px",
                    fontWeight: "normal",
                  }}
                >
                  Email address *
                </p>
                <Form.Item
                  name="email"
                  rules={[{ required: true, type: "email" }]}
                  validateTrigger={["onBlur"]}
                >
                  <Input size="large" style={{ marginBottom: "5%" }} />
                </Form.Item>

                {/* <Input.Group size="large"> */}
                <Row style={{ marginBottom: "5%" }} gutter={12}>
                  <Col span={9}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "19px",
                        fontWeight: "normal",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Country Code*
                    </p>
                    <Form.Item
                      name="country_code"
                      rules={[{ required: true, message: "Required Field" }]}
                    >
                      <Select
                        style={{}}
                        size="large"
                        // options={OPTIONS}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {OPTIONS}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={15}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "19px",
                        fontWeight: "normal",
                      }}
                    >
                      Phone number *
                    </p>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          pattern: /^\d+$/,
                          message: "Input only numbers",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                {/* </Input.Group> */}

                <p
                  style={{
                    textAlign: "left",
                    fontSize: "19px",
                    fontWeight: "normal",
                  }}
                >
                  Date of Birth *
                </p>
                <div style={{ textAlign: "left", marginBottom: "40px" }}>
                  <Form.Item
                    name="dob"
                    rules={[{ required: true, message: "Required Field" }]}
                  >
                    {/* {React.createElement(()=> */}
                    <DatePicker
                      size="large"
                      style={{ width: "50%" }}
                      onChange={this.onDateChange}
                    />
                    {/* )} */}
                  </Form.Item>
                </div>

                <p
                  style={{
                    textAlign: "left",
                    fontSize: "19px",
                    fontWeight: "normal",
                    marginBottom: 20,
                  }}
                >
                  Location Details
                </p>
                <Row gutter={12} style={{ marginBottom: 20 }}>
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "19px",
                        fontWeight: "normal",
                      }}
                    >
                      City*
                    </p>
                    <Form.Item
                      name="city"
                      rules={[{ required: true, message: "Required Field" }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "19px",
                        fontWeight: "normal",
                      }}
                    >
                      State*
                    </p>
                    <Form.Item
                      rules={[{ required: true, message: "Required Field" }]}
                      name="state"
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "19px",
                    fontWeight: "normal",
                  }}
                >
                  Country*
                </p>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="country"
                >
                  {/* <Input size="large" style={{marginBottom:30}} /> */}
                  <Select
                    style={{}}
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {COUNTRIES}
                  </Select>
                </Form.Item>

                <br />
                <br />
                <Form.Item name="submit">
                  <Button
                    size="large"
                    type="primary"
                    className="button-main"
                    style={{ marginBottom: 0 }}
                    htmlType="submit"
                    block
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
              {console.log("formreffff", this.formRef)}
            </Col>
            <Col span={7}></Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: selectProfile(state),
});

const mapDispatchToProps = () => ({
  updateProfileAsync,
});

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(Profile);
