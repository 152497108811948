import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Select } from "antd";
import moment from "moment";
import "./Dashboard.css";
import "./Timeline.css";
import {
  selectProfile,
  updateHoursPerWeekCommitmentAsync,
} from "slices/dashboardSlice";
import HELPERS from "utils/helper";
import {
  TIMELINE_AREAS,
  getWidth,
  monthsBeforeToday,
  getMonths,
  getMonthsWithYears,
} from "utils/timeline";

const { Option } = Select;

const Timeline = () => {
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const today = moment();
  const sectionRef = React.createRef();
  const [marginLeft, setMarginLeft] = useState("0");
  const hoursPerWeekCommitment = profile
    ? profile.hours_per_week_commitment
    : 0;
  const joinedOn = profile ? moment(new Date(profile.date_joined)) : today;

  HELPERS.log("Updated Timeline", profile);

  useEffect(() => {
    HELPERS.log(
      "Updated Timeline",
      sectionRef.current ? sectionRef.current.offsetWidth : null,
      marginLeft,
      joinedOn.date(),
      joinedOn.daysInMonth()
    );
    if (sectionRef.current) {
      let margin = `${
        (joinedOn.date() * sectionRef.current.offsetWidth) /
        joinedOn.daysInMonth()
      }px`;
      if (margin !== marginLeft) {
        setMarginLeft(margin);
      }
    }
  }, []);

  const updateHoursPerWeekCommitment = (value) => {
    dispatch(updateHoursPerWeekCommitmentAsync(value));
  };

  return (
    <div>
      <div style={{ margin: "20px" }}>
        <Card
          bordered={false}
          style={{
            boxShadow: "0px 3px 16px 0px #1313131A",
            borderRadius: "12px",
            textAlign: "left",
          }}
        >
          <div className="h" style={{
            fontWeight: "800",
            color:"#111111",
            fontFamily:'AvenirBook',
            fontSize: "18px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gridGap: "12px",
            gap: "12px",
            }}>
            
            <div className="div-my-career-roadmap-title">
                {/* Future Release Build */}
                <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/dashboardMyTasksDrag.svg" className="element-hide-temporary" />
                <span className="my-career-roadmap-title">My Career Roadmap</span>
            </div>
            <div
              className="element-hide-temporary"  //Future Release Build
              style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8px",
              }}
              >
              <button
                type="primary"
                size="large"
                className="button-main my-career-roadmap-add-new-phase-button"
              >&#43;&nbsp;Add New Phase</button>
              <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/myCareerRoadmapMaxIcon.svg" />
            </div>
          </div>
              <br />
          <div className="woken-progress">
            <div className="section"
              style={{
                marginBottom: "42px",
              }}
            >
              <div
                className="today"
                style={{
                  marginLeft: `${
                    monthsBeforeToday(today.month(), joinedOn) * 11.11111
                  }%`,
                }}
              >
                <span
                  className="title"
                  style={{
                    paddingLeft: `${
                      (today.date() * 100) / today.daysInMonth()
                    }%`,
                  }}
                >
                  Today
                </span>
                <span
                  className="marker"
                  style={{
                    left: `${(today.date() * 100) / today.daysInMonth()}%`,
                  }}
                ></span>
              </div>
            </div>
            {/* <div className="timeline title section">
              <span
                ref={sectionRef}
                className="space"
                style={{
                  marginLeft,
                }}
              ></span>
              <div
                className="portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.EXPLORATION,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Career Exploration"
              >
                <span>Career Exploration</span>
              </div>
              <div
                className="portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.JOB_SEARCH,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Job Search"
              >
                <span>Job Search</span>
              </div>
            </div> */}
            {/* <div class="timeline section"></div>
            <div class="timeline section"></div>
            <div class="timeline section"></div> */}
            {/* <div class="timeline section">
              <span
                className="space"
                style={{
                  // marginLeft: "102px",
                  width: "21%",
                }}
              ></span>
              <div style={{ position: "relative", width: "60px" }}>
                <div className="" style={{
                    position:"absolute",
                    top:"-38px"
                  }}>
                  <button className="" style={{
                    width:"", height:"32px",background:"#CCE6E7",
                    borderRadius:"4px",width:"171px", color:"#111111" , fontSize:"16px"  , border:"none" , padding:"4px 8px", position:"relative"
                    }}>
                    Personal Branding
                  </button>
                  <svg width="8" style={{
                    position:"absolute" , top:"28px", left:"10px"
                    }} height="30" viewBox="0 0 6 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 16.6667C4.47276 16.6667 5.66667 15.4728 5.66667 14C5.66667 12.5272 4.47276 11.3333 3 11.3333C1.52724 11.3333 0.333333 12.5272 0.333333 14C0.333333 15.4728 1.52724 16.6667 3 16.6667ZM3.5 14L3.5 -9.53674e-07H2.5L2.5 14H3.5Z" fill="#9F9F9F"/>
                  </svg>
                </div>
                <span
                  className="job-search portion"
                  style={{
                    width: `${getWidth(
                      TIMELINE_AREAS.JOB_SEARCH,
                      hoursPerWeekCommitment
                    )}%`,
                    width: "60px",
                    background: "linear-gradient(90deg, rgba(2, 90, 97, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%)",
                    padding: "0",
                  }}
                  title="Personal Branding"
                >
                </span>
              </div>
              <span
                className="job-search portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.JOB_SEARCH,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Job Searching"
              >
                Job Searching
              </span>
              <span
                className="job-search portion"
                style={{
                  // width: `${getWidth(
                  //   TIMELINE_AREAS.JOB_SEARCH,
                  //   hoursPerWeekCommitment
                  // )}%`,
                  width: "29%",
                  background: "linear-gradient(0deg, #D5F1F4, #D5F1F4),linear-gradient(90deg, rgba(0, 26, 28, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%);",
                  borderRight: "0px solid #329CA0",
                }}
                title="Career Advancement"
              >
                Career Advancement
              </span>
            </div> */}
            <div className="timeline section">
              <span
                className="space"
                style={{
                  marginLeft: "79.9355px",
                  // width: `${getWidth(
                  //   TIMELINE_AREAS.REMAINING,
                  //   hoursPerWeekCommitment
                  // )}%`,
                }}
              ></span>
              <span
                className="exploration portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.EXPLORATION,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Career Exploration"
              >
                Career Exploration
              </span>
              {/* <span
                className="job-search portion"
                style={{
                  // width: `${getWidth(
                  //   TIMELINE_AREAS.JOB_SEARCH,
                  //   hoursPerWeekCommitment
                  // )}%`,
                  width: "100%",
                  background: "linear-gradient(90deg, rgba(6, 131, 141, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%)",
                  borderRight: "0px solid #329CA0",
                }}
                title="Upskilling"
              >
                Upsklling
              </span> */}
              <span
                className="job-search portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.JOB_SEARCH,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Job Search"
              >
                Job Search
              </span>
            </div>
            {/* <br /> */}
            <div className="months section" style={{ textAlign: "center" }}>
              {/* {getMonths(joinedOn).map((month) => (
                <span className="month" key={month}>
                  {month}
                </span>
              ))} */}
              {getMonthsWithYears(joinedOn).map((monthYear) => (
                <div className="month" key={monthYear}>
                  <div>{monthYear.month}</div>
                  <div>{monthYear.year}</div>
                </div>
              ))}
            </div>
          </div>
          <br />

          <div className="commit-hours-sec">
            I commit
            <Select
              defaultValue="1"
              bordered={false}
              style={{ color: "#32AAB0" }}
              listHeight={120}
              value={profile ? profile.hours_per_week_commitment : null}
              onChange={updateHoursPerWeekCommitment}
            >
              {Array.apply(null, { length: 30 }).map((item, index) => (
                <Option key={`option-item-${index}`} value={index + 1}>
                  {index + 1}
                </Option>
              ))}
            </Select>
            hours per week to my process.
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Timeline;
