import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { Card, Row, Col } from "antd";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import Targetrolessteps from "./Targetrolessteps";
import DueDate from "./../Common/DueDate";
import Stepinfo from "./../Modals/Stepinfo";
import {
  selectTargetRolesStepInfo,
  setStepNotifiedAsync,
} from "slices/dashboardSlice";

class TargetRoles extends React.Component {
  state = {
    collapsed: false,
    showInfo: null, //show/hide infobox
    setTarget: null, //show/hide duedate component
    curr_step: 0,
  };

  setStep = (val) => {
    this.setState({
      curr_step: val,
    });
  };

  //opens set date box (pink colored) if date in header is clicked
  setDateAgain = () => {
    this.setState({
      setTarget: true,
    });
  };

  //close button handling
  onCloseClick = () => {
    this.setState({
      setTarget: false,
    });
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.props.toggle();
  };

  notifyStep = () => {
    if (
      this.props.stepInfo &&
      !this.props.stepInfo.notified &&
      this.props.impersonated
    ) {
      this.props.setStepNotifiedAsync(
        this.props.stepInfo.id,
        this.props.stepInfo.step
      );
    }
  };

  onDateSet = () => {
    // setTimeout(() => {
    this.onCloseClick();
    // }, 6000);
  };

  newReleased = () => this.props.stepInfo && !this.props.stepInfo.notified;

  showInfo = () =>
    this.state.showInfo === true ||
    (this.state.showInfo === null && this.newReleased());

  targetSet = () =>
    this.state.setTarget === true ||
    (this.state.setTarget === null &&
      !!this.props.stepInfo &&
      this.props.stepInfo.complete_by === null &&
      this.props.stepInfo.status != 'DO');

  stepId = () => (!!this.props.stepInfo ? this.props.stepInfo.id : -1);

  render() {
    return (
      <div style={{ padding: "4% 0" }}>
        <div className="collapse-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? (
            <RightCircleFilled className="make-white" />
          ) : (
            <LeftCircleFilled className="make-white" />
          )}
        </div>

        <Card
          bordered={false}
          style={{
            margin: "20px !important",
            boxShadow: "0px 3px 16px 0px #1313131A",
            borderRadius: "12px",
            textAlign: "left",
            marginRight: "6%",
            marginLeft: "20px",
            padding: "0 2%",
          }}
        >
          <div>
            <Row style={{ padding: "4% 0% 3% 0%" }}>
              <Col
                span={18}
                style={{
                  font: "normal normal 300 30px/12px AvenirBook",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Target Roles&nbsp;
                <span
                  className={`just-released ${
                    this.newReleased() ? "" : "hidden"
                  }`}
                >
                  NEW
                </span>
                {this.props.stepInfo && (
                  // this.props.stepInfo.complete_by !== null &&
                  <span
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      position: "relative",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={this.setDateAgain}
                  >
                    <img
                      src="https://django-woken-static.s3.amazonaws.com/static/media/calendaricon.c713d4db.svg"
                      style={{ position: "relative", top: "-3px" }}
                    ></img>
                    &nbsp;&nbsp;
                    {this.props.stepInfo.complete_by
                      ? "Due date:"
                      : "Set Due Date"}
                    &nbsp;
                    {this.props.stepInfo &&
                      this.props.stepInfo.complete_by !== null &&
                      Moment(this.props.stepInfo.complete_by).format(
                        "MM-DD-YYYY"
                      )}
                  </span>
                )}
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  marginTop: "-20px",
                }}
              >
                <a
                  href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                  target="_blank"
                  style={{
                    marginRight: "10px",
                   
                      cursor:"pointer"
                   
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="mail_2_" data-name="mail (2)" transform="translate(-0.001)">
                      <g id="Group_2502" data-name="Group 2502" transform="translate(0.001)">
                        <path id="Path_1376" data-name="Path 1376" d="M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm0,4.917,8.285,5.166H5.716Zm8.388,13.416h0a1.5,1.5,0,0,1-1.5,1.5H7.114a1.5,1.5,0,0,1-1.5-1.5V10.412a1.492,1.492,0,0,1,.024-.257l7.915,4.936.03.016.032.017a.831.831,0,0,0,.172.067l.018,0a.854.854,0,0,0,.2.025h0a.839.839,0,0,0,.2-.025l.018,0a.839.839,0,0,0,.172-.067l.032-.017.03-.016,7.915-4.936a1.49,1.49,0,0,1,.024.257v7.921Z" transform="translate(-0.001)" fill="#199094"/>
                      </g>
                    </g>
                  </svg>
                </a>
                <span
                  onClick={() =>
                    this.setState({
                      showInfo: true,
                    })
                  }
                  style={{
                    cursor:"pointer"
                  }}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" style={{marginTop: "5px"}}>
                  <path id="question" d="M14,0A14,14,0,1,0,28,14,14.015,14.015,0,0,0,14,0Zm0,22.167A1.167,1.167,0,1,1,15.167,21,1.167,1.167,0,0,1,14,22.167Zm1.847-7.418a1.172,1.172,0,0,0-.68,1.061v.524a1.167,1.167,0,1,1-2.333,0V15.81a3.515,3.515,0,0,1,2.036-3.18,3.813,3.813,0,0,0,2.047-2.713,2.917,2.917,0,1,0-5.833,0,1.167,1.167,0,1,1-2.333,0,5.25,5.25,0,1,1,10.5,0,6.079,6.079,0,0,1-3.4,4.832Zm0,0" fill="#199094"/>
                </svg>
                </span>
              </Col>
            </Row>
            <p
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #F0F0F0",
              }}
            ></p>
          </div>
          {/* starts here__________________________________________________________________________________ */}

          {this.showInfo() ? (
            <Stepinfo
              stepname="Target Roles"
              closeInfo={() => {
                this.notifyStep();
                this.setState({
                  showInfo: false,
                });
              }}
            />
          ) : this.targetSet() ? (
            <div>
              <DueDate
                stepName="Target Roles"
                stepKey={this.props.stepInfo.step}
                onDateSet={this.onDateSet}
                onCloseClick={this.onCloseClick}
                stepId={this.props.stepInfo.id}
                targetDate={
                  this.props.stepInfo.complete_by !== null
                    ? Moment(this.props.stepInfo.complete_by)
                    : null
                }
              />{" "}
              <Targetrolessteps
                setStep={this.setStep}
                stepId={this.stepId()}
                curr_step={this.state.curr_step}
              />
            </div>
          ) : (
            <Targetrolessteps
              style={{ opacity: "0.4", display: "none" }}
              stepId={this.stepId()}
              fade={!this.targetSet()}
              setStep={this.setStep}
              curr_step={this.state.curr_step}
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stepInfo: selectTargetRolesStepInfo(state),
});

const mapDispatchToProps = () => ({
  setStepNotifiedAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(TargetRoles);