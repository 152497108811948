import React from "react";
import { Row, Col, Input, Button, Form } from "antd";
import AUTH_API from "../../../api/auth";
import "./ProfSummary.css";
import { PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const Items = [
  {
    title: "What is your professional title? ",
    subtext:
      "For example: digital marketing professional, product management professional, HR professional, etc. This should be a bridge between your background and where you want to go next or how you want to be seen as a professional.",
    default_len: 1,
    extras: false,
  },
  {
    title:
      "List your core functional areas of expertise based on an aggregate of the main skills you have honed in your prior roles.        ",
    subtext:
      "Include at least 3 and no more than 7 core areas of expertise. These should be 1-3 words per skill. Examples: project management, sales, budget management, vendor management, product design, event planning, data analysis, account management, software development, etc.",
    default_len: 5,
    extras: true,
  },
  {
    title: "List the industries you've worked in.        ",
    subtext:
      "For example: hospitality, advertising, finance, healthcare, education, tech, real estate, etc.",
    default_len: 3,
    extras: true,
  },
  {
    title:
      "Describe what work you particularly love doing and/or what type of work you do particularly well.        ",
    subtext:
      "Start the phrase with the verb. For example: analyzing problems holistically and from the view of multiple stakeholders",
    default_len: 1,
    full_length: true,
    extras: false,
  },
  {
    title: "What is the goal/outcome of that activity?        ",
    subtext:
      "Take the above activity and think about the end goal. For example: to ensure that both internal and external clients are strategic and creative when implementing solutions",
    default_len: 1,
    full_length: true,
    extras: false,
  },
  {
    title:
      "Tell us how or why you do this so well by listing which soft skills or approaches you utilize that makes you especially effective at this strength?        ",
    subtext:
      "For example: empathetic, active listening, facilitating team collaboration, leveraging a holistic viewpoint, etc.",
    default_len: 3,
    extras: true,
  },
  {
    title: "What type of role do you want to pursue?         ",
    subtext:
      "This can be as broad as you are aware of right now, but explain authentically the type of work or role titles that you are most interested in.",
    default_len: 1,
    extras: false,
  },
  {
    title: "What type of industry do you want to go into?         ",
    subtext:
      "You can list two options if you are considering more than one, but ideally, you would clarify and go after your ideal target industry!  Don't worry, this won't limit you, but it will help you find the right opportunities.",
    default_len: 1,
    extras: true,
  },
];

export default class FreeForm extends React.Component {
  render() {
    console.log("free form comp rendered");
    return (
      <div>
        {Items.map((item, index) => {
          return (
            <div className="ip-div">
              <h3 className="ip-div-head">{item.title}</h3>
              <p className="ip-div-subhead">{item.subtext}</p>
              <Row>
                <Col span={item.full_length ? 24 : 12}>
                  <Form.List
                    name={index + 1}
                    rules={[
                      {
                        required: true,
                        validator: async (_, values) => {
                          console.log("values", values);
                          if (!values || values.length < 1) {
                            return Promise.reject("Fill atleast one field");
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {Array.from(Array(item.default_len).keys()).map(
                          (it, index) => (
                            <div style={{ marginBottom: 15 }}>
                              <Form.Item
                                name={index.toString()}
                                rules={
                                  index === 0
                                    ? [
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]
                                    : []
                                }
                                // required={false}
                                key={fields.key}
                              >
                                <TextArea
                                  autoSize={{ minRows: 2, maxRows: 60 }}
                                >
                                  {" "}
                                </TextArea>
                              </Form.Item>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </Form.List>
                  <Form.List name={index + 1 + "_added"}>
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, index) => (
                            <div
                              style={{ marginBottom: 15 }}
                            >
                              <Form.Item
                                name={index.toString()}
                                style={{ marginBottom: 15 }}
                              >
                                <TextArea
                                  autoSize={{ minRows: 2, maxRows: 60 }}
                                >
                                  {" "}
                                </TextArea>
                              </Form.Item>
                            </div>
                          ))}
                          {item.extras ? (
                            <Button
                              type="dashed"
                              style={{ height: 50 }}
                              onClick={() => {
                                add();
                              }}
                              block
                              size="large"
                            >
                              <PlusOutlined /> Add field
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }}
                  </Form.List>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  }
}
