import { Button, Card, Col, Form, Input, Popconfirm, Row } from "antd";
import React from "react";
import "./InterviewResearch.css";

const { TextArea } = Input;
// const { Option } = Select;

export default class InterviewResearch extends React.Component {
  formRef = React.createRef();
  state = {
    additional_q: [[], [], [], [], [], []],
    loading: true,
    submitted: false,
    companies: [],
    current: 0,
    card_keys: [],
  };

  componentDidMount() {}
  render() {
    console.log("this.props.name", this.props.name, typeof this.props.name);
    return (
      <div>
        {/* <Row> */}
        <Form.List name={this.props.name}>
          {(fields, { add, remove }) => (
            <>
              {Array.from(Array(1).keys()).map((it, index) => (
                <Row>
                  <Col span="16">
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className="research_notes-card-focus-within"
                    >
                      <Form.Item name={this.props.name + "1"}>
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 60 }}
                          placeholder="Enter Title Here"
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={1}
                        ></TextArea>
                      </Form.Item>
                      <Form.Item name={this.props.name + "2"}>
                        <TextArea
                          autoSize={{ minRows: 7, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={8}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                  </Col>
                  <Col span="8">
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className="research_notes-card-focus-within"
                      style={{ width: "100%" }}
                    >
                      <Form.Item name={index + "3"}>
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 60 }}
                          placeholder="Enter Title Here"
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={1}
                        ></TextArea>
                      </Form.Item>
                      <Form.Item name={index + "4"}>
                        <TextArea
                          autoSize={{ minRows: 7, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={8}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>

        <div style={{ margin: "10px 0 30px", height: "40px" }}>
          <Popconfirm
            title="Are you sure you want to delete ?"
            onConfirm={() => this.props.deleteDraft(this.props.name)}
          >
            <button
              style={{
                float: "right",
                width: "150px",
              }}
              className="button-secondary"
              block
            >
              Delete
            </button>
          </Popconfirm>
        </div>
      </div>
    );
  }
}
