import { Button, Col, Row } from "antd";
import React from "react";
import AUTH_API from "../../../api/auth";
import Singlecontactselect from "./Singlecontactselect";
import "./Tracker.css";

export default class Addcontacts extends React.Component {
  category_mapping = {
    fr: "Friends",
    aq: "Acquaintances",
    pc: "Past Colleagues",
    so: "Social Organizations",
    al: "Alumni",
    tr: "Target Roles",
    ti: "Target Industries",
    tc: "Target Companies",
    rc: "Recruiters",
    cc: "Cold Connections",
  };
  state = {
    contacts: [],
    loading: true,
    selected_count: 0,
  };
  componentDidMount() {
    AUTH_API.getAllContacts()
      .then((response) => {
        console.log("tr !!", response);
        const contacts = response.data;
        contacts.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        console.log("sorted", contacts);
        let selected_count = 0;
        contacts.forEach((element) => {
          if (element.selected === true) selected_count += 1;
        });
        console.log("selected_count", selected_count);
        this.setState({
          contacts: contacts,
          selected_count: selected_count,
        });
      })
      .catch((err) => console.log("no roles found!", err));
  }

  onCheck = (e, id) => {
    console.log(e, id);
    if (e === true)
      this.setState({
        selected_count: this.state.selected_count + 1,
      });
    else
      this.setState({
        selected_count: this.state.selected_count - 1,
      });

    const data = {
      selected: e,
    };
    AUTH_API.patchContact(data, id, "NT")
      .then((response) => {
        console.log("patch res", response);
      })
      .catch((err) => console.log("not patched", err));
  };
  onGo = () => {
    // if(this.state.selected_count >= 10)
    this.props.next();
    // else
    // message.error("Select atleast 10 contacts!")
  };

  render() {
    console.log("start text", this.props.start_text);
    return (
      <>
        {!this.props.start_text ? (
          <p className="contact-sel-heading">
            Start this step by selecting contacts you want to reach out to.
          </p>
        ) : (
          ""
        )}
        <Row>
          <Col span={24}>
            <p className="add-contact-heading">Add Contacts</p>
            <p className="tracker-subtext">
              Select up to 10 contacts you’d like to import. You will start your
              reach outs with these contacts.
            </p>
            <Row gutter={8}>
              <Col
                span={10}
                style={{ textAlign: "left" }}
                className="select-heading"
              >
                Name
              </Col>
              <Col
                span={10}
                className="select-heading"
                style={{ textAlign: "left" }}
              >
                Type of connection
              </Col>
              <Col
                span={4}
                className="select-heading"
                style={{ textAlign: "left" }}
              >
                Select
              </Col>
            </Row>
            <p className="separator"></p>
            <div className="contact-list-scroll">
              {this.state.loading && (
                <>
                  {this.state.contacts.map((item) => (
                    <Singlecontactselect
                      id={item.id}
                      name={item.title}
                      type={this.category_mapping[item.contact_type]}
                      onCheck={(e, id) => this.onCheck(e, id)}
                      checked={item.selected}
                    />
                  ))}
                </>
              )}
            </div>
            <Row>
              <Col span={24}>
                <span style={{ float: "right", padding: "30px 0 10px" }}>
                  {this.state.selected_count} contacts selected
                </span>
              </Col>
            </Row>

            <Row>
              <Col span={8}></Col>
              <Col span={8}></Col>
              <Col span={8}>
                <button
                  type="primary"
                  className="button-main"
                  size="large"
                  onClick={this.onGo}
                  style={{
                    width:"100%"
                  }}
                >
                  Next
                </button>
              </Col>
            </Row>
          </Col>
          {/* <Col span={11}></Col> */}
        </Row>
      </>
    );
  }
}
