import { InfoCircleTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { Card, Popover } from "antd";
import "antd/dist/antd.css";
import React from "react";
import "./contacts.css";

const gridStyle = {
  width: "40%",
  textAlign: "center",
};

const cont = (
  <div className="popover-content">
    Friends who are well connected who <br />
    might be able to make introductions.
  </div>
);

export default class Contacttableparent extends React.Component {
  state = {
    show_all: false,
  };

  category_mapping = {
    "Family & Friends": "fr",
    Acquaintances: "aq",
    "Past Colleagues": "pc",
    "Social Organizations": "so",
    Alumni: "al",
    "Target Roles": "tr",
    "Target Industries": "ti",
    "Target Companies": "tc",
    Recruiters: "rc",
    "Cold Connections": "cc",
  };

  // handleDelete=(current)=>{
  //   // console.log('handle del',current);
  //   const all_contacts = [...this.props.categorized_contacts];
  //   all_contacts.filter(item => all_contacts.indexOf(item) !== current);
  //   // console.log('after delete', all_contacts.filter(item => all_contacts.indexOf(item) !== parseInt(current)));
  //   this.setState({
  //     modal_visible: false,
  //     contacts: all_contacts.filter(item => all_contacts.indexOf(item) !== parseInt(current)),
  //   });
  // }

  // onSubmit = (values, current) => {
  //   // console.log("submit", values, current);
  //   const all_contacts = [...this.props.categorized_contacts];
  //   all_contacts[current] = values;
  //   // console.log('new state', all_contacts);
  //   this.setState({
  //     modal_visible: false,
  //     contacts: all_contacts,
  //   });
  // };

  render() {
    // console.log('all contacts',this.props.categorized_contacts, this.props.category );
    // console.log('table of', this.props.categorized_contacts);
    const contacts_list = [...this.props.categorized_contacts];
    // const fake_list = [];
    if (contacts_list.length < 4) {
      for (var i = contacts_list.length; i < 4; i++) {
        contacts_list.push({
          name: "Add Contact Here",
          company: "",
          email: "",
          linkedin: "",
          target_roles: "",
          target_industries: "",
        });
      }
    }

    return (
      <div className="outer-card" style={{ boxShadow: "0px 3px 16px 0px #1313131A" }}>
        <Card className="outer-card">
          <div className="header-set">
            <span className="contact-cat">{this.props.category}</span>
            <Popover placement="bottomLeft" content={this.props.info_content}>
              <InfoCircleTwoTone className="info-circle" />
            </Popover>
            <PlusCircleFilled
              className="add-icon"
              onClick={() => {
                this.props.addNew(this.category_mapping[this.props.category]);
              }}
            />
          </div>

          {this.state.show_all
            ? contacts_list.map((item, index) => (
                <Card.Grid
                  id={index}
                  style={gridStyle}
                  className="contact-card"
                  onClick={
                    this.props.categorized_contacts.length > index
                      ? (e) => {
                          this.props.showModal(
                            e,
                            this.category_mapping[this.props.category],
                            index
                          );
                          // console.log('showmodal clicked');
                        }
                      : () => {
                          this.props.addNew(
                            this.category_mapping[this.props.category]
                          );
                          // console.log('addnew clicked');
                        }
                  }
                >
                  {item.name}
                </Card.Grid>
              ))
            : contacts_list.map(
                (item, index) =>
                  index < 6 && (
                    // <Card.Grid id={index} style={gridStyle} className='contact-card' onClick={(e)=>this.props.showModal(e, this.category_mapping[this.props.category])}>{item.name}</Card.Grid>)
                    <Card.Grid
                      id={index}
                      style={gridStyle}
                      className="contact-card"
                      onClick={
                        this.props.categorized_contacts.length > index
                          ? (e) => {
                              this.props.showModal(
                                e,
                                this.category_mapping[this.props.category],
                                index,
                              );
                              // console.log('showmodal clicked', index, this.props.categorized_contacts.length);
                            }
                          : () => {
                              this.props.addNew(
                                this.category_mapping[this.props.category]
                              );
                              // console.log('addnew clicked', index, this.props.categorized_contacts.length);
                            }
                      }
                    >
                      <span
                        style={
                          item.name === "Add Contact Here"
                            ? { opacity: "0.4" }
                            : { opacity: "1" }
                        }
                      >
                        {item.name}
                      </span>
                    </Card.Grid>
                  )
              )}
        </Card>
        <div className="view-all">
          {this.props.categorized_contacts.length > 6 ? (
            this.state.show_all ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({ show_all: !this.state.show_all });
                }}
              >
                View Less&#8963;
              </span>
            ) : (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({ show_all: !this.state.show_all });
                }}
              >
                View All &#8964;
              </span>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}