import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Input } from "antd";
import moment from "moment";
import React from "react";
import "./CoverLetter.css";

Font.register({
  family: "Monteserrat",
  src: "https://fonts.googleapis.com/css2?family=AvenirBook&display=swap",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src:
        'https://fonts.googleapis.com/css2?family=AvenirBook:wght@600&display=swap" rel="stylesheet',
      fontStyle: "normal",
      fontWeight: 600,
    },
  ],
});
const { TextArea } = Input;
const date = moment().format("DD/MM/YYYY");
const styles = StyleSheet.create({
  doc: {
    backgroundColor: "#ffffff",
  },
  page: {
    //   flexDirection: 'row',
    backgroundColor: "#ffffff",
    //   margin: '10pt 20pt 20pt 30pt',
  },
  section: {
    margin: "10 20 20 20 ",
    padding: 10,
    display: "grid",
    //   flexGrow: 1
  },
  cl_pdf_title: {
    textAlign: "center",
    // font: 'normal normal 600 20px/22px AvenirBook',
    fontSize: "20pt",
    color: "#606368",
  },
  cl_subtitle: {
    margin: "10px 0",
    textAlign: "center",
    // font: 'normal normal normal 14px/22px AvenirBook',
    fontSize: "12pt",
    color: "#606368",
  },
  cl_para: {
    textAlign: "left",
    // font: 'normal normal normal 14px/26px AvenirBook',
    lineHeight: 1.5,
    fontSize: "12pt",
    marginBottom: 15,
    color: "#606368",
  },
  cl_pdf_text: {
    textAlign: "left",
    // font: 'normal normal normal 14px/26px AvenirBook',
    lineHeight: 1.5,
    fontSize: "12pt",
    color: "#606368",
  },
});
export default class Test extends React.Component {
  pdfExportComponent = React.createRef();
  bodyRef = React.createRef();
  state = {
    submitted: false,
    Api_response: this.props.obj,
    inp: "",
  };
  render() {
    console.log("generated CL pdf rendered", this.state.Api_response);
    return (
      <>
        {!this.state.Api_response.hasOwnProperty("edited_values") ? (
          <Document style={styles.doc}>
            <Page size="A4" style={styles.page} wrap>
              <View style={{ padding: "20px" }} fixed></View>
              <View style={styles.section}>
                <Text style={styles.cl_pdf_title}>{this.props.name}</Text>
                <Text style={styles.cl_subtitle}>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response["01"]},&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response["02"]} |&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response["03"]} |&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response["04"]} |&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response["05"]}&nbsp;
                  </Text>
                </Text>
                <Text style={{ ...styles.cl_pdf_text, margin: "5px 0" }}>
                  {date}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, marginBottom: "3px" }}>
                  {this.state.Api_response["11"]}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, marginBottom: "3px" }}>
                  {this.state.Api_response["12"]}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, marginBottom: "3px" }}>
                  {this.state.Api_response["13"]}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, margin: "7px 0 10px" }}>
                  To Whom it May Concern,
                </Text>

                <Text style={styles.cl_para}>
                  {/* I am writing to express my strong interest in the  {this.state.Api_response['21']}  position at */}
                  <Text style={styles.cl_pdf_text}>
                    I am writing to express my strong interest in the
                  </Text>
                  <Text style={styles.cl_pdf_text}>
                    {"\n"}
                    {this.state.Api_response["21"]}
                  </Text>
                  <Text style={styles.cl_pdf_text}> position at </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["11"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>that I found</Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["23"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>
                    . I am very interested in this position as it seems to be an
                    ideal match for my experience and skills in{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["24"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>. </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["25"]}{" "}
                  </Text>
                </Text>
                <Text style={styles.cl_para}>
                  <Text style={styles.cl_pdf_text}>At </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["31"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}> as an </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["32"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>, I</Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["33"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>. </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["34"]}{" "}
                  </Text>
                </Text>
                <Text style={styles.cl_para}>
                  <Text style={styles.cl_pdf_text}>
                    Prior to this experience, I was a{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["42"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}> at </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["41"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>, where I </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["43"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>. </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["44"]}{" "}
                  </Text>
                </Text>
                <Text style={styles.cl_para}>
                  {this.state.Api_response["51"]}{" "}
                  {this.state.Api_response["52"]}
                  {/* <Text style={styles.cl_pdf_text}> {this.state.Api_response['51']} </Text>
                                <Text style={styles.cl_pdf_text}>. </Text>
                                <Text style={styles.cl_pdf_text}> {this.state.Api_response['52']} </Text> */}
                </Text>
                <Text style={styles.cl_para}>
                  <Text style={styles.cl_pdf_text}>
                    I would be grateful to discuss this opportunity with you
                    further. Please don’t hesitate to reach out at{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>
                    {" "}
                    {this.state.Api_response["61"]}{" "}
                  </Text>
                  <Text style={styles.cl_pdf_text}>
                    . Thank you in advance for your time and consideration.
                  </Text>
                </Text>
                <Text style={{ ...styles.cl_pdf_text, margin: "5px 0" }}>
                  Best,
                </Text>
                <Text style={styles.cl_pdf_text}>{this.props.name}</Text>
              </View>
            </Page>
          </Document>
        ) : (
          <Document style={styles.doc}>
            <Page size="A4" style={styles.page} wrap>
              <View style={{ padding: "20px" }} fixed></View>
              <View style={styles.section}>
                <Text style={styles.cl_pdf_title}>{this.props.name}</Text>
                <Text style={styles.cl_subtitle}>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response.edited_values["01"]},&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response.edited_values["02"]} |&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response.edited_values["03"]} |&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response.edited_values["04"]} |&nbsp;
                  </Text>
                  <Text style={{ ...styles.cl_pdf_text, textAlign: "center" }}>
                    {this.state.Api_response.edited_values["05"]}&nbsp;
                  </Text>
                </Text>
                <Text style={{ ...styles.cl_pdf_text, margin: "5px 0" }}>
                  {date}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, marginBottom: "3px" }}>
                  {this.state.Api_response.edited_values["11"]}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, marginBottom: "3px" }}>
                  {this.state.Api_response.edited_values["12"]}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, marginBottom: "3px" }}>
                  {this.state.Api_response.edited_values["13"]}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, margin: "7px 0 10px" }}>
                  To Whom it May Concern,
                </Text>

                <Text style={styles.cl_para}>
                  {/* I am writing to express my strong interest in the  {this.state.Api_response['21']}  position at */}
                  {this.state.Api_response.edited_values["para1"]}
                </Text>
                <Text style={styles.cl_para}>
                  {this.state.Api_response.edited_values["para2"]}
                </Text>
                <Text style={styles.cl_para}>
                  {this.state.Api_response.edited_values["para3"]}
                </Text>
                <Text style={styles.cl_para}>
                  {this.state.Api_response.edited_values["para4"]}
                </Text>
                <Text style={styles.cl_para}>
                  {this.state.Api_response.edited_values["para5"]}
                </Text>
                <Text style={{ ...styles.cl_pdf_text, margin: "5px 0", display: 'flex', flexDirection:'column'}}>
                  Best,<Text style={styles.cl_pdf_text}>{"\n \n"}</Text>
                </Text>
                <Text style={styles.cl_pdf_text}>{this.props.name}</Text>
              </View>
            </Page>
          </Document>
        )}
      </>
    );
  }
}
