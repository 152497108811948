import React from "react";
import { Row, Col, Checkbox } from "antd";

export default class Singleindustryselect extends React.Component {
  state = {
    checked: this.props.checked,
  };
  render() {
    return (
      <div>
        <Row
          gutter={8}
          style={{ color: "#111111", font: "", margin: "0 0 17px 0" }}
        >
          <Col span={7} style={{ textAlign: "center" }}>
            {this.props.count}
          </Col>
          <Col span={7}>{this.props.name}</Col>
          <Col span={9} style={{ paddingLeft: "80px" }}>
            <Checkbox
              defaultChecked={this.props.checked}
              onChange={(e) =>
                this.props.onCheck(e.target.checked, this.props.id)
              }
            ></Checkbox>
          </Col>
        </Row>
        <hr style={{}} />
      </div>
    );
  }
}
