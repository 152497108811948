import axios from "axios";
import { message } from "antd";
import React from "react";
import moment from "moment";
import { STEP_KEY } from "./steps";
// import Cookies from "js-cookie";
// import USER_ROLES from "./roles"
//axios global configuration
axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? "https://platform.iamwoken.com/api"
    : "https://iamwokenstg.ebizonstaging.com/api/";
// : "https://platform.iamwoken.com/api";
// axios.defaults.withCredentials = true

axios.defaults.headers.post["Content-Type"] = "application/json";
// Ping interval in minutes
const pingInterval = process.env.NODE_ENV === "production" ? 5 : 1;
// const pingInterval = process.env.NODE_ENV === "production" ? 300 : 300;

const PINGLIMIT = process.env.NODE_ENV === "production" ? 6 : 10;
// const PINGLIMIT = process.env.NODE_ENV === "production" ? 300 : 300;

let pingCount = 0;
let curr_step = "dashboard";
  
let HELPERS = {
  intervalHandle: null,
  savedHandle: null,
  orderTasks: (tasks) =>
    tasks.sort((a, b) => {
      const d1 = moment(a.due_date);
      const d2 = moment(b.due_date);
      return d1.isBefore(d2) ? -1 : d1.isAfter(d2) ? 1 : 0;
    }),
  getCurrentStep: () => {
    let stepName = "dashboard";

    if (window.location.pathname.indexOf("onboarding") !== -1) {
      stepName = STEP_KEY.Onboarding;
    } else if (window.location.pathname.indexOf("assessment") !== -1) {
      stepName = STEP_KEY.Assessment;
    } else if (window.location.pathname.indexOf("target-roles") !== -1) {
      stepName = STEP_KEY.TargetRoles;
    } else if (window.location.pathname.indexOf("target-industries") !== -1) {
      stepName = STEP_KEY.TargetIndustries;
    } else if (window.location.pathname.indexOf("research-roles") !== -1) {
      stepName = STEP_KEY.ResearchRoles;
    } else if (window.location.pathname.indexOf("research-industries") !== -1) {
      stepName = STEP_KEY.ResearchIndustries;
    } else if (window.location.pathname.indexOf("networking/contacts") !== -1) {
      stepName = STEP_KEY.Contacts;
    } else if (
      window.location.pathname.indexOf("networking/email-drafts") !== -1
    ) {
      stepName = STEP_KEY.EmailDrafts;
    } else if (window.location.pathname.indexOf("networking/tracker") !== -1) {
      stepName = STEP_KEY.Tracker;
    } else if (
      window.location.pathname.indexOf("networking/call-prep") !== -1
    ) {
      stepName = STEP_KEY.CallPrep;
    } else if (
      window.location.pathname.indexOf("networking/call-notes") !== -1
    ) {
      stepName = STEP_KEY.CallNotes;
    } else if (
      window.location.pathname.indexOf("networking/reflections") !== -1
    ) {
      stepName = STEP_KEY.NetworkingReflections;
    } else if (
      window.location.pathname.indexOf("learning-opportunities") !== -1
    ) {
      stepName = STEP_KEY.LearningOpportunities;
    } else if (
      window.location.pathname.indexOf("materials/professional-summary") !== -1
    ) {
      stepName = STEP_KEY.ProfessionalSummary;
    } else if (window.location.pathname.indexOf("materials/resume") !== -1) {
      stepName = STEP_KEY.Resume;
    } else if (
      window.location.pathname.indexOf("materials/cover-letter") !== -1
    ) {
      stepName = STEP_KEY.CoverLetter;
    } else if (window.location.pathname.indexOf("materials/linkedin") !== -1) {
      stepName = STEP_KEY.Linkedin;
    } else if (
      window.location.pathname.indexOf("job-search/application-tracker") !== -1
    ) {
      stepName = STEP_KEY.ApplicationTracker;
    } else if (
      window.location.pathname.indexOf("job-search/interview-research") !== -1
    ) {
      stepName = STEP_KEY.InterviewResearch;
    } else if (
      window.location.pathname.indexOf("job-search/interview-prep") !== -1
    ) {
      stepName = STEP_KEY.InterviewPractice;
    } else if (
      window.location.pathname.indexOf("job-search/reflections") !== -1
    ) {
      stepName = STEP_KEY.Reflections;
    }

    return stepName;
  },
  stopPing: () => {
    if (HELPERS.intervalHandle !== null) {
      HELPERS.log("PING: Clearing old interval...");
      clearInterval(HELPERS.intervalHandle);
    }
  },
  resetPing: () => {
    pingCount = 0;
  },
  ping: () => {
    try {
      if (HELPERS.intervalHandle !== null) {
        HELPERS.log("PING: Clearing old interval...");
        clearInterval(HELPERS.intervalHandle);
      }

      HELPERS.log("PING: Setting up....");
      HELPERS.intervalHandle = setInterval(() => {
        let step = HELPERS.getCurrentStep();
        console.log(step, curr_step, 'pingCount', pingCount);

        if (step !== "dashboard" && step === curr_step) {
          console.log("in if", pingCount);
          pingCount += 1;
        } else {
          pingCount = 0;
          // localStorage.setItem('curr_step', step)
          curr_step = step;
          console.log("in else", curr_step);
        }

        // HELPERS.log("PING: Pinging", pingCount, step, localStorage.getItem('curr_step'), step !== "dashboard" && step===localStorage.getItem('curr_step'));

        if (pingCount > PINGLIMIT) {
          if (step === "JR" || step === "NF")
            if (pingCount > PINGLIMIT * 3) {
              window.location.href = "/";
            }
          window.location.href = "/";
        }

        step === "dashboard" ||
          HELPERS.secureRequest({
            url: `stepreflection/ping/`,
            method: "POST",
            data: {
              step,
              pingInterval,
            },
          });
      }, pingInterval * 60 * 1000);
    } catch (e) {
      HELPERS.log(e);
    }
  },
  changesSaved: (func) => {
    try {
      if (HELPERS.savedHandle !== null) {
        HELPERS.log("saved: Clearing old interval...");
        clearInterval(HELPERS.savedHandle);
      }

      HELPERS.log("saved state....");
      HELPERS.savedHandle = setInterval(() => {
        HELPERS.log("PING: Pinging");
        func();
      }, 60 * 1000);
    } catch (e) {
      HELPERS.log(e);
    }
  },
  log: (...args) => {
    if (process.env.NODE_ENV !== "production") {
      console.log("$$woken$$", ...args);
    }
  },
  error: (...args) => {
    if (process.env.NODE_ENV !== "production") {
      console.error("$$woken$$", ...args);
    }
  },
  getCookie: (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },
  // removeCookie: (key) => {
  //     if (key)
  //         Cookies.remove(key)
  // },
  // setCookie: (key, value) => {
  //     if (key && value)
  //         Cookies.set(key, value)
  // },
  // isRoleAssigned: () => {
  //     return !!Cookies.get("roleCode");
  // },
  // isLoggedIn: () => {
  //     return localStorage.getItem("isLoggedIn");
  // },
  request: (config) => {
    config.headers = config.headers ? config.headers : {};
    const csrfTokenEl = document.querySelector("[name=csrfmiddlewaretoken]");
    const csrfToken =
      HELPERS.getCookie("csrftoken") || (csrfTokenEl && csrfTokenEl.value);
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }

    console.log("$$woken$$", config);
    return axios
      .request(config)
      .then((response) => {
        console.log("in resp");
        return response;
      })
      .catch((err) => {
        console.log(
          "err in helper........................................................................................................",
          err
        );
        if (
          err.response &&
          (err.response.status === 403 || err.response.status === 401)
        ) {
          // localStorage.clear()
          console.log(err.response);
          if (
            err.response.data.detail === "You must be a client to access this."
          )
            window.location.href = "/coach";
          else if (err.response.data.detail === "User inactive or deleted.") {
            message.error(
              "You have got a corrupt token in your browser, please logout and login again!"
            );
            localStorage.clear();
          } else {
            message.error(
              "You have got a corrupt token in your browser, please logout and login again!"
            );
            localStorage.clear();
            window.location.href = "/signin";
          }
        } else {
          throw new Error("Error");
        }
      });
  },
  secureRequest: (config) => {
    config.headers = config.headers ? config.headers : {};
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    if (config.url !== "stepreflection/ping/") {
      console.log("resetting ping");
      HELPERS.resetPing();
    }

    if (config.Stepname) {
      config.headers["Stepname"] = config.Stepname;
    }

    return HELPERS.request(config);
  },
  debounce: (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  },
  debounceFormChanges: (func) => {
    return HELPERS.debounce(func, 1000);
  },
  getUserId: () => localStorage.getItem("user_id"),
  // convertDateTime: (string, format) => {
  //     return moment(string).format(format);
  // },
  // getAcronym: (string) => {
  //     return string.match(/\b(\w)/g).join("")
  // },
  // removeASCII: (string) => {
  //     return string.replace(/[^\x20-\x7E]/g, “”);
  // },
  // trimObj: (obj) => {
  //     if (!Array.isArray(obj) && typeof obj != "object") return obj;
  //     return Object.keys(obj).reduce(function (acc, key) {
  //         acc[key.replace(/[^\x20-\x7E]/g, “”)] = typeof obj[key] == "string" ? obj[key].replace(/[^\x20-\x7E]/g, “”) : obj[key];
  //         return acc;
  //     }, Array.isArray(obj) ? [] : {});
  // },
  // trimJSON: (obj) => {
  //     if (!Array.isArray(obj) && typeof obj != "object") return obj;
  //     return Object.keys(obj).reduce(function (acc, key) {
  //         acc[key.trim()] = typeof obj[key] == "string" ? obj[key].trim() : obj[key];
  //         return acc;
  //     }, Array.isArray(obj) ? [] : {});
  // },
  converToFormData: (obj, rootName, ignoreList) => {
    var formData = new FormData();
    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + "[" + i + "]");
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "." + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== "undefined") {
            formData.append(root, data);
          }
        }
      }
    }
    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }
    appendFormData(obj, rootName);
    return formData;
  },
  // updatedConvertToFormData: (obj, rootName, ignoreList) => {
  //     var formData = new FormData();
  //     function appendFormData(data, root) {
  //         if (!ignore(root)) {
  //             root = root || "";
  //             if (data instanceof File) {
  //                 formData.append(root, data);
  //             } else if (Array.isArray(data)) {
  //                 for (var i = 0; i < data.length; i++) {
  //                     appendFormData(data[i], root + "[" + i + "]");
  //                 }
  //             } else if (typeof data === "object" && data) {
  //                 for (var key in data) {
  //                     if (data.hasOwnProperty(key)) {
  //                         if (root === "") {
  //                             appendFormData(data[key], key);
  //                         } else {
  //                             appendFormData(data[key], root + key);
  //                         }
  //                     }
  //                 }
  //             } else {
  //                 if (data !== null && typeof data !== "undefined") {
  //                     formData.append(root, data);
  //                 }
  //             }
  //         }
  //     }
  //     function ignore(root) {
  //         return Array.isArray(ignoreList)
  //             && ignoreList.some(function (x) { return x === root; });
  //     }
  //     appendFormData(obj, rootName);
  //     return formData;
  // },
  // getUserRoles: (roles) => {
  //     if (roles.length) {
  //         let filteredRoles = roles.filter(role => {
  //             return role.name && role.name.startsWith("role_");
  //         });
  //         let newRoles = filteredRoles.map(role => {
  //             return USER_ROLES[role.name];
  //         });
  //         return newRoles;
  //     }
  //     return [];
  // },
  // getNameInitials: (firstName, lastName) => {
  //     let initials = "";
  //     if (firstName.length > 0) initials += firstName[0];
  //     if (lastName.length > 0) initials += lastName[0];
  //     return initials
  // },
  // getFileNameFromURL: (url) => {
  //     let a = url.split("/");
  //     return a[a.length - 1];
  // },
  // modifyURL: (params) => {
  //     let roleCode = HELPERS.getCookie("roleCode");
  //     const filter_obj = params;
  //     const url = new URL(`api/v1/teams/export-sp_val/${roleCode}`, window.location.origin);
  //     Object.keys(params).forEach(key => {
  //         url.searchParams.append(key, filter_obj[key])
  //     });
  //     console.log("url print", url);
  //     return url;
  // },
  // modifyURLTimesheet: (params) => {
  //     const filter_obj = params;
  //     const url = new URL(`api/v1/timesheets/dump-timesheet-data/`, window.location.origin);
  //     Object.keys(params).forEach(key => {
  //         url.searchParams.append(key, filter_obj[key])
  //     });
  //     console.log("url print", url);
  //     return url;
  // },
  // isLocalHost: () => {
  //     return Boolean(
  //         window.location.hostname === "localhost" ||
  //         // [::1] is the IPv6 localhost address.
  //         window.location.hostname === "[::1]" ||
  //         // 127.0.0.1/8 is considered localhost for IPv4.
  //         window.location.hostname.match(
  //             /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  //         )
  //     );
  // },
  // formatErrors : (errors) =>{
  //     return errors.map((err ) => err.errors[0])
  // }
};

const WEEKLY_REFLECTION_TYPES = {
  NETWORKING: "networking",
  JOB_SEARCH: "job_search",
};

export { WEEKLY_REFLECTION_TYPES };
export default HELPERS;
