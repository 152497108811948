import React from "react";
import { Row, Col, Input, Tag, Form, Select, Button, message } from "antd";
import AUTH_API from "./../../api/auth";
import Freeformbox from "./Freeformbox";
import HELPERS from "utils/helper";

const { Option } = Select;
const { TextArea } = Input;

function tagRender(props) {
  const { label, closable, onClose } = props;
  return (
    <Tag
      color={"white"}
      closable={closable}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "14px 5px",
        padding: "2px 15px",
        backgroundColor: "#8375E8",
      }}
    >
      {label}
    </Tag>
  );
}

const OPTIONS = [
  "Strategize/plan for the process/product",
  "Research the process/product",
  "Create/build the process/product",
  "Run/execute the process/product",
  "Manage or oversee the process/product",
  "Document procedures for the process/product",
  "Build relevant relationships/connect stakeholders",
  "Communicate relevant updates/information",
  "Resolve relevant issues",
  "Pitch or sell the process/product",
  "Analyze the process/product",
  "Improve the process/product",
  "Digitize/automate the process/product",
  "Enhance efficiency of the the process/product",
  "Reduce relevant risk",
  "Teach/train others to use the process/product",
  "Advise others about the process/product",
  "Coach others to use the process/product",
];

const Q_20 = {
  "The Manager": "manage",
  "The Innovator": "innovate",
  "The Technician": "do",
};

export default class Experiences extends React.Component {
  formRef = React.createRef();
  state = {
    ispartial: false,
    assess_id: "",
    q_20: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    AUTH_API.getAssessment()
      .then((response) => {
        console.log("get assessment", response, this.formRef);
        // this.formRef.current.setFieldsValue(response.data.experiences);
        this.props.getRef(this.formRef);
        this.setState({
          assess_id: response.data.id,
          ispartial: response.data.experiences
            ? response.data.experiences["7"] === "Partially"
              ? true
              : false
            : "",
          q_20: response.data.experiences ? response.data.experiences[21] : "",
          alldata: response.data.experiences,
        });
        if (this.formRef.current) {
        }
        // this.formRef.current.setFieldsValue(response.data.experiences);
      })
      .catch((err) => console.log("err", err));
  }

  showQuestion = (val) => {
    if (val === "Partially")
      this.setState({
        ispartial: true,
      });
    else
      this.setState({
        ispartial: false,
      });
  };

  onValChange = (val, allvalues) => {
    // console.log('form values', allvalues)
    console.log("this.formref", this.formRef);
    this.props.getRef(this.formRef);
    AUTH_API.patchAssessment({ experiences: allvalues }, this.state.assess_id)
      .then((response) => {
        // console.log('experiences patched!!', response);
        message.success('Your work has been saved')
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  onChange_20 = (val) => {
    this.setState({
      q_20: val,
    });
  };

  onFinish = () => {
    console.log("onfin");
    this.props.next();
  };

  onFail = () => {
    message.error("Fill all the required fields first");
  };

  render() {
    return (
      <div className="experiences">
        <p
          style={{
            color: "#199094",
            font: "normal normal 600 18px/22px AvenirBook",
          }}
        >
          Complete the first section of the assessment. This section focuses on
          activities you love doing. Go with your gut and don’t overthink it!
        </p>
        {console.log(
          "before form",
          this.state.alldata,
          this.formRef.current &&
            this.formRef.current.setFieldsValue(this.state.alldata)
        )}
        <Form
          ref={this.formRef}
          // initialValues={this.state.alldata}
          name="experiences"
          onValuesChange={HELPERS.debounceFormChanges(this.onValChange)}
          onFinish={this.onFinish}
          onFinishFailed={this.onFail}
        >
          <div className="disabled-div" id="2">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              1. List 3 experiences you really enjoyed. These might be things
              you chose to pursue. These can be personal projects, elements of
              certain jobs, activities or experiences from outside of work,
              experiences from several years ago, etc.
            </p>
            <Form.List name="2">
              {(fields, { add, remove }) => (
                <Row gutter={36}>
                  <Col span={8}>
                    <p>Experience 1</p>
                    <Form.Item
                      rules={[{ required: true, message: "Required Field" }]}
                      name="2a"
                    >
                      <TextArea ></TextArea>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <p>Experience 2</p>
                    <Form.Item
                      rules={[{ required: true, message: "Required Field" }]}
                      name="2b"
                    >
                      <TextArea ></TextArea>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <p>Experience 3</p>
                    <Form.Item
                      rules={[{ required: true, message: "Required Field" }]}
                      name="2c"
                    >
                      <TextArea ></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.List>
          </div>

          <div className="disabled-div" id="3">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              2. Select the experience you enjoyed the most. Be sure to pick one
              that aligns most with your professional interests rather than
              interests you consider hobbies. We'll dig in to this experience
              more in further questions.
            </p>
              <Row gutter={36}>
                <Col span={8}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="3"
                  >
                    <Select size="large" 
                      // style={{ width: "20%" }}
                    >
                      <Option id="1" value={"Experience 1"}>
                        Experience 1
                      </Option>
                      <Option id="2" value={"Experience 2"}>
                        Experience 2
                      </Option>
                      <Option id="3" value={"Experience 3"}>
                        Experience 3
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                </Col>
                <Col span={8}>
                </Col>
              </Row>
          </div>

          <div className="disabled-div" id="4">
            <p style={{ paddingBottom: "20px", fontSize: "17px" }}>
              3. Describe what you were doing in this experience using verbs or
              short phrases. Examples: designing, coding, writing, presenting,
              analyzing, consulting, selling, teaching, researching, etc.
            </p>
            {/* <Row gutter={36}> */}
              {/* <Col span={16}> */}
                <Freeformbox q_number={4} default_len={4} />
              {/* </Col> */}
              {/* <Col span={8}></Col> */}
            {/* </Row> */}
          </div>

          <div className="disabled-div" id="5">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              4. Describe the purpose, goal, or intended outcome of the
              experience. Be as specific as you can. Who were you helping and
              why? Example: to solve a certain problem, to learn about a topic
              or industry, to help a group of people achieve a certain goal,
              etc.
            </p>

            <div className="cont-5">
              <Row gutter={36}>
                <Col span={16}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="5"
                  >
                    <TextArea
                      className="text-5"
                      autoSize={{ minRows: 2, maxRows: 60 }}
                    >
                      {" "}
                    </TextArea>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
              </Row>
            </div>
          </div>

          <div className="disabled-div" id="6">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              5. Tell me what you loved about this experience.{" "}
            </p>

            <div className="cont-6">
              <Row gutter={36}>
                <Col span={16}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="6"
                  >
                    <TextArea
                      className="text-6"
                      autoSize={{ minRows: 2, maxRows: 60 }}
                    >
                      {" "}
                    </TextArea>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
              </Row>
              
            </div>
          </div>

          {/* <div className='disabled-div' id ='6'>
                    <p style={{paddingTop:'14px', paddingBottom:'20px', fontSize: "17px"}}>
                        6. The goal of this experience was to: </p>
                    <Form.Item rules={[{ required: true, message: 'Required Field' }]}  name="">
                        <div className="cont-6">
                            <TextArea className="text-6"  autoSize={{ minRows: 2, maxRows: 60 }}> </TextArea>
                        </div>
                    </Form.Item>
                </div> */}

          <div className="disabled-div" id="7">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              6. If you could pursue this as a career, would you?
            </p>
            <Row gutter={36}>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="7"
                >
                  <Select
                    size="large"
                    // style={{ width: "20%" }}
                    onChange={(val) => this.showQuestion(val)}
                  >
                    <Option id="1" value={"Yes"}>
                      Yes
                    </Option>
                    <Option id="2" value={"Partially"}>
                      Partially
                    </Option>
                    <Option id="3" value={"No"}>
                      No
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
              </Col>
              <Col span={8}>
              </Col>
            </Row>
          </div>

          {/*--------------------------------------------- show if partially is selected (might have a problem while setting def val)-------------------------------------------------------------*/}

          {this.state.ispartial && (
            <div className="disabled-div" id="71">
              <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
                Since you chose partially, which parts of the experience would
                you keep?
              </p>
              <Row gutter={36}>
                <Col span={16}>
                  <Form.List name="7a">
                    {(fields, { add, remove }) => (
                      <>
                        <div style={{ marginBottom: 15 }}>
                          {/* <p>Experience 1</p> */}
                          <Form.Item
                            rules={[{ required: true, message: "Required Field" }]}
                            name="1"
                          >
                            <TextArea
                              autoSize={{ minRows: 2, maxRows: 60 }}
                            ></TextArea>
                          </Form.Item>
                        </div>
                        <div style={{ marginBottom: 15 }}>
                          {/* <p>Experience 2</p> */}
                          <Form.Item name="2">
                            <TextArea
                              autoSize={{ minRows: 2, maxRows: 60 }}
                            ></TextArea>
                          </Form.Item>
                        </div>

                        <div style={{ marginBottom: 15 }}>
                          {/* <p>Experience 3</p> */}
                          <Form.Item name="3">
                            <TextArea
                              autoSize={{ minRows: 2, maxRows: 60 }}
                            ></TextArea>
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </Form.List>
                </Col>
                <Col span={8}></Col>
              </Row>
              
            </div>
          )}

          <div className="disabled-div" id="8">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              7. Imagine your resume 5 years from now. What might you see
              appearing on that "future" resume? What are some skills or
              achievements you want to add to your resume? Start each bullet
              with a strong verb.
            </p>
            <Freeformbox q_number={8} default_len={4} />
          </div>

          {/* ---------------------------------------------------------I would------------------------------------------------------------------ */}
          <div className="disabled-div" id="9">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              8. If someone were to ask you to do a project today, and you could
              choose what that project would be, what would you choose?
            </p>
            <Freeformbox q_number={9} default_len={4} prefix={"I would"} />
          </div>

          <div className="disabled-div" id="10">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              9. What types of experiences, activities, tasks, or projects do
              you quickly jump to say yes to?
            </p>
            <Freeformbox q_number={10} default_len={3} />
          </div>

          <div className="disabled-div" id="11">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              10. List and describe more activities that you love doing in a
              brief phrase. These can be projects within your job or outside of
              your job. Make the phrases as specific as you can. Example:
              planning events, connecting individuals, presenting new ideas,
              analyzing large data sets, researching new topics deeply and
              presenting learnings, drawing logos, improving process efficiency,
              training individuals, etc.
            </p>
            <Freeformbox q_number={11} default_len={4} />
          </div>

          <div className="disabled-div" id="12">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              11. If you had to go choose how to spend an hour of your time
              right now, what would you choose to do?
            </p>
            <Freeformbox q_number={12} default_len={1} />
          </div>

          <div className="disabled-div" id="13">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              12. If you were to walk into a company today without a specific
              role determined, what should they ask you to do?
            </p>
            <Freeformbox q_number={13} default_len={1} />
          </div>

          {/*------------------------------------- I WAS  --------------------------------------------------------------------------------------------*/}
          <div className="disabled-div" id="14">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              13. Think about an experience where you had had a positive impact
              on other people. Explain what you were doing in this experience.
            </p>
            <Freeformbox q_number={14} default_len={1} />
          </div>

          {/*------------------------------------- I would want to improve  --------------------------------------------------------------------------------------------*/}
          <div className="disabled-div" id="15">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              14. Imagine you have to solve a problem or improve a system. What
              kind of problem or system would you want to improve?
            </p>
            <Freeformbox q_number={15} default_len={1} />
          </div>

          {/*------------------------------------- I would --------------------------------------------------------------------------------------------*/}
          <div className="disabled-div" id="16">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              15. If you had to create or build something new, what would you
              create or build?
            </p>
            <Freeformbox q_number={16} default_len={4} />
          </div>

          {/*------------------------------------- My side hustle is --------------------------------------------------------------------------------------------*/}
          <div className="disabled-div" id="17">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              16. Do you have a side hustle? If so, tell us about it.
            </p>
            <Freeformbox q_number={17} default_len={1} />
          </div>

          {/* ------------------------------------------------ create --------------------------------------------------------------- */}
          <div className="disabled-div" id="18">
            <p style={{ fontSize: "17px" }}>
              17. Fill in the following sentence: "People often say I should be
              a
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                name="18a"
                // style={{display: 'inline-block', verticalAlign: 'middle'}}
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginTop: "-4px",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  // bordered={false}
                ></TextArea>
              </Form.Item>
              because I have a knack for
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                name="18b"
                //  style={{display: 'inline-block', verticalAlign: 'middle'}}
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginTop: "-4px",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              >
                {/* <Input bordered={false} placeholder='personal or professional skill'></Input> */}
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  // bordered={false}
                ></TextArea>
              </Form.Item>
            </p>
          </div>

          {/* -------------------------------------------------- I was ----------------------------------------------------------------------- */}

          <div className="disabled-div" id="19">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              18. Have you ever noticed moments where you lose track of time?
              Tell me about what you were doing in those moments.
            </p>
            <Freeformbox q_number={19} default_len={1} />
          </div>

          <div className="disabled-div" id="20">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              19. What activities, tasks, or projects energize you?
            </p>
            <Freeformbox q_number={20} default_len={3} />
          </div>

          <div className="disabled-div" id="21">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              20. Which of these roles do you mostly enjoy playing: the
              innovator, the manager, or the technician (doer)?
            </p>
            <Row gutter={36}>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="21"
                >
                  <Select
                    size="large"
                    // style={{ width: "20%" }}
                    placeholder="Choose Role"
                    onChange={this.onChange_20}
                  >
                    <Option id="1" value={"The Innovator"}>
                      The Innovator
                    </Option>
                    <Option id="2" value={"The Manager"}>
                      The Manager
                    </Option>
                    <Option id="3" value={"The Technician"}>
                      The Technician
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
              </Col>
              <Col span={8}>
              </Col>
            </Row>
          </div>

          {/* -----------------------added ------------------------------------------------ */}

          <div className="disabled-div" id="21a">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              21. You like to be {this.state.q_20 ? this.state.q_20 : "_____"}.
              What do you like to{" "}
              {this.state.q_20 ? Q_20[this.state.q_20] : "_____"}?
            </p>
            <Row gutter={36}>
              <Col span={16}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="21a"
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>

          {/* ------------------------------------------------------ I admire the career of ------------------------------------------------------------*/}

          <div className="disabled-div" id="22">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              22. Is there anyone's career that you admire, or maybe even are
              jealous of?
            </p>
            {/* MADE CHANGE HERE IN NO. OF ROWS, SEE IF ANYTHING BREAKS */}
            <Freeformbox q_number={22} default_len={3} />
          </div>

          {/* -----------------------added , goes in TR------------------------------------------------ */}

          <div className="disabled-div" id="21b">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              {/* 22 a.  */}
              22.a What do you admire about their careers?
            </p>
            {/* MADE CHANGE HERE IN NO. OF ROWS, SEE IF ANYTHING BREAKS */}
            <Row gutter={36}>
              <Col span={16}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="21b"
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>

          <div className="disabled-div" id="23">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              23. If you were the protagonist of a movie, what would be the
              overarching theme of the story?
            </p>

            <div className="cont-23">
            <Row gutter={36}>
              <Col span={16}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="23"
                >
                  <TextArea
                    className="text-23"
                    autoSize={{ minRows: 2, maxRows: 60 }}
                  >
                    {" "}
                  </TextArea>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            </div>
          </div>

          <div className="disabled-div" id="24">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              24. If you think about your work and project experiences thus far
              in your life, is there a thread that connects them? Describe the
              thread.
            </p>

            <div className="cont-24">
            <Row gutter={36}>
              <Col span={16}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="24"
                >
                  <TextArea
                    className="text-24"
                    autoSize={{ minRows: 2, maxRows: 60 }}
                  >
                    {" "}
                  </TextArea>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            </div>
          </div>

          <div className="disabled-div" id="25">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              25. If you had to play a role on a project, what is your most
              natural area of contribution?{" "}
            </p>

            <div>
              <Row gutter={36}>
                <Col span={16}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="25"
                  >
                    <TextArea autoSize={{ minRows: 2, maxRows: 60 }}> </TextArea>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
              </Row>
            </div>
          </div>
          {/* ------------------------------------------------------ multiselect here----------------------------------------------------------- */}
          <div className="disabled-div" id="26">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              26. Imagine your workplace has a process or product, would you
              rather:{" "}
            </p>
            <Row gutter={36}>
                <Col span={8}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="26"
                  >
                    <Select
                      mode="tags"
                      showArrow
                      tagRender={tagRender}
                      placeholder=""
                      style={{ width: "100%", margin: "6px" }}
                      size="large"
                    >
                      {/* {[...OPTIONS]} */}
                      {OPTIONS.map((item, index) => (
                        <Option key={"inc" + index + 100} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
          </div>

          <div className="disabled-div" id="27">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              27. Consider your natural workstyle and then consider some of the
              problems you mentioned you would like to solve. Can you think of
              any job roles that align with your workstyle and also would allow
              you to solve the problems you care about?
            </p>
            <Freeformbox q_number={27} default_len={1} />
          </div>
          {/* ------------------------------------------------------------ I was influenced by---------------------------------------------- */}
          <div className="disabled-div" id="28">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              28. Think back to your childhood. Was there either a one-time
              experience or an ongoing influence that affected your sense of
              motivation or drive (whether consciously or subconsciously)?
            </p>

            <div className="cont-27">
              <Row gutter={36}>
                <Col span={16}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="28"
                  >
                    <TextArea
                      className="text-27"
                      autoSize={{ minRows: 2, maxRows: 60 }}
                    >
                      {" "}
                    </TextArea>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
              </Row>
            </div>
          </div>

          <div className="disabled-div" id="29">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              29. If so, how did that experience affect what you want to
              contribute back to the world today?
            </p>

            <div className="cont-28">
              <Row gutter={36}>
                <Col span={16}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="29"
                  >
                    <TextArea
                      className="text-28"
                      autoSize={{ minRows: 2, maxRows: 60 }}
                    >
                      {" "}
                    </TextArea>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
              </Row>
            </div>
          </div>

          <div className="disabled-div" id="30">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              30. List some former job roles you have held.
            </p>
            <Freeformbox q_number={30} default_len={3} />
          </div>
          {/* ------------------------------------------------ I loved--------------------------------------- */}
          <div className="disabled-div" id="31">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              31. List any responsiblities that you particularly loved from past
              roles.
            </p>
            <Freeformbox q_number={31} default_len={3} />
          </div>
          {/* -----------------------------------------------------I disliked ----------------------------------------------------------------- */}
          <div className="disabled-div" id="32">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              32. List any responsiblities that you particularly disliked from
              past roles.
            </p>
            <Freeformbox q_number={32} default_len={3} />
          </div>
          {/* -----------------------------------------------------Whats missing is ----------------------------------------------------------------- */}

          <div className="disabled-div" id="33">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              33. What has been missing from your current or prior roles?
            </p>
            <Freeformbox q_number={33} default_len={3} />
          </div>

          {/* --------------------added ----------------------------------- */}

          <div className="disabled-div" id="33a">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              {/* 33 a.  */}
              33.a Imagine you are in a world without any of your current
              friends or family. No one knows you. You can have any job or dive
              into any type of work without any prerequisite skills or
              interviews required. You are allowed to choose any job based on
              intended enjoyment alone. What type of work would you choose to
              dive into? Picture it. What do you see yourself doing?
            </p>
            {/* MADE CHANGE HERE IN NO. OF ROWS, SEE IF ANYTHING BREAKS */}
            <Row gutter={36}>
              <Col span={16}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="33a"
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>

          {/* --------------------added ----------------------------------- */}

          <div className="disabled-div" id="33b">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              {/* 33 b.  */}
              33.b Do you see yourself in a role that where you can mostly:
              Serve or support internal employees within the organization or
              Serve or support external-facing clients/stakeholders?
            </p>
            {/* MADE CHANGE HERE IN NO. OF ROWS, SEE IF ANYTHING BREAKS */}
            <Row gutter={36}>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true, message: "Required Field" }]}
                  name="33b"
                >
                  <Select size="large" 
                    // style={{ width: "50%" }}
                  >
                    <Option
                      id="1dsa"
                      value={
                        "Serve or support internal employees within the organization"
                      }
                    >
                      Serve or support internal employees within the organization
                    </Option>
                    <Option
                      id="2ca"
                      value={
                        "Serve or support external-facing clients/stakeholders"
                      }
                    >
                      Serve or support external-facing clients/stakeholders
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
          </div>

          <div className="disabled-div" id="33c">
            <p style={{ paddingTop: "14px", paddingBottom: "20px", fontSize: "17px" }}>
              33.c Do you prefer to:
            </p>
            <Row gutter={36}>
                <Col span={8}>
                  <Form.Item
                    rules={[{ required: true, message: "Required Field" }]}
                    name="33c"
                  >
                    <Select size="large" 
                      // style={{ width: "20%" }}
                    >
                      <Option id="1eer" value={"Fix"}>
                        Fix
                      </Option>
                      <Option id="2caa" value={"Understand"}>
                        Understand
                      </Option>
                      <Option id="3tyt" value={"Build/Create"}>
                        Build/Create
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
          </div>
          <Row>
            <Col span={6}>
              {/* <Button onClick={this.props.prev} size='large' block>Back</Button> */}
            </Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <Form.Item name="submit-b">
                <button htmlType="submit" size="large" type="primary" block className="button-main" style={{
                  width:"100%"
                }}>
                  Next
                </button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}