import { PlusOutlined } from "@ant-design/icons";
import { Card, Tag } from "antd";
import React from "react";
import "./Learning.css";
// import Learningcard from './Learningcard'

export default class Categorycomp extends React.Component {
  render() {
    // console.log(this.props.categorized_learning)

    return (
      <Card.Grid hoverable={false} className="col-width">
        <div style={{ height: "200px", }} className="custom-card-content">
          <p className="col-headerA">{this.props.heading}</p>
          <p style={{ fontSize: 16 }} className="col-headerA">
            {this.props.subhead}
          </p>
          <p className="col-subhead">{this.props.subheading}</p>
        </div>
        <Card.Grid
          className="add-new-btn"
          onClick={() => {
            this.props.openModal(this.props.category);
          }}
        >
          <PlusOutlined className="plus-icon" />
        </Card.Grid>
        {this.props.categorized_learning.map((item) => (
          <Card.Grid
            className="learning-card"
            onClick={() => {
              this.props.openModalWithData(this.props.category, item);
            }}
            style={
              item.interest_level < 4
                ? { borderBottom: "3px solid #199094" }
                : item.interest_level < 7
                ? { borderBottom: "3px solid #199094" }
                : { borderBottom: "3px solid #199094" }
            }
          >
            <p className="learning-content">{item.name}</p>
            {item.roles.length ? (
              <div>
                <p className="learning-content-label">ROLE</p>
                <div className="role-list">
                  <Tag
                    color="#8375E8  
                    // 1./"
                    className="role-tag"
                  >
                    {item.roles[0]}
                  </Tag>{" "}
                  {item.roles.length - 1
                    ? "+" + (item.roles.length - 1).toString()
                    : ""}
                </div>
              </div>
            ) : (
              ""
            )}
            {item.industries.length ? (
              <div>
                <p className="learning-content-label">INDUSTRY</p>
                <div className="role-list">
                  <Tag color="#FFA86B" className="ind-tag">
                    {item.industries[0]}
                  </Tag>{" "}
                  {item.industries.length - 1
                    ? "+" + (item.industries.length - 1).toString()
                    : ""}
                </div>
              </div>
            ) : (
              ""
            )}
            {item.interest_level ? (
              <div>
                <p className="learning-content-label">INTEREST LEVEL</p>
                <p
                  className="interest-level-text"
                  style={
                    item.interest_level < 3
                      ? { color: "#199094" }
                      : item.interest_level < 6
                      ? { color: "#199094" }
                      : { color: "#199094" }
                  }
                >
                  {item.interest_level < 4
                    ? "Low"
                    : item.interest_level < 7
                    ? "Medium"
                    : "High"}
                </p>
              </div>
            ) : (
              ""
            )}
          </Card.Grid>
        ))}
      </Card.Grid>
    );
  }
}
