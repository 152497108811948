import React from "react";
import { Badge } from "antd";
import "antd/dist/antd.css";
import Cards from "../../Staticcontent/Staticcontentforcards";

const style = { paddingBottom: "10px" };

export default class TargetrolesC2 extends React.Component {
  render() {
    console.log(this.props);
    return (
      <div>
        <p
          style={{
            font: "normal normal 600 20px/12px AvenirBook",
            color: "#414141",
            padding: "40px 0",
          }}
        >
          How to achieve this step?
        </p>
        {Cards[this.props.stepname].card2Content.map((val, index) => {
          return (
            <div style={{ display: "flex" }}>
              <Badge color="#199094" />
              <p style={style}>
                {Cards[this.props.stepname].card2Content[index].mainPoint}
                {Cards[this.props.stepname].card2Content[index].subPoint
                  ? Cards[this.props.stepname].card2Content[index].subPoint.map(
                      (val, index) => {
                        return (
                          <div style={{ display: "flex", marginTop: 10 }}>
                            <Badge color="#199094" />
                            <p>{val}</p>
                          </div>
                        );
                      }
                    )
                  : null}
              </p>
            </div>
          );
        })}
        {/* <div style={{display:'flex'}}>
                    <Badge color="#ACE3E6"/><p style={style}>Create a comprehensive list of roles based on your interests.</p>
                </div>
                <div style={{display:'flex'}}>
                    <Badge color="#ACE3E6"/><p style={style}>In order to create that list, use your knowledge of the world and roles with which you are already familiar. Pick roles based on your current understanding. </p>
                </div>
                <div style={{display:'flex'}}>
                    <Badge color="#ACE3E6"/><p style={style}>There is no limit. Pick however many roles seem relevant to each one of your interests.</p>
                </div>
                <div style={{display:'flex'}}>
                    <Badge color="#ACE3E6"/><p style={style}>After you have a complete list, you'll narrow it down into a shortlist of options you want to learn more about moving forward.</p>
                </div>
                <div style={{display:'flex'}}>
                    <Badge color="#ACE3E6"/><p style={style}>Finally, prioritize your shortlist of roles.  </p>
                </div>
                <div style={{display:'flex'}}>
                    <Badge color="#ACE3E6"/><p style={{paddingBottom:0}}>Try not to think ahead. Focus on the task at hand. We're not making any hard decisions just yet. This process may very well lead you to new outcomes and insights that aren't on your oiginal list. Your list is just a starting point in a process that will become more fluid as you learn more. Trust the process and see where it takes you!
                    </p>
                </div>   */}
      </div>
    );
  }
}
