const TIMELINE_AREAS = {
  EXPLORATION: 0,
  JOB_SEARCH: 1,
  REMAINING: 2,
};

const getWidth = (area, commitment) => {
  let width = 25;

  if (commitment > 0) {
    switch (area) {
      case TIMELINE_AREAS.EXPLORATION:
        width =
          commitment <= 5
            ? 31
            : commitment <= 10
            ? 22.222222
            : commitment <= 20
            ? 11.111111
            : 8.333333;
        break;
      case TIMELINE_AREAS.JOB_SEARCH:
        width = commitment <= 20 ? 44 : 33.333333;
        break;
      case TIMELINE_AREAS.REMAINING:
        width =
          100 -
          getWidth(TIMELINE_AREAS.EXPLORATION, commitment) -
          getWidth(TIMELINE_AREAS.JOB_SEARCH, commitment);
        break;
      default:
        break;
    }
  }

  return width;
};

function getColSpan() {}

//will use object to get months using modulo
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getMonths = (joinedOn) => {
  let m = [];
  let joined = joinedOn.month() + 1;
  if (joined < 2) {
    m = [months[months.length - 1], ...months.slice(joined - 1, joined + 6)];
  } else if (joined < 6) {
    m = months.slice(joined - 2, joined + 7);
  } else {
    m = months.slice(joined - 2);
    m = [...m, ...months.slice(0, 9 - m.length)];
  }

  return m;
};

const getMonthsWithYears = (date) => {
  let result = [];
  const currentMonth = date.month() + 1;
  const currentYear = date.year();
  
  const formatMonthYear = (month, year) => {
    return {month: months[(month + 12) % 12], year: year};
  };

  let startMonth = currentMonth - 1;
  let startYear = currentYear;
  
  if (startMonth <= 0) {
    startMonth += 12;
    startYear--;
  }

  for (let i = 0; i < 12; i++) {
    let month = startMonth + i;
    let year = startYear;
    
    if (month > 12) {
      month -= 12;
      year++;
    }
    
    result.push(formatMonthYear(month - 1, year));
  }

  return result;
};

const monthsBeforeToday = (month, joinedOn) => {
  return getMonths(joinedOn).indexOf(months[month]);
};

export { TIMELINE_AREAS, getWidth, getColSpan, getMonths, getMonthsWithYears, monthsBeforeToday };


// const TIMELINE_AREAS = {
//   EXPLORATION: 0,
//   JOB_SEARCH: 1,
//   REMAINING: 2,
// };

// const getWidth = (area, commitment) => {
//   let width = 25;

//   if (commitment > 0) {
//     switch (area) {
//       case TIMELINE_AREAS.EXPLORATION:
//         width =
//           commitment <= 5
//             ? 31
//             : commitment <= 10
//             ? 22.222222
//             : commitment <= 20
//             ? 11.111111
//             : 8.333333;
//         break;
//       case TIMELINE_AREAS.JOB_SEARCH:
//         width = commitment <= 20 ? 44 : 33.333333;
//         break;
//       case TIMELINE_AREAS.REMAINING:
//         width =
//           100 -
//           getWidth(TIMELINE_AREAS.EXPLORATION, commitment) -
//           getWidth(TIMELINE_AREAS.JOB_SEARCH, commitment);
//         break;
//       default:
//         break;
//     }
//   }

//   return width;
// };

// function getColSpan() {}

// // Months array for reference
// const months = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];

// // Returns months only
// const getMonths = (joinedOn) => {
//   let m = [];
//   let joined = joinedOn.month() + 1;

//   if (joined < 2) {
//     m = [months[months.length - 1], ...months.slice(joined - 1, joined + 6)];
//   } else if (joined < 6) {
//     m = months.slice(joined - 2, joined + 7);
//   } else {
//     m = months.slice(joined - 2);
//     m = [...m, ...months.slice(0, 9 - m.length)];
//   }

//   return m;
// };

// const getMonthsWithYears = (joinedOn) => {
//   const joinedMonth = joinedOn.month(); // 0-based index for months
//   const joinedYear = joinedOn.year();

//   let timeline = [];
//   let currentYear = joinedYear;

//   // Loop through 9 months starting from the joined month
//   for (let i = -1; i < 8; i++) {
//     let monthIndex = joinedMonth + i;

//     // Adjust for negative or overflowing month indexes
//     if (monthIndex < 0) {
//       monthIndex += 12;
//       currentYear -= 1; // If we wrap back to previous year
//     } else if (monthIndex >= 12) {
//       monthIndex -= 12;
//       currentYear += 1; // If we wrap forward to next year
//     }

//     timeline.push(`${months[monthIndex]} ${currentYear}`);
//   }

//   return timeline;
// };


// const monthsBeforeToday = (month, joinedOn) => {
//   return getMonths(joinedOn).indexOf(months[month]);
// };

// export { TIMELINE_AREAS, getWidth, getColSpan, getMonths, getMonthsWithYears, monthsBeforeToday };
