import React from "react";
import { Badge } from "antd";
import "antd/dist/antd.css";
import Cards from "../../Staticcontent/Staticcontentforcards";

const style = { paddingBottom: "24px" };

export default class TargetrolesC1 extends React.Component {
  render() {
    console.log(this.props);
    return (
      <div>
        <p
          style={{
            font: "normal normal 600 20px/12px AvenirBook",
            color: "#414141",
            padding: "40px 0",
          }}
        >
          More resources
        </p>

        <div>
          {Cards[this.props.stepname].card3Content.map((val, index) => {
            return (
              <div style={style}>
                <Badge color="#199094" />
                <a target="_blank" rel="noopener noreferrer" href={val.link}>
                  {val.text}
                </a>
              </div>
            );
          })}
          {/* <div style={style}>
                        <Badge color="#ACE3E6"/><a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/11huY322rJiMr0wEPPfLzCTncPoIiNcsc/view?usp=sharing" >Book Chapters 8-9 </a>
                    </div>
                    <div style={style}>
                        <Badge color="#ACE3E6"/><a target="_blank" rel="noopener noreferrer" href="https://youtu.be/kZnX45UzDoo" >Video: What is a job? </a>
                    </div>
                    <div style={style}>
                        <Badge color="#ACE3E6"/><a target="_blank" rel="noopener noreferrer" href="https://youtu.be/rXLBqjmxhco" >Video: Translate your interests into options </a>
                    </div>
                    <div style={style}>
                        <Badge color="#ACE3E6"/><a target="_blank" rel="noopener noreferrer" href="https://blog.accepted.com/wake-up-to-your-amazing-career-possibilities-episode-354/" >Podcast </a>
                    </div>
                    <div style={style}>
                        <Badge color="#ACE3E6"/><a target="_blank" rel="noopener noreferrer" href="https://medium.com/@getwoken/tell-me-what-you-want-what-you-really-really-want-426ef5ecfa03" >Blog</a>
                    </div> */}
        </div>
      </div>
    );
  }
}
