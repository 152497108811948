import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { Card, Col, Row, Steps } from "antd";
import React from "react";
import Stepinfo from "../Modals/Stepinfo";
import Scheduler from "./Scheduler";
// const { Step } = Steps;

export class CalendarBase extends React.Component {
  state = {
    collapsed: false, //menu collapse
    showInfo: null, //show/hide infobox
  };

  //close button handling
  onCloseClick = () => {
    this.setState({
      setTarget: false,
    });
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.props.toggle();
  };

  changeRoute = () => {
    this.props.history.push("/dashboard");
  };

  showInfo = () => this.state.showInfo === true;

  render() {
    // console.log('state', this.state)
    // {console.log('time', this.state.curr_comp_target_date[this.state.current])}

    return (
      <div style={{ padding: "4% 0" }}>
        <div className="collapse-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? (
            <RightCircleFilled className="make-white" />
          ) : (
            <LeftCircleFilled className="make-white" />
          )}
        </div>

        <Card
          bordered={false}
          style={{
            margin: "20px !important",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "13px",
            textAlign: "left",
            marginRight: "6%",
            marginLeft: "20px",
            padding: "0 2%",
          }}
        >
          <div>
            <Row style={{ padding: "4% 0% 3% 0%" }}>
              <Col
                span={18}
                style={{
                  font: "normal normal 300 30px/12px AvenirBook",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Calendar
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  marginTop: "-20px",
                }}
              >
                <a
                  href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                  target="_blank"
                  style={{
                    marginRight: "10px",
                   
                      cursor:"pointer"
                   
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="mail_2_" data-name="mail (2)" transform="translate(-0.001)">
                      <g id="Group_2502" data-name="Group 2502" transform="translate(0.001)">
                        <path id="Path_1376" data-name="Path 1376" d="M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm0,4.917,8.285,5.166H5.716Zm8.388,13.416h0a1.5,1.5,0,0,1-1.5,1.5H7.114a1.5,1.5,0,0,1-1.5-1.5V10.412a1.492,1.492,0,0,1,.024-.257l7.915,4.936.03.016.032.017a.831.831,0,0,0,.172.067l.018,0a.854.854,0,0,0,.2.025h0a.839.839,0,0,0,.2-.025l.018,0a.839.839,0,0,0,.172-.067l.032-.017.03-.016,7.915-4.936a1.49,1.49,0,0,1,.024.257v7.921Z" transform="translate(-0.001)" fill="#199094"/>
                      </g>
                    </g>
                  </svg>
                </a>
                {/* <span
                  onClick={() =>
                    this.setState({
                      showInfo: true,
                    })
                  }
                >
                  <img
                    style={{ cursor: "pointer", marginTop: "5px" }}
                    src="https://django-woken-static.s3.amazonaws.com/static/media/question.74f30bbb.svg"
                  />
                </span> */}
              </Col>
            </Row>
            <p
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #E5F4F8",
              }}
            ></p>
          </div>

          {this.showInfo() ? (
            <Stepinfo
              stepname="Assessment"
              closeInfo={() =>
                this.setState({
                  showInfo: false,
                })
              }
            />
          ) : (
            ""
          )}
          <Scheduler />
        </Card>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   stepInfo: selectMaterialsStepInfo(state),
// });

// const mapDispatchToProps = () => ({
//   setStepNotifiedAsync,
// });

// export default connect(mapStateToProps, mapDispatchToProps())(CalendarBase);