import { DownloadOutlined } from "@ant-design/icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button, Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { selectProfile, updateQuickReflection } from "slices/dashboardSlice";
import HELPERS from "utils/helper";
import AUTH_API from "../../../api/auth";
import "./CoverLetter.css";
import EditableCL from "./EditableCL";
import Test from "./Test";

const { TextArea } = Input;

class GeneratedCL extends React.Component {
  bodyRef = React.createRef();
  state = {
    submitted: false,
    Api_response: {},
    inp: "",
    editable: false,
    loading: true,
    saved: false,
  };
  componentDidMount() {
    AUTH_API.getMaterials()
      .then((response) => {
        console.log("get Api data", response);
        const form_values =
          response.data.length > 0 ? response.data[0].cover_letter : "";
        this.setState({
          Api_response: response.data.length > 0 ? response.data[0] : {},
          loading: false,
        });
      })
      .catch((err) => console.log("cover letter get Api error!", err));
  }

  onSubmit = () => {
    this.setState({ ...this.state, submitted: true });
  };
  render() {
    console.log(
      "generated CL rendered",
      this.state.Api_response,
      "props from Redux",
      this.props
    );
    return (
      <>
        {this.state.loading ? (
          <p style={{ textAlign: "center", fontSize: "14px" }}>
            ..... Generating Cover Letter{" "}
          </p>
        ) : (
          <div style={{ marginTop: "47px", }}>
            <div className="cl-g-head">
              <div>
                <span>
                  {this.props.profile
                    ? this.props.profile.first_name + "'s"
                    : "User's"}{" "}
                </span>
                <span style={{ color: "#199094" }}> Cover Letter</span>
              </div>
              <PDFDownloadLink
                document={
                  <Test
                    name={
                      this.props.profile
                        ? this.props.profile.first_name +
                          " " +
                          this.props.profile.last_name
                        : "User"
                    }
                    obj={this.state.Api_response.cover_letter}
                  />
                }
                fileName="Cover_letter.pdf"
                className="download-cl"
              >
                <DownloadOutlined
                  style={{ color: "#199094", fontSize: 20, padding: "0 5px" }}
                />
                Download Doc
              </PDFDownloadLink>
            </div>

            {this.state.editable ? (
              <div className="cl-pdf-wrap">
                <EditableCL
                  f_name={
                    this.props.profile ? this.props.profile.first_name : "User"
                  }
                  name={
                    this.props.profile
                      ? this.props.profile.first_name +
                        " " +
                        this.props.profile.last_name
                      : "User"
                  }
                  obj={this.state.Api_response.cover_letter}
                  materials_id={this.state.Api_response.id}
                />
              </div>
            ) : (
              <div className="cl-pdf-wrap">
                <div className="pdf-body" ref={this.bodyRef}>
                  <Test
                    name={
                      this.props.profile
                        ? this.props.profile.first_name +
                          " " +
                          this.props.profile.last_name
                        : "User"
                    }
                    obj={this.state.Api_response.cover_letter}
                  />
                </div>
              </div>
            )}
            {!this.state.editable ? (
              <div style={{ marginTop: "20px" }}>
                <a
                  onClick={() => this.setState({ editable: true })}
                  className="button-secondary"
                  style={{ paddingLeft: "20px", paddingRight:"20px", float: "left" }}
                >
                  Edit
                </a>
                <button
                className="button-main"
                  type="primary"
                  onClick={() => {
                    HELPERS.log("calling updateQuickReflection", {
                      stepId: this.props.stepId,
                      show: true,
                    });
                    this.props.updateQuickReflection({
                      stepId: this.props.stepId,
                      show: true,
                    });
                  }}
                  style={{ float: "right" ,paddingLeft:"20px", paddingRight:"20px"}}
                >
                  Submit to Coach
                </button>
              </div>
            ) : (
              <div style={{ marginTop: "20px" }}>
                <button
                className="button-main"
                  type="primary"
                  onClick={() =>
                    this.setState({ editable: false, saved: true })
                  }
                  style={{ float: "right",paddingLeft:"20px", paddingRight:"20px" }}
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: selectProfile(state),
});

const mapDispatchToProps = () => ({
  updateQuickReflection,
});

export default connect(mapStateToProps, mapDispatchToProps())(GeneratedCL);
