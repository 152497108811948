import { Button, Col, Row } from "antd";
import React from "react";
import AUTH_API from "./../../../api/auth";
import Contactmodalparent from "./Contactmodalparent";
import Contacttableparent from "./Contacttableparent";
import * as CONTENT from "./Infocontent";

export default class Contacts extends React.Component {
  formRef = React.createRef();

  state = {
    loading: true,
    modal_visible: false,
    selected_contact: {
      category: "",
      id: "",
    },
    contacts: {
      fr: [
        {
          name: "F Contact Name1",
          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
        {
          name: "F Contact Name2",

          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
        {
          name: "F Contact Name3",

          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
        {
          name: "F Contact Name4",
          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
        {
          name: "F Contact Name5",

          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
        {
          name: "F Contact Name6",

          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
      ],

      aq: [
        {
          name: "A Contact Name",

          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
        {
          name: "A Contact Name",

          company: "",
          email: "",
          linkedin: "",
          roles: "",
          industries: "",
        },
      ],
      pc: [],
      so: [],
      al: [],
      tr: [],
      ti: [],
      tc: [],
      rc: [],
      cc: [],
    },
  };

  category_mapping = {
    Friends: "fr",
    Acquaintances: "aq",
    "Past Colleagues": "pc",
    "Social Organizations": "so",
    Alumni: "al",
    "Target Roles": "tr",
    "Target Industries": "ti",
    "Target Companies": "tc",
    Recruiters: "rc",
    "Cold Connections": "cc",
  };

  getContacts = () => {
    AUTH_API.getAllContacts()
      .then((response) => {
        console.log("tcontacts res!!", response);
        const all_contacts = [...response.data];
        let state_data = {
          fr: [],
          aq: [],
          pc: [],
          so: [],
          al: [],
          tr: [],
          ti: [],
          tc: [],
          rc: [],
          cc: [],
        };
        all_contacts.sort((a, b) => {
          return a.id - b.id;
        });
        all_contacts.forEach((contact) => {
          state_data[contact.contact_type].push({
            name: contact.title,
            company: contact.company_name,
            email: contact.email,
            linkedin: contact.linkedin,
            roles: contact.roles,
            industries: contact.industries,
            id: contact.id,
          });
        });

        // console.log('mapped contacts', state_data);
        this.setState({
          contacts: state_data,
          loading: false,
          modal_visible: false,
          selected_contact: {
            category: "",
            id: "",
          },
        });
      })
      .catch((err) => console.log("no contacts found!", err));
  };

  componentDidMount() {
    this.getContacts();
  }

  handleCancel = () => {
    // console.log('cancel');
    this.setState({
      modal_visible: false,
      selected_contact: {
        category: "",
        id: "",
      },
    });
  };

  showModal = (event, category, contact_id) => {
    // console.log("btn", event.target.id, category);
    // console.log('curr contact', this.state.contacts)
    var contact = event.target.id === undefined ? contact_id : event.target.id 
    this.setState({
      modal_visible: true,
      selected_contact: { category: category, id: contact_id },
    });
  };

  handleDelete = (id) => {
    // console.log('handle del',this.state.selected_contact);
    const selected_cat = this.state.selected_contact.category;
    const category_contacts = [...this.state.contacts[selected_cat]];
    const all_contacts = { ...this.state.contacts };
    all_contacts[
      this.state.selected_contact.category
    ] = category_contacts.filter(
      (item) =>
        category_contacts.indexOf(item) !==
        parseInt(this.state.selected_contact.id)
    );
    // const modified ={...this.state.contacts, selected_cat: category_contacts.filter(item => category_contacts.indexOf(item) !== parseInt(current))}
    // console.log('modified', all_contacts);
    // console.log('after deletion ', category_contacts);
    // all_contacts.filter(item => all_contacts.indexOf(item) !== current);
    // console.log('after delete', category_contacts.filter(item => category_contacts.indexOf(item) !== parseInt(this.state.selected_contact.id)));
    if (id) {
      AUTH_API.deleteContact(id)
        .then((response) => console.log(response))
        .catch((err) => console.log(err));
    }

    this.setState({
      modal_visible: false,
      contacts: all_contacts,
      selected_contact: {
        category: "",
        id: "",
      },
    });
  };

  onSubmit = (values, id) => {
    // console.log("submit", this.state.selected_contact, 'form values', values, 'id', id);
    const selected_cat = this.category_mapping[values.category];
    // const selected_id = this.state.selected_contact.id;
    // console.log('seleted id', selected_id)
    // const category_contacts = [...this.state.contacts[selected_cat]];
    // console.log('category contacts', category_contacts)
    // const all_contacts = { ...this.state.contacts };
    // delete values['category'];
    if (id) {
      //patch
      console.log("patch hoga");
      const data = {
        title: values.name,
        company_name: values.company,
        email: values.email,
        linkedin: values.linkedin,
        roles: values.roles,
        industries: values.industries,
        contact_type: this.category_mapping[values.category],
      };
      // console.log('patch data', data)
      AUTH_API.patchContact(data, id)
        .then((response) => {
          console.log("patch res", response);
          this.getContacts();
        })
        .catch((err) => {
          console.log("not patched", err);
        });
    } else {
      console.log("post hoga");

      const data = {
        title: values.name ? values.name : "",
        country_code: "",
        phone: "",
        company_name: values.company ? values.company.toString() : "",
        email: values.email ? values.email : "",
        linkedin: values.linkedin ? values.linkedin.toString() : "",
        roles: values.roles ? values.roles : [],
        industries: values.industries ? values.industries : [],
        contact_type: this.category_mapping[values.category],
        selected: false,
        tracker_info: [],
        call_prep: [],
        notes: "",
        reflections: [],
      };

      // console.log('post data', data)
      AUTH_API.setNewContact(data, "CO")
        .then((response) => {
          console.log("post res", response);
          this.getContacts();
        })
        .catch((err) => {
          console.log("not posted", err);
        });
    }
  };

  addNew = (cat) => {
    if (this.state.contacts[cat].length >= 4) {
      // console.log('>4 so add new');
      this.setState({
        modal_visible: true,
        selected_contact: { category: cat, id: "" },
      });
    } else {
      const target_index = this.state.contacts[cat].length;
      // console.log("add buttn", target_index, cat);
      this.setState({
        modal_visible: true,
        selected_contact: { category: cat, id: target_index },
      });
    }
  };

  render() {
    // console.log('contacts list rendered', this.state.contacts)
    const in_modal_contacts = { ...this.state.contacts };
    // console.log('in the modal',
    // in_modal_contact_cat? in_modal_contact_cat[ind]:'undefined rn', typeof this.state.contacts[this.state.selected_contact.category] );
    return (
      <>
        {!this.state.loading && (
          <div>
            {this.state.modal_visible && (
              <Contactmodalparent
                visible={this.state.modal_visible}
                handleCancel={this.handleCancel}
                onSubmit={this.onSubmit}
                category_mapping={this.category_mapping}
                handleDelete={this.handleDelete}
                current_data={
                  this.state.selected_contact.id === ""
                    ? ""
                    : this.state.contacts[this.state.selected_contact.category][
                        parseInt(this.state.selected_contact.id)
                      ]
                }
                current_category={Object.keys(this.category_mapping).find(
                  (key) =>
                    this.category_mapping[key] ===
                    this.state.selected_contact.category
                )}
              />
            )}

            <p className="contacts-head">
              Each table below represents the different categories of people
              that make up your network. Brainstorm a few contacts in each
              category and don’t worry about filling in email or LinkedIn
              information.
            </p>
            <Row gutter={64}>
              <Col span={12}>
                <Contacttableparent
                  category={"Family & Friends"}
                  categorized_contacts={this.state.contacts.fr}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT1}
                />
                <br />
                <Contacttableparent
                  category={"Past Colleagues"}
                  categorized_contacts={this.state.contacts.pc}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT3}
                />
                <br />
                <Contacttableparent
                  category={"Alumni"}
                  categorized_contacts={this.state.contacts.al}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT5}
                />
                <br />
                <Contacttableparent
                  category={"Target Industries"}
                  categorized_contacts={this.state.contacts.ti}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT7}
                />
                <br />
                <Contacttableparent
                  category={"Recruiters"}
                  categorized_contacts={this.state.contacts.rc}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT9}
                />
                <br />
              </Col>
              <Col span={12}>
                <Contacttableparent
                  category={"Acquaintances"}
                  categorized_contacts={this.state.contacts.aq}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT2}
                />
                <br />
                <Contacttableparent
                  category={"Social Organizations"}
                  categorized_contacts={this.state.contacts.so}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT4}
                />
                <br />
                <Contacttableparent
                  category={"Target Roles"}
                  categorized_contacts={this.state.contacts.tr}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT6}
                />
                <br />
                <Contacttableparent
                  category={"Target Companies"}
                  categorized_contacts={this.state.contacts.tc}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT8}
                />
                <br />
                <Contacttableparent
                  category={"Cold Connections"}
                  categorized_contacts={this.state.contacts.cc}
                  showModal={this.showModal}
                  addNew={this.addNew}
                  info_content={CONTENT.CONTENT10}
                />
                <br />
              </Col>
            </Row>
            <div style={{ textAlign: "end", padding: "20px 0" }}>
              <button
                type="primary"
                size="large"
                
                onClick={this.props.onSubmit}
                className="button-main"
                            style={{
                              width:"20%"
                            }}
              >
                Submit to Coach
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}