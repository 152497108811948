import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

export default function CoachHeader({ cohortInfo }) {

  

  console.log(cohortInfo,"im new cohorInfo")
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };


  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          // localStorage.removeItem("token");
          // localStorage.removeItem("isCoach");
          localStorage.clear();
          window.location.href = "/signin";
        }}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const newScrollPosition =
        scrollContainerRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);

      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      updateScrollButtons();
      container.addEventListener('scroll', updateScrollButtons);
      return () => container.removeEventListener('scroll', updateScrollButtons);
    }
  }, []);

  useEffect(() => {
    console.log("Component mounted!");
  }, []);

  // if (!cohortInfo || Object.keys(cohortInfo).length === 0) {
  //   return <div>Loading cohort information...</div>;
  // }

  return (
    <>
      <div className="coach-dashboard">
      <header className="header-coach-dashboard">
        <div className="header-inner-left">
        <Link
                        to={`/coach/dashboard/`}
                       
                      >
        <img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/logo.png" Width="123.95px" Height="32px" />
        </Link>
        <span className='user-name-header-top'>Welcome, Rachel</span>
        <Link
                        to={`/coach/dashboard/`}
                       
                      >

        <p className="coach-dashboard-text">Coach Dashboard</p>
                      </Link>
        </div>
       
         
                                <Dropdown overlay={menu} placement="bottomLeft">
                        <Button type="text" style={{ color: "white" }}>
                        <span className='right-container-user-name'>
            Rachel Serwetz
            </span>  <DownOutlined />
                        </Button>
                      </Dropdown>
       
      </header>
      </div>
      <div className="metrics-container">
        {canScrollLeft && (
          <button
            className="scroll-button scroll-left"
            onClick={() => scroll('left')}
            aria-label="Scroll left"
          >
           <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_39642_22788)">
              <circle cx="33" cy="30.5" r="17" fill="white"/>
            </g>
            <path d="M36.75 38.5L29.25 31L36.75 23.5" stroke="#199094" strokeWidth="2"/>
            <defs>
              <filter id="filter0_d_39642_22788" x="0" y="0.5" width="66" height="66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="8"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0745098 0 0 0 0 0.0745098 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_39642_22788"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_39642_22788" result="shape"/>
              </filter>
            </defs>
          </svg>
          </button>
        )}

        <div className="metrics-scroll" ref={scrollContainerRef}>
          {/* Metrics content */}
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Days Since Last Session:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.days_since_last_session?.toFixed(2)}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Months on Platform:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.months_on_platform?.toFixed(2)}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Time Spent Per Week:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.time_spent_per_week || "N/A"}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Sessions Per Month:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.sessions_per_month?.toFixed(2)}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Session Length Time:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.session_length_time || "N/A"}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Total Time Spent:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.total_time_spent || "N/A"}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Total Active Users:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.total_active_users || "N/A"}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Stress Level:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.stress_level?.toFixed(2)}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Clarity Level:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.clarity_level?.toFixed(2)}</span>
              </div>
          </div>
          <div className="metric-card">
            <div className="mertic-top-container">
              <span className='metric-title'>Confidence Level:</span></div>
              <div className='mertic-bottom-container'>
              <span className='metric-subtitle'>{cohortInfo.confidence_level?.toFixed(2)}</span>
              </div>
          </div>
          

      
         
        </div>

        {canScrollRight && (
          <button
            className="scroll-button scroll-right"
            onClick={() => scroll('right')}
            aria-label="Scroll right"
          >
           <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_39642_22788)">
<circle cx="33" cy="30.5" r="17" fill="white"/>
</g>
<path d="M29.25 23.5L36.75 31L29.25 38.5" stroke="#199094" stroke-width="2"/>
<defs>
<filter id="filter0_d_39642_22788" x="0" y="0.5" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="8"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0745098 0 0 0 0 0.0745098 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_39642_22788"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_39642_22788" result="shape"/>
</filter>
</defs>
</svg>

          </button>
        )}
      </div>
    </>
  );
}

{/* <div className="name-header">welcome, Rachel!</div>
          <Row>
            {/* <Col span={10}></Col> */}
          //   <Col span={24}>
          //     {this.state.cohortInfo ? (
          //       this.state.cohortInfo.total_active_users ? (
          //         <p
          //           style={{
          //             font: "normal normal 600 14px/7px AvenirBook",
          //             padding: "10px 0 0 40px",
          //             color: "#51e34c",
          //             textAlign: "left",
          //           }}
          //         >
          //           Cohort &nbsp;&nbsp;&nbsp;
          //           <span className="active-users">
          //             {this.state.cohortInfo.total_active_users
          //               ? this.state.cohortInfo.total_active_users
          //               : ""}{" "}
          //             Users
          //           </span>
          //         </p>
          //       ) : (
          //         ""
          //       )
          //     ) : (
          //       ""
          //     )}
          //     <div className="cohort-box">
          //       <div className="avg-time">
          //         Days since last use
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.days_since_last_session.toFixed(2)
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Months
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.months_on_platform.toFixed(2)
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Weekly time
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.time_spent_per_week
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Sessions per month
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.sessions_per_month.toFixed(2)
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Session length time
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.session_length_time
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Overall time
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.total_time_spent
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Level of stress
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.stress_level.toFixed(2)
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Level of career clarity
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.clarity_level.toFixed(2)
          //             : ""}
          //         </div>
          //       </div>
          //       <div className="avg-time">
          //         Level of certainty on process
          //         <div
          //           style={{
          //             font: "normal normal bold 11px/25px AvenirBook",
          //             color: "#000000",
          //           }}
          //         >
          //           {Object.keys(this.state.cohortInfo).length > 0
          //             ? this.state.cohortInfo.confidence_level.toFixed(2)
          //             : ""}
          //         </div>
          //       </div>
          //     </div>
          //   </Col>
          // </Row>
        // </div> 


