import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {updateTaskAsync, deleteTaskAsync, setStepGoalAsync} from "../../slices/dashboardSlice";
import { Checkbox, DatePicker, Popconfirm} from "antd";
import './MyTasks.css'
import {EditOutlined} from "@ant-design/icons";

const spinner =
  // "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/spinner.svg";
  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/inprogressLarge.svg";
  // <>
  // <div className="dashboard-my-tasks-tag-ongoing status-badge"><span class="status-dot"></span>In Progress</div>
  // </>
const weeklyReflection =
  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_think_2157497.ad0d2dc4.svg";

const phone =
  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_call_2442970.svg";

const TaskComp = (props) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [date, setDate] = useState(props.date);

  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };
  const handleChange = (date, dateString) => {
    console.log("changed date:", date, dateString);
    setDate(dateString);
    setDatePickerIsOpen(!datePickerIsOpen);
    var timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    const task = {
      due_date: date,
    };
    props.id > 0 ?
    dispatch(updateTaskAsync(props.id, task))
        : dispatch(setStepGoalAsync(
                props.stepId,
                props.stepKey,
                `${dateString}`,
                props.stepName
            ));
  };

  const getDueDays = (dueDate) => {
    if (!dueDate || !moment(dueDate).isValid()) {
      // return 'Due Date';
      return `Set a new goal date!`;
    }
    const today = moment();
    const diff = moment(dueDate).diff(today, 'days');
    const day = Math.abs(diff) == 1 ? 'day' : 'days';
    if (diff < 0) {
      return `Overdue by ${Math.abs(diff)} ${day}!`;
    } else if (diff === 0) {
        return "Due today";
    } else {
        return `Due in ${diff} ${day}`;
    }
  };


  return (
    <li
      key={props.id}
      className={
        props.title === "Schedule Call with Coach"
          ? "call"
          : props.weeklyReflection
          ? "weekly-reflection"
          : props.status === "ongoing"
          ? "ongoing"
          : props.status === "just-released"
          ? "botom-purple"
          : ""
      }
    >
      

      <div style={{fontSize: "17px", fontWeight: 400, color: "#111111", display: "flex", justifyContent: "space-between"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gridGap: "12px",
            gap: "12px",
          }}
        >
        <Checkbox
          className="element-hide-temporary" //Future Release Build
              // defaultChecked={this.props.checked}
              // onChange={(e) =>
              //   this.props.onCheck(e.target.checked, this.props.id)
              // }
            ></Checkbox>
        <span className="title">{props.title}</span>
        </div>
        <div>
        <span><img src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/topLeftArrow.svg" /></span>
        </div>
        </div>
      {
        props.id && props.hasDelete &&
        <EditOutlined
            style={{marginRight: '20px'}}
            className="editable drag-hover"
            onClick={props.onEdit}
        />
      }
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      marginTop: "15px",
      alignItems: "center",
    }}>
      
      {props.status === "just-released" ? (
        // <span
        //   className="just-released"
        //   style={{ marginLeft: 0, marginRight: "10px" }}
        // >NEW</span>
        <div className="dashboard-my-tasks-tag-notStarted status-badge"><img
        src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/newOrNotStartedLarge.svg"
        className="status-dot"
        />Not Started</div>
      ) : props.title !== "Schedule Call with Coach" ? (
        <div className="dashboard-my-tasks-tag-ongoing status-badge"><img
        src={props.weeklyReflection ? weeklyReflection : spinner}
        className="spinner status-dot"
        />In Progress</div>
        // <img
        // src={props.weeklyReflection ? weeklyReflection : spinner}
        // className="spinner"
        // />
        // props.weeklyReflection ? weeklyReflection : spinner
      ) : (
        <img src={phone} className="phone" />
      )}

        <label
            htmlFor={`start-date-${props.id}`}
            className="due-date"
            onClick={() => openDatePicker()}
            style={{cursor: "pointer", marginBottom: '0', textAlign: "right"}}
            // hidden={props.id < 1}
            >
          {
            getDueDays(date)  
          }
          <br />
          {
            date ? moment(date).format("MMM DD 'YY") : '' // Future Release Build
            // date
          }
        </label>
      {
        props.id && props.hasDelete &&
        <div style={{float: "right", paddingRight: '10px'}}>
          <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                dispatch(deleteTaskAsync(props.id))
              }}
              >
            <span style={{cursor: "pointer"}} className="drag-hover">
                <img src="https://django-woken-static.s3.amazonaws.com/static/media/delete.037bee2b.svg"/>
              </span>
          </Popconfirm>
        </div>
      }
      </div>
      <DatePicker
        id={`start-date-${props.id}`}
        onClickOutside={openDatePicker}
        open={datePickerIsOpen}
        style={{ visibility: "hidden", width: "0", float: "right" }}
        defaultValue={date && moment(date)}
        onChange={(date, dateString) => handleChange(date, dateString)}
      />
    </li>
  );
};

export default TaskComp;
