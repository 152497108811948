import {Button, Form, message} from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "../../../api/auth";
import FreeForm from "./FreeFormComp";
import GS_wrapper from "./GS_wrapper";
import "./ProfSummary.css";

export default class ProfSummary extends React.Component {
  formRef = React.createRef();

  state = {
    generate: false,
    Api_response: "",
    loading: true,
  };
  componentDidMount() {
    AUTH_API.getMaterials()
      .then((response) => {
        console.log("get Api data", response);
        const form_values =
          response.data.length > 0 ? response.data[0].professional_summary : "";
        this.setState({
          Api_response: response.data.length > 0 ? response.data[0] : "",
          loading: false,
          generate: form_values.generate || form_values.edited ? true : false,
        });
        this.formRef.current.setFieldsValue({
          ...form_values,
        });
      })
      .catch((err) => console.log("prof summary get Api error!", err));
  }
  onFormChange = (changed_values, all_values) => {
    console.log("changed_values:", changed_values, "all values:", all_values);
    const data = {
      professional_summary: all_values,
    };
    const id = this.state.Api_response.id;
    AUTH_API.patchMaterials(data, id, "PS")
      .then((response) => {
        console.log("patch Api data", response);
        message.success('Your work has been saved')
        this.setState({
          Api_response: response.data,
        });
      })
      .catch((err) => console.log("prof summary patch Api error!", err));
  };
  onGenerate = () => {
    let form_data = this.formRef.current.getFieldsValue();
    form_data["generate"] = true;
    console.log("form data", form_data);
    const data = {
      professional_summary: form_data,
    };
    const id = this.state.Api_response.id;
    AUTH_API.patchMaterials(data, id, "PS")
      .then((response) => {
        console.log("patch Api data", response);
        message.success('Your work has been saved')
        this.setState({ generate: true });
      })
      .catch((err) => console.log("prof summary patch Api error!", err));
  };

  render() {

    console.log("prof summary rendered", this.state.generate);
    return (
      <div>
        <h3 className="resume-head" style={{
          color:"#199094"
        }}>
          Draft a strong professional summary to represent your background, your
          strengths, and your direction.
        </h3>
        {this.state.loading ? (
          "...Loading"
        ) : (
          <div>
            {!this.state.generate && ( // Future Release Build (replace [this.state.generate] with [!this.state.generate])
              <div>
                <Form
                  name="prof-summary-form"
                  ref={this.formRef}
                  onValuesChange={HELPERS.debounceFormChanges(
                    this.onFormChange
                  )}
                  onFinish={this.onGenerate}
                >
                  <FreeForm />
                  <Form.Item>
                    <button
                      className="button-main"
                      style={{ width: "310px" }}
                      type="primary"
                      // onClick={()=>this.setState({generate:true})}
                      htmlType="submit"
                      size={"large"}
                    >
                      Generate Professional Summary
                    </button>
                  </Form.Item>
                </Form>
              </div>
            )}

            {this.state.generate && <GS_wrapper stepId={this.props.stepId} />}
          </div>
        )}
      </div>
    );
  }
}
