// OLD CODE INITIAL COMMIT

// import { DownOutlined } from "@ant-design/icons";
// import { Button, Col, Dropdown, Layout, Menu, Row } from "antd";
// import COACH_API from "api/coach";
// import "bootstrap/dist/css/bootstrap.min.css";
// import React from "react";
// import { Link, Route, Switch, Redirect } from "react-router-dom";
// // import "antd/dist/antd.css";
// import "./Coach.css";
// import DrillDown from "./CoachDrilldown/DrillDown";
// import CoachTable from "./CoachTable";
// // import Notification from "./Notification";

// const { Content } = Layout;

// const menu = (
//   <Menu>
//     <Menu.Item
//       onClick={() => {
//         // localStorage.removeItem("token");
//         // localStorage.removeItem("isCoach");
//         localStorage.clear();
//         window.location.href = "/signin";
//       }}
//     >
//       Log Out
//     </Menu.Item>
//   </Menu>
// );

// export default class CoachDBlayout extends React.Component {
//   state = {
//     notiVisible: false,
//     cohortInfo: {},
//   };

//   componentDidMount() {
//     COACH_API.cohortInfo()
//       .then((response) => {
//         console.log("cohort info get api respone:", response);
//         this.setState({ cohortInfo: response.data });
//       })
//       .catch((err) => console.log(err));
//   }

//   onHover = () => {
//     // console.log('hover!!')
//   };

//   render() {
//     return (
//       <div
//         id="module"
//         // className="headerimage"
//         ref="module"
//         onMouseOver={this.onHover}
//         style={{ backgroundColor: "#FCFCFC", minHeight: "100vh" }}
//       >
//         <div>
//           <Row>
            
//             <Col>
//               <div style={{ paddingTop: "2%", border:"2px solid red" }}>
//                 <Row>
//                   <Col span={11}>
//                     <div style={{ textAlign: "left" }}>
//                       <Link
//                         to={`/coach/dashboard/`}
//                         style={{ color: "inherit !important" }}
//                       >
//                         <Button
//                           type="text"
//                           style={{ fontWeight: "bold", color: "white" }}
//                         >
//                           Dashboard
//                         </Button>
//                       </Link>
//                     </div>
//                   </Col>
//                   <Col span={9}>{/* <Notification /> */}</Col>
//                   <Col span={4}>
//                     <div style={{}}>
//                       <Dropdown overlay={menu} placement="bottomLeft">
//                         <Button type="text" style={{ color: "white" }}>
//                           Rachel Serwetz <DownOutlined />
//                         </Button>
//                       </Dropdown>
//                     </div>
//                   </Col>
//                   {/* <Col span={}></Col> */}
//                 </Row>
//               </div>
//             </Col>
//           </Row>
//           <div className="name-header">welcome, Rachel!</div>
//           <Row>
//             {/* <Col span={10}></Col> */}
//             <Col span={24}>
//               {this.state.cohortInfo ? (
//                 this.state.cohortInfo.total_active_users ? (
//                   <p
//                     style={{
//                       font: "normal normal 600 14px/7px AvenirBook",
//                       padding: "10px 0 0 40px",
//                       color: "#51e34c",
//                       textAlign: "left",
//                     }}
//                   >
//                     Cohort &nbsp;&nbsp;&nbsp;
//                     <span className="active-users">
//                       {this.state.cohortInfo.total_active_users
//                         ? this.state.cohortInfo.total_active_users
//                         : ""}{" "}
//                       Users
//                     </span>
//                   </p>
//                 ) : (
//                   ""
//                 )
//               ) : (
//                 ""
//               )}
//               <div className="cohort-box">
//                 <div className="avg-time">
//                   Days since last use
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.days_since_last_session.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Months
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.months_on_platform.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Weekly time
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.time_spent_per_week
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Sessions per month
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.sessions_per_month.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Session length time
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.session_length_time
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Overall time
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.total_time_spent
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Level of stress
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.stress_level.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Level of career clarity
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.clarity_level.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Level of certainty on process
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px AvenirBook",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.confidence_level.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </div>

//         <Layout style={{ backgroundColor: "#f5f5f500" }}>
//           <Layout style={{ backgroundColor: "#f5f5f500" }}>
//             <Content style={{ backgroundColor: "#f5f5f500" }}>
//               <div>
//                 {/* {console.log(this.state.step)} */}
//                 {/* {this.state.step} */}
//                 {/* <Assessment toggle={this.toggle} collapsed = {this.state.collapsed} /> */}
//                 {/* <Route path='/assessment' component={DownOutlined} />
//                                     <Route path='/target-roles' component={DownOutlined} /> */}
//               </div>
//             </Content>
//           </Layout>
//         </Layout>
//         <Switch>
//           {/*<Route path="/coach" exact component={CoachTable}/>
//                     <Route path="/dd" exact component={DrillDown}/>*/}
//           <Route
//             exact
//             path="/coach/dashboard"
//             // component={CoachTable}
//             render={
//               localStorage.getItem("isCoach")
//                 ? () => <CoachTable />
//                 : () => <Redirect to="/" />
//             }
//           />
//           <Route
//             exact
//             path="/coach/drill-down"
//             component={DrillDown}
//             {...this.props}
//           />
//         </Switch>
//       </div>
//     );
//   }
// }


















// My latest code with new header



import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, Menu, Row } from "antd";
import COACH_API from "api/coach";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Link, Route, Switch, Redirect } from "react-router-dom";
// import "antd/dist/antd.css";
import "./Coach.css";
import DrillDown from "./CoachDrilldown/DrillDown";
import CoachTable from "./CoachTable";
// import Notification from "./Notification";
import CoachHeader from "./CoachHeader";

const { Content } = Layout;

const menu = (
  <Menu>
    <Menu.Item
      onClick={() => {
        // localStorage.removeItem("token");
        // localStorage.removeItem("isCoach");
        localStorage.clear();
        window.location.href = "/signin";
      }}
    >
      Log Out
    </Menu.Item>
  </Menu>
);

export default class CoachDBlayout extends React.Component {
  state = {
    notiVisible: false,
    cohortInfo: {},
  };

  componentDidMount() {
    COACH_API.cohortInfo()
      .then((response) => {
        console.log("cohort info get api respone:", response);
        this.setState({ cohortInfo: response.data });
      })
      .catch((err) => console.log(err));
      this.setState({ cohortInfo: {} });
  }

  onHover = () => {
    // console.log('hover!!')
  };

  render() {

    const { cohortInfo } = this.state;
    return (
      <div
        id="module"
        // className="headerimage"
        ref="module"
        onMouseOver={this.onHover}
        style={{ backgroundColor: "#FCFCFC", minHeight: "100vh" }}
      >
     <CoachHeader cohortInfo={cohortInfo} />

       {/* paste here layout */}
       <Layout style={{ backgroundColor: "#f5f5f500" }}>
          <Layout style={{ backgroundColor: "#f5f5f500" }}>
            <Content style={{ backgroundColor: "#f5f5f500" }}>
              <div>
                {/* {console.log(this.state.step)} */}
                {/* {this.state.step} */}
                {/* <Assessment toggle={this.toggle} collapsed = {this.state.collapsed} /> */}
                {/* <Route path='/assessment' component={DownOutlined} />
                                    <Route path='/target-roles' component={DownOutlined} /> */}
              </div>
            </Content>
          </Layout>
        </Layout>
        <Switch>
          {/*<Route path="/coach" exact component={CoachTable}/>
                    <Route path="/dd" exact component={DrillDown}/>*/}
          <Route
            exact
            path="/coach/dashboard"
            // component={CoachTable}
            render={
              localStorage.getItem("isCoach")
                ? () => <CoachTable />
                : () => <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/coach/drill-down"
            component={DrillDown}
            {...this.props}
          />
        </Switch>
      </div>
    );
  }
}


// Reverted Changes Coach Dashboard

// import { DownOutlined } from "@ant-design/icons";
// import { Button, Col, Dropdown, Layout, Menu, Row } from "antd";
// import COACH_API from "api/coach";
// import "bootstrap/dist/css/bootstrap.min.css";
// import React from "react";
// import { Link, Route, Switch, Redirect } from "react-router-dom";
// // import "antd/dist/antd.css";
// import "./Coach.css";
// import DrillDown from "./CoachDrilldown/DrillDown";
// import CoachTable from "./CoachTable";
// // import Notification from "./Notification";
 
// const { Content } = Layout;
 
// const menu = (
//   <Menu>
//     <Menu.Item
//       onClick={() => {
//         // localStorage.removeItem("token");
//         // localStorage.removeItem("isCoach");
//         localStorage.clear();
//         window.location.href = "/signin";
//       }}
//     >
//       Log Out
//     </Menu.Item>
//   </Menu>
// );
 
// export default class CoachDBlayout extends React.Component {
//   state = {
//     notiVisible: false,
//     cohortInfo: {},
//   };
 
//   componentDidMount() {
//     COACH_API.cohortInfo()
//       .then((response) => {
//         console.log("cohort info get api respone:", response);
//         this.setState({ cohortInfo: response.data });
//       })
//       .catch((err) => console.log(err));
//   }
 
//   onHover = () => {
//     // console.log('hover!!')
//   };
 
//   render() {
//     return (
//       <div
//         id="module"
//         className="headerimage-coachdashboard"
//         ref="module"
//         onMouseOver={this.onHover}
//         style={{ backgroundColor: "#FCFCFC", minHeight: "100vh" }}
//       >
//         <div>
//           <Row>
//             <Col span={5}></Col>
//             <Col span={19}>
//               <div style={{ paddingTop: "2%" }}>
//                 <Row>
//                   <Col span={11}>
//                     <div style={{ textAlign: "left" }}>
//                       <Link
//                         to={`/coach/dashboard/`}
//                         style={{ color: "inherit !important" }}
//                       >
//                         <Button
//                           type="text"
//                           style={{ fontWeight: "bold", color: "white" }}
//                         >
//                           Dashboard
//                         </Button>
//                       </Link>
//                     </div>
//                   </Col>
//                   <Col span={9}>{/* <Notification /> */}</Col>
//                   <Col span={4}>
//                     <div style={{}}>
//                       <Dropdown overlay={menu} placement="bottomLeft">
//                         <Button type="text" style={{ color: "white" }}>
//                           Rachel Serwetz <DownOutlined />
//                         </Button>
//                       </Dropdown>
//                     </div>
//                   </Col>
//                   {/* <Col span={}></Col> */}
//                 </Row>
//               </div>
//             </Col>
//           </Row>
//           <div className="name-header">welcome, Rachel!</div>
//           <Row>
//             {/* <Col span={10}></Col> */}
//             <Col span={24}>
//               {this.state.cohortInfo ? (
//                 this.state.cohortInfo.total_active_users ? (
//                   <p
//                     style={{
//                       font: "normal normal 600 14px/7px Montserrat",
//                       padding: "10px 0 0 40px",
//                       color: "#51e34c",
//                       textAlign: "left",
//                     }}
//                   >
//                     Cohort &nbsp;&nbsp;&nbsp;
//                     <span className="active-users">
//                       {this.state.cohortInfo.total_active_users
//                         ? this.state.cohortInfo.total_active_users
//                         : ""}{" "}
//                       Users
//                     </span>
//                   </p>
//                 ) : (
//                   ""
//                 )
//               ) : (
//                 ""
//               )}
//               <div className="cohort-box">
//                 <div className="avg-time">
//                   Days since last use
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.days_since_last_session.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Months
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.months_on_platform.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Weekly time
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.time_spent_per_week
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Sessions per month
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.sessions_per_month.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Session length time
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.session_length_time
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Overall time
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.total_time_spent
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Level of stress
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.stress_level.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Level of career clarity
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.clarity_level.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//                 <div className="avg-time">
//                   Level of certainty on process
//                   <div
//                     style={{
//                       font: "normal normal bold 11px/25px Montserrat",
//                       color: "#000000",
//                     }}
//                   >
//                     {Object.keys(this.state.cohortInfo).length > 0
//                       ? this.state.cohortInfo.confidence_level.toFixed(2)
//                       : ""}
//                   </div>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </div>
 
//         <Layout style={{ backgroundColor: "#f5f5f500" }}>
//           <Layout style={{ backgroundColor: "#f5f5f500" }}>
//             <Content style={{ backgroundColor: "#f5f5f500" }}>
//               <div>
//                 {/* {console.log(this.state.step)} */}
//                 {/* {this.state.step} */}
//                 {/* <Assessment toggle={this.toggle} collapsed = {this.state.collapsed} /> */}
//                 {/* <Route path='/assessment' component={DownOutlined} />
//                                     <Route path='/target-roles' component={DownOutlined} /> */}
//               </div>
//             </Content>
//           </Layout>
//         </Layout>
//         <Switch>
//           {/*<Route path="/coach" exact component={CoachTable}/>
//                     <Route path="/dd" exact component={DrillDown}/>*/}
//           <Route
//             exact
//             path="/coach/dashboard"
//             // component={CoachTable}
//             render={
//               localStorage.getItem("isCoach")
//                 ? () => <CoachTable />
//                 : () => <Redirect to="/" />
//             }
//           />
//           <Route
//             exact
//             path="/coach/drill-down"
//             component={DrillDown}
//             {...this.props}
//           />
//         </Switch>
//       </div>
//     );
//   }
// }
 