import React from "react";
import { Card, Col, Row } from "antd";
import "antd/dist/antd.css";
// import Rachel from './../../../Assets/rachel.png';
// import Testimonials from './../../../Assets/testimonial.svg';

export default class AssessmentC1 extends React.Component {
  render() {
    return (
      <div>
        <p
          style={{
            font: "normal normal 600 20px/12px AvenirBook",
            color: "#414141",
            padding: "40px 0",
          }}
        >
          Why is this important?
        </p>
        <Row gutter={32}>
          <Col span={12} style={{}}>
            <Card bordered={false} style={{ height: "100%" }}>
              <div
                style={{
                  paddingBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src="https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png"></img>
                <div style={{ paddingLeft: "5%" }}>
                  <p
                    style={{
                      font: "normal normal bold 14px/18px AvenirBook",
                      margin: 0,
                    }}
                  >
                    Rachel Serwetz{" "}
                  </p>
                  <p
                    style={{
                      font: "normal normal normal 12px/18px AvenirBook",
                      color: "#898E8E",
                      margin: 0,
                    }}
                  >
                    Your Coach
                  </p>
                </div>
              </div>
              Open-ended reflection is a critical first step to explore your
              interests, experiences, and priorities. We'll uncover what you
              love doing, what content you love, and what environment you thrive
              in.
            </Card>
          </Col>

          <Col span={12} style={{}}>
            <Card bordered={false} style={{ height: "100%" }}>
              <div
                style={{
                  paddingBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src="https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg"></img>
                <div style={{ paddingLeft: "5%" }}>
                  <p
                    style={{
                      font: "normal normal bold 14px/18px AvenirBook",
                      margin: 0,
                    }}
                  >
                    Holly
                  </p>
                  <p
                    style={{
                      font: "normal normal normal 12px/18px AvenirBook",
                      color: "#898E8E",
                    }}
                  >
                    Woken Client
                  </p>
                </div>
              </div>
              The assessment surveys have been really helpful to get me to think
              through my skills, interests, and experiences. The questionnaire
              is really helpful to get me started thinking about what kind of
              interests I have and where my skills are.
            </Card>
          </Col>
        </Row>
        <br />
        <br />
        <Row gutter={32}>
          <Col span={12} style={{}}>
            <Card bordered={false} style={{ height: "100%" }}>
              <div
                style={{
                  paddingBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src="https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg"></img>
                <div style={{ paddingLeft: "5%" }}>
                  <p
                    style={{
                      font: "normal normal bold 14px/18px AvenirBook",
                      margin: 0,
                    }}
                  >
                    Daniel
                  </p>
                  <p
                    style={{
                      font: "normal normal normal 12px/18px AvenirBook",
                      color: "#898E8E",
                      margin: 0,
                    }}
                  >
                    Woken Client
                  </p>
                </div>
              </div>
              I enjoyed the explorative nature of the self-assessment and how I
              was encouraged to dream big.
            </Card>
          </Col>

          <Col span={12} style={{}}>
            <Card bordered={false} style={{ height: "100%" }}>
              <div
                style={{
                  paddingBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src="https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg"></img>
                <div style={{ paddingLeft: "5%" }}>
                  <p
                    style={{
                      font: "normal normal bold 14px/18px AvenirBook",
                      margin: 0,
                    }}
                  >
                    Anna
                  </p>
                  <p
                    style={{
                      font: "normal normal normal 12px/18px AvenirBook",
                      color: "#898E8E",
                    }}
                  >
                    Woken Client
                  </p>
                </div>
              </div>
              It was hard but really made me think!!!!
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
