import React from "react";
import { connect } from "react-redux";
import { Row, Col, Steps, Button } from "antd";
import "./Targetroles.css";
import TRprioritize from "./TRprioritize";
import Potentialroles from "./Potentialroles";
import TRselect from "./TRselect";
import { updateQuickReflection } from "slices/dashboardSlice";
import Sorry from "Components/Modals/sorry";
import { selectTargetRoles, } from "slices/targetRolesSlice";


const { Step } = Steps;

class TargetRolesSteps extends React.Component {
  state = {
    // current:0,
    current: this.props.curr_step,
    showSorry: false
  };
  next = () => {
    const selected_roles = this.props.roles.filter(item => item.learn_more)
    let current = this.state.current;
    if (selected_roles.length > 10 && current == 1) {
      this.setState({ showSorry: true })
      return
    }
    current += 1;
    this.setState({ current });
    this.props.setStep(current);
  };

  call = () => {
    console.log("run");
  };

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
    this.props.setStep(current);
  }

  render() {
    const { current } = this.state;
    const steps = [
      {
        title: "Potential Roles",
        content: <Potentialroles next={() => this.next()} />,
      },
      {
        title: "Target Roles",
        content: <TRselect />,
      },
      {
        title: "Prioritize",
        content: <TRprioritize />,
      },
    ];

    // console.log(this.props.fade);
    console.log('Test')

    return (
      <div
        style={this.props.fade ? {} : { opacity: "0.4", pointerEvents: "none" }}
      >
        <Sorry
          message={"Please limit your choices to 10 roles. This does not mean you are closing yourself off to any options, but we want to focus your research on what you think is most interesting, just as a starting point. You can edit or adjust your list after this step."}
          visible={this.state.showSorry} onClose={() => this.setState({showSorry:false})}/>
        <Steps
          current={current}
          style={{ padding: "2% 0 6% 0", fontSize: 14 }}
          onChange={(current) => this.setState({ current: current })}
        >
          {steps.map((item, index) => (
            <Step
              style={{ fontSize: 12 }}
              key={item.title}
              title={item.title}
              status={index === this.state.current ? "process" : "wait"}
            />
          ))}
        </Steps>

        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          <Row>
            <Col span={5} style={{ paddingTop: "5%" }}>
              {current > 0 && (
                <button
                  size="large"
                  style={{ margin: "0 8px", color: "#199094", borderColor: "#199094",width:"100%" }}
                  onClick={() => this.prev()}
                  block
                  className="button-secondary"
                >
                  Back
                </button>
              )}
            </Col>
            <Col span={7}></Col>
            <Col span={7}></Col>

            <Col span={5} style={{ textAlign: "right", padding: "5% 0% 5% 0" }}>
              {current < steps.length - 1 && current === 1 && (
                <button
                  size="large"
                  type="primary"
                  onClick={() => this.next()}
                  block
                  className="button-main"
                  style={{
                    width:"100%"
                  }}
                >
                  Next
                </button>
              )}
              {current === steps.length - 1 && (
                <button
                  size="large"
                  type="primary"
                  block
                  onClick={() => {
                    this.props.updateQuickReflection({
                      stepId: this.props.stepId,
                      show: true,
                    });
                  }}
                  className="button-main"
                  style={{
                    width:"100%"
                  }}
                >
                  Submit
                </button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
});

const mapDispatchToProps = () => ({
  updateQuickReflection,
});

export default connect(mapStateToProps, mapDispatchToProps())(TargetRolesSteps);