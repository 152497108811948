import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  Select,
  Tag,
  Form,
  Button,
  message,
  Popconfirm,
} from "antd";
import HELPERS from "utils/helper";
import { DeleteOutlined } from "@ant-design/icons";
import {
  selectAssessment,
  fetchAssessmentAsync,
  updateAssessmentAsync,
} from "slices/assessmentSlice";
import {
  selectAllIndustries,
  addIndustryAsync,
  updateIndustryAsync,
} from "slices/targetIndustriesSlice";

import Sorry from "Components/Modals/sorry";

const { Option } = Select;

function tagRender(props) {
  const { label, closable, onClose } = props;

  return (
    <Tag
      color={"white"}
      closable={closable}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "14px 5px",
        padding: "2px 15px",
        backgroundColor: "#FFA86B",
      }}
    >
      {label}
    </Tag>
  );
}

class PotentialIndustries extends React.Component {
  formRef = React.createRef();

  state = {
    roles_set: null,
    showSorry: false
  };

  onUpdate = (values) => {
    HELPERS.log("values", values, this.props.industries);
    if (Object.values(values)[0][0] !== "") {
      const allIndustries = [...this.props.allIndustries];
      const industries = [...Object.values(values)[0]];
      const addTo = parseInt(Object.keys(values)[0]);
      let index = -1;

      HELPERS.log(
        this.props.industries.selectedIndustries[addTo]
          ? this.props.industries.selectedIndustries[addTo].length
          : "new entry",
        industries.length
      );

      if (
        this.props.industries.selectedIndustries[addTo] &&
        this.props.industries.selectedIndustries[addTo].length >
          industries.length
      ) {
        // User deleted something
        for (var industry of this.props.industries.selectedIndustries[addTo]) {
          index = this.props.industries.indexes[industry];

          HELPERS.log("checking", addTo, allIndustries[index]);

          if (industries.indexOf(industry) === -1) {
            var selected_for = [...allIndustries[index].selected_for];

            selected_for.splice(selected_for.indexOf(addTo), 1);

            allIndustries[index].selected_for = selected_for;

            HELPERS.log("After remove", allIndustries[index]);

            this.props.updateIndustryAsync(
              allIndustries[index].id,
              {
                selected_for,
              },
              "TI"
            );

            // assuming only one can be removed at a time
            break;
          }
        }
      } else {
        // User added something
        // assuming the newly added industry is at the end of the list
        if(this.props?.industries?.selectedIndustries[addTo]?.length >= 7){
          this.setState({showSorry: true})
        }
        index = this.props.industries.indexes[
          industries[industries.length - 1]
        ];
        var selected_for = [];

        if (!isNaN(index)) {
          selected_for = [...allIndustries[index].selected_for];
        }

        if (selected_for.indexOf(addTo) === -1) {
          selected_for.push(addTo);
          if (!isNaN(index)) {
            allIndustries[index].selected_for = selected_for;
            HELPERS.log("After add", allIndustries[index]);
          }

          const temp = isNaN(index)
            ? industries[industries.length - 1]
            : allIndustries[index].value;

          if (!this.state.roles_set.has(temp)) {
            let temp_set = new Set(this.state.roles_set);
            console.log("should post", this.state.roles_set);
            temp_set.add(temp);

            if (isNaN(index) || allIndustries[index].id <= 0) {
              this.props.addIndustryAsync(
                {
                  title: isNaN(index)
                    ? industries[industries.length - 1]
                    : allIndustries[index].value,
                  selected_for,
                  priority: 0,
                  learn_more: false,
                  research_questions: [],
                  additional_questions: [],
                  reflections: [],
                },
                "TI"
              );
            }
            this.setState({
              roles_set: temp_set,
            });
          } else {
            this.props.updateIndustryAsync(
              allIndustries[index].id,
              {
                selected_for,
              },
              "TI"
            );
          }
        }
      }
    }
    message.success('Your work has been saved');
  };

  componentDidUpdate() {
    if (this.props.industries && this.props.industries.selectedIndustries) {
      this.formRef.current.setFieldsValue(
        this.props.industries.selectedIndustries
      );
    }
  }

  componentDidMount() {
    let allroles = Object.values(
      this.props.industries.selectedIndustries
    ).flat();
    let set1 = new Set(allroles);
    // console.log('roles from start', this.props.roles.selectedRoles, Object.values(this.props.roles.selectedRoles).flat(), set1)
    this.setState({
      roles_set: set1,
    });
  }

  onDeleteRes = (key, value, ind) => {
    console.log("del", key, value, ind);
    console.log(this.props.assessment_data);
    let resp_data = {};
    if (this.props.assessment_data.environment.tr_exceptions) {
      resp_data = { ...this.props.assessment_data.environment.tr_exceptions };
      console.log("resp data", resp_data);
    } else {
      resp_data = {};
    }

    resp_data[[key, ind]] = 1;

    let patch_data = {
      environment: {
        ...this.props.assessment_data.environment,
        tr_exceptions: { ...resp_data },
      },
    };
    console.log("data to be patched", patch_data);
    this.props.updateAssessmentAsync(this.props.assessment_data.id, patch_data);
    // let resp_data={content_areas:{...this.props.assessment_data.content_areas}}
    // if (ind!==-1){
    //   let updated_data={...this.props.assessment_data.content_areas[key]}
    //   updated_data[ind]=''
    //   // resp_data={experiences:{...this.props.assessment_data.experiences, ...updated_data}}
    //   resp_data.content_areas[key]=updated_data
    //   console.log('response data of arr', updated_data, resp_data)
    // }
    // else{
    //   resp_data.content_areas[key]=''
    //   console.log('response data', resp_data)
    // }

    // // const resp_data={}
    // this.props.updateAssessmentAsync(this.props.assessment_data.id, resp_data)
    message.success("Response has been deleted!");
  };

  onFinish = () => {
    this.props.next();
  };

  onFinishFailed = () => {
    message.error("Please fill all the required fields first");
  };

  render() {
    HELPERS.log("PotentialIndustries", this.props.allQuestions);
    const exception_keys = this.props.assessment_data
      ? this.props.assessment_data.environment.tr_exceptions
        ? this.props.assessment_data.environment.tr_exceptions
        : {}
      : {};

    return (
      <div>
        <p
          style={{
            color: "#199094",
            font: "normal normal 600 18px/22px AvenirBook",
          }}
        >
          For each of the things you love doing, select one or more industries
          that you think could involve these activities.
        </p>
        <Sorry
          message={"Try to keep your tags to a maximum of 7 industries per reflection. Remember, nothing is set in stone, but tagging the top most relevant choices is the most helpful at this step. You can edit or adjust your target industries throughout the rest of the process."}
          visible={this.state.showSorry} onClose={() => this.setState({showSorry:false})}/>

        <Row>
          <Col
            span={12}
            style={{
              font: "normal normal bold 16px/44px AvenirBook",
              // color: "#111111",
            }}
          >
            <span></span>
          </Col>
          <Col span={1}></Col>
          <Col
            span={11}
            style={{
              font: "normal normal bold 17px/44px AvenirBook",
              color: "#111111",
            }}
          >
            <span>What industries do these functions translate to:</span>
          </Col>
        </Row>

        <Form
          ref={this.formRef}
          name="prioritize"
          onValuesChange={this.onUpdate}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          {this.props.allQuestions &&
            this.props.allQuestions.map((item, index) => (
              <Row
                style={
                  !exception_keys[[item.key, item.ind]] &&
                  item.value &&
                  item.value.replace(/\r\n|\n|\r/gm, "").replace(/\s+/g, "")
                    ? { padding: "40px 0" }
                    : { display: "none" }
                }
                className="assessment-row"
                key={`potential-industry-row-${index}`}
              >
                <Col span={12}>
                  <Card className="left-content">{item.value}</Card>
                </Col>
                <Col span={1}></Col>
                <Col span={10}>
                  <Form.Item
                    name={index}
                    rules={
                      !exception_keys[[item.key, item.ind]] &&
                      item.value &&
                      item.value.replace(/\r\n|\n|\r/gm, "").replace(/\s+/g, "")
                        ? [{ required: true, message: "Required Field" }]
                        : []
                    }
                  >
                    <Select
                      mode="tags"
                      showArrow
                      tagRender={tagRender}
                      key={index}
                      // onChange={this.onUpdate2}
                      placeholder="Select Industry"
                      style={{ width: "100%" }}
                      options={this.props.allIndustries}
                      size="large"
                      onDeselect={this.onDeselect}
                    >
                      {[]}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  {item.key !== 9999 && (
                    <Popconfirm
                      title="Are you sure you want to delete?"
                      onConfirm={() => {
                        this.onDeleteRes(item.key, item.value, item.ind);
                      }}
                    >
                      <DeleteOutlined className="del-assessment" />
                    </Popconfirm>
                  )}
                </Col>
              </Row>
            ))}
          {/* <Form.Item name='submitbtn'>
            <Button htmlType='submit' type='primary' block style={{float:'right', width:'21%'}} size='large'>Next</Button>
          </Form.Item> */}

          <button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ float: "right", width: "20%" }}
            className="button-main"
          >
            Next
          </button>
        </Form>
      </div>
    );
  }
}

class PotentialIndustriesWrapper extends React.Component {
  state = {
    fetched: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    HELPERS.log("PotentialIndustriesWrapper", this.props);
    if (!this.state.fetched) {
      HELPERS.log("Trying to execute fetchAssessmentAsync...");
      this.props.fetchAssessmentAsync();
      this.setState({ fetched: true });
    }
    HELPERS.log("PotentialIndustriesWrapper ab", this.props);
  }

  getIndustries = () => {
    let res = {
      indexes: {},
      selectedIndustries: {},
    };

    if (this.props.allIndustries && this.props.allIndustries.length > 0) {
      this.props.allIndustries.forEach((industry, index) => {
        res.indexes[industry.value] = index;

        industry.selected_for.forEach((index) => {
          if (res.selectedIndustries[index]) {
            res.selectedIndustries[index].push(industry.value);
          } else {
            res.selectedIndustries[index] = [industry.value];
          }
        });
      });
    }

    return res;
  };

  allQuestions = () => {
    let ALLQ = [];
    if (this.props.assessment && this.props.assessment.content_areas) {
      let exact_data = { ...this.props.assessment.content_areas };
      // console.log('exact data', exact_data)
      const allowed = ["42", "42_added", "56"];
      const filtered = Object.keys(exact_data)
        .filter((key) => !allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = exact_data[key];
          return obj;
        }, {});
      // console.log('filtered', filtered)
      const filtered_keys = Object.keys(filtered);
      // console.log('-------------------------------------------------------------------------------------------------')
      filtered_keys.map((item, index) => {
        if (typeof filtered[item] === "string") {
          // console.log('mapped into string',typeof filtered[item],filtered[item], 'onChangekey', item)
          ALLQ.push({
            key: item,
            value: filtered[item],
            ind: -1,
          });
        } else {
          // console.log('map it further', Array.isArray(filtered[item]), filtered[item], 'onChangekey', item,'++++++++++++++++++++++++++++++++++')
          if (Array.isArray(filtered[item])) {
            filtered[item].map((elem, index) => {
              // console.log('single elem',elem,'onChangeIndex',index, item)
              ALLQ.push({
                key: item,
                value: elem,
                ind: index,
              });
            });
          } else {
            Object.keys(filtered[item]).map((elem, index) => {
              // console.log('single elem',elem,'onChangeIndex',index, item)
              ALLQ.push({
                key: item,
                value: filtered[item][elem],
                ind: elem,
              });
            });
          }
        }
        return;
      });
      // console.log('what I want', ALLQ)
      // ALLQ.push({
      //   key:9999,
      //   value:"When looking at your list of reflections, do you see any trends that might be reflected in a potential role?",
      //   ind:9999
      // })
    }
    return ALLQ;
  };

  render() {
    return (
      <PotentialIndustries
        allQuestions={this.allQuestions()}
        industries={this.getIndustries()}
        allIndustries={this.props.allIndustries}
        updateIndustryAsync={this.props.updateIndustryAsync}
        addIndustryAsync={this.props.addIndustryAsync}
        assessment_data={this.props.assessment}
        updateAssessmentAsync={this.props.updateAssessmentAsync}
        next={this.props.next}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  assessment: selectAssessment(state),
  allIndustries: selectAllIndustries(state),
});

const mapDispatchToProps = () => ({
  fetchAssessmentAsync,
  updateAssessmentAsync,
  addIndustryAsync,
  updateIndustryAsync,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(PotentialIndustriesWrapper);