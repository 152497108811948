import React from "react";
import { Modal, Button, Row, Col, Radio } from "antd";

import { CloseOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import {
  selectStepReflections,
  updateStepReflectionAsync,
} from "slices/dashboardSlice";

class Skipstep extends React.Component {
  state = {
    visible: this.props.visible,
    current: 1,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log("stepref:", this.props.obj, this.state.current);
    const id = this.props.obj.id;
    const data = {
      ...this.props.obj,
      status: "LO",
    };
    this.props.updateStepReflectionAsync(id, data);
    let step = "";
    let step_2 = null;
    switch (this.state.current) {
      case "1":
        step = "LO";
        console.log("switch case 1");
        break;
      case "2":
        step = "PS";
        console.log("switch case 2");
        break;
      case "3":
        step = "LO";
        step_2 = "PS";
        console.log("switch case 3");
        break;
      default:
        step = "LO";
        break;
    }
    const step_id = this.props.stepReflections[step].id;
    console.log("step id:", step_id, this.props.stepReflections[step]);
    const step_data = {
      ...this.props.stepReflections[step],
      status: "UN",
    };
    this.props.updateStepReflectionAsync(step_id, step_data);
    if (step_2) {
      this.props.updateStepReflectionAsync(
        this.props.stepReflections[step_2].id,
        {
          ...this.props.stepReflections[step_2],
          status: "UN",
        }
      );
    }

    this.setState({
      visible: false,
    });
  };

  render() {
    const icon = <CloseOutlined className="close-icon" />;
    const radioStyle = {
      display: "block",
      height: "30px",
      fontSize: "16px",
    };
    return (
      <>
        <Modal
          title=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width="50%"
          style={{ textAlign: "center", padding: 0 }}
          closeIcon={icon}
        >
          <div>
            <div
              style={{
                background: "#C677E3 0% 0% no-repeat padding-box",
                margin: "-24px",
              }}
            >
              <img
                style={{ width: "100%" }}
                src="https://django-woken-static.s3.amazonaws.com/static/media/thankyou.5dd024a1.svg"
                alt=""
              ></img>
              <p
                style={{
                  font: "normal normal bold 24px/12px AvenirBook",
                  padding: "24px 0 70px 0",
                  color: "white",
                }}
              >
                Your next step is ready!
              </p>
            </div>

            <Row>
              <Col span={2}></Col>
              <Col span={20}>
                <p style={{ margin: "60px 0 40px 0", fontSize: "16px" }}>
                  Great job! You’ve found clarity on your career path! Now, tell
                  us how you want to proceed.
                </p>
                <Radio.Group
                  style={{ textAlign: "left", fontSize: "16px" }}
                  defaultValue="1"
                  onChange={(e) => {
                    this.setState({ current: e.target.value });
                  }}
                >
                  <Radio style={radioStyle} value="1">
                    I want to pursue an experiential learning
                  </Radio>
                  <p style={{ paddingLeft: "25px", fontSize: "16px" }}>
                    opportunity to help me pivot into my desired role and
                    industry.
                  </p>
                  <Radio style={radioStyle} value="2">
                    I want to jump right into the job hunt.
                  </Radio>
                  <Radio style={radioStyle} value="3">
                    I want to do both simultaneously.
                  </Radio>
                </Radio.Group>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Button
              type="primary"
              style={{ margin: "70px 0 80px", width: "200px" }}
              size="large"
              onClick={this.handleCancel}
            >
              Submit
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  stepReflections: selectStepReflections(state),
});

const mapDispatchToProps = () => ({
  updateStepReflectionAsync,
});

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(Skipstep);
