import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  Select,
  Tag,
  Form,
  Popconfirm,
  message,
  Button,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import HELPERS from "utils/helper";
import {
  selectAssessment,
  fetchAssessmentAsync,
  updateAssessmentAsync,
} from "slices/assessmentSlice";
import {
  selectAllRoles,
  addRoleAsync,
  updateRoleAsync,
} from "slices/targetRolesSlice";

import Sorry from "Components/Modals/sorry";


function tagRender(props) {
  const { label, closable, onClose } = props;

  return (
    <Tag
      color={"white"}
      closable={closable}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "14px 5px",
        padding: "2px 15px",
        backgroundColor: "#8375E8",
      }}
    >
      {label}
    </Tag>
  );
}

class PotentialRoles extends React.Component {
  formRef = React.createRef();

  state = {
    roles_set: null,
    showSorry: false
  };

  onUpdate = (values, allVal) => {
    HELPERS.log(
      "values",
      values,
      Object.values(values)[0][0],
      allVal,
      this.props.roles
    );
    if (Object.values(values)[0][0] !== "") {
      const allRoles = [...this.props.allRoles];
      const roles = [...Object.values(values)[0]];
      const addTo = parseInt(Object.keys(values)[0]);
      let index = -1;
      

      HELPERS.log(
        this.props.roles.selectedRoles[addTo]
          ? this.props.roles.selectedRoles[addTo].length
          : "new entry",
        roles.length
      );

      if (
        this.props.roles.selectedRoles[addTo] &&
        this.props.roles.selectedRoles[addTo].length > roles.length
      ) {
        // User deleted something
        for (var role of this.props.roles.selectedRoles[addTo]) {
          index = this.props.roles.indexes[role];

          HELPERS.log("checking", addTo, allRoles[index]);

          if (roles.indexOf(role) === -1) {
            var selected_for = [...allRoles[index].selected_for];

            selected_for.splice(selected_for.indexOf(addTo), 1);

            allRoles[index].selected_for = selected_for;

            HELPERS.log("After remove", allRoles[index]);

            this.props.updateRoleAsync(
              allRoles[index].id,
              { selected_for },
              "TR"
            );

            // assuming only one can be removed at a time
            break;
          }
        }
        message.success('Your work has been saved');
      } else {
        // User added something
        // assuming the newly added role is at the end of the list
        if(this.props?.roles?.selectedRoles[addTo]?.length >= 7){
          this.setState({showSorry: true})
        }
        index = this.props.roles.indexes[roles[roles.length - 1]];
        let selected_for = [];

        if (!isNaN(index)) {
          selected_for = [...allRoles[index].selected_for];
        }

        if (selected_for.indexOf(addTo) === -1) {
          selected_for.push(addTo);
          if (!isNaN(index)) {
            allRoles[index].selected_for = selected_for;
            HELPERS.log("After add", allRoles[index]);
          }

          const temp = isNaN(index)
            ? roles[roles.length - 1]
            : allRoles[index].value;

          if (!this.state.roles_set.has(temp)) {
            let temp_set = new Set(this.state.roles_set);
            console.log("should post", this.state.roles_set);
            temp_set.add(temp);

            if (isNaN(index) || allRoles[index].id <= 0) {
              console.log("posts");
              this.props.addRoleAsync(
                {
                  title: isNaN(index)
                    ? roles[roles.length - 1]
                    : allRoles[index].value,
                  selected_for,
                  priority: 0,
                  learn_more: false,
                  research_questions: [],
                  additional_questions: [],
                  reflections: [],
                },
                "TR"
              );
            }
            this.setState({
              roles_set: temp_set,
            });
          } else {
            console.log("last else");
            this.props.updateRoleAsync(
              allRoles[index].id,
              { selected_for },
              "TR"
            );
          }
        }
      }
    }
  };

  componentDidUpdate() {
    if (this.props.roles && this.props.roles.selectedRoles) {
      this.formRef.current.setFieldsValue(this.props.roles.selectedRoles);
    }
  }

  componentDidMount = () => {
    console.log("did mount", this.props.roles, this.props.roles.selectedRoles);
    let allroles = Object.values(this.props.roles.selectedRoles).flat();
    let set1 = new Set(allroles);
    console.log(
      "roles from start",
      this.props.roles.selectedRoles,
      Object.values(this.props.roles.selectedRoles).flat(),
      set1
    );
    this.setState({
      roles_set: set1,
    });
  };

  onDeleteRes = (key, value, ind) => {
    console.log("del key, ind", key, ind, value);
    console.log(this.props.assessment_data);
    let resp_data = {};
    if (this.props.assessment_data.environment.tr_exceptions) {
      resp_data = { ...this.props.assessment_data.environment.tr_exceptions };
      console.log("resp data", resp_data);
    } else {
      resp_data = {};
    }

    resp_data[[key, ind]] = 1;

    let patch_data = {
      environment: {
        ...this.props.assessment_data.environment,
        tr_exceptions: { ...resp_data },
      },
    };
    console.log("data to be patched", patch_data);
    this.props.updateAssessmentAsync(this.props.assessment_data.id, patch_data);

    // if(parseInt(key)>50){
    //   let resp_data={environment:{...this.props.assessment_data.environment}}
    //   if (ind!==-1){
    //     let updated_data={...this.props.assessment_data.environment[key]}
    //     updated_data[ind]=''
    //     // resp_data={experiences:{...this.props.assessment_data.experiences, ...updated_data}}
    //     resp_data.environment[key]=updated_data
    //     console.log('response data of arr', updated_data, resp_data)
    //   }
    //   else{
    //     resp_data.environment[key]=''
    //     console.log('response data', resp_data)
    //   }
    //     this.props.updateAssessmentAsync(this.props.assessment_data.id, resp_data)
    // }

    // else{
    //   let resp_data={experiences:{...this.props.assessment_data.experiences}}
    //   if (ind!==-1){
    //     let updated_data={...this.props.assessment_data.experiences[key]}
    //     updated_data[ind]=''
    //     // resp_data={experiences:{...this.props.assessment_data.experiences, ...updated_data}}
    //     resp_data.experiences[key]=updated_data
    //     console.log('response data of arr', updated_data, resp_data)
    //   }
    //   else{
    //     resp_data.experiences[key]=''
    //     console.log('response data', resp_data)
    //   }
    //     this.props.updateAssessmentAsync(this.props.assessment_data.id, resp_data)
    //   }

    message.success("Response has been deleted!");
  };

  onFinish = () => {
    // console.log('props', this.props)
    this.props.next();
  };
  onFinishFailed = () => {
    message.error("Please fill all the required fields first");
  };

  render() {
    console.log("state", this.state);
    const exception_keys = this.props.assessment_data
      ? this.props.assessment_data.environment.tr_exceptions
        ? this.props.assessment_data.environment.tr_exceptions
        : {}
      : {};
    return (
      <div>
        <p
          style={{
            color: "#199094",
            font: "normal normal 600 18px/22px AvenirBook",
          }}
        >
          For each of the things you love doing, select one or more job titles
          that you think could involve these activities.
        </p>
        <Sorry
          message={"Try to keep your tags to a maximum of 7 roles per reflection. Remember, nothing is set in stone, but tagging the top most relevant choices is the most helpful at this step. You can edit or adjust your target roles throughout the rest of the process."}
          visible={this.state.showSorry} onClose={() => this.setState({showSorry:false})}/>
        <Row>
          <Col
            span={12}
            style={{
              font: "normal normal bold 16px/44px AvenirBook",
              color: "#111111",
            }}
          >
            <span></span>
          </Col>
          <Col span={1}></Col>
          <Col
            span={11}
            style={{
              font: "normal normal bold 17px/44px AvenirBook",
              color: "#111111",
            }}
          >
            <span>What role titles do these functions translate to:</span>
          </Col>
        </Row>

        <Form
          ref={this.formRef}
          name="prioritize"
          onValuesChange={this.onUpdate}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          {this.props.allQuestions &&
            this.props.allQuestions.map((item, index) => (
              <Row
                style={
                  !exception_keys[[item.key, item.ind]] &&
                  item.value &&
                  item.value.replace(/\r\n|\n|\r/gm, "").replace(/\s+/g, "")
                    ? { padding: "40px 0" }
                    : { display: "none" }
                }
                className="assessment-row"
                key={`potential-role-q-${index}`}
              >
                <Col span={12}>
                  <Card className="left-content">
                    {/* {this.props.allQuestions[index]} */}
                    {item.value}
                  </Card>
                </Col>
                <Col span={1}></Col>
                <Col span={10}>
                  <Form.Item
                    name={index}
                    rules={
                      !exception_keys[[item.key, item.ind]] &&
                      item.value &&
                      item.value.replace(/\r\n|\n|\r/gm, "").replace(/\s+/g, "")
                        ? [{ required: true, message: "Required Field" }]
                        : []
                    }
                  >
                    <Select
                      mode="tags"
                      showArrow
                      tagRender={tagRender}
                      key={index}
                      placeholder="Select Role"
                      style={{ width: "100%" }}
                      options={this.props.allRoles}
                      size="large"
                      onDeselect={this.onDeselect}
                    >
                      {[]}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  {item.key !== 9999 && (
                    <Popconfirm
                      title="Are you sure you want to delete?"
                      onConfirm={() => {
                        this.onDeleteRes(item.key, item.value, item.ind);
                      }}
                    >
                      <DeleteOutlined className="del-assessment" />
                    </Popconfirm>
                  )}
                </Col>
              </Row>
            ))}
          <button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ float: "right", width: "20%" }}
            className="button-main"
          >
            Next
          </button>
        </Form>
      </div>
    );
  }
}

class PotentialRolesWrapper extends React.Component {
  state = {
    fetched: false,
    assess_id: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // HELPERS.log("PotentialRolesWrapper", this.props);
    if (!this.state.fetched) {
      // HELPERS.log("Trying to execute fetchAssessmentAsync...");
      this.props.fetchAssessmentAsync();
      // console.log('check id', this.props.assessment)
      this.setState({
        fetched: true,
        assess_id: this.props.assessment ? this.props.assessment.id : "",
      });
    }
  }

  getRoles = () => {
    let res = {
      indexes: {},
      selectedRoles: {},
    };

    if (this.props.allRoles && this.props.allRoles.length > 0) {
      this.props.allRoles.forEach((role, index) => {
        res.indexes[role.value] = index;

        role.selected_for.forEach((index) => {
          if (res.selectedRoles[index]) {
            res.selectedRoles[index].push(role.value);
          } else {
            res.selectedRoles[index] = [role.value];
          }
        });
      });
    }
    return res;
  };

  allQuestions = () => {
    let ALLQ = [];
    if (
      this.props.assessment &&
      this.props.assessment.experiences &&
      this.props.assessment.environment
    ) {
      let exact_data = {
        ...this.props.assessment.experiences,
        74: this.props.assessment.environment["74"],
        75: this.props.assessment.environment["75"],
        76: this.props.assessment.environment["76"],
        79: this.props.assessment.environment["79"],
      };
      // console.log('exact data', exact_data)
      const allowed = ["7", "32", "32_added", "30", "30_added", "33c"];
      // const allowed_ex = ["74", "75", "76", "79"]
      const filtered = Object.keys(exact_data)
        .filter(
          (key) => !allowed.includes(key)
          // || allowed_ex.includes(key)
        )
        .reduce((obj, key) => {
          obj[key] = exact_data[key];
          return obj;
        }, {});
      // console.log('filtered', filtered)
      const filtered_keys = Object.keys(filtered);
      // console.log('-------------------------------------------------------------------------------------------------')
      filtered_keys.map((item, index) => {
        if (typeof filtered[item] === "string") {
          // console.log('mapped into string',typeof filtered[item],filtered[item], 'onChangekey', item)
          ALLQ.push({
            key: item,
            value: filtered[item],
            ind: -1,
          });
        } else {
          // console.log('map it further', Array.isArray(filtered[item]), filtered[item], 'onChangekey', item,'++++++++++++++++++++++++++++++++++')
          if (Array.isArray(filtered[item])) {
            filtered[item].map((elem, index) => {
              // console.log('single elem',elem,'onChangeIndex',index, item)
              ALLQ.push({
                key: item,
                value: elem,
                ind: index,
              });
            });
          } else {
            filtered[item] &&
              Object.keys(filtered[item]).map((elem, index) => {
                // console.log('single elem',elem,'onChangeIndex',index, item)
                ALLQ.push({
                  key: item,
                  value: filtered[item][elem],
                  ind: elem,
                });
              });
          }
        }
      });
      // console.log('what it was', ALLQ)
      if (ALLQ[3]) {
        if (ALLQ[3].value === "Experience 1") {
          ALLQ[0] = ALLQ[0];
        } else {
          if (ALLQ[3].value === "Experience 2") {
            ALLQ[0] = ALLQ[1];
          } else if (ALLQ[3].value === "Experience 3") {
            ALLQ[0] = ALLQ[2];
          }
        }
        ALLQ.splice(1, 3);
      }
      // console.log('what I want', ALLQ)
      ALLQ.push({
        key: 9999,
        value:
          "When looking at your list of reflections, do you see any trends that might be reflected in a potential role?",
        ind: 9999,
      });
    }
    return ALLQ;
  };

  render() {
    return (
      <PotentialRoles
        allQuestions={this.allQuestions()}
        roles={this.getRoles()}
        allRoles={this.props.allRoles}
        updateRoleAsync={this.props.updateRoleAsync}
        addRoleAsync={this.props.addRoleAsync}
        assessment_data={this.props.assessment}
        updateAssessmentAsync={this.props.updateAssessmentAsync}
        next={this.props.next}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  assessment: selectAssessment(state),
  allRoles: selectAllRoles(state),
});

const mapDispatchToProps = () => ({
  fetchAssessmentAsync,
  updateAssessmentAsync,
  addRoleAsync,
  updateRoleAsync,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(PotentialRolesWrapper);