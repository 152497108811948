import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { Card, Row, Col, Steps } from "antd";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import "./JobSearchbase.css";
import DueDate from "../Common/DueDate";
import Stepinfo from "../Modals/Stepinfo";
import InterviewPrep from "./InterviewPrep/InterviewPrep";
import InterviewResearch from "./InterviewResearch/InterviewResearch";
import Trackerbase from "./Tracker/Trackerbase";
import {
  selectJobSearchStepInfo,
  setStepNotifiedAsync,
  selectStepReflections,
} from "slices/dashboardSlice";
const { Step } = Steps;

class JobSearchBase extends React.Component {
  state = {
    current: this.props.current_step ? this.props.current_step : 0,
    collapsed: false, //menu collapse
    showInfo: null, //show/hide infobox
    setTarget: null, //show/hide duedate component
    curr_comp_target_date: {
      0: "",
      1: "",
      2: "",
      3: "",
      4: "",
    }, //date coming from Duedate.js
  };

  // checks if step is locked or not
  isLocked = (step) =>
    this.props.stepReflections[step]
      ? this.props.stepReflections[step].status === "LO"
      : false;

  //opens set date box (pink colored) if date in header is clicked
  setDateAgain = () => {
    // console.log('set!');
    this.setState({
      setTarget: true,
    });
  };

  //close button handling
  onCloseClick = () => {
    this.setState({
      setTarget: false,
    });
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.props.toggle();
  };

  changeRoute = () => {
    this.props.history.push("/dashboard");
  };

  notifyStep = () => {
    if (
      this.props.stepInfo[this.state.current] &&
      !this.props.stepInfo[this.state.current].notified &&
      this.props.impersonated
    ) {
      this.props.setStepNotifiedAsync(
        this.props.stepInfo[this.state.current].id,
        this.props.stepInfo[this.state.current].step
      );
    }
  };

  onDateSet = () => {
    // setTimeout(() => {
    console.log("DONE !");
    this.onCloseClick();
    // }, 6000);
  };

  newReleased = () =>
    this.props.stepInfo[this.state.current] &&
    !this.props.stepInfo[this.state.current].notified;

  showInfo = () =>
    this.state.showInfo === true ||
    (this.state.showInfo === null && this.newReleased());

  targetSet = () =>
    this.state.setTarget === true ||
    (this.state.setTarget === null &&
      !!this.props.stepInfo[this.state.current] &&
      this.props.stepInfo[this.state.current].complete_by === null && this.props.stepInfo[this.state.current].status != 'DO');

  stepId = () =>
    !!this.props.stepInfo[this.state.current]
      ? this.props.stepInfo[this.state.current].id
      : -1;

  render() {
    const steps = [
      {
        title: "Application Tracker",
        content: <Trackerbase />,
      },
      {
        title: "Interview Research",
        content: <InterviewResearch />,
      },
      {
        title: "Behavioral Interview Prep",
        content: <InterviewPrep stepId={this.stepId()} />,
      },
    ];

    return (
      <div style={{ padding: "4% 0" }}>
        <div className="collapse-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? (
            <RightCircleFilled className="make-white" />
          ) : (
            <LeftCircleFilled className="make-white" />
          )}
        </div>

        <Card
          bordered={false}
          style={{
            margin: "20px !important",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "13px",
            textAlign: "left",
            marginRight: "6%",
            marginLeft: "20px",
            padding: "0 2%",
          }}
        >
          <div>
            <Row style={{ padding: "4% 0% 3% 0%" }}>
              <Col
                span={18}
                style={{
                  font: "normal normal 300 30px/12px AvenirBook",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {steps[this.state.current].title}&nbsp;
                <span
                  className={`just-released ${
                    this.newReleased() ? "" : "hidden"
                  }`}
                >
                  NEW
                </span>
                {this.props.stepInfo[this.state.current] && (
                  // this.props.stepInfo[this.state.current].complete_by !== null &&
                  <span
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      position: "relative",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={this.setDateAgain}
                  >
                    <img
                      src="https://django-woken-static.s3.amazonaws.com/static/media/calendaricon.c713d4db.svg"
                      style={{ position: "relative", top: "-3px" }}
                    ></img>
                    &nbsp;&nbsp;
                    {this.props.stepInfo[this.state.current].complete_by
                      ? "Due date"
                      : "Set Due Date"}
                    &nbsp;
                    {this.props.stepInfo[this.state.current] &&
                      this.props.stepInfo[this.state.current].complete_by &&
                      Moment(
                        this.props.stepInfo[this.state.current].complete_by
                      ).format("MM-DD-YYYY")}
                  </span>
                )}
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  marginTop: "-20px",
                }}
              >
                <a
                  href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                  target="_blank"
                >
                  <img
                    style={{ padding: "0 10px" }}
                    src="https://django-woken-static.s3.amazonaws.com/static/media/mail.5c666ac1.svg"
                  />
                </a>
                <span
                  onClick={() =>
                    this.setState({
                      showInfo: true,
                    })
                  }
                >
                  <img
                    style={{ cursor: "pointer", marginTop: "5px" }}
                    src="https://django-woken-static.s3.amazonaws.com/static/media/question.74f30bbb.svg"
                  />
                </span>
              </Col>
            </Row>
            <p
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #F0F0F0",
              }}
            ></p>
          </div>

          {this.showInfo() ? (
            <Stepinfo
              stepname={steps[this.state.current].title}
              closeInfo={() => {
                this.notifyStep();
                this.setState({
                  showInfo: false,
                });
              }}
            />
          ) : this.targetSet() ? (
            <div>
              <DueDate
                stepName={steps[this.state.current].title}
                stepKey={this.props.stepInfo[this.state.current].step}
                onDateSet={this.onDateSet}
                onCloseClick={this.onCloseClick}
                stepId={this.props.stepInfo[this.state.current].id}
                targetDate={
                  this.props.stepInfo[this.state.current].complete_by !== null
                    ? Moment(
                        this.props.stepInfo[this.state.current].complete_by
                      )
                    : null
                }
              />
              <div style={{ opacity: "0.4", pointerEvents: "none" }}>
                <Steps
                  current={this.state.current}
                  onChange={(current) => this.setState({ current: current })}
                  className="networking-nav"
                >
                  {/* {steps.map(item => (
                                <Step key={item.title} title={item.title.toUpperCase()} onClick={()=>{<Redirect to='/dashboard'/>}}/>
                            ))} */}
                  <Step
                    key="1"
                    title={steps[0].title}
                    disabled={this.isLocked("AT") ? true : false}
                    onClick={() => {
                      if (!this.isLocked("AT"))
                        this.props.history.push(
                          "/job-search/application-tracker"
                        );
                    }}
                    status={this.state.current === 0 ? "process" : "wait"}
                  />
                  <Step
                    key="2"
                    title={steps[1].title}
                    disabled={this.isLocked("IR") ? true : false}
                    onClick={() => {
                      if (!this.isLocked("IR"))
                        this.props.history.push(
                          "/job-search/interview-research"
                        );
                    }}
                    status={this.state.current === 1 ? "process" : "wait"}
                  />
                  <Step
                    key="3"
                    title={steps[2].title}
                    disabled={this.isLocked("BP") ? true : false}
                    onClick={() => {
                      if (!this.isLocked("BP"))
                        this.props.history.push("/job-search/interview-prep");
                    }}
                    status={this.state.current === 2 ? "process" : "wait"}
                  />
                </Steps>

                {steps[this.state.current].content}
              </div>
            </div>
          ) : (
            <div>
              <Steps
                current={this.state.current}
                onChange={(current) => this.setState({ current: current })}
                className="networking-nav"
              >
                {/* {steps.map(item => (
                                <Step key={item.title} title={item.title.toUpperCase()} />
                            ))} */}
                <Step
                  key="1"
                  title={steps[0].title}
                  disabled={this.isLocked("AT") ? true : false}
                  onClick={() => {
                    if (!this.isLocked("AT"))
                      this.props.history.push(
                        "/job-search/application-tracker"
                      );
                  }}
                  status={this.state.current === 0 ? "process" : "wait"}
                />
                <Step
                  key="2"
                  title={steps[1].title}
                  disabled={this.isLocked("IR") ? true : false}
                  onClick={() => {
                    if (!this.isLocked("IR"))
                      this.props.history.push("/job-search/interview-research");
                  }}
                  status={this.state.current === 1 ? "process" : "wait"}
                />
                <Step
                  key="3"
                  title={steps[2].title}
                  disabled={this.isLocked("BP") ? true : false}
                  onClick={() => {
                    if (!this.isLocked("BP"))
                      this.props.history.push("/job-search/interview-prep");
                  }}
                  status={this.state.current === 2 ? "process" : "wait"}
                />
              </Steps>
              {steps[this.state.current].content}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stepInfo: selectJobSearchStepInfo(state),
  stepReflections: selectStepReflections(state),
});

const mapDispatchToProps = () => ({
  setStepNotifiedAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(JobSearchBase);
